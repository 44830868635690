import { Button, Form, Input, message } from "antd";
import { PageLoader } from "components/page-loader/page-loader";
import { EventsApiService } from "features/events/api/events-api.service";
import { useCreateEventResult } from "features/events/use-cases/create-event-result-code";
import { useEffect, useState } from "react";


interface ResultItem {
  result_code: string;

}

interface Result {
  items: ResultItem[];
}

const ResultsComponent = ({ id }: { id: string }) => {

  const { mutate, isLoading } = useCreateEventResult();
  const formName = "result-url-form"

  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);

  const [result, setResult] = useState<Result>({ items: [] });

  const [resultLoading, setResultLoading] = useState<boolean>(false);

  const getResult = async () => {
    setResultLoading(true);
    try {
      const apires = await EventsApiService().getResultCode(id as any)
      setResult(apires as Result)
      setResultLoading(false);
    } catch (error) {
      message.error('')
    }
  }

  useEffect(() => {
    getResult();
  }, [])

  useEffect(() => {

    form.setFieldsValue({
      Result: result.items[0]?.result_code
    });

  }, [result]);

  const [form] = Form.useForm();


  const handleSave = (values: any) => {
    const formData = {
      event_id: id,
      result_value: values.Result,
      flag: "UPDATE_RESULT_CODE "
    };
    mutate(formData,);
  };

  if (resultLoading) return <PageLoader />

  return (

    <>
      <div className="rslt_wrap">
        <Form
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          // initialValues={getInitialValues}
          onFinish={handleSave}
          // requiredMark={'optional'}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}

        >

          <div className="rslt_l">
            <Form.Item
              // label={"Result"}
              name="Result"
              className="result_form"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                placeholder={"Enter Result"}
              />

            </Form.Item>

            <div className='button-group'>
              <Button className={"btn_common"} htmlType='submit' loading={isLoading} >Save</Button>
            </div>

          </div>
        </Form>
      </div>
    </>
  )
};

export default ResultsComponent;
