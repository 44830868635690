import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ContentTopBar } from 'components';
import { useGetTrackList } from 'features/events/use-cases/get-track-list';
import { usePermissions } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useEffect, useState } from 'react'

import { TrackListTable } from './track-list-tabel';
import { AddTrackListModal } from './tracklist-add-modal/add-tracklist-modal';



const TrackListComponent = ({ id, tab }: { id: string; tab: string }) => {

  const permissions = usePermissions();

  const { params, updateParams } =
    usePaginationParams<any>({
      page: 1,
      size: 10,
      order_by: '-rank',
      event_id: id,
    });

  const { data, isFetching, refetch } = useGetTrackList(params);
  const shouldHandleFieldCreation = permissions?.fields.add;
  const [showAddEventModal, setShowAddEventModal] = useState<boolean>(false);
  const [trackdata, setTrackdata] = useState(undefined)

  const handleCloseModal = () => {
    setShowAddEventModal(false);
    document.body.style.overflow = 'visible';
  }

  useEffect(() => {
    refetch()
  }, [])

  const handleOpenModal = () => {
    setShowAddEventModal(true);
    setTrackdata(undefined)
    document.body.style.overflow = 'hidden';
  }

  useEffect(() => {
    if (tab === "5") {
      refetch();
    }
  }, [tab]);

  return (
    <>
      <ContentTopBar
        title={''}
        renderButtons={
          <>
            {shouldHandleFieldCreation ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <Button
                  icon={<PlusOutlined />}
                  onClick={handleOpenModal}
                >
                  Add Track
                </Button>
                <div className={`cstmrightmdl ${showAddEventModal && "show"}`}>
                  <AddTrackListModal id={id} showAddEventModal={showAddEventModal} trackdata={trackdata} refetch={refetch} onClose={handleCloseModal} />
                </div>

              </div>
            ) : null}
          </>
        }
      />

      <TrackListTable
        id={id}
        data={data}
        isLoading={isFetching}
        tableParams={params}
        updateParams={updateParams}
        refetch={refetch}
        setTrackdata={setTrackdata}
        trackdata={trackdata}
      />
    </>
  )
}

export default TrackListComponent;
