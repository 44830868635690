import { Button, Form, Grid, Input } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ColorFilterFormField, ColorFilterFormValues } from '../type';


const { useBreakpoint } = Grid;

type EventsFilterFormProps = {
  formId: string;
  handleFilter: (values: ColorFilterFormValues) => void;
  initialValues: ColorFilterFormValues;
  closeModal?: () => void;
};

export const ColorFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: EventsFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<ColorFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
    handleFilter({...initialValues})
  };

  const handleOnFinish = (values: ColorFilterFormValues) => {
    handleFilter(values as any);
    closeModal?.();
  };

  return (
    <Form<ColorFilterFormValues>
      id={formId}
      name={formId}
      form={form}
      layout='vertical'
      onFinish={handleOnFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper style={{width:'100%'}}>
        <StandardItemsWrapper style={{width:'100%'}}>
          <div className='invoice_list' style={{width:'100%'}}>
          <Form.Item<ColorFilterFormValues>
            label={"Color Type"}
            name={ColorFilterFormField.type}
          >
            <Input placeholder={'Enter color Type'} />
          </Form.Item>
          {screens.lg && (
        <ContentFilters.ButtonsWrapper className='filter_btn'>
          <Button type='primary' htmlType='submit'>
            {t('events.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{t('events.buttons.reset')}</Button>
        </ContentFilters.ButtonsWrapper>
      )}
          </div>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>
  
    </Form>
  );
};
