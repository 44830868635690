import { apiClient, ApiClientConfig } from "api";
export type CraeteTitleCmsrDTO = {
  title: string,
  is_active: boolean,
}
export const NotificationApiService = () => {
  const getNotificationType = async (
    params: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/announcement/type`,
      params,
    });

    return response.data;
  };
  const getNotificationList = async (
    params: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/announcement/list`,
      params,
    });

    return response.data;
  };
  const createNotificationType = async (payload: CraeteTitleCmsrDTO) => {
    const response = await apiClient({
      endpoint: `/admin/announcement/type`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const createNotificationList = async (payload: CraeteTitleCmsrDTO) => {
    const response = await apiClient({
      endpoint: `/admin/announcement/list`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const updateStatus = async (payload: CraeteTitleCmsrDTO) => {
    const response = await apiClient({
      endpoint: `/admin/events/updatestatus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const sendNotification = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/admin/senddevicenotification`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

 

  return {
    getNotificationType,
    getNotificationList,
    updateStatus,
    createNotificationType,
    createNotificationList,
    sendNotification
  };
};
