

export enum CategoryFilterFormField {
  CATEGORY = 'name',
}
export type CategoryFormFieldValue = {
  [CategoryFilterFormField.CATEGORY]: string | undefined;
}
export enum CouponFilterFormField{
  COUPONTITLE='coupon_title',
  ISACTIVE='is_active',
  COUPONCODE='coupon_code'
}
export type CouponFormFieldValue={
  [CouponFilterFormField.COUPONTITLE]:string | undefined
  [CouponFilterFormField.ISACTIVE]:string | undefined
  [CouponFilterFormField.COUPONCODE]:string | undefined

}
export enum ShopItemFormField {
  CATEGORY = 'shop_category_id',
  is_active = "is_active",
  item_name = "name"
}
export type ShopItemFormFieldValue = {
  [ShopItemFormField.CATEGORY]: string | undefined;
  [ShopItemFormField.is_active]: string | undefined;
  [ShopItemFormField.item_name]: string | undefined;
}
export type SocialFilterFormValues = {
  [CategoryFilterFormField.CATEGORY]: string | undefined;
};

export enum itemFilterFormField {
  CATEGORY = "category"
}
export type ItemFilterFormValues = {
  [itemFilterFormField.CATEGORY]: string | undefined;
};