import { ContentFilters } from 'components';
import { useState } from 'react';
import { CompanyType } from 'types';

import { NotificationListFilterForm } from '../notification-main-filter/notification-filter-form';
import { NotificationFilterFormValues } from '../type';
type VendingTopBarProps = {
  getInitialFilterValues: NotificationFilterFormValues;
  handleFilter: (values: NotificationFilterFormValues) => void;
  refetch: any
};

export const NotificationTopBar = ({
  getInitialFilterValues,
  handleFilter,

}: VendingTopBarProps) => {

  useState<CompanyType>();

  const formId = 'users-filters-form';


  return (
    <>



      <ContentFilters formId={formId}>
        <NotificationListFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
