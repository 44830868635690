
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { CategoryListTable } from '../components/category/list-table';
import { CategoryFormFieldValue } from '../components/shop-filter-form/type';
import { CategoryListTopBar } from '../components/top-bar/list-top-bar';
import { useGetCategory } from '../use-cases/get-category';
const getInitialFilterValues: any = {
  name: undefined,
};
export const CategoryListView = () => {
  const { params, updateParams, resetPage } = usePaginationParams<any>({
    page: 1,
    size: 10,
    ...getInitialFilterValues,
  });

  const { data, isFetching, refetch } = useGetCategory(params, {
    retry: false,
  });

  const [categoryEditData, setCategoryData] = useState<any>(undefined);

  const handleFiltersChange = (values: CategoryFormFieldValue) => {
    updateParams(values);
    resetPage();
    refetch()
  };

  return (
    <>
      <CategoryListTopBar
        handleFilter={handleFiltersChange}
        getInitialFilterValues={getInitialFilterValues}
      />
      <CategoryListTable
        isLoading={isFetching}
        data={data}
        itemsCount={'' as any}
        tableParams={params}
        updateParams={updateParams}
        refetch={refetch}
        categoryEditData={categoryEditData}
        setCategoryData={setCategoryData}
      />
      <Outlet />
    </>
  );
};
