export enum WalletFormField {
    Id = 'id',
    Email = 'email',
    Name = 'name',
    Description = 'description',
    Amount = 'amount',
    CustomerId = "customer_id",
    FirstName = 'first_name',
    LastName = 'last_name',
    MobileNo = 'mobile_no',
}

export type WalletFormValues = {
    [WalletFormField.Id]: string;
    [WalletFormField.Email]: string;
    [WalletFormField.Name]: string;
    [WalletFormField.Description]: string;
    [WalletFormField.Amount]: number;
    [WalletFormField.CustomerId]: string;
    [WalletFormField.FirstName]: string;
    [WalletFormField.LastName]: string;
    [WalletFormField.MobileNo]: string;

};
