import { PaginatedRequestParams } from 'api';
import { MainFiltersParams } from 'components/main-filters/main-filters';

export enum EventsFilterFormField {
  FieldName = 'name',
}

export type CmsSportFilterFormValues = {
  [EventsFilterFormField.FieldName]: string | undefined;
};

export type cmsFilterFormPaginated = CmsSportFilterFormValues &
  MainFiltersParams &
  PaginatedRequestParams;
