import { t } from 'i18next';
import { SportType } from 'types';
import { Event, EventCategory } from 'types/event';
const MAX_PLAYERS_CAP = 24;

export const maxPlayersValues = Array.from(
  { length: MAX_PLAYERS_CAP / 2 },
  (item, index) => (index + 1) * 2,
);

export const formatMaxPlayersToTeamSize = (maxPlayers: number) => {
  // return `${maxPlayers / 2} - ${maxPlayers / 2}`;
  return `${maxPlayers} - ${maxPlayers}`;
};

export const getTeamSizeOptions = () => {
  return maxPlayersValues.map((item) => ({
    label: formatMaxPlayersToTeamSize(item),
    value: item,
  }));
};

export const timeSlotsOptions = [
  { value: '30', label: '30 min' } as const,
  { value: '60', label: '60 min' } as const,
  { value: '90', label: '90 min' } as const,
  { value: '120', label: '120 min' } as const,
];

export const typeOptions = [
  {
    value: EventCategory.Indoor,
    label: t('events.formEvents.categoriesType.indoor'),
  } as const,
  {
    value: EventCategory.Outdoor,
    label: t('events.formEvents.categoriesType.outdoor'),
  } as const,
];

export const sportTypeOptions = [
  {
    value: SportType.courtBased,
    label: t('events.formEvents.sportType.courtBased'),
  } as const,
  {
    value: SportType.laneBased,
    label: t('events.formEvents.sportType.laneBased'),
  } as const,
];

export const eventsFilterOptionsMap = (events?: Event[]) => {
  if (!events) return [];

  const eventsOptions = events.map((field) => ({
    label: field.name,
    value: field.id,
  }));

  return [...eventsOptions];
};


export const validateAmount = (_: any, value: string) => {
  const lngPattern = /^\d*\.?\d{0,3}$/;
  if (!value || lngPattern.test(value)) {

    return Promise.resolve();
  }

  return Promise.reject(new Error('Please enter a valid numeric value with at most three decimal places'));
};

export const isArabicCharacter = (char: string) => {
  const arabicRegex = /[\u0600-\u06FF]/;

  return arabicRegex.test(char);
};

export const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const char = String.fromCharCode(event.charCode);
  if (!isArabicCharacter(char)) {
    event.preventDefault();
  }
};

export const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
  const pasteData = event.clipboardData.getData('text');
  if (!Array.from(pasteData).every(isArabicCharacter)) {
    event.preventDefault();
  }
};


export const handleKeyAmount = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const input = event.currentTarget as HTMLInputElement;
  const currentValue = input.value;
  const selectionStart = input.selectionStart ?? 0; // Default to 0 if null or undefined
  const selectionEnd = input.selectionEnd ?? 0; // Default to 0 if null or undefined

  // Allow backspace if there's a selection or if it's not at the beginning
  if (event.key === 'Backspace') {

    return;
  }

  // Allow only numbers and a decimal point
  if (!/[0-9.]/.test(event.key)) {
    event.preventDefault();

    return;
  }

  // Check if the current input already has a decimal point
  const dotIndex = currentValue.indexOf('.');
  const hasDecimal = dotIndex !== -1;

  // Split the value into integer and decimal parts
  const [integerPart, decimalPart] = currentValue.split('.');

  // Prevent entering more than 4 digits before the decimal point
  if (!hasDecimal && integerPart.length >= 4 && selectionStart <= dotIndex) {
    event.preventDefault();

    return;
  }

  // Prevent entering more than 3 digits after the decimal point
  if (hasDecimal && selectionStart > dotIndex && decimalPart?.length >= 3) {
    event.preventDefault();

    return;
  }

  // Prevent entering a second decimal point
  if (event.key === '.' && hasDecimal) {
    event.preventDefault();

    return;
  }

  // Prevent entering more than 4 digits before the decimal if selection is at the end
  if (integerPart.length >= 4 && !hasDecimal && event.key !== '.' && selectionStart === selectionEnd) {
    event.preventDefault();

    return;
  }
};

export const handleKeyDownThree = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const input = event.currentTarget as HTMLInputElement;
  const currentValue = input.value;
  const selectionStart = input.selectionStart ?? 0; // Default to 0 if null or undefined
  const selectionEnd = input.selectionEnd ?? 0; // Default to 0 if null or undefined

  // Allow backspace if there's a selection or if it's not at the beginning
  if (event.key === 'Backspace') {
    if (selectionStart !== selectionEnd || selectionStart > 0) {

      return; // Allow backspace when there's text selected or cursor is not at the beginning
    }
  }

  // Check if the input exceeds 3 digits and not selecting anything
  if (currentValue.length >= 3 && selectionStart === selectionEnd) {
    event.preventDefault();

    return;
  }

  // Allow only numbers (0-9)
  if (!(event.key === 'Backspace' || /[0-9]/.test(event.key))) {
    event.preventDefault();
  }
};



export const handleKeyPercent = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const input = event.currentTarget as HTMLInputElement;
  const currentValue = input.value;
  const selectionStart = input.selectionStart ?? 0; // Default to 0 if null or undefined
  const selectionEnd = input.selectionEnd ?? 0; // Default to 0 if null or undefined

  // Allow backspace if there's a selection or if it's not at the beginning
  if (event.key === 'Backspace') {

    return;
  }

  // Allow only numbers and a decimal point
  if (!/[0-9.]/.test(event.key)) {
    event.preventDefault();

    return;
  }

  // Check if the current input already has a decimal point
  const dotIndex = currentValue.indexOf('.');
  const hasDecimal = dotIndex !== -1;

  // Split the value into integer and decimal parts
  const [integerPart, decimalPart] = currentValue.split('.');

  // Prevent entering more than 4 digits before the decimal point
  if (!hasDecimal && integerPart.length >= 2 && selectionStart <= dotIndex) {
    event.preventDefault();

    return;
  }

  // Prevent entering more than 3 digits after the decimal point
  if (hasDecimal && selectionStart > dotIndex && decimalPart?.length >= 3) {
    event.preventDefault();

    return;
  }

  // Prevent entering a second decimal point
  if (event.key === '.' && hasDecimal) {
    event.preventDefault();

    return;
  }

  // Prevent entering more than 4 digits before the decimal if selection is at the end
  if (integerPart.length >= 2 && !hasDecimal && event.key !== '.' && selectionStart === selectionEnd) {
    event.preventDefault();

    return;
  }
};





