
import { ItemSalesFilterFormValues, ItemSalesFilterFormValuesPaginated } from 'features/users/components/users-filter-form/types';
import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ItemSalesTable } from '../component/item-sales-table/items-sales-table';
import { ItemSalesTopBar } from '../component/top-bar/item-sales-bar';
import { useGetItemSalesList } from '../use-cases/get-items-sales';

const getInitialFilterValues: ItemSalesFilterFormValues = {
    invoice_no: undefined,
    name: undefined,
    phone_number: undefined,
    email_address: undefined,
    payment_method: undefined,
    payment_status: undefined,
    coupon_applied: undefined,
    item_delivery: undefined,
    order_from: undefined,
    order_to: undefined,
};



export const ItemSalesView = () => {

    const { params, updateParams, resetPage } = usePaginationParams<any>({
        page: 1,
        size: 10,
        ...getInitialFilterValues,
    });

    const { data, refetch, isFetching } = useGetItemSalesList(params, { retry: false });

    const handleFiltersChange = (values: ItemSalesFilterFormValuesPaginated) => {
        updateParams(values);
        resetPage();
        refetch();
    };


    return (

        <>
            <ItemSalesTopBar
                handleFilter={handleFiltersChange}
                getInitialFilterValues={getInitialFilterValues}
            />
            <ItemSalesTable
                isSocialListLoading={isFetching}
                DeliveryList={data}
                tableParams={params as any}
                updateParams={updateParams}
                refetch={refetch}
            />
            <Outlet />
        </>
    );
};
