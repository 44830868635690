import { FullScreenLoader } from 'components';
import { UserDataFetchFailedEvent, useSessionContext } from 'features/auth';
import { useUserContext } from 'features/users';
import { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { ROUTES } from './constants';

export const AuthenticatedRoute = ({
  children,
}: {
  children: ReactElement;
}) => {
  const { isLoggedIn } = useSessionContext();
  const { status } = useUserContext();
  const location = useLocation();
  if (!isLoggedIn) {
    return <Navigate to={ROUTES.LOGIN} replace state={{ from: location }} />;
  }

  if (status === 'loading') {
    return <FullScreenLoader />;
  }

  if (status === 'error') {
    window.dispatchEvent(new UserDataFetchFailedEvent());
  }

  if (status === 'success') {
    return children;
  }

  return null;
};
