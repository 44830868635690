import { Button, Checkbox, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { useGetBoothDataById } from "features/events/use-cases/get-booth-data-by-id";
import { useUpdateBoothDetails } from "features/events/use-cases/update-booth-details";
import { handleKeyAmount } from "features/events/utils";
import { useEffect, useState } from "react";


interface props {
    onClose: () => void,
    isOpen: boolean;
    editData: any,
    id: string,
}

export const SvgLayoutModal = ({ onClose, isOpen, editData, id }: props) => {
    const [form] = useForm<any>();

    const formName = 'add-single-booth-form';

    const [boothPrice, setBoothPrice] = useState<string>("");
    const [available, setAvailable] = useState<boolean>(true);
    const [isHidden, setIsHidden] = useState<boolean>(false);
    const [zoneName, setZoneName] = useState<string>("");

    const params = {
        event_id: id,
        booth_id: editData?.id,
    }

    const { data: apiBoothData, refetch } = useGetBoothDataById(params, { enabled: false });


    useEffect(() => {
        if (isOpen) {
            refetch();
        }

    }, [isOpen])

    useEffect(() => {
        if (apiBoothData) {
            form.setFieldsValue({
                zoneName: apiBoothData?.zone,
                Price: apiBoothData?.price,
                isAvailable: apiBoothData?.is_available,
                isHidden: apiBoothData?.is_hidden
            });

            setZoneName(apiBoothData?.zone);
            setBoothPrice(apiBoothData?.price);
            setAvailable(apiBoothData?.is_available);
            setIsHidden(apiBoothData?.is_hidden);
        }

    }, [apiBoothData])

    const { mutate } = useUpdateBoothDetails();

    const handleSubmit = () => {
        const payload = {
            event_id: id,
            zone: zoneName,
            price: boothPrice,
            booth_id: editData.id,
            is_available: available,
            is_hidden: isHidden,

        };

        mutate(payload, { onSuccess: handleClose });
    }

    const handleClose = () => {
        setZoneName("");
        setBoothPrice("");
        form.resetFields();
        onClose();
    };

    if (!id) return null;

    return (

        <>
            <div className='popup'>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>Set Properties Booth No. {editData?.id}</h4>
                </div>
                <div className='modal_body'>

                    <Form
                        layout="vertical"
                        form={form}
                        name={formName}
                        onFinish={handleSubmit}>
                        <div className="mdl_input">

                            <Form.Item
                                label="Create Zone"
                                name="zoneName"
                            >
                                <Input
                                    onChange={(e) => setZoneName(e.target.value)}
                                    value={zoneName}
                                    placeholder="Please Enter Zone Name"
                                />
                            </Form.Item>

                            <Form.Item
                                label="Price"
                                name="Price"
                                rules={[
                                    {
                                        required: true,
                                        message: " "
                                    }
                                ]}
                            >
                                <Input
                                    onChange={(e) => setBoothPrice(e.target.value)}
                                    value={boothPrice}
                                    onKeyDown={handleKeyAmount}
                                />

                            </Form.Item>
                        </div>


                        <div className="mdl_check">
                            <div className="form-group">
                                <label htmlFor="isAvailable">
                                    <Checkbox
                                        type="checkbox"

                                        id="hidden"
                                        name="hidden"
                                        checked={available}
                                        onChange={(e) => setAvailable(e.target.checked)}
                                    />
                                    Is Available</label>
                            </div>

                            <div className="form-group" >
                                <label htmlFor="isAvailable">
                                    <Checkbox
                                        type="checkbox"

                                        id="hidden"
                                        name="hidden"
                                        checked={isHidden}
                                        onChange={(e) => setIsHidden(e.target.checked)}
                                    />
                                    Is Hidden</label>
                            </div>
                        </div>
                        <div className='button-group' >
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
};

