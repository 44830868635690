import {
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Form, Input, Radio } from 'antd';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import {
  UserFormField,
} from 'features/users/components/users-filter-form';
import { useTranslation } from 'react-i18next';
import { phoneNumberPattern } from 'utils/validations';

const { TextArea } = Input;
// {
//   form
// }: {
//   form: FormInstance<UserFormValues>;
// }
export const UserInfoFormItems = () => {
  const { t } = useTranslation();

  //const genderOptions = useGenderOptions();


  // const [data, loading, refetch] = useGetRole();
  // const [role, setRole] = useState({
  //   items: []
  // })



  // const fetchRole = async () => {
  //   try {
  //     const apiRes = await UserApiService().getRole();
  //     // setRole(apiRes as any)
  //   } catch (error) {
  //     message.error(error as any)
  //   }
  // }

  // useEffect(() => {
  //   fetchRole(); // Call fetchRole function

  // }, [])

  return (
    <FormSectionsSeparator title={""}>
      <Form.Item<UserFormField>
        label={t('adminUsers.formFields.firstName.label')}
        name={UserFormField.FirstName}
        rules={[
          {
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Input
          placeholder={t('adminUsers.formFields.firstName.placeholder')}
          prefix={<UserOutlined className='input-prefix-icon' />}
        />
      </Form.Item>
      <Form.Item<UserFormField>
        label={t('adminUsers.formFields.lastName.label')}
        name={UserFormField.LastName}
        rules={[
          {
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Input
          placeholder={t('adminUsers.formFields.lastName.placeholder')}
          prefix={<UserOutlined className='input-prefix-icon' />}
        />
      </Form.Item>

      <Form.Item<UserFormField>
        label={t('adminUsers.formFields.email.label')}
        name={UserFormField.Email}
        rules={[
          {
            required: false,
            whitespace: true,
          },
          {
            type: 'email',
            message: t('validateMessages.custom.email'),
          },
        ]}
      >
        <Input
          placeholder={t('adminUsers.formFields.email.placeholder')}
          prefix={<MailOutlined className='input-prefix-icon' />}

        />
      </Form.Item>

      <Form.Item<UserFormField>
        label={t('adminUsers.formFields.phoneNumber.label')}
        name={UserFormField.PhoneNumber}
        rules={[
          {
            required: true,
          },
          {
            pattern: phoneNumberPattern,
            message: t('validateMessages.custom.phoneNumber'),
          },
        ]}
      >
        <Input
          type='tel'
          placeholder={t('adminUsers.formFields.phoneNumber.placeholder')}
          prefix={<PhoneOutlined className='input-prefix-icon' />}
          autoComplete="off"
          maxLength={15}
          onKeyPress={(e) => {
            // Check if the entered key is a number, "+", or Backspace (to allow deletion)
            const isNumberOrPlus = /^[0-9+]*$/.test(e.key);
            const isBackspace = e.key === 'Backspace';

            // Check if the total length after the new character insertion will be <= 15
            const inputValue = (e.target as HTMLInputElement).value || '';
            const totalLength = inputValue.length + (isBackspace ? -1 : 1);

            if (!isNumberOrPlus || totalLength > 15) {
              e.preventDefault();
            }
          }}
        />
      </Form.Item>

      <Form.Item<UserFormField>
        label="Address"
        name={UserFormField.Address}
        rules={[
          {
            required: false,
          },
        ]}

      >
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item<UserFormField>
        label="Is Active"
        name={UserFormField.IsActive}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group >
          <Radio value={true} > Yes</Radio>
          <Radio value={false}> No </Radio>
        </Radio.Group>
      </Form.Item>
      {/* 
      <Form.Item<UserFormField>
        label={t('adminUsers.formFields.gender.label')}
        name={UserFormField.Gender}
        rules={[
          {
            required: false,
            whitespace: true,
          },
        ]}
      >
        <Select
          options={genderOptions}
          placeholder={t('adminUsers.formFields.gender.placeholder')}
          className='has-prefix-icon'
          suffixIcon={
            <>
              <SmileOutlined className='prefix-icon' />
              <DownOutlined className='suffix-icon' />
            </>
          }
        />
      </Form.Item>
          */}
    </FormSectionsSeparator>
  );
};
