import { Button, Form, Input, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useCreateList } from 'features/notification/use-cases/create-list';
import { useGetType } from 'features/notification/use-cases/get-type';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationFilterFormField, NotificationFilterFormFieldList, NotificationFilterFormValueslist } from '../type';

export const AddNotiModalList = ({ onClose, refetch, EditData }: { onClose: () => void; showModal: boolean, refetch: () => void, EditData: any }) => {

    const formName = 'add-shop-coupon-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<NotificationFilterFormValueslist>();
    const { isLoading, mutate } = useCreateList();
    const { data } = useGetType('' as any, { retry: false });

    const handleClose = () => {
        onClose();
        form.resetFields();
    }

    const { t } = useTranslation();
    // const getInitialValues = {
    //     [NotificationFilterFormFieldList.TITLE]: undefined,
    //     [NotificationFilterFormFieldList.ANNOUNCEMENTTYPEID]: undefined,
    //     [NotificationFilterFormFieldList.CONTENT]: undefined,
    //     [NotificationFilterFormField.is_active]: true,
    // };
    const handleUpdate = (formData: any) => {
        formData[NotificationFilterFormField.ID] = EditData?.id;
        mutate(formData, { onSuccess: hanldeSuccess });
    };

    const hanldeSuccess = () => {
        handleClose();
        refetch();
    }
    useEffect(() => {
        if (EditData) {
            form.setFieldsValue(EditData)
        } else {
            form.resetFields()
        }
    }, [EditData])

    return (
        <>
            <div className='popup'>
                <div className='modal_head'>
                    <h4> {EditData ? 'Edit List' : 'Add List'}</h4>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                </div>
                <div className='modal_body' >
                    <Form<NotificationFilterFormValueslist>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        // initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <FormSectionsSeparator title={""}>
                            <Form.Item<NotificationFilterFormFieldList>
                                label={"Title"}
                                name={NotificationFilterFormFieldList.TITLE}

                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Enter Title"}
                                />
                            </Form.Item>
                            <Form.Item<NotificationFilterFormFieldList>
                                label="Type" name={NotificationFilterFormFieldList.ANNOUNCEMENTTYPEID}>
                                <Select
                                    placeholder="Please select"
                                    style={{ width: "100%" }}
                                    options={data?.items?.map((res: any) => ({
                                        label: res.title,
                                        value: res.id
                                    }))}
                                />
                            </Form.Item>
                            <Form.Item<NotificationFilterFormFieldList>
                                label={'Content'} name={NotificationFilterFormFieldList.CONTENT}>
                                <TextArea />
                            </Form.Item>
                        </FormSectionsSeparator>
                        <div className='form-button'>
                            <Button htmlType='submit' className='btn' type='primary'>Save</Button>
                        </div>
                    </Form>
                </div>
            </div >
        </>
    )
};

