import { LockOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { UpdateUserDTO } from 'features/users/api/user-api.service';
import { UserInfoFormItems } from 'features/users/components/modals/user-info-form-items';
import {
  UserFormField,
  UserFormValues,
} from 'features/users/components/users-filter-form';
import { useCreateUser, useUpdateUser } from 'features/users/use-cases';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PasswordFormItems } from './password-form-items';


export const AddAgentUserModal = ({ onClose, agentUserEditData }: { onClose: () => void, agentUserEditData: any }) => {
  const formName = agentUserEditData ? 'edit-user-form' : 'add-user-form';
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<UserFormValues>();
  const { t } = useTranslation();

  const { isLoading, mutate } = useCreateUser();

  const handleClose = () => {
    onClose();
    form.resetFields();
  }

  const getInitialValues = {
    [UserFormField.FirstName]: undefined,
    [UserFormField.LastName]: undefined,
    [UserFormField.PhoneNumber]: undefined,
    [UserFormField.Company]: undefined,
    [UserFormField.Role]: undefined,
    [UserFormField.Email]: undefined,
    [UserFormField.Address]: undefined,
    [UserFormField.IsActive]: undefined,
  };

  //[UserFormField.Gender]: undefined,
  // export type CreateUserDTO = {
  //   company_id?: string;
  //   email?: string | null;
  //   first_name: string;
  //   gender?: Gender | null;
  //   last_name: string;
  //   password: string;
  //   phone_number: string;
  //   role: string;
  // };

  // const [role, setRole] = useState({
  //   items: []
  // })



  // const fetchRole = async () => {
  //   try {
  //     const apiRes = await UserApiService().getRole();
  //     setRole(apiRes as any)
  //   } catch (error) {
  //     message.error(error as any)
  //   }
  // }

  const { mutate: mutateUpdateUser } =
    useUpdateUser();

  const onSuccess = () => {
    handleClose();
  };

  const handleAgentUserUpdate = (formData: any) => {
    const payload: UpdateUserDTO = {
      id: formData.id,
      company_id: formData.company_id,
      email: formData.email.length ? formData.email : null,
      first_name: formData.first_name,
      gender: formData.gender,
      last_name: formData.last_name,
      password: formData.password,
      phone_number: formData.phone_number,
      role: formData.role,
      is_active: formData.is_active,
      address: formData.address
    };
    mutateUpdateUser(payload, { onSuccess });
  };


  useEffect(() => {

    if (agentUserEditData) {
      form.setFieldsValue(agentUserEditData);
    } else {
      form.resetFields();
    }

  }, [agentUserEditData, form]);


  const handleUpdate = (formData: UserFormValues) => {
    formData.role = 'SuffixAgent';
    if (agentUserEditData) {
      formData.id = agentUserEditData.id;
      handleAgentUserUpdate(formData);
    } else {
      mutate(formData, { onSuccess: handleClose });
    }
  };

  return (
    <>

      <div className={'popup'}>
        <div className='modal_head'>
          <button onClick={handleClose} className='close-btn'>
            &times;
          </button>
          <h4>Add new agent</h4>
        </div>
        <div className='modal_body'>
          <Form<UserFormValues>
            id={formName}
            name={formName}
            form={form}
            layout='vertical'
            initialValues={getInitialValues}
            onFinish={handleUpdate}
            // requiredMark={'optional'}
            onFinishFailed={() => {
              setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
            disabled={isLoading}
          >
            <UserInfoFormItems  />
            {agentUserEditData ? (<Form.Item<UserFormField>
              label={'Change Password'}
              name={UserFormField.Password}
              rules={[
                {
                  required: false,
                  whitespace: true,
                  min: 4,
                },
                {
                  pattern: /(?=.*\d)/,
                  message: t('validateMessages.custom.passwordDigit'),
                },
              ]}
            >
              <Input.Password
                placeholder={t('adminUsers.formFields.password.placeholder')}
                prefix={<LockOutlined className='input-prefix-icon' />}
              />
            </Form.Item>) : (<PasswordFormItems />)}

            <div className='form-button'>
              <Button type="primary" htmlType='submit' className="btn" >Save</Button>
            </div>
          </Form>
        </div>
      </div>

    </>
  );
};
