import { ArrowLeftOutlined } from '@ant-design/icons';
import { Layout as AntLayout } from 'antd';
import IconSiderTrigger from 'assets/icons/logout.svg';
import { Button } from 'components';
import { useNewPermissions, usePermissions } from 'features/users';
import { useWindowSize } from 'hooks';
import { useSideBar } from 'providers/side-bar-provider';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getMenuItems } from './menu-items';
import { SideBarWrapper, StyledMenu } from './side-bar.styles';
import { getActiveKeys } from './utils';


// type userPermission = {
//   user_role:string;
//   role:userRole
// }

export const SideBar = () => {
  const { collapsed, toggleCollapsed } = useSideBar();
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();
  const location = useLocation();
  const permissions = usePermissions();
  const permissionsNew = useNewPermissions();
  const { Sider } = AntLayout;



  useEffect(() => {



    const closeMobileMenu = () => {
      if (isMobile && !collapsed) {
        toggleCollapsed();
      }
    };

    closeMobileMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);



  const activeKeys = useMemo(() => {
    return getActiveKeys(location.pathname);
  }, [location.pathname]);


  // const [openKeys, setOpenKeys] = useState<string[]>([]);

  // const onOpenChange = (keys: string[]) => {
  //   const latestOpenKey = keys.find((key) => !openKeys.includes(key));
  //   if (!items.some((item) => item?.key === latestOpenKey)) {
  //     setOpenKeys(keys);
  //   } else {
  //     setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  //   }
  // };

  const [openKeys, setOpenKeys] = useState<string[]>(() => {
    const savedOpenKeys = localStorage.getItem('openKeys');

    return savedOpenKeys ? JSON.parse(savedOpenKeys) : activeKeys;
  });

  const onOpenChange = (keys: string[]) => {
    setOpenKeys(keys.slice(-1)); // Keep only the latest opened submenu
    localStorage.setItem('openKeys', JSON.stringify(keys.slice(-1)));
  };

  if (!permissions) return null;

  const items = getMenuItems(permissionsNew);


  return (
    <SideBarWrapper className='main_side'>

      <Sider
        id='sideBar'
        data-testid='side-bar'
        breakpoint='lg'
        theme='light'
        collapsible
        collapsed={collapsed}
        collapsedWidth={isMobile ? '0' : '48'}
        width={isMobile ? 260 : 200}
        onCollapse={toggleCollapsed}

        trigger={
          <Button
            type='text'
            aria-controls='sideBar'
            aria-expanded={!collapsed}
            className='side-bar-trigger-button'
          >
            <ArrowLeftOutlined
              className={collapsed ? 'side-bar-trigger-collapsed' : undefined}
              src={IconSiderTrigger}
              alt={collapsed ? t('sideBar.expand') : t('sideBar.collapse')}
              style={{ width: 23, color: "white", fontWeight: 900, fontSize: 25 }}
            />
          </Button>
        }
        className='side-bar-sider'
      >

        <StyledMenu
          selectedKeys={activeKeys}
          defaultOpenKeys={!collapsed ? activeKeys : undefined}
          mode='inline'
          items={items}
          className='side-bar-menu'
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        />
      </Sider>

    </SideBarWrapper>
  );
};
