import { PaginatedRequestParams } from 'api';
import { MainFiltersParams } from 'components/main-filters/main-filters';

export enum TshirtFilterFormField {
  TITLE = 'title',
  ACTIVE="is_active"
}

export type TshirtFilterFormValues = {
  [TshirtFilterFormField.TITLE]: string | undefined;
  [TshirtFilterFormField.ACTIVE]: string | undefined;

};

export type TshirtFilterFormPaginated = TshirtFilterFormValues &
  MainFiltersParams &
  PaginatedRequestParams;
