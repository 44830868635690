export enum CurrencyConversionFormField {
    Id = 'Id',
    CurrencyName = 'CurrencyName',
    ConvertValue = 'ConvertValue',
    ToCurrency = 'ToCurrency',
    IsActive = 'IsActive',
}

export type CurrencyConversion = {
    Id: string | undefined;
    CurrencyName: string;
    ConvertValue: number;
    ToCurrency: string;
    isActive: boolean;
};

export type CurrencyConversionFormValues = {
    [CurrencyConversionFormField.Id]: string | undefined;
    [CurrencyConversionFormField.CurrencyName]: string;
    [CurrencyConversionFormField.ConvertValue]: number;
    [CurrencyConversionFormField.ToCurrency]: string;
    [CurrencyConversionFormField.IsActive]: boolean;
};