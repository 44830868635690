import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Empty, Grid, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
  EventsFilterFormValues
} from 'features/events';
import { useEventActiveStatus } from 'features/events/use-cases/event-active-status';
import { useEventDeleteStatus } from 'features/events/use-cases/event-delete-status';
import { useGetMarketingCode } from 'features/events/use-cases/get-marketing-code';
import { usePaginationParams } from 'hooks';
import { useEffect, useState } from 'react';
import { Event } from 'types/event';

import { AddMarketingCodeModal } from './add-marketing-code-modal/add-marketing-code-modal';

const { useBreakpoint } = Grid;


export const EventMarketingCodeComponent = ({ id, tabs }: { id: any; tabs: string }) => {
  const screens = useBreakpoint();

  const initialFilterValues: EventsFilterFormValues = {
    field_name: undefined,
  };

  const { params, updateParams } =
    usePaginationParams<any>({
      event_id: id,
      page: 1,
      size: 10,
      order_by: '',
      ...initialFilterValues,
    });

  const { data, isLoading, refetch } = useGetMarketingCode(params, { retry: false });
  const [showMarketingModal, setShowMarketingModal] = useState<boolean>(false);
  const [marketData, setMarketData] = useState<any>("");


  const handleCloseModal = () => {
    document.body.style.overflow = 'visible';
    setMarketData("");
    setShowMarketingModal(false);
  }


  const handleOpenModal = (record: any) => {
    document.body.style.overflow = 'hidden';
    setMarketData(record);
    setShowMarketingModal(true);
  }


  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Event> | SorterResult<Event>[],
  ) => {
    updateParams({
      ...params,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'
          : '-name',
    });
  };
  ;



  const { mutate } = useEventDeleteStatus();

  const handleDelete = (record: any) => {
    const deletedFormData = {
      id: record.id,
      status: false,
      flag: "EVENT_MARKETING_DELETE"
    }
    mutate(deletedFormData);
  }

  const { mutate: updateStatusMutate, isLoading: statusLoading } = useEventActiveStatus();

  const handleUpdateStatus = (updateId: any, isChecked: any) => {
    const statusFormData = {
      id: updateId,
      status: isChecked,
      flag: "EVENT_MARKETING_SET_STATUS"
    }
    updateStatusMutate(statusFormData);
  }


  const { confirm } = Modal;

  const showDeleteConfirm = (event: any, record: any) => {
    event.stopPropagation();
    confirm({
      title: 'Are you sure you want to delete this Ticket?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(record);
      },
    });
  };

  useEffect(() => {
    if (tabs == "2") {
      refetch();
    }
  }, [tabs])

  // if (isLoading) return <Loader />;
  // if (!permissions) return null;

  return (

    <>
      <div className={`cstmrightmdl ${showMarketingModal ? "show" : ''}`}>
        <AddMarketingCodeModal eventid={id} onClose={handleCloseModal} showMarketingModal={showMarketingModal} marketData={marketData} refetch={refetch} />
      </div>


      <StyledTableWrapper>
        <StyledTableHeader>
          <div className='tket_head'>
            <RecordCountBadge
              text={"Marketing code found"}
              count={data?.total || 0}
            />


            <Button
              icon={<PlusOutlined />}
              onClick={() => handleOpenModal("")}
              type='primary'
            >
              Add Marketing Code

            </Button>


          </div>

        </StyledTableHeader>
        <Table<Event>
          locale={{
            emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
          }}
          loading={isLoading && { indicator: <div><TableLoader /></div> }}
          dataSource={isLoading ? [] : data?.items}
          rowKey='id'
          onChange={handleTableChange}
          pagination={{
            current: params.page,
            defaultPageSize: params.size,
            total: data?.total,
          }}
          className='event_table cstltblwthldr'

        >
          <Column<Event>
            title={'Header Code'}
            dataIndex={'header_code'}
            sorter={true}
            sortDirections={['ascend', 'descend', 'ascend']}
            defaultSortOrder={'descend'}
            width={screens.xxl ? 'auto' : 100}
            ellipsis


          />

          <Column<Event>
            title={'BodyCode'}
            dataIndex={'body_code'}
            width={screens.xxl ? 'auto' : 200}
            className='cell-direction-rtl'
            ellipsis

          />

          <Column<Event>
            title={'Invoice Code'}
            dataIndex={'invoice_code'}
            width={screens.xxl ? 'auto' : 240}
            ellipsis

          />
          <Column<Event>
            title={'Provider'}
            dataIndex={'provider'}
            width={screens.xxl ? 'auto' : 240}
          />
          <Column<Event>
            title={"Action"}
            width={screens.xxl ? 'auto' : 162}
            dataIndex={'is_active'}
            render={(_, record) => (
              <>
                <Space>
                  <Tooltip title="Edit">
                    <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                  </Tooltip>

                  <Tooltip title="Delete">
                    <Button shape="circle" icon={<DeleteOutlined />} onClick={(event) => showDeleteConfirm(event, record)} />
                  </Tooltip>
                  <Tooltip title="Is Active">
                    <Switch
                      defaultChecked={record.is_active}
                      checked={record.is_active}
                      loading={statusLoading}
                      onChange={(isChecked) => handleUpdateStatus(record.id, isChecked)}
                    />
                  </Tooltip>
                </Space>
              </>
            )}
          />
        </Table>

      </StyledTableWrapper>
    </>
  );
};



