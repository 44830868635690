import { PaginatedRequestParams } from 'api';

export enum VendingFilterFormField {
  ParticipantId = 'participant_id',
  Name = 'full_name',
  Email = 'email_address',
  Category = 'category_name',
  RaceName = 'event_name',
}

export type VendingFilterFormValues = {
  [VendingFilterFormField.ParticipantId]: string | undefined;
  [VendingFilterFormField.Name]: string | undefined;
  [VendingFilterFormField.Email]: string | undefined;
  [VendingFilterFormField.Category]: string | undefined;
  [VendingFilterFormField.RaceName]: string | undefined;
};

export type VendingFilterFormValuesPaginated = VendingFilterFormValues &
  PaginatedRequestParams;





