import { Button, Form, Grid, Input } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CategoryFilterFormField, CategoryFormFieldValue, SocialFilterFormValues } from './type';
const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
  formId: string;
  handleFilter: (values: CategoryFormFieldValue) => void;
  initialValues: CategoryFormFieldValue;
  closeModal?: () => void;
};

export const CategoryFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: UsersFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<CategoryFormFieldValue>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues })
  };

  const onFinish = (formValues: CategoryFormFieldValue) => {
    handleFilter(formValues);
    closeModal?.();
  };

  return (
    <Form<CategoryFormFieldValue>
      id={formId}
      name={formId}
      className='filters-form'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper style={{ width: '100%' }}>
        <StandardItemsWrapper style={{ width: '100%' }}>
          <div className='invoice_list' style={{ width: '100%' }}>
            <Form.Item<SocialFilterFormValues>
              label={t('shops.formfield.fieldName.label')}
              name={CategoryFilterFormField.CATEGORY}
            >
              <Input placeholder={'Enter a Category'} />
            </Form.Item>
            {screens.lg && (
              <ContentFilters.ButtonsWrapper className='filter_btn'>
                <Button type='primary' htmlType='submit'>
                  {t('common.buttons.filter', { count: 1 })}
                </Button>
                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
              </ContentFilters.ButtonsWrapper>
            )}
          </div>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

    </Form>
  );
};
