import { Button, Form, Grid, Input } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { VendingFilterFormField, VendingFilterFormValues } from './types';

const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
  formId: string;
  handleFilter: (values: VendingFilterFormValues) => void;
  initialValues: VendingFilterFormValues;
  closeModal?: () => void;
};

export const VendingFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: UsersFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<VendingFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();


  const onReset = () => {
    form.resetFields();
    handleFilter(initialValues);
  };

  const onFinish = (formValues: VendingFilterFormValues) => {
    handleFilter(formValues);
    closeModal?.();
  };

  return (

    <Form<VendingFilterFormValues>
      id={formId}
      name={formId}
      className='filters-form'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper style={{ width: '100%' }}>
        <StandardItemsWrapper style={{ width: '100%' }}>
          {/* <div className='invoice_list' style={{width:'100%'}}> */}
          <div className='invoice_list'>
            <Form.Item<VendingFilterFormValues>
              label={t('vendingMachine.formFields.participantId.label')}
              name={VendingFilterFormField.ParticipantId}
            >
              <Input
                placeholder={t('vendingMachine.formFields.participantId.placeholder')}
              />
            </Form.Item>
            <Form.Item<VendingFilterFormValues>
              label={t('vendingMachine.formFields.name.label')}
              name={VendingFilterFormField.Name}
            >
              <Input
                placeholder={t('vendingMachine.formFields.name.placeholder')}
              />
            </Form.Item>
            <Form.Item<VendingFilterFormValues>
              label={t('vendingMachine.formFields.emailId.label')}
              name={VendingFilterFormField.Email}
            >
              <Input
                placeholder={t('vendingMachine.formFields.emailId.placeholder')}
              />
            </Form.Item>
            <Form.Item<VendingFilterFormValues>
              label={t('vendingMachine.formFields.raceCategory.label')}
              name={VendingFilterFormField.Category}
            >
              <Input
                placeholder={t('vendingMachine.formFields.raceCategory.placeholder')}
              />
            </Form.Item>

            <Form.Item<VendingFilterFormValues>
              label={t('vendingMachine.formFields.raceName.label')}
              name={VendingFilterFormField.RaceName}
            >
              <Input placeholder={t('vendingMachine.formFields.raceName.placeholder')} />
            </Form.Item>

            {screens.lg && (
              <ContentFilters.ButtonsWrapper className='filter_btn'>
                <Button type='primary' htmlType='submit'>
                  {t('common.buttons.filter', { count: 1 })}
                </Button>
                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
              </ContentFilters.ButtonsWrapper>
            )}
          </div>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>
    </Form>
  );
};
