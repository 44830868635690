import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { eventsQueryKeys } from 'features/events';
import { queryClient } from 'providers';

import { confirmPayDTO, TransectionApiServices } from '../api/transection-api.service';

const { confirmPaymentBulk } = TransectionApiServices();

export const useConfirmPaymentBulk = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: confirmPayDTO) => {
            return confirmPaymentBulk(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
                message.success("payment has been approved successfully!!");
            },
        },
    );
};