import { Pagination } from 'antd';
import { ContentTopBar, StyledPaginationWrapper } from 'components';
import { PageLoader } from 'components/page-loader/page-loader';
import { useGetFaq } from 'features/events/use-cases/get-faq';
import { usePaginationParams } from 'hooks';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import { EventsFilterFormValues } from '../../events-filter-form';
import { FaqViewTable } from './faqview';

const FAQComponent = ({ tabs }: { tabs: string }) => {

  const { id } = useParams();

  const [mainPage, setMainPage] = useState<any>(1);
  const [totalPage, setTotalPage] = useState<any>(0);
  const [currentPage, setCurrentPage] = useState<any>(1);

  const initialFilterValues: EventsFilterFormValues = {
    field_name: undefined,
  };

  const { params, updateParams } =
    usePaginationParams<any>({
      page: mainPage,
      size: 10,
      order_by: '-rank',
      event_id: id,
      ...initialFilterValues,
    });

  const { data, isFetching, refetch } = useGetFaq(params);

  const [FaqData, setFaqData] = useState<any>(undefined);

  const handleChangePagination = (page: any) => {
    setMainPage(page);
  }

  useEffect(() => {
    setTotalPage(data?.total);
    setCurrentPage(data?.page)
  }, [data])


  useEffect(() => {
    updateParams({ ...params, page: mainPage });
  }, [mainPage]);

  useEffect(() => {
    if (tabs === "9") {
      refetch();
    }
  }, [tabs]);

  if (isFetching) return <PageLoader />;

  return (

    <>
      <ContentTopBar
        title={''}
      />

      <FaqViewTable
        id={id}
        allData={data}
        // excelData={excelData}
        isLoading={isFetching}
        // beforeUpload={beforeUpload}
        // handleUpload={handleUpload}
        tableParams={params}
        updateParams={updateParams}
        FaqData={FaqData}
        setFaqData={setFaqData}
        refetch={refetch}
      />

      <StyledPaginationWrapper>
        < Pagination
          total={totalPage}
          pageSize={10}
          current={currentPage}
          onChange={handleChangePagination}
          showSizeChanger={false}
        />
      </StyledPaginationWrapper>
    </>
  )
}

export default FAQComponent;
