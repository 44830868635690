import { Bar, BarChart, LabelList, Tooltip, XAxis } from 'recharts';

import CustomTooltip from '../custom-tooltip';

const ParticipantView = ({ data }: any) => {
  return (
    <>
      <BarChart
        width={980}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >

        <XAxis dataKey="name" />
        {/* <YAxis /> */}
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend />         */}
        <Bar dataKey="value" fill="#FF8042"  >
          <LabelList dataKey="value" position="top" />
        </Bar>
      </BarChart>
    </>
  )
}
export default ParticipantView