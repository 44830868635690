import {
  apiClient,
  // ApiClientConfig,
  // PaginatedRequestParams,
  // PaginatedResponse,
} from 'api';

// import { axiosInstance } from 'api/axios-instance';
// import { CONFIG } from 'config';
import { listDataType } from '../components/type';

export const DashboardApiService = () => {

  const getEvents = async (Flag: string) => {
    const response = await apiClient({
      endpoint: `admin/dropdownlist?${Flag}`
    })

    return response.data
  }

  const getDashboardGraphData = async (eventId: number, categoryId: number, Flag: string) => {

    const response = await apiClient<listDataType>({
      endpoint: `admin/dashboard?EventId=${eventId}&SportsCategoryId=${categoryId}&Flag=${Flag}`

    })

    return response.data
  }

  return {
    getEvents,
    getDashboardGraphData
  };
};



