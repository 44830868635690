import { Button, Form, Grid, Input } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationFilterFormField, NotificationFilterFormValues } from '../type';


const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
  formId: string;
  handleFilter: (values: NotificationFilterFormValues) => void;
  initialValues: NotificationFilterFormValues;
  closeModal?: () => void;
};

export const NotificationTypeFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: UsersFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<NotificationFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();


  const onReset = () => {
    form.resetFields();
    handleFilter(initialValues);
  };

  const onFinish = (formValues: NotificationFilterFormValues) => {
    handleFilter(formValues);
    closeModal?.();
  };

  return (
    
    <Form<NotificationFilterFormValues>
      id={formId}
      name={formId}
      className='filters-form'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper style={{width:'100%'}}>
        <StandardItemsWrapper style={{width:'100%'}}>
          {/* <div className='invoice_list' style={{width:'100%'}}> */}
          <Form.Item<NotificationFilterFormValues>
            label={'Type'}
            name={NotificationFilterFormField.TITLE}
          >
            <Input
              placeholder={'Enter Type'}
            />
          </Form.Item>
          {screens.lg && (
        <ContentFilters.ButtonsWrapper className='filter_btn'>
          <Button type='primary' htmlType='submit'>
            {t('common.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
        </ContentFilters.ButtonsWrapper>
      )}
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>  
    </Form>
  );
};
