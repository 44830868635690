import { Button, Form } from 'antd';
import { useCreateSize } from 'features/cms/use-cases/create-size';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AddSizeForm from './add-size-form';
import { SizeFormField, SizeFormValues } from './types';


export const AddSizeModal = ({ onClose, refetch, sizeEditData }: { onClose: () => void; showSizeModal: boolean, refetch: () => void, sizeEditData: any }) => {
    const { id } = useParams();
    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<SizeFormValues>();
    const { isLoading, mutate } = useCreateSize();

    const getInitialValues = {
        [SizeFormField.Id]: id,
        [SizeFormField.size_name]: undefined,
        [SizeFormField.size_name_ar]: undefined,
        [SizeFormField.IsActive]: undefined,
    };

    const handleUpdate = (formData: SizeFormValues) => {
        formData[SizeFormField.Id] = sizeEditData?.id;
        mutate(formData, { onSuccess: hanldeSuccess });
        form.resetFields()
    };

    const hanldeSuccess = () => {
        onClose();
        refetch();
    }


    const hanldeClose = () => {
        onClose();
        form.resetFields();
    }

    useEffect(() => {
        if (sizeEditData) {
            form.setFieldsValue(sizeEditData);
        } else {
            form.resetFields();
        }
    }, [sizeEditData])


    return (

        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={hanldeClose} className='close-btn'>
                    &times;
                </button>
                <h4>{sizeEditData ? "Edit Size" : "Add Size"}</h4>
            </div>
            <div className='modal_body' style={{
                height: "90vh",
                overflowY: "auto"
            }}>
                <Form<SizeFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    requiredMark={'optional'}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <AddSizeForm />
                </Form>
                <div className='button-group'>
                    <Button htmlType='submit' loading={isLoading} onClick={form.submit} className='btn'>Save</Button>
                </div>

            </div>
        </div>

    )
};

