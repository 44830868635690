import { UploadOutlined } from '@ant-design/icons';
import { Grid, Image } from 'antd';
import { Button, ContentTopBar } from 'components';
import { PageLoader } from 'components/page-loader/page-loader';
import { getSuffixUserId } from 'features/auth';
import { EventsFilterFormValues } from 'features/events/components/events-filter-form';
import { useGetGalleryReceiptImg } from 'features/events/use-cases/get-event-receipt-img';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import NoImage from '../../../../../../assets/No_Image_Available.jpg';
import { AddReceiptImgModal } from '../add-receipt-img-modal/add-receipt-img-modal';

const EventReceiptImage = ({ activeTab }: { activeTab: string }) => {
  const { id } = useParams();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [gallaryModal, setGallaryModal] = useState<boolean>(false);

  const suffixUserId = getSuffixUserId();

  const initialFilterValues: EventsFilterFormValues = {
    field_name: undefined,
  };


  // Get initial params
  const initialParams = {
    user_id: suffixUserId,
    event_id: id,
    order_by: '-rank',
    size: 12,
    flag: activeTab === "1" ? "banner" : activeTab === "2" ? "Event" : "",
    ...initialFilterValues,
  };

  const handleCloseModal = () => {
    setGallaryModal(false)
    document.body.style.overflow = 'visible';
  }

  const { params } = usePaginationParams<any>({
    ...initialParams,
    page: 1,
  });


  const { data, isFetching, refetch } = useGetGalleryReceiptImg(params);
  const dataId = (data?.items !== null && data?.items.length > 0) ? data.items[0]?.id : 0;

  if (!id) return null;

  const handleOpenModal = () => {
    setGallaryModal(true);
    document.body.style.overflow = 'hidden';
  }

  return (
    <>
      <ContentTopBar
        title={''}
        renderButtons={
          <Button icon={!screens.xs ? <UploadOutlined /> : undefined} className='btn tpmrng' onClick={handleOpenModal}>
            {"Upload"}
          </Button>
        }
      />
      {isFetching ?
        <PageLoader />
        :
        <>
          <>
            <div className='glry_list'>
              {data.items !== null && data.items.length > 0 ?
                <div className='glery_bx'> {/* Adding key prop to each rendered item */}
                  <Image
                    style={{ borderRadius: '6px', width: '100%', height: 'auto' }}
                    src={data?.items[0]?.receipt_image_url}
                    placeholder={true}
                    preview={false}
                  // onError={(e: any) => { e.target.src = 'https://images.ecestaticos.com/-mBz1VmNnHUdV56hmZwRW8cJhLw=/0x115:2265x1390/1200x675/filters:fill(white):format(jpg)/f.elconfidencial.com%2Foriginal%2F8ec%2F08c%2F85c%2F8ec08c85c866ccb70c4f1c36492d890f.jpg'; }}
                  />
                </div>
                :
                <div>
                  <img src={NoImage} height={"300px"} />
                </div>
              }
            </div>
          </>

        </>
      }
      <div className={`cstmrightmdl upld_modl ${gallaryModal && "show"}`}>
        <AddReceiptImgModal id={id} dataId={dataId} onClose={handleCloseModal} gallaryModal={gallaryModal} refetch={refetch} />
      </div>
    </>
  )
}

export default EventReceiptImage;

