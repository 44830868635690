export enum EventFormField {
  EventMaster = 'event_master_id',
  EventName = 'event_name',
  EventNameArabic = 'event_name_arabic',
  SportsCategory = 'categoryId',
  StartDate = 'start_date',
  EndDate = 'end_date',
  EventDate = "event_date",
  Currency = 'currency',
}

export type EventFormValues = {
  [EventFormField.EventMaster]: string;
  [EventFormField.EventName]: string;
  [EventFormField.EventNameArabic]: string;
  [EventFormField.SportsCategory]: string;
  [EventFormField.StartDate]: string;
  [EventFormField.EndDate]: string;
  [EventFormField.EventDate]: string;
  [EventFormField.Currency]: string;
  is_active: boolean

};

export enum EventDetailsField {
  EventMaster = 'event_master_id',
  EventName = 'event_name',
  EventNameArabic = 'event_name_arabic',
  SportsCategory = 'sports_category_id',
  StartDate = 'start_date',
  EndDate = 'end_date',
  Currency = 'currency',

}

export type EventDetailsFormValues = {
  [EventDetailsField.EventMaster]: string;
  [EventDetailsField.EventName]: string;
  [EventDetailsField.EventNameArabic]: string;
  [EventDetailsField.SportsCategory]: string;
  [EventDetailsField.StartDate]: string;
  [EventDetailsField.EndDate]: string;
  [EventDetailsField.Currency]: string;
};
type Item = {
  id: number;
  Text: string;
  // Add more properties as needed
};

export type CurrencyCategory = {
  items: Item[];
};

