
import { Button, Checkbox, Form, Radio, Skeleton } from 'antd';
import rightline from 'assets/icons/arrow-right-line.png';
import { EventsApiService } from 'features/events/api/events-api.service';
import { useSettingsUpdateForm } from 'features/events/use-cases/settings-update-form';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';

interface SettingItem {
    event_id: number;
    setting_id: number;
    setting_name: string;
    setting_type_id: number;
    setting_type_name: string;
    setting_input_type: string;
    setting_group_index_id: number;
    is_active: boolean;
    // Add other properties if needed
}


interface ApiResponse {
    page: number;
    size: number;
    pages: number;
    total: number;
    items: SettingItem[]; // Assuming SettingItem interface is defined
    // Add other properties if they exist
}

export const EventDetailsSetting = ({ id, setShow, show }: { id: any, setShow: any, show: boolean }) => {

    const [settingData, setSettingData] = useState<SettingItem[]>([]);
    const { isLoading, mutate } = useSettingsUpdateForm();
    const [getLoading, setGetLoading] = useState<boolean>(false);

    const groupedItems: { [key: number]: any[] } = {};
    settingData?.forEach((item: any) => {
        if (!groupedItems[item.setting_type_id]) {
            groupedItems[item.setting_type_id] = [];
        }
        groupedItems[item.setting_type_id].push(item);
    });


    const fetchSettings = async () => {
        setGetLoading(true);
        const response = await EventsApiService().getSettingsData(id);
        const apiResponse = response as ApiResponse;

        if (Array.isArray(apiResponse.items)) {
            const items: SettingItem[] = apiResponse.items;
            setSettingData(items);
            setGetLoading(false);
        }
        // setGetLoading(false);

    };

    useEffect(() => {
        fetchSettings();
    }, [])

    const handleSubmit = () => {
        const checkedItems: any[] = settingData
            .filter(item => item.is_active)
            .map(item => ({
                event_setting_id: item.setting_id
                ,
                is_active: item.is_active,
            }));
        const formData = {
            event_id: id,
            selected_event_setting: JSON.stringify(checkedItems),
        }
        mutate(formData);
    };


    const handleCheckboxChange = (item: SettingItem) => {
        const updatedSettingData = settingData.map(setting => {
            if (setting.setting_id === item.setting_id) {

                return { ...setting, is_active: !setting.is_active };
            }

            return setting;
        });
        setSettingData(updatedSettingData);
    };

    const handleRadioChange = (item: SettingItem, option: string) => {
        const updatedSettingData = settingData.map(setting => {
            if (setting.setting_type_id === item.setting_type_id) {

                return { ...setting, is_active: setting.setting_name === option };
            }

            return setting;
        });

        setSettingData(updatedSettingData);
    };


    return (
        <div  >

            {/* <div className='ar_icon'>
            <SettingOutlined onClick={() => setShow(true)} />
            </div> */}
            <div className='hd_modal'>
                {!show && <button type='button' className='btn' onClick={() => setShow(!show)}>
                    <img src={rightline} />
                </button>}
                <h1>Event Setting</h1>
            </div>

            {getLoading ?
                <Skeleton active paragraph={{ rows: 20 }} />
                :
                <>

                    <FormSectionsSeparator title="">
                        <div className='sbx_1'>
                            {Object.keys(groupedItems).map((settingTypeId: any) => (
                                <div key={settingTypeId} className='sb_form'>
                                    <h4>{groupedItems[settingTypeId][0].setting_type_name}</h4>
                                    {groupedItems[settingTypeId][0].setting_input_type === 'checkbox' && <div className='eb_f'>
                                        {groupedItems[settingTypeId].map((item: SettingItem) => (
                                            <div key={item.setting_id} className='ef_l'>
                                                {item.setting_input_type === 'checkbox' ?
                                                    <Checkbox
                                                        className="ssb_in"
                                                        name={item.setting_name}
                                                        checked={item.is_active}
                                                        onChange={() => handleCheckboxChange(item)}
                                                    >
                                                        {item.setting_name}
                                                    </Checkbox>
                                                    :
                                                    null}
                                            </div>
                                        ))}
                                    </div>}
                                    <div className='radio_bx'>
                                        {groupedItems[settingTypeId][0].setting_input_type === 'radio' && (
                                            <Radio.Group
                                                className="ssb_in"
                                                value={groupedItems[settingTypeId].find(option => option.is_active)?.setting_name}
                                                onChange={(e) => handleRadioChange(groupedItems[settingTypeId][0], e.target.value)}

                                            >
                                                {groupedItems[settingTypeId].map((radioOption: SettingItem, index) => {

                                                    return (
                                                        <div key={index}>
                                                            {(radioOption.setting_type_name != "Event Currency") &&
                                                                <Radio key={radioOption.setting_id} value={radioOption.setting_name}
                                                                // disabled={radioOption.setting_type_name === "Event Currency"}
                                                                >
                                                                    {radioOption.setting_name}
                                                                </Radio>
                                                            }

                                                            {(radioOption.setting_type_name === "Event Currency" && radioOption.is_active === true) &&
                                                                <Radio key={radioOption.setting_id} value={radioOption.setting_name}
                                                                // disabled={radioOption.setting_type_name === "Event Currency"}
                                                                >
                                                                    {radioOption.setting_name}
                                                                </Radio>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </Radio.Group>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>

                        <Form.Item className='form-button r_side_btn'>
                            <Button className='btn' onClick={handleSubmit} loading={isLoading} type='primary'>SAVE</Button>
                        </Form.Item>
                    </FormSectionsSeparator >
                </>}

        </div >
    )
};
