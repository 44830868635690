import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { queryClient } from 'providers';

import { CMSApiService, CreateColorDTO } from '../api/cms-api.service';
import { eventsQueryKeys } from './query-keys';

const { createColor } = CMSApiService();

export const useCreateColor = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: CreateColorDTO) => {
            return createColor(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: (data:any) => {
                const { ResponseMessage } = data;
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
                if(data.ResponseStatus==0){
                    message.error(ResponseMessage)
                }else{
                    message.success(ResponseMessage);
                }
            },
        },
    );
};
