import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import {
    AuthApiService,
    logoutDTO,
} from 'features/auth';

import { handleError } from '../../../api';

const { AuthLogoutService } = AuthApiService();


export const useLogoutService = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: logoutDTO) => {

            return AuthLogoutService(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: (data: any) => {
                const { ResponseMessage } = data;
                if (data.ResponseStatus == 0) {
                    message.error(ResponseMessage)
                } else {
                    message.success(ResponseMessage);
                }
            },
        },
    );
};
