import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { ContentFilters, ContentTopBar } from 'components';
import { useState } from 'react';
import { CompanyType } from 'types';

import { ParticipateFilterForm } from '../transaction-filter-form/participate-list-filter';
import { ParticiateFilterFormValues } from '../transaction-filter-form/type';
import { ParticipantReportModal } from './participant-report-filter/participant-report-modal';

type ParticipateTopBarProps = {
  getInitialFilterValues: ParticiateFilterFormValues;
  handleFilter: (values: ParticiateFilterFormValues) => void;
};

export const ParticipateListTopBar = ({
  getInitialFilterValues,
  handleFilter,
}: ParticipateTopBarProps) => {
  useState<CompanyType>();
  const formId = 'participate-filters-form';
  const [participant, setParticipant] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");

  const openParticipantModal = (val: string) => {
    setParticipant(true);
    setModalType(val);
    document.body.style.overflow = 'hidden';
  }

  const closeParticipantModal = () => {
    setParticipant(false);
    setModalType("");
    document.body.style.overflow = 'visible';
  }


  return (

    <>
      <div className={`cstmrightmdl ${participant && "show"}`}>
        <ParticipantReportModal
          closeModal={closeParticipantModal}
          isOpen={participant}
          modalType={modalType}
        />
      </div>

      <ContentTopBar
        title={'Participant list'}

        renderButtons={
          <div className='parti_btn'>
            <Tooltip title={"Export"}>
              <Button
                icon={<FileExcelOutlined />}
                onClick={() => openParticipantModal("ParticipantReport")}
                type='primary'
              // loading={loading}
              >
                Participants Report
              </Button>
            </Tooltip>

            <Tooltip title={"Export"}>
              <Button
                icon={<FileExcelOutlined />}
                onClick={() => openParticipantModal("FinancialReport")}
                type='primary'
              >
                Financial Report
              </Button>
            </Tooltip>
          </div>
        }
      />
      <ContentFilters formId={formId}>
        <ParticipateFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
