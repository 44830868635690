import { Button, Form } from 'antd';
import { useCreateFaq } from 'features/events/use-cases/create-faq';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AddFaqForm } from './addFaqFom';
import { FaqFormField, FaqFormValues } from './type';



export const AddEventFaqModal = ({ onClose, showAddEventModal, FaqData, refetch }: { onClose: () => void; showAddEventModal: boolean, FaqData: any, refetch: () => void }) => {
    const { id } = useParams();
    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);

    const handleClose = () => {
        onClose();
        form.resetFields();
    }


    const [form] = Form.useForm<FaqFormValues>();
    const { isLoading, mutate } = useCreateFaq();
    const getInitialValues = {
        [FaqFormField.id]: FaqData?.id,
        [FaqFormField.question]: undefined,
        [FaqFormField.answer]: undefined,
    };
    const handleUpdate = (formData: FaqFormValues) => {
        // formData[FaqFormField.question] = organizerData?.id;
        formData[FaqFormField.id] = FaqData?.id;
        formData[FaqFormField.event_id] = +id!;

        mutate(formData, { onSuccess: handleSuccess });
        // setTimeout(() => {
        //     refetch();
        // }, 1000)
    };

    useEffect(() => {
        if (FaqData) {
            form.setFieldsValue(FaqData);
        }
    }, [FaqData])

    const handleSuccess = () => {
        handleClose();
        refetch()
    }

    return (

        <>

            <div className={showAddEventModal ? 'popup' : 'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{FaqData ? 'Edit Faq' : "Add Faq"}</h4>
                </div>
                <div className='modal_body'>
                    <Form<FaqFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <AddFaqForm />
                    </Form>
                    <div className='button-group'>
                        <Button htmlType='submit' onClick={form.submit} className='btn'>Save</Button>
                    </div>

                </div>
            </div>
        </>

    )
};
