import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import {
    MainFilters,
    MainFiltersParams,
} from 'components/main-filters/main-filters';
import {
    EventsFilterFormValues,
} from 'features/events';
import { usePermissions } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { CountriesTable } from '../components';
import { CountryFilterFormPaginated, CountryFilterFormValues, ManageFilterForm } from '../components/manage-country-filter-form';
import { AddCountriesModal } from '../components/manage-countyries/add-countries-modal/add-countriesModal-modal';
import { useGetCountries } from '../use-cases/get-countries';


const { useBreakpoint } = Grid;

const initialFilterValues: CountryFilterFormValues = {
    name: undefined,
    is_active: undefined
};

export const ManageCountyriesView = () => {
    const screens = useBreakpoint();
    const permissions = usePermissions();
    const { params, updateParams, resetPage } =
        usePaginationParams<CountryFilterFormPaginated>({
            page: 1,
            size: 10,
            order_by: '-rank',
            ...initialFilterValues,
        });

    const { data, isFetching, refetch } = useGetCountries(params as any, { retry: false });
    const [countriesEditData, setCountriesEditData] = useState<any>(undefined);

    const shouldHandleFieldCreation = permissions?.fields.add;

    const handleFiltersChange = (values: EventsFilterFormValues) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    const handleMainFiltersChange = (mainParams: MainFiltersParams) => {
        updateParams(mainParams);
        resetPage();
    };
    const [showCountriesModal, setShowCountriesModal] = useState(false);
    const formId = 'events-filters-form';

    const handleCloseModal = () => {
        setShowCountriesModal(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = () => {
        setShowCountriesModal(true);
        document.body.style.overflow = 'hidden';
    }

    return (
        <>
            <ContentTopBar
                title={"Manage Countries"}
                renderHeadingContent={
                    <MainFilters onChange={handleMainFiltersChange} />
                }
                renderButtons={
                    <>
                        {shouldHandleFieldCreation ? (
                            <>
                                <Button
                                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                                    type='primary'
                                    onClick={handleOpenModal}
                                >
                                    {"Add Countries"}
                                </Button>
                                <div className={`cstmrightmdl ${showCountriesModal && "show"}`}>

                                    <AddCountriesModal showCountriesModal={showCountriesModal} refetch={refetch} onClose={handleCloseModal} countriesEditData={countriesEditData} />
                                </div>

                            </>
                        ) : null}
                    </>
                }
            />

            <ContentFilters formId={formId}>
                <ManageFilterForm
                    formId={formId}
                    handleFilter={handleFiltersChange as any}
                    initialValues={initialFilterValues as any}
                />
            </ContentFilters>

            <CountriesTable
                data={data}
                isLoading={isFetching}
                tableParams={params}
                updateParams={updateParams}
                refetch={refetch}
                countriesEditData={countriesEditData}
                setCountriesEditData={setCountriesEditData}
            />
            <Outlet />
        </>
    );
};



