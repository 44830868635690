import {
    QueryFunction,
    QueryKey,
    useQuery,
    UseQueryOptions,
  } from '@tanstack/react-query';
  import { App } from 'antd';
  import { handleError } from 'api';
  import { UserApiService, VendingReportResponse,VendorListParams } from 'features/vending-machine';
  import qs from 'qs';

  import { userQueryKeys } from './query-keys';
  
  const { getVendingReports } = UserApiService();
  
  const getUsersQuery = (
    params: VendorListParams,
    options?: UseQueryOptions<VendingReportResponse>,
  ): {
    queryKey: QueryKey;
    queryFn: QueryFunction<VendingReportResponse>;
    options?: UseQueryOptions<VendingReportResponse>;
  } => ({
    queryKey: userQueryKeys.list(params),
    queryFn: ({ signal }) =>
        getVendingReports(params, {
        config: {
          signal,
          paramsSerializer: {
            serialize: (queryParams) =>
              qs.stringify(queryParams, {
                arrayFormat: 'repeat',
                encode: true,
              }),
          },
        },
      }),
    ...options,
  });
  
  export const useGetVendingReportList = (
    params: VendorListParams,
    options?: UseQueryOptions<VendingReportResponse>,
  ) => {
    const { message } = App.useApp();
       
    return   useQuery<VendingReportResponse, Error>(
      getUsersQuery(params, {
        onError: (error: unknown) => {
          const uiMessage = handleError(error);
  
          message.error(uiMessage);
        },
        ...options,
      }),
    );
   

  };

 



  