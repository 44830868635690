import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Grid, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
    EventsResponse,
} from 'features/events';
import { useStatusResponse } from 'features/shop-item/use-cases/update-status-response-msg';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';
import { Event } from 'types/event';

import { CurrencyFilterFormPaginated } from '../currency-filter-form';
import { AddCurrencyConversionModal } from './Add-currency-conversion-madal/add-currency-conversion-modal';
const { useBreakpoint } = Grid;
type EventsTableProps = {
    isLoading: boolean;
    data?: EventsResponse;
    tableParams: CurrencyFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<CurrencyFilterFormPaginated>>;
    refetch: () => void;
    currencyEditData: any;
    setCurrencyEditData: any;
};

export const CurrencyConversionTabel = ({
    data,
    isLoading,
    tableParams,
    updateParams,
    refetch,
    currencyEditData,
    setCurrencyEditData
}: EventsTableProps) => {
    const screens = useBreakpoint();
    const permissions = usePermissions();

    const { mutate: mutateUpdateItem, isLoading: isLoadingUpdate } =
        useStatusResponse();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Event> | SorterResult<Event>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: Array.isArray(sorter)
                ? undefined
                : sorter.order === 'ascend'
                    ? 'name'
                    : '-name',
        });
    };
    const { confirm } = Modal;

    const handleDelete = async (id: any) => {
        mutateUpdateItem({
            id: id,
            flag: "DELETE_EVENT_CURRENCY",
            status: true
        });
    };

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: 'Are you sure you want to delete this Currency?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record.id);
            },
        });
    };
    const [showCurrencyModal, setShowCurrencyModal] = useState(false);
    const handleCloseModal = () => {
        setShowCurrencyModal(false);
        setCurrencyEditData(undefined);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = (record: any) => {
        setShowCurrencyModal(true);
        setCurrencyEditData(record);
        document.body.style.overflow = 'hidden';
    }

    if (!permissions) return null;

    return (
        <>
            <div className={`cstmrightmdl ${showCurrencyModal && "show"}`}>
                <AddCurrencyConversionModal showCurrencyModal={showCurrencyModal} refetch={refetch} onClose={handleCloseModal} currencyEditData={currencyEditData} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={"Currency found"}
                        count={data?.total || 0}
                    />
                </StyledTableHeader>
                <Table<Event>
                    className="cstltblwthldr"
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading && { indicator: <div><TableLoader /></div> }}
                    dataSource={isLoading ? [] : data?.items}
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.page,
                        defaultPageSize: tableParams.size,
                        total: data?.total,
                    }}
                // scroll={tableWidth}
                >
                    <Column<Event>
                        title={'Currency Name'}
                        dataIndex={'CurrencyName'}
                        // sorter={true}
                        // sortDirections={['ascend', 'descend', 'ascend']}
                        // defaultSortOrder={'descend'}
                        width={screens.xxl ? 'auto' : 200}
                    />

                    <Column<any>
                        title={'To Currency'}
                        dataIndex={'ToCurrency'}
                        width={screens.xxl ? 'auto' : 120}

                    />

                    <Column<any>
                        title={'Convert Value'}
                        dataIndex={'ConvertValue'}
                        width={screens.xxl ? 'auto' : 120}

                    />
                    <Column<Event>
                        title={"Action"}
                        width={screens.xxl ? 'auto' : 162}
                        dataIndex={'IsActive'}
                        render={(_, record) => (
                            <>
                                <Space>
                                    <Tooltip title="Edit">
                                        <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                                    </Tooltip>

                                    <Tooltip title="Delete">
                                        <Button shape="circle" loading={isLoadingUpdate} onClick={() => showDeleteConfirm(record)} icon={<DeleteOutlined />} />
                                    </Tooltip>
                                    <Tooltip title="Is Active">
                                        <Switch
                                            defaultChecked={record.IsActive}
                                            checked={record.IsActive}
                                            loading={isLoadingUpdate}
                                            onChange={(isChecked) => {
                                                mutateUpdateItem({
                                                    id: record.id,
                                                    flag: "CURRENCY_SET_STATUS",
                                                    status: isChecked,
                                                });
                                                // Update the is_active status locally
                                                record.IsActive = isChecked;
                                            }}

                                        />
                                    </Tooltip>
                                </Space>
                            </>
                        )}
                    />
                </Table>
            </StyledTableWrapper>
        </>

    );
};

