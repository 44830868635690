import { CloseCircleFilled } from '@ant-design/icons';
import { Grid, ModalProps as AntModalProps } from 'antd';
import { CSSProperties } from 'react';

import { StyledModal } from './modal.styles';
const { useBreakpoint } = Grid;

export type ModalProps = AntModalProps & { isFullScreenOnMobile?: boolean };

export const Modal = ({
  title,
  open,
  children,
  isFullScreenOnMobile = true,
  ...props
}: ModalProps) => {
  const screens = useBreakpoint();
  const modalStyle: CSSProperties = {
    right: 0, // Position the modal from the right side
    // Add any other custom styles here if needed
  };
  
return (
    <StyledModal
      title={title}
      open={open}
      className={!screens.lg ? 'modal-mobile' : undefined}
      style={modalStyle}
      isFullScreenOnMobile={isFullScreenOnMobile}
      closeIcon={<CloseCircleFilled />}
      {...props}
    >
      {children}
    </StyledModal>
  );
};
