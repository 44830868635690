import { EditOutlined } from '@ant-design/icons';
import { Button, Space, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Switch, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { getUserFullName, usePermissions } from 'features/users';
import { EndUserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { useUpdateUserStatus } from 'features/users/use-cases/update-user';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'types';

// import { DEFAULT_TIMEZONE, formatDate } from 'utils/date';
import { AddAppUserModal } from '../modals/add-app-user-modal';

type UsersTableProps = {
  isUsersListLoading: boolean;
  usersList: User[];
  itemsCount: number;
  tableParams: EndUserFilterFormValuesPaginated;
  updateParams: Dispatch<SetStateAction<EndUserFilterFormValuesPaginated>>;
  userEditData: any;
  setUserEditData: any;
};


export const EndUsersTable = ({
  usersList,
  isUsersListLoading,
  itemsCount,
  tableParams,
  updateParams,
  userEditData,
  setUserEditData,
}: UsersTableProps) => {
  const { t } = useTranslation();
  const permissions = usePermissions();

  const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
  const [loadingSwitchId, setLoadingSwitchId] = useState('');

  const { mutate: mutateUpdateUserStatus, isLoading: isLoadingUpdateUserStatus } = useUpdateUserStatus();

  if (!permissions) return null;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'
          : '-name',
    });
  };

  const formatDateTime = (dateTimeString: string | number | Date) => {
    const dateTime = new Date(dateTimeString);

    // Extract date components
    const day = dateTime.getDate().toString().padStart(2, '0');
    const month = (dateTime.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = dateTime.getFullYear().toString();

    // Extract time components
    // let hours = dateTime.getHours();
    // // const amPM = hours >= 12 ? 'PM' : 'AM';
    // hours = hours % 12;
    // hours = hours ? hours : 12; 
    // const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    // const seconds = dateTime.getSeconds().toString().padStart(2, '0');

    // Construct formatted date-time string
    //${hours}:${minutes}:${seconds} ${amPM}
    const formattedDateTime = `${day}/${month}/${year} `;

    return formattedDateTime;
  };
  //  ...
  const handleCloseModal = () => {
    setShowUpdateUserModal(false);
    setUserEditData(undefined);
  }


  const handleOpenModal = (record: any) => {
    setShowUpdateUserModal(true);
    setUserEditData(record);
  }

  // const resetRecorId = ()=>{
  //   setLoadingSwitchId('');
  // }

  return (
    <>
      {showUpdateUserModal ? (<div className={`cstmrightmdl ${showUpdateUserModal && "show"}`}>
        <AddAppUserModal onClose={handleCloseModal} userEditData={userEditData} />
      </div>) : ''}

      <StyledTableWrapper>
        <StyledTableHeader>
          <RecordCountBadge
            text={t('adminUsers.foundUsers')}
            count={itemsCount}
          />
        </StyledTableHeader>
        <Table<User>
          loading={isUsersListLoading}
          dataSource={usersList}
          rowKey='id'
          onChange={handleTableChange}
          pagination={{
            current: tableParams.page,
            defaultPageSize: tableParams.size,
            total: itemsCount,
          }}
          scroll={{ x: 1440 }}
        // onRow={(record) => {
        //   return {
        //     onClick: () => {
        //       const selection = window?.getSelection()?.toString();

        //       if (!selection?.length) {
        //         navigate(getRoute(ROUTES.USERS_APP_EDIT, record.id));
        //       }
        //     },
        //   };
        // }}
        >
          <Column<User>
            title={t('adminUsers.table.customerName')}
            dataIndex={'customerName'}
            render={(_, record) => getUserFullName(record)}
            sorter={true}
            sortDirections={['ascend', 'descend', 'ascend']}
            defaultSortOrder={'ascend'}
          />
          <Column<User>
            title={t('adminUsers.table.email')}
            dataIndex={'user'}
            render={(_, record) => record.email}
          />
          <Column<User>
            title={t('adminUsers.table.phoneNumber')}
            dataIndex={'user'}
            render={(_, record) => record.phone_number}
          />
          <Column<User>
            title={t('adminUsers.table.date')}
            dataIndex={'user'}
            render={(_, record) =>
              formatDateTime(
                record.created_at
              )
            }
          />
          <Column<User>
            title={"Action"}
            dataIndex={'user'}
            render={(_, record) => (
              <Space>
                <Tooltip title="Edit">
                  <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                </Tooltip>
                <Tooltip title="is active">
                  <Switch
                    defaultChecked={record.is_active}
                    checked={record.is_active}
                    loading={loadingSwitchId == record.id && isLoadingUpdateUserStatus}
                    onChange={(isChecked) => {
                      setLoadingSwitchId(record.id);
                      mutateUpdateUserStatus({
                        Id: record.id,
                        is_active: isChecked,
                        flag: "status_updated"
                      })
                    }}
                    onClick={(checked, event) => {
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                  />
                </Tooltip>
              </Space>
            )}
          />
        </Table>
      </StyledTableWrapper>
    </>
  );
};
