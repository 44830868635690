import { Form, Input } from 'antd';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useTranslation } from 'react-i18next';

import { OutletFormField } from '../../type';

export const AddOutletForm = () => {
    const { t } = useTranslation();

    return (

        <FormSectionsSeparator title={""}>
            <Form.Item<OutletFormField>
                label={"Name"}
                name={OutletFormField.name}

                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Input
                    placeholder={"Enter Name"}
                />
            </Form.Item>
            <Form.Item<OutletFormField>
                label={"Contact No"}
                name={OutletFormField.contact_no}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Input placeholder={'Enter contact No'} />
            </Form.Item>
            <Form.Item<OutletFormField>
                label={"Email Id"}
                name={OutletFormField.email}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Input placeholder={'Enter Email Id'} />
            </Form.Item>
            <Form.Item<OutletFormField>
                label={"Address"}
                name={OutletFormField.address}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Input placeholder={'Enter Address'} />
            </Form.Item>

        </FormSectionsSeparator>
    );
};
