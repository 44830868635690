export enum ColorFormField {
    Id = 'id',
    Title = 'title',
    TitleArabic = 'title_arabic',
    ColorCode = 'color_code',
    IsActive = 'is_active',
}



export type Color = {
    Id?: string;
    Title: string;
    TitleArabic: string;
    ColorCode: string;
    IsActive: boolean;
};

export type ColorFormValues = {
    [ColorFormField.Id]: string | undefined;
    [ColorFormField.Title]: string;
    [ColorFormField.TitleArabic]: string;
    [ColorFormField.ColorCode]: string;
    [ColorFormField.IsActive]: boolean;

};