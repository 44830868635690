export enum CategoryFormField {
    Id = 'id',
    title = 'title',
    IsActive = 'is_active',
}
export enum SizeFormField {
    Id = 'id',
    shop_item_id = 'shop_item_id',
    size_master_id = 'size_master_id',
    size_value="size_value",
    color_id="color_id",
    color_code="color_code",
    quantity="quantity",
    sort_order="sort_order",
    total_quantity="total_quantity",
    is_featured="is_featured",

}
export enum ImageFormField {
    shop_item_id = 'shop_item_id',
    color_id="color_id",
    File="files",
    is_featured="is_featured",

}
export type ImageFormValues = {
    [ImageFormField.shop_item_id]: any;
    [ImageFormField.color_id]: string;
    [ImageFormField.File]: any;
    [ImageFormField.is_featured]: boolean;
};
export type SizeFormValues = {
    [SizeFormField.Id]: string | undefined;
    [SizeFormField.shop_item_id]: string;
    [SizeFormField.size_master_id]: string;
    [SizeFormField.size_value]: string;
    [SizeFormField.color_id]: string;
    [SizeFormField.color_code]: string;
    [SizeFormField.quantity]: string;
    [SizeFormField.sort_order]: string;
    [SizeFormField.total_quantity]: string;
    [SizeFormField.is_featured]: string;
};
export enum deliveryFormField {
    Id = 'id',
    country_id = 'country_id',
    area_id = 'area_id',
    delivery_charges="delivery_charges",
    expr_delivery_charges="expr_delivery_charges"
}
export enum ItemFormField {
    Id = 'id',
    title = 'title',
    title_arabic = 'title_arabic',
    shop_category_id="shop_category_id",
    description="description",
    shiping_returns="shiping_returns",
    sales_price="sales_price",
    base_price="base_price",
    image_url="image_url",
    is_delivery="is_delivery",
    address_note="address_note",
    discount_type_id="discount_type_id",
    size_availability="size_availability",
    color_availability="color_availability",
    is_active="is_active"
}
export type ItemFormValues = {
    [ItemFormField.Id]: string | undefined;
    [ItemFormField.title]: string;
    [ItemFormField.title_arabic]: string;
    [ItemFormField.shop_category_id]: string;
    [ItemFormField.shiping_returns]: string;
    [ItemFormField.sales_price]: string;
    [ItemFormField.base_price]: string;
    [ItemFormField.image_url]: string;
    [ItemFormField.is_delivery]: string;
    [ItemFormField.address_note]: string;
    [ItemFormField.discount_type_id]: string;
    [ItemFormField.size_availability]: string;
    [ItemFormField.color_availability]: string;
    [ItemFormField.is_active]: string;
};
export type deliveryFormValues = {
    [deliveryFormField.Id]: string | undefined;
    [deliveryFormField.country_id]: string;
    [deliveryFormField.area_id]: string;
    [deliveryFormField.delivery_charges]: string;
    [deliveryFormField.expr_delivery_charges]: string;
};
export type CategoryFormValues = {
    [CategoryFormField.Id]: string | undefined;
    [CategoryFormField.title]: string;
    [CategoryFormField.IsActive]: boolean;
};
export type size={
    items:[]
}