import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { CouponListTable } from '../components/coupon-management/coupon-list-table';
import { CouponFormFieldValue } from '../components/shop-filter-form/type';
import { CouponListTopBar } from '../components/top-bar/coupon-top-bar';
import { useGetEventMastersCoupon } from '../use-cases/get-coupons';

const getInitialFilterValues: CouponFormFieldValue = {
  coupon_title: undefined,
  is_active: undefined,
  coupon_code: undefined
};
export const CouponListView = () => {
  const { params, updateParams, resetPage } = usePaginationParams<any>({
    page: 1,
    size: 10,
    ...getInitialFilterValues,
  });

  const { data, refetch, isFetching } = useGetEventMastersCoupon("SHOPITEMS_COUPONS", params, { retry: false });

  const handleFiltersChange = (values: CouponFormFieldValue) => {
    updateParams(values);
    resetPage();
    refetch()
  };

  return (

    <>
      <CouponListTopBar
        handleFilter={handleFiltersChange}
        getInitialFilterValues={getInitialFilterValues}
        refetch={refetch}
      />

      <CouponListTable
        isSocialListLoading={isFetching}
        data={data}
        // itemsCount={'' as any}
        tableParams={params}
        updateParams={updateParams}
        refetch={refetch}
      />
      <Outlet />
    </>
  );
};
