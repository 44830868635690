import { UserOutlined } from '@ant-design/icons'
import { Form, Input } from 'antd'
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator'
import { useTranslation } from 'react-i18next'

import { ColorFormField } from './types'

const AddColorForm = () => {
    const { t } = useTranslation();

    return (
        <div>
            <FormSectionsSeparator title={""}>

                <Form.Item<ColorFormField>
                    label={"Title"}
                    name={ColorFormField.Title}

                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Input
                        placeholder={"Title"}
                        prefix={<UserOutlined className='input-prefix-icon' />}
                    />
                </Form.Item>


                <Form.Item<ColorFormField>
                    label={"A Title"}
                    name={ColorFormField.TitleArabic}

                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Input
                        placeholder={"A Title"}
                    />
                </Form.Item>

                <Form.Item<ColorFormField>
                    label={"Color Code"}
                    name={ColorFormField.ColorCode}

                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Input
                        placeholder={"Color Code"}
                    />
                </Form.Item>


            </FormSectionsSeparator>
        </div>
    )
}

export default AddColorForm;
