import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import {
    MainFilters,
    MainFiltersParams,
} from 'components/main-filters/main-filters';
import { usePermissions } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { SizeTabel } from '../components/size-management';
import { AddSizeModal } from '../components/size-management/Add-size-modal/add-size-modal';
import { SizeFilterForm } from '../components/size-management-filter-form/size-management-filter-form';
import { ManagementFilterFormPaginated, ManagementFilterFormValues } from '../components/type';
import { useGetSize } from '../use-cases/get-size';

const { useBreakpoint } = Grid;

const initialFilterValues: ManagementFilterFormValues = {
    size_name: undefined,
};

export const SizeManagementView = () => {
    const screens = useBreakpoint();
    const permissions = usePermissions();
    const { params, updateParams, resetPage } =
        usePaginationParams<ManagementFilterFormPaginated>({
            page: 1,
            size: 10,
            order_by: '-rank',
            ...initialFilterValues,
        });

    const { data, isFetching, refetch } = useGetSize(params, { retry: false });
    const [sizeEditData, setSizeEditData] = useState<any>(undefined);
    const [showSizeModal, setShowSizeModal] = useState(false);

    const shouldHandleFieldCreation = permissions?.fields.add;

    const handleFiltersChange = (values: ManagementFilterFormValues) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    const handleMainFiltersChange = (mainParams: MainFiltersParams) => {
        updateParams(mainParams);
        resetPage();
    };
    const formId = 'events-filters-form';

    const handleCloseModal = () => {
        setShowSizeModal(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenmodal = () => {
        setShowSizeModal(true);
        document.body.style.overflow = 'hidden';
    }


    return (
        <>
            <ContentTopBar
                title={"Size Management"}
                renderHeadingContent={
                    <MainFilters onChange={handleMainFiltersChange} />
                }
                renderButtons={
                    <>
                        {shouldHandleFieldCreation ? (
                            <>
                                <Button
                                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                                    type='primary'
                                    onClick={handleOpenmodal}
                                >
                                    {"Add Size"}
                                </Button>
                                <div className={`cstmrightmdl ${showSizeModal && "show"}`}>
                                    <AddSizeModal showSizeModal={showSizeModal} refetch={refetch} onClose={handleCloseModal} sizeEditData={sizeEditData} />

                                </div>

                            </>
                        ) : null}
                    </>
                }
            />
            <ContentFilters formId={formId}>
                <SizeFilterForm
                    formId={formId}
                    handleFilter={handleFiltersChange}
                    initialValues={initialFilterValues}
                />
            </ContentFilters>
            <SizeTabel
                data={data}
                isLoading={isFetching}
                tableParams={params}
                updateParams={updateParams}
                refetch={refetch}
                sizeEditData={sizeEditData}
                setSizeEditData={setSizeEditData}
            />
            <Outlet />
        </>
    );
};

