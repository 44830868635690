import { PaginatedRequestParams } from 'api';

export enum InvoiceFilterFormField {
    InvoiceId = "invoice_number",
    Event_Name = 'event_name',
    Name = 'name',
    MobileNo = 'MobileNumber',
    Email = 'email',
    paymentMethod = "paymentMethod",
    TransactionStatus = 'tansactionStatus',
    BookingDate = 'bookingDate',
    TransactionId = 'tranactionId',
    RefrenceNumber = 'RefrenceNumber',
    AuthId = 'AuthId',
    Currency = "currency",
    UserType = 'userType',
    Year = "year",
    EventItem = 'event_item',
    // PayType = 'pay_type',
    // Date = 'date',
}

export type InvoiceFilterFormValues = {
    [InvoiceFilterFormField.InvoiceId]: string | undefined;
    [InvoiceFilterFormField.Event_Name]: string | undefined;
    [InvoiceFilterFormField.Name]: string | undefined;
    [InvoiceFilterFormField.Currency]: string | undefined;
    [InvoiceFilterFormField.MobileNo]: string | undefined;
    [InvoiceFilterFormField.Email]: string | undefined;
    [InvoiceFilterFormField.paymentMethod]: string | undefined;
    [InvoiceFilterFormField.TransactionStatus]: string | undefined;
    [InvoiceFilterFormField.BookingDate]: any;
    [InvoiceFilterFormField.TransactionId]: string | undefined;
    [InvoiceFilterFormField.AuthId]: string | undefined;
    [InvoiceFilterFormField.RefrenceNumber]: string | undefined;
    [InvoiceFilterFormField.UserType]: string | undefined;
    [InvoiceFilterFormField.Year]: number | undefined;
    [InvoiceFilterFormField.EventItem]: string | undefined;
};

export type InvoiceFilterFormValuesPaginated = InvoiceFilterFormValues &
    PaginatedRequestParams;