import { Button, Form, Input, message } from "antd";
import { PageLoader } from "components/page-loader/page-loader";
import { EventsApiService } from "features/events/api/events-api.service";
import { useCreateEventParticipant } from "features/events/use-cases/create-event-participant";
import { useGetParticipantsRequest } from "features/events/use-cases/get-request-participants";
import { useEffect, useState } from "react";

interface ParticipantsItem {
  result_api_url: string;
}

interface participants {
  items: ParticipantsItem[];
}

const ParticipantDataComponent = ({ id }: { id: any }) => {

  const { mutate, isLoading: addLoading } = useCreateEventParticipant();

  const { refetch } = useGetParticipantsRequest(id, { enabled: false });

  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const formName = "participant-url-form"

  const [form] = Form.useForm();

  const handleSave = (values: any) => {
    const formData = {
      event_id: id,
      result_value: values.Participant,
      flag: "UPDATE_RESULT_API_URL "
    };
    mutate(formData, { onSuccess: getResult });
  };

  const [participants, setParticipants] = useState<participants>({ items: [] });
  const [urlLoading, setUrlLoading] = useState<boolean>(false);

  const getResult = async () => {
    setUrlLoading(true);
    try {
      const apires = await EventsApiService().getParticipantsUrl(id as any)
      setParticipants(apires as participants);
      setUrlLoading(false);
    } catch (error) {
      message.error('')
    }
  }

  useEffect(() => {
    getResult();
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      Participant: participants.items[0]?.result_api_url
    });

  }, [participants]);

  const handleSendRequest = () => {
    refetch();
  };


  if (urlLoading) return <PageLoader />

  return (

    <>
      <div className="rslt_wrap">
        <Form
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          // initialValues={getInitialValues}
          onFinish={handleSave}
          // requiredMark={'optional'}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}

        >

          <div className="rslt_l">
            <Form.Item
              // label={"Result"}
              name="Participant"
              className="result_form"
              rules={[
                {
                  required: true,
                  // whitespace: true,
                },
              ]}
            >
              <Input
                placeholder={"Enter Participant Url"}
              />

            </Form.Item>

            <div className='button-group'>
              <Button htmlType='submit' loading={addLoading} className='btn' style={{ marginRight: 10 }}>Save</Button>
              <Button disabled={!participants.items[0]?.result_api_url}
                // loading={requestLoading}
                onClick={handleSendRequest}
              >Send Previous data to result API</Button>

            </div>

          </div>
        </Form>



      </div>
    </>
  )
};

export default ParticipantDataComponent;

