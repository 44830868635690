import { Button, Form } from 'antd';
import { useAddSponser } from 'features/events/use-cases/create-sponser';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AddSponserForm } from './add-sponser-form';
import { SponserFormField, SponserFormValues } from './types';

const AddSponserModal = ({ onClose, refetch, sponserData }: { onClose: () => void; refetch: () => void; sponserData: any }) => {
    const { id } = useParams();
    const eventId = id;

    const formName = 'add-sponser-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<SponserFormValues>();


    const { isLoading, mutate } = useAddSponser(sponserData?.id);
    const [fileData, setFileData] = useState<File | any>(null);

    const getInitialValues = {
        [SponserFormField.Id]: id,
        [SponserFormField.EventId]: eventId,
        [SponserFormField.Name]: undefined,
        [SponserFormField.Link]: undefined,
        [SponserFormField.ContactNumber]: undefined,
        [SponserFormField.Address]: undefined,
        [SponserFormField.Email]: undefined,
        [SponserFormField.IsActive]: undefined,
    };


    const handleClose = () => {
        onClose();
        form.resetFields();
        setFileData(null);
    }

    const handleUpdate = (formData: SponserFormValues) => {
        formData[SponserFormField.Id] = sponserData?.id;
        formData[SponserFormField.EventId] = id;
        formData[SponserFormField.IsActive] = true;
        formData[SponserFormField.File] = fileData;
        mutate(formData, { onSuccess: hanldeSuccess });
    };

    useEffect(() => {
        if (sponserData) {
            form.setFieldsValue(sponserData);
        } else {
            form.resetFields();
        }
    }, [sponserData])

    const hanldeSuccess = () => {
        handleClose();
        refetch();
    }

    return (


        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={handleClose} className='close-btn'>
                    &times;
                </button>
                <h4>{sponserData ? "Edit Sponser" : "Add Sponser"}</h4>
            </div>
            <div className='modal_body'>
                <Form<SponserFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    // requiredMark={'optional'}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <AddSponserForm setFileData={setFileData} fileData={fileData} sponserData={sponserData} />
                    <Button loading={isLoading} type="primary" htmlType='submit' className="event-gallary-submit-btn" onClick={() => form.submit}>Save</Button>
                </Form>


            </div>
        </div>
    )
};


export default AddSponserModal;