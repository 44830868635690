import { Button, Form, message } from 'antd';
import { UserApiService } from 'features/users';
import { VolunteerInfoFormItems } from 'features/users/components/modals/volunteer-user-form-item';
import {
    VolunteerUserFormField,
    VolunteerUserFormValues,
} from 'features/users/components/users-filter-form';
import { useCreateVolunteerUser, useUpdateVolunteerUser } from 'features/users/use-cases/create-user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PasswordFormItems } from './password-form-items';

const { getVendingEvents } = UserApiService();

type eventListType = {
    Id: string;
    Text: string;
    Value: string;
}
// type selectedEvent = {
//     label: string;
//     value: number;
// }

export const AddVolunteerUserModal = ({ onClose, volunteerEditData }: { onClose: () => void, volunteerEditData: any }) => {
    const formName = 'add-user-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<VolunteerUserFormValues>();
    const { t } = useTranslation();
    const [eventList, setEventList] = useState<eventListType[]>([]);

    const { isLoading, mutate } = useCreateVolunteerUser();
    const { mutate: update_mutate } = useUpdateVolunteerUser();

    const handleClose = () => {
        onClose();
        form.resetFields();
    }

    const getInitialValues = {
        [VolunteerUserFormField.Name]: undefined,
        [VolunteerUserFormField.EventId]: undefined,
        [VolunteerUserFormField.Email]: undefined,
        [VolunteerUserFormField.PhoneNumber]: undefined,
        [VolunteerUserFormField.Password]: undefined,
        [VolunteerUserFormField.ConfirmPassword]: undefined,
        [VolunteerUserFormField.IsRefundAllowed]: undefined,
        [VolunteerUserFormField.Status]: undefined,
    };

    const EventList = async () => {
        try {
            const apiRes = await getVendingEvents(`Flag=${'Events'}`);
            setEventList(apiRes.items);

        } catch (error) {
            message.error("error")

        }

    }

    useEffect(() => {
        EventList();

        if (volunteerEditData) {

            form.setFieldsValue(volunteerEditData);
            form.setFieldsValue({ EventId: volunteerEditData.EventName });

        } else {
            form.resetFields();
        }

    }, [volunteerEditData, form]);

    const handleUpdate = (formData: VolunteerUserFormValues) => {

        if (volunteerEditData) {
            formData.Id = volunteerEditData.Id;
            formData.EventId = volunteerEditData.EventId;
            formData.Password = '';
            update_mutate(formData, { onSuccess: handleClose });
        } else {
            formData.Id = 0;
            mutate(formData, { onSuccess: handleClose });
        }

    };



    const handleChange = (value: any) => {
        volunteerEditData.EventId = value;
        // { value: "lucy", key: "lucy", label: "Lucy (101)" }
    };

    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{volunteerEditData ? 'Edit volunteer user' : t('volunteer.add_modal_text')}</h4>
                </div>
                <div className='modal_body'>
                    <Form<VolunteerUserFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <VolunteerInfoFormItems eventList={eventList} handleChange={handleChange} />
                        {!volunteerEditData ? (<PasswordFormItems />) : ''}

                        <div className='form-button'>
                            <Button type="primary" htmlType='submit' className="btn" >Save</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

