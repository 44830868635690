
import { Empty, Skeleton, Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { Volunteer } from 'types';

import { LogsUserFilterFormValuesPaginated } from '../users-filter-form/logs-types';

type UsersTableProps = {
  isLoading: boolean;
  data: any;
  tableParams: LogsUserFilterFormValuesPaginated;
  updateParams: Dispatch<SetStateAction<LogsUserFilterFormValuesPaginated>>;
};

export const LogsUsersTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,

}: UsersTableProps) => {

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Volunteer> | SorterResult<Volunteer>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'
          : '-name',
    });
  };


  return (
    <>

      <StyledTableWrapper>
        <StyledTableHeader>
          <RecordCountBadge
            text={'Total Logs '}
            count={data?.total}
          />
        </StyledTableHeader>
        <Table<any>
          onChange={handleTableChange}
          pagination={{
            current: tableParams.page,
            defaultPageSize: tableParams.size,
            total: data?.total,
          }}
          scroll={{ x: 1440 }}
          locale={{
            emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
          }}
          loading={isLoading && { indicator: <div><TableLoader /></div> }}
          dataSource={isLoading ? [] : data?.items}
          rowKey='id'
          className=' cstltblwthldr'

        >
          <Column<any>
            title={'Name'}
            dataIndex={'FullName'}
            sorter={true}
            sortDirections={['ascend', 'descend', 'ascend']}
            defaultSortOrder={'ascend'}
          />
          <Column<any>
            title={'Phone'}
            dataIndex={'PhoneNumber'}
          />
          <Column<any>
            title={'Email'}
            dataIndex={'Email'}

          />
          <Column<any>
            title={'Login Time'}
            // dataIndex={'LoginTime'}
            render={(_, record) => record.LoginTime && moment(record.LoginTime).format("YYYY-MM-DD HH:MM:SS")}
          />

          <Column<any>
            title={'Logout Time'}
            // dataIndex={'LogoutTime'}
            render={(_, record) => record.LogoutTime && moment(record.LogoutTime).format("YYYY-MM-DD HH:MM:SS")}
          />

        </Table>
      </StyledTableWrapper>
    </>
  );
};
