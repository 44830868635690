import { Button, Form, Grid, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportFilterFormField, ReportFilterFormValues } from './types';

const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
    formId: string;
    handleFilter: (values: ReportFilterFormValues) => void;
    initialValues: ReportFilterFormValues;
    closeModal?: () => void;
};

export const ReportFilterForm = ({
    formId,
    handleFilter,
    initialValues,
    closeModal,
}: UsersFilterFormProps) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<ReportFilterFormValues>();
    const { t } = useTranslation();
    const screens = useBreakpoint();

    const onReset = () => {
        form.resetFields();
        handleFilter({ ...initialValues });
    };

    const onFinish = (formValues: ReportFilterFormValues) => {
        handleFilter(formValues);
        closeModal?.();
    };


    return (

        <Form<ReportFilterFormValues>
            id={formId}
            name={formId}
            className='filters-form'
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            onFinishFailed={() => {
                setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
        >
            <ContentFilters.FormItemsWrapper>
                <StandardItemsWrapper>
                    <Form.Item
                        label={'Report Type'}
                        name={ReportFilterFormField.ReportType}
                    >
                        <Select
                            defaultValue="Select Report Type"

                            options={[
                                { value: 'FinancialReport', label: 'Financial Report' },
                                { value: 'InvoiceReport', label: 'Invoice Report' },
                                { value: 'ParticipantReport', label: 'Participant Report' },
                                { value: 'ItemSalesReport', label: 'Item Sales Report' },
                            ]}
                        />
                    </Form.Item>



                    {screens.lg && (
                        <ContentFilters.ButtonsWrapper className='rprt_filter'>
                            <Button type='primary' htmlType='submit'>
                                {t('common.buttons.filter', { count: 1 })}
                            </Button>
                            <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
                        </ContentFilters.ButtonsWrapper>
                    )}
                </StandardItemsWrapper>
            </ContentFilters.FormItemsWrapper>

        </Form>
    );
};
