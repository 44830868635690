import { Button, DatePicker, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { payFormatDate } from 'utils/payload-date-';

import { MainEventsFilterFormField, MainEventsFilterFormValues } from './types';

const { useBreakpoint } = Grid;

type EventsFilterFormProps = {
    formId: string;
    handleFilter: (values: MainEventsFilterFormValues) => void;
    initialValues: MainEventsFilterFormValues;
    closeModal?: () => void;
    sportsData: any;
    currencyData: any;
};

export const MainEventFilterForm = ({
    formId,
    handleFilter,
    initialValues,
    closeModal,
    sportsData,
    currencyData
}: EventsFilterFormProps) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<MainEventsFilterFormValues>();
    const { t } = useTranslation();
    const screens = useBreakpoint();

    const onReset = () => {
        form.resetFields();
        handleFilter({ ...initialValues });
    };

    const handleOnFinish = (values: MainEventsFilterFormValues) => {
        const formattedValues = {
            ...values,
            [MainEventsFilterFormField.DateFrom]: values?.start_date && payFormatDate(values?.start_date),
            [MainEventsFilterFormField.DateTo]: values?.end_date && payFormatDate(values?.end_date),
            [MainEventsFilterFormField.EventDate]: values?.event_date && payFormatDate(values?.event_date),
        };

        handleFilter(formattedValues as any);
        closeModal?.();
    };

    return (
        <Form<MainEventsFilterFormValues>
            id={formId}
            name={formId}
            form={form}
            layout='vertical'
            onFinish={handleOnFinish}
            initialValues={initialValues}
            onFinishFailed={() => {
                setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
            className='filter_f'
        >
            <ContentFilters.FormItemsWrapper>
                <StandardItemsWrapper className='fliter_lst' style={{ paddingBottom: 16 }}>
                    <Form.Item<MainEventsFilterFormValues>
                        label={'Event Name'}
                        name={MainEventsFilterFormField.EventName}

                    >
                        <Input placeholder={'Event Name'} />
                    </Form.Item>
                    <Form.Item<MainEventsFilterFormValues>
                        label={"Start Date"}
                        name={MainEventsFilterFormField.DateFrom}
                    >
                        <DatePicker placeholder={"Start Date"} format="DD-MM-YYYY" />
                    </Form.Item>

                    <Form.Item<MainEventsFilterFormValues>
                        label={"End Date"}
                        name={MainEventsFilterFormField.DateTo}
                    >
                        <DatePicker placeholder={"End Date"}
                            format="DD-MM-YYYY"
                        />
                    </Form.Item>

                    <Form.Item<MainEventsFilterFormValues>
                        label={"Event Date"}
                        name={MainEventsFilterFormField.EventDate}
                    >
                        <DatePicker placeholder={"Event Date"} format="DD-MM-YYYY" />
                    </Form.Item>

                    <Form.Item<MainEventsFilterFormValues>
                        label={t('events.formEvents.sportsCategory.label')}
                        name={MainEventsFilterFormField.SportsCategory}
                    >
                        <Select
                            options={sportsData?.items.map((res: any) => ({ label: res.Text, value: res.Id }))}
                            placeholder={t('events.formEvents.sportsCategory.placeholder')}
                            className='has-prefix-icon'

                        />
                    </Form.Item>


                    <Form.Item<MainEventsFilterFormValues>
                        label={t('events.formEvents.currency.label')}
                        name={MainEventsFilterFormField.Currency}
                    >
                        <Select
                            options={currencyData?.items.map((res: any) => ({ label: res.Text, value: res.Id }))}
                            placeholder={t('events.formEvents.currency.placeholder')}
                            className='has-prefix-icon'
                        />
                    </Form.Item>

                    {screens.lg && (
                        // <ContentFilters.ButtonsWrapper>
                        <Form.Item className='filt_btn'>
                            <Button type='primary' htmlType='submit' className='btn_common'>
                                {t('events.buttons.filter', { count: 1 })}
                            </Button>
                            <Button onClick={onReset}>{t('events.buttons.reset')}</Button>
                        </Form.Item>
                        // </ContentFilters.ButtonsWrapper>
                    )}
                </StandardItemsWrapper>
            </ContentFilters.FormItemsWrapper>


        </Form>
    );
};
