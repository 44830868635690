import { useGetTickets } from 'features/events/use-cases/get-price-category';
import { usePaginationParams } from 'hooks';

import { EventsFilterFormPaginated, EventsFilterFormValues } from '../../events-filter-form';
import { PriceTable } from '../price-table/price-table';


const EventDetailsPriceCategory = ({ id }: { id: string }) => {

    const initialFilterValues: EventsFilterFormValues = {
        field_name: undefined,
    };

    const { params, updateParams } =
        usePaginationParams<EventsFilterFormPaginated>({
            page: 1,
            size: 10,
            order_by: '-name',
            ...initialFilterValues,
        });

    const { data, isFetching, refetch } = useGetTickets(id, params, { retry: false });

    return (
        <>
            <PriceTable
                data={data}
                isLoading={isFetching}
                tableParams={params}
                refetch={refetch}
                updateParams={updateParams}

            />
        </>
    )
}

export default EventDetailsPriceCategory;