import {
    QueryFunction,
    QueryKey,
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    useQuery,
    UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
    EventsApiService,
    EventsParams,
    EventsResponse,
} from 'features/events/api/events-api.service';

import { ticketsQueryKeys } from './tickets-query-keys';


const { getPriceCategory } = EventsApiService();

const getEventsQuery = (
    id: string,
    params: EventsParams,
    options?: UseQueryOptions<EventsResponse>,
): {
    queryKey: QueryKey;
    queryFn: QueryFunction<EventsResponse>;
    options?: UseQueryOptions<EventsResponse>;
} => ({
    queryKey: ticketsQueryKeys.list(params),
    queryFn: ({ signal }) => getPriceCategory(id, params, { config: { signal } }),
    ...options,
});

export const useGetTickets = (
    id: string,
    params: EventsParams,
    options?: UseQueryOptions<EventsResponse>,
) => {
    const { message } = App.useApp();

    return useQuery<EventsResponse, Error>(
        getEventsQuery(id, params, {
            onError: (error: unknown) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            ...options,
        }),
    );
};

export const useGetEventsInfiniteQuery = (
    id: string,
    params: EventsParams,
    options?: UseInfiniteQueryOptions<EventsResponse>,
) => {
    const { message } = App.useApp();

    return useInfiniteQuery({
        queryKey: ticketsQueryKeys.list(params),
        queryFn: ({ signal, pageParam }) =>
            getPriceCategory(
                id,
                { ...params, page: pageParam || params.page },
                { config: { signal } },
            ),
        getNextPageParam: (lastPage) => {
            const hasMorePages = lastPage.pages > lastPage.page;

            return hasMorePages ? lastPage.page + 1 : undefined;
        },
        onError: (error: unknown) => {
            const uiMessage = handleError(error);

            message.error(uiMessage);
        },
        ...options,
    });
};



