import { DownloadOutlined } from '@ant-design/icons';
import { Button, Empty, message, Skeleton, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, Table } from 'components';
import { TableLoader } from 'components/table-loader/table-loader';
import { usePermissions } from 'features/users';
import { EndUserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { Dispatch, SetStateAction } from 'react';
import { User } from 'types';

type ParticipateTableProps = {
    isSocialListLoading: boolean;
    DeliveryList: any;
    refetch: any,
    tableParams: EndUserFilterFormValuesPaginated;
    updateParams: Dispatch<SetStateAction<EndUserFilterFormValuesPaginated>>;
    isFetching: boolean;
};
export const ReportsTableList = ({
    DeliveryList,
    isSocialListLoading,
    tableParams,
    updateParams,
    isFetching,
}: ParticipateTableProps) => {

    const permissions = usePermissions();

    if (!permissions) return null;

    const handleTableChange = (
        pagination: TablePaginationConfig,
        sorter: SorterResult<User> | SorterResult<User>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: Array.isArray(sorter)
                ? undefined
                : sorter.order === 'ascend'
                    ? 'name'
                    : '-name',
        });
    };

    const formatDate = (dateTimeString: string | number | Date) => {
        if (!dateTimeString) {
            return ''; // Return an empty string if no date is provided
        }

        const date = new Date(dateTimeString);
        if (isNaN(date.getTime())) {
            return ''; // Return an empty string if the date is invalid
        }

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear().toString();

        return `${day}/${month}/${year}`;
    };


    const isValidUrl = (url: string) => {
        try {
            new URL(url);

            return true;
        } catch (_) {

            return false;
        }
    }

    const handleDownloadFile = (file: string) => {
        if (isValidUrl(file)) {
            const link = document.createElement('a');
            link.href = file;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            message.success("Reports downloaded successfully")
        } else {
            message.error("file is not available")
        }

    }

    return (
        <>
            {/* Your modal component */}
            {/* <StyledTableHeader>
                <div style={{ marginTop: '32px', color: 'black' }}>
                    <RecordCountBadge
                        text={"Reports found"}
                        count={isSocialListLoading ? 0 : DeliveryList?.total || 0}
                    />
                </div>
            </StyledTableHeader> */}



            <Table className='cstltblwthldr scrl_tble'
                locale={{
                    emptyText: isFetching ? <Skeleton active={true} /> : <Empty />
                }}
                loading={isSocialListLoading && { indicator: <div><TableLoader /></div> }}
                dataSource={isSocialListLoading ? [] : DeliveryList?.items}

                rowKey='Id'
                onChange={handleTableChange}
                pagination={{
                    current: tableParams?.page,
                    defaultPageSize: tableParams?.size,
                    total: DeliveryList?.total,
                }}
                scroll={{ x: 1240 }}
            // onRow={(record: any) => {
            //     return {
            //         onClick: () => {
            //             const selection = window?.getSelection()?.toString();

            //             if (!selection?.length) {
            //                 navigate(getRoute(ROUTES.TRANSACTION_INVOICE_LIST_EDIT, record.booking_id));
            //             }
            //         },
            //     };
            // }}
            >
                <Column
                    title={'Report Type'}
                    dataIndex={'ReportType'}

                />

                <Column
                    title={'File Name'}
                    dataIndex={'FileName'}

                />

                <Column
                    title={'Start Date'}
                    // dataIndex={'paid_at'}
                    render={(record: any) => {
                        return <h1>{formatDate(record?.StartDate)} </h1>
                    }}
                />

                <Column
                    title={'End Date'}
                    // dataIndex={'paid_at'}
                    render={(record: any) => {
                        return <h1>{formatDate(record?.EndDate)} </h1>
                    }}
                />
                <Column
                    title={'Status'}
                    render={(record: any) => {
                        return <>{record.IsProcess ? <Button type="primary" className='dwmld_btn' onClick={() => handleDownloadFile(record.FileUrl)} icon={<DownloadOutlined />}>Download</Button > : <Button type="primary" className='prepare_btn'>Preparing</Button>} </>
                    }}

                />

                {/* <Column
                    title={'Action'}
                    render={(record: any) => {
                        return <>
                            <Tooltip title="Download">
                                <Button shape="circle" type="primary" icon={<DownloadOutlined />} onClick={() => handleDownloadFile(record.FileName)} style={{ backgroundColor: 'green' }} />
                            </Tooltip>
                        </>
                    }}

                /> */}

            </Table >
        </>
    );
};
