import { PaginatedRequestParams } from "api";

export enum LogsUserFilterFormField {
    Name = 'full_name',
    Email = 'email',
    PhoneNumber = 'phone',
}

export type LogsUserFilterFormValues = {
    [LogsUserFilterFormField.Name]: string | undefined;
    [LogsUserFilterFormField.Email]: string | undefined;
    [LogsUserFilterFormField.PhoneNumber]: string | undefined;

};

export type LogsUserFilterFormValuesPaginated = LogsUserFilterFormValues &
    PaginatedRequestParams;
