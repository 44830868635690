import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { PageLoader } from 'components/page-loader/page-loader';
import { useGetEventCoupon } from 'features/events/use-cases/get-coupon';
import { usePermissions } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useEffect, useState } from 'react';

import { AddCouponModal } from './coupon-management/Add-coupon-madal/add-coupon-modal';
import { CouponTabel } from './coupon-management/coupon-tabel';
import { CouponTabFilterForm } from './coupon-tabs-filter/coupon-tab-list-filter';
import { EventCouponBulkUpload } from './event-coupon-bulk-upload';

const { useBreakpoint } = Grid;


const getInitialFilterValues: any = {
    coupon_title: undefined,
    coupon_code: undefined

};

export const CouponTab = ({ id, tab }: { id: string; tab: string }) => {
    const screens = useBreakpoint();
    // const { id } = useParams();
    const permissions = usePermissions();
    const { params, updateParams, resetPage } =
        usePaginationParams<any>({
            flag: "EVENT_COUPONS",
            event_id: id,
            page: 1,
            size: 10,
            order_by: '-rank',
        });

    const { data, isFetching, isLoading, refetch } = useGetEventCoupon(params, { retry: true });

    useEffect(() => {
        if (tab === "3") {
            refetch();
        }
    }, [tab, id]);

    const [couponEditData, setCouponEditData] = useState<any>(undefined);
    const [showCouponModal, setShowCouponModal] = useState(false);

    const shouldHandleFieldCreation = permissions?.fields.add;


    const handleCloseModal = () => {
        setShowCouponModal(false);
        setCouponEditData(undefined);
        document.body.style.overflow = 'visible';
    }

    const handleOpen = () => {
        setShowCouponModal(true);
        document.body.style.overflow = 'hidden';
    }

    const formId = 'coupon-tabs-filters-form';

    const handleFiltersChange = (values: any) => {
        updateParams(values);
        resetPage();
        refetch()
    };



    return (
        <>
            <ContentTopBar
                title={""}
                renderButtons={
                    <>
                        {shouldHandleFieldCreation ? (
                            <div className='two_btn'>
                                <EventCouponBulkUpload id={id} />
                                <Button

                                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                                    onClick={handleOpen}
                                    type='primary'
                                >
                                    Add Coupon
                                </Button>
                            </div>
                        ) : null}
                    </>
                }
            />

            <ContentFilters formId={formId}>
                <CouponTabFilterForm
                    formId={formId}
                    handleFilter={handleFiltersChange}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>

            {isFetching ?
                <PageLoader />
                :
                <CouponTabel
                    data={data}
                    isLoading={isLoading}
                    tableParams={params}
                    updateParams={updateParams}
                    refetch={refetch}
                    couponEditData={couponEditData}
                    setCouponEditData={setCouponEditData}
                />}

            <div className={`cstmrightmdl ${showCouponModal && "show"}`}>
                <AddCouponModal showCouponModal={showCouponModal} refetch={refetch} onClose={handleCloseModal} couponEditData={couponEditData} />
            </div>

        </>
    );
};

