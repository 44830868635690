import { CopyOutlined, DeleteOutlined, ExclamationCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Empty, Grid, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
// import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
  EventsResponse,
} from 'features/events';
import { useCopyEvent } from 'features/events/use-cases/copy-event';
import { useEventActiveStatus } from 'features/events/use-cases/event-active-status';
import { useEventDeleteStatus } from 'features/events/use-cases/event-delete-status';
import { useNewPermissions, usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Event } from 'types/event';

import { MainEventsFilterFormPaginated } from '../main-event-filter-form/types';


const { useBreakpoint } = Grid;

type EventsTableProps = {
  isLoading: boolean;
  data?: EventsResponse;
  tableParams: MainEventsFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<MainEventsFilterFormPaginated>>;
  refetch: () => void;
};

export const EventsTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
  refetch,
}: EventsTableProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const userPermission = useNewPermissions();

  const [loadingSwitchId, setLoadingSwitchId] = useState('');

  const { confirm } = Modal;

  const showDeleteConfirm = (event: any, record: any) => {
    event.stopPropagation();
    confirm({
      title: 'Are you sure you want to delete this event?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(record);
      },
    });
  };

  const showCopyConfirm = (event: any, id: any) => {
    event.stopPropagation();
    confirm({
      title: 'Are you sure you want to copy this event?',
      icon: <CopyOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleCopy(id);
      },
    });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,

  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: ""
    });
  };


  // const handleTableDeleteChange = (newPage: number
  // ) => {
  //   updateParams({
  //     ...tableParams,
  //     page: newPage,
  //     size: 10
  //   })
  // };



  const handleNavigate = (record: any) => {
    navigate(`/events/${record.id}/details`); // Navigate with encoded name
  };

  const formatDate = (dateTimeString: string | number | Date) => {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  };

  const formatDateTime = (dateTimeString: string | number | Date) => {
    const dateTime = new Date(dateTimeString);

    // Extract date components
    const day = dateTime.getDate().toString().padStart(2, '0');
    const month = (dateTime.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = dateTime.getFullYear().toString();

    // Extract time components
    let hours = dateTime.getHours();
    const amPM = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    // const seconds = dateTime.getSeconds().toString().padStart(2, '0');

    // Construct formatted date-time string
    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${amPM}`;

    return formattedDateTime;
  };


  const { mutate } = useEventDeleteStatus();

  const handleDelete = (record: any) => {
    // event.stopPropagation();
    const deletedFormData = {
      id: record.id,
      status: false,
      flag: "EVENTS_DELETE"
    }
    mutate(deletedFormData, {
      // onSuccess: () => {
      //   handleTableDeleteChange();
      // }


    });

    // if (data && (data.total % 10 === 1)) {
    //   const newPage = data.page - 1;
    //   handleTableDeleteChange(newPage);
    // }

  }

  const { mutate: copyMutate, isLoading: copyLoading } = useCopyEvent();

  const { mutate: updateStatusMutate, isLoading: statusLoading } = useEventActiveStatus();

  const handleUpdateStatus = (id: any, isChecked: any) => {
    const statusFormData = {
      id: id,
      status: isChecked,
      flag: "EVENTS_SET_STATUS"
    }
    updateStatusMutate(statusFormData, { onSuccess: handleResetId });
  }

  const handleResetId = () => {
    setLoadingSwitchId("");
  }

  const handleCopy = (id: any) => {
    copyMutate(id, {
      onSuccess: () => {
        refetch();
      }
    });
  }

  if (!permissions) return null;
  // useEffect(()=>{
  //     console.log('userPermission'+ userPermission?.user_role);
  // },[]);

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('events.foundCompanies')}
          count={data?.total || 0}
        />
      </StyledTableHeader>
      <Table<Event>
        // loading={isLoading}
        // className='cstltblwthldr'
        locale={{
          emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
        }}
        loading={isLoading && { indicator: <div><TableLoader /></div> }}
        dataSource={isLoading ? [] : data?.items}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,

        }}
        className='cstm_table cstltblwthldr'
        // scroll={tableWidth}
        onRow={(record) => {
          return {
            onClick: () => {
              const selection = window?.getSelection()?.toString();

              if (!selection?.length) {
                // navigate(getRoute(ROUTES.EVENTS_DETAILS, record.id));
                handleNavigate(record)
              }
            },
          };
        }}
      >
        <Column<Event>
          title={'Event Name'}
          // dataIndex={'event_name'}
          // sorter={true}
          // sortDirections={['ascend', 'descend', 'ascend']}
          // defaultSortOrder={'descend'}
          width={screens.xxl ? 'auto' : 200}
          // ellipsis
          onCell={(record) => {
            return {
              onClick: () => {
                const selection = window?.getSelection()?.toString();
                if (!selection?.length) {
                  handleNavigate(record)
                }
              },
            };
          }}

          render={(value: any, record: any) => {
            return (
              <span className='arow_tab'>
                <RightOutlined />
                <h1>{record.event_name}</h1>
              </span>

            )
          }}
        />



        <Column<Event>
          title={'Start Date'}
          // dataIndex={'start_date'}
          width={screens.xxl ? 'auto' : 120}

          render={(record: any) => {
            return <h1>{formatDate(record?.start_date)} </h1>
          }}
        />

        <Column<Event>
          title={'End Date'}
          // dataIndex={'end_date'}
          width={screens.xxl ? 'auto' : 120}
          render={(record: any) => {
            return <h1>{formatDate(record?.end_date)} </h1>
          }}
        />

        <Column<Event>
          title={'Event Date'}
          // dataIndex={'event_date'}
          width={screens.xxl ? 'auto' : 120}
          render={(record: any) => {
            return <h1>{formatDateTime(record?.event_date)} </h1>
          }}
        />

        <Column<Event>
          title={'Sports Category'}
          dataIndex={'category_name'}
          width={screens.xxl ? 'auto' : 120}
        />
        {/* <Column<Event>
          title={'Start Date'}
          dataIndex={'start_date'}
          width={screens.xxl ? 'auto' : 200}
          className='cell-direction-rtl'
          ellipsis
          render={(text: string) => (
            moment(text).format('YYYY-MM-DD') 
          )}
        />

        <Column<Event>
          title={'End Date'}
          dataIndex={'end_date'}
          width={screens.xxl ? 'auto' : 240}
          ellipsis
          render={(text: string) => (
            moment(text).format('YYYY-MM-DD') // Adjust the format as needed
          )}
        /> */}
        <Column<Event>
          title={'Currency'}
          dataIndex={'currency_name'}
          width={screens.xxl ? 'auto' : 120}
        />
        {userPermission?.user_role != "SuffixAgent" ? (
          <Column<Event>
            title={"Action"}
            width={screens.xxl ? 'auto' : 162}
            // dataIndex={'is_active'}
            render={(_, record) => (
              <>
                <Space>
                  {/* <Tooltip title="view">
                  <Button shape="circle" icon={<EyeOutlined />}
                    onClick={(event) => handleView(event)}
                  />
                </Tooltip> */}

                  {/* <Tooltip title="Edit">
                  <Button shape="circle" icon={<EditOutlined />}
                    onClick={(event) => handleOpenModal(event)}
                  />
                </Tooltip> */}


                  <Tooltip title="Copy">
                    <Button shape="circle" icon={<CopyOutlined />} onClick={(event) => showCopyConfirm(event, record.id)} />
                  </Tooltip>

                  <Tooltip title="Delete">
                    <Button shape="circle" loading={copyLoading} icon={<DeleteOutlined />} onClick={(event) => showDeleteConfirm(event, record)} />
                  </Tooltip>
                  <Tooltip title="Is Active">
                    <Switch
                      defaultChecked={record.is_active}
                      checked={record.is_active}
                      loading={loadingSwitchId == record.id && statusLoading}
                      onChange={(isChecked) => {
                        setLoadingSwitchId(record.id);
                        handleUpdateStatus(record.id, isChecked)
                      }}
                      onClick={(checked, event) => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    />

                  </Tooltip>

                </Space>
              </>
            )}
          />

        ) : null}
      </Table>
    </StyledTableWrapper>
  );
};
