import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { queryClient } from 'providers';

import { EventsApiService } from '../api/events-api.service';
import { eventsQueryKeys } from './query-keys';
const { updateStatus } = EventsApiService();

export const UpdateStatus = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: any) => {
      return updateStatus(payload as any);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: eventsQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: eventsQueryKeys.detail(variables.id as any),
          }),
        ]);
        message.success("Status updated successfully");
      },
    },
  );
};
