import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Grid, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
    EventsFilterFormPaginated,
} from 'features/events';
import { useEventActiveStatus } from 'features/events/use-cases/event-active-status';
import { useEventDeleteStatus } from 'features/events/use-cases/event-delete-status';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';

import { AddOrganizerModal } from './add-organizer-modal';
import { Organizer } from './types';
const { useBreakpoint } = Grid;

type OrganizerTableProps = {
    isLoading: boolean;
    data?: any;
    tableParams: EventsFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<EventsFilterFormPaginated>>;
    organizerData: any;
    setOrganizerData: any;
    refetch: () => void;
};

export const OrganizerTable = ({
    data,
    isLoading,
    tableParams,
    updateParams,
    organizerData,
    setOrganizerData,
    refetch
}: OrganizerTableProps) => {

    const screens = useBreakpoint();
    const permissions = usePermissions();

    const { isLoading: deleteLoading, mutate } = useEventDeleteStatus();

    const { mutate: updateStatusMutate, isLoading: statusLoading } = useEventActiveStatus();


    const handleTableChange = (
        pagination: TablePaginationConfig,
        // filters: Record<string, FilterValue | null>,
        // sorter: SorterResult<Organizer> | SorterResult<Organizer>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: "",
        });
    };

    const tableWidth = screens.xxl ? { x: 1440 } : { x: 1200 };

    const [showAddEventModal, setShowAddEventModal] = useState<boolean>(false);

    if (!permissions) return null;

    const handleCloseModal = () => {
        setShowAddEventModal(false);
        setOrganizerData(undefined);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = (record: any) => {
        setShowAddEventModal(true);
        setOrganizerData(record);
        document.body.style.overflow = 'hidden';
    }

    const { confirm } = Modal;

    const showDeleteConfirm = (id: any) => {
        confirm({
            title: 'Are you sure you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(id);
            },
        });
    };





    const handleDelete = (id: any) => {
        const deletedFormData = {
            id: id,
            status: false,
            flag: "ORGANIZER_DELETE"
        }
        mutate(deletedFormData, { onSuccess: refetch });
    }




    const handleUpdateStatus = (id: any, isChecked: any) => {
        const statusFormData = {
            id: id,
            status: isChecked,
            flag: "ORGANIZER_SET_STATUS"
        }
        updateStatusMutate(statusFormData, { onSuccess: refetch });
    }

    return (
        <>
            <div className={`cstmrightmdl ${showAddEventModal && "show"}`}>
                <AddOrganizerModal showAddEventModal={showAddEventModal} onClose={handleCloseModal} organizerData={organizerData} refetch={refetch} />
            </div>


            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={"Organizer found"}
                        count={data?.total || 0}
                    />
                </StyledTableHeader>
                <Table<Organizer>
                    className="cstltblwthldr"
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading && { indicator: <div><TableLoader /></div> }}
                    dataSource={isLoading ? [] : data?.items}
                    rowKey='id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.page,
                        defaultPageSize: tableParams.size,
                        total: data?.total,
                    }}
                    scroll={tableWidth}

                >
                    <Column<Organizer>
                        title={'S.No'}
                        key="serialNumber"
                        width={100}
                        ellipsis
                        render={(text, record, index) => index + 1}
                    />
                    <Column<Organizer>
                        title={'Name'}
                        dataIndex={'name'}
                        sorter={true}
                        sortDirections={['ascend', 'descend', 'ascend']}
                        defaultSortOrder={'descend'}
                        width={screens.xxl ? 'auto' : 240}
                        ellipsis

                    />
                    <Column<Organizer>
                        title={'Link'}
                        dataIndex={'link'}
                        width={screens.xxl ? 'auto' : 240}
                        ellipsis
                    />
                    <Column<Organizer>
                        title={'Contact No'}
                        dataIndex={'contact_no'}
                        width={screens.xxl ? 'auto' : 200}
                        className='cell-direction-rtl'
                    />


                    <Column<Organizer>
                        title={'Email'}
                        dataIndex={'email'}
                        width={screens.xxl ? 'auto' : 240}
                    />
                    <Column<Organizer>
                        title={'Address'}
                        dataIndex={'address'}
                        width={screens.xxl ? 'auto' : 240}
                    />

                    <Column<Organizer>
                        title={"Action"}
                        width={screens.xxl ? 'auto' : 162}
                        dataIndex={'is_active'}
                        render={(_, record) => (
                            <>
                                <Space>

                                    <Tooltip title="Edit">
                                        <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                                    </Tooltip>

                                    <Tooltip title="Delete">
                                        <Button shape="circle" loading={deleteLoading} icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(record.id)} />
                                    </Tooltip>
                                    <Tooltip title="Is Active">
                                        <Switch
                                            defaultChecked={record.is_active}
                                            checked={record.is_active}
                                            loading={statusLoading}
                                            onChange={(isChecked) => {
                                                handleUpdateStatus(record.id, isChecked);
                                                record.is_active = isChecked;
                                            }}

                                        />
                                    </Tooltip>
                                </Space>
                            </>
                        )}
                    />

                </Table>
            </StyledTableWrapper>
        </>
    );
};

