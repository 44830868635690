import { ConfigProvider } from 'antd';
import { validateMessages } from 'locales/ant-design';
import { ReactNode } from 'react';
import { customAntColors, theme } from 'styles';

interface AntDesignConfigProviderProps {
  children: ReactNode;
}

export const AntDesignConfigProvider = ({
  children,
}: AntDesignConfigProviderProps) => {
  return (
    <ConfigProvider
      form={{
        validateMessages: validateMessages,
        requiredMark: false,
      }}
      theme={{
        token: {
          ...customAntColors,
          fontFamily: "'Graphik', 'GraphikArabic'",
          fontWeightStrong: 500,
        },
        components: {
          Layout: {
            headerBg: theme.colors.greenDark,
            colorText: theme.colors.gray1,
          },
          Menu: {
            itemSelectedColor: theme.colors.greenDark,
          },
          Button: {
            colorLink: theme.colors.greenLight,
            colorLinkHover: theme.colors.greenDark,
            colorLinkActive: theme.colors.greenDark,
          },
          Typography: {
            colorSuccess: theme.colors.colorPrimary,
            fontWeightStrong: 500,
          },
          Anchor: {
            colorText: theme.colors.greenLight,
            colorLink: theme.colors.greenLight,
            colorLinkHover: theme.colors.greenLight,
            colorLinkActive: theme.colors.red5,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
