import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Empty, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
  EventsResponse,
} from 'features/events';
import { useStatusResponse } from 'features/shop-item/use-cases/update-status-response-msg';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';
import { Event } from 'types/event';

import { AreaFilterFormPaginated } from '../area-filter-form';
import { AddAreaModal } from './add-area-modal/add-area-modal';


type EventsTableProps = {
  isLoading: boolean;
  data?: EventsResponse;
  tableParams: AreaFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<AreaFilterFormPaginated>>;
  refetch: () => void;
  areaEditData: any;
  setAreaEditData: any;
};

export const AreaTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
  refetch,
  areaEditData,
  setAreaEditData
}: EventsTableProps) => {
  const permissions = usePermissions();

  const { mutate: mutateUpdateEvent, isLoading: isLoadingUpdate } =
    useStatusResponse();


  const handleTableChange = (
    pagination: TablePaginationConfig,
    // filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Event> | SorterResult<Event>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'
          : '-name',
    });
  };

  const handleDelete = async (id: any) => {
    mutateUpdateEvent({
      id: id,
      flag: "AREA_DELETE",
      status: true
    });
  };

  const [showAreaModal, setShowAreaModal] = useState(false);

  const handleCloseModal = () => {
    setShowAreaModal(false);
    setAreaEditData(undefined);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = (record: any) => {
    setShowAreaModal(true);
    setAreaEditData(record);
    document.body.style.overflow = 'hidden';
  }

  if (!permissions) return null;

  return (
    <>
      <div className={`cstmrightmdl ${showAreaModal && "show"}`}>
        <AddAreaModal showAreaModal={showAreaModal} refetch={refetch} onClose={handleCloseModal} areaEditData={areaEditData} />

      </div>

      <StyledTableWrapper>
        <StyledTableHeader>
          <RecordCountBadge
            text={"Area found"}
            count={data?.total || 0}
          />
        </StyledTableHeader>
        <Table<Event>
          className="cstltblwthldr"
          locale={{
            emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
          }}
          loading={isLoading && { indicator: <div><TableLoader /></div> }}
          dataSource={isLoading ? [] : data?.items}
          rowKey='id'
          onChange={handleTableChange}
          pagination={{
            current: tableParams.page,
            defaultPageSize: tableParams.size,
            total: data?.total,
          }}
        // scroll={tableWidth}
        >

          <Column<Event>
            title={'Country'}
            dataIndex={'name'}
          // width={screens.xxl ? 'auto' : 200}
          // render={(record) => (
          //   <>
          //     <RightOutlined />
          //     <span style={{ marginLeft: 8 }}>{record.name}</span>

          //   </>
          // )}

          // onCell={(record: any) => ({
          //   onClick: () => {
          //     handleOpenModal(record);
          //   },
          // })}
          />

          <Column<Event>
            title={'Area'}
            dataIndex={'name'}
          // width={screens.xxl ? 'auto' : 200}
          />

          <Column<Event>
            title={"Is Active"}
            // width={screens.xxl ? 'auto' : 162}
            dataIndex={'is_active'}
            render={(_, record) => (
              <>
                <Space>
                  <Tooltip title="Edit">
                    <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                  </Tooltip>

                  <Tooltip title="Delete">
                    <Button shape="circle" loading={isLoadingUpdate} onClick={() => handleDelete(record.id)} icon={<DeleteOutlined />} />
                  </Tooltip>
                  <Tooltip title="Is Active">
                    <Switch
                      defaultChecked={record.is_active}
                      checked={record.is_active}
                      loading={isLoadingUpdate}
                      onChange={(isChecked) =>
                        mutateUpdateEvent({
                          id: record.id,
                          flag: "AREA_SET_STATUS",
                          status: isChecked,
                        })
                      }
                      onClick={(checked, event) => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    />
                  </Tooltip>
                </Space>
              </>
            )}
          />
        </Table>
      </StyledTableWrapper>
    </>

  );
};
