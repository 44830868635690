export enum SportsCatFormField {
    Id = 'id',
    CategoryName = 'category_name',
    File = 'file',
    IsActive = 'is_active',
    ImageUrl = "image_url"
}

export type SportsCat = {
    Id: string | undefined;
    CategoryName: string;
    isActive: boolean;
    File: any;
    image_url: any;
};

export type SportsCatFormValues = {
    [SportsCatFormField.Id]: string | undefined;
    [SportsCatFormField.CategoryName]: string;
    [SportsCatFormField.File]: any;
    [SportsCatFormField.IsActive]: boolean;
    [SportsCatFormField.ImageUrl]: string;
};