import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Grid, Image, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
    EventsFilterFormPaginated,
} from 'features/events';
import { Organizer } from 'features/events/components/event-organizer/types';
import { useEventActiveStatus } from 'features/events/use-cases/event-active-status';
import { useEventDeleteStatus } from 'features/events/use-cases/event-delete-status';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';

import { AddTrackListModal } from './tracklist-add-modal/add-tracklist-modal';



const { useBreakpoint } = Grid;

type OrganizerTableProps = {
    id: any;
    isLoading: boolean;
    data?: any;
    tableParams: EventsFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<EventsFilterFormPaginated>>;
    refetch: () => void;
    setTrackdata: any,
    trackdata: any
};

export const TrackListTable = ({
    id,
    data,
    isLoading,
    tableParams,
    updateParams,
    refetch,
    setTrackdata,
    trackdata
}: OrganizerTableProps) => {
    const screens = useBreakpoint();
    const permissions = usePermissions();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Organizer> | SorterResult<Organizer>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: Array.isArray(sorter)
                ? undefined
                : sorter.order === 'ascend'
                    ? 'name'
                    : '-name',
        });
    };

    const [showAddEventModal, setShowAddEventModal] = useState<boolean>(false);

    const handleCloseModal = () => {
        setShowAddEventModal(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = (record: any) => {
        setShowAddEventModal(true);
        setTrackdata(record)
        document.body.style.overflow = 'hidden';
    }


    const { isLoading: deleteLoading, mutate } = useEventDeleteStatus();

    const { mutate: updateStatusMutate, isLoading: statusLoading } = useEventActiveStatus();

    const { confirm } = Modal;

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: 'Are you sure you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record.id);
            },
        });
    };

    const handleDelete = (deleteId: any) => {

        const deletedFormData = {
            id: deleteId,
            status: false,
            flag: "RACE_TRACK_DELETE"
        }
        mutate(deletedFormData, { onSuccess: refetch });
    }

    const handleUpdateStatus = (updateId: any, isChecked: any) => {
        const statusFormData = {
            id: updateId,
            status: isChecked,
            flag: "RACE_TRACK_SET_STATUS"
        }
        updateStatusMutate(statusFormData, { onSuccess: refetch });
    }

    if (!permissions) return null;


    return (
        <>
            <div className={`cstmrightmdl ${showAddEventModal && "show"}`}>
                <AddTrackListModal id={id} showAddEventModal={showAddEventModal} trackdata={trackdata} refetch={refetch} onClose={handleCloseModal} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Track Found'}
                        count={data?.total || 0}
                    />

                </StyledTableHeader>
                <Table<Organizer>
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading && { indicator: <div><TableLoader /></div> }}
                    dataSource={isLoading ? [] : data?.items}
                    rowKey='id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.page,
                        defaultPageSize: tableParams.size,
                        total: data?.total,
                    }}
                    className='layout_table cstltblwthldr'
                >
                    <Column<Organizer>
                        title={'S.No'}
                        key="serialNumber"
                        width={100}
                        ellipsis
                        render={(text, record, index) => index + 1}
                    />

                    <Column<Organizer>
                        title={'Track Name'}
                        dataIndex={'name'}
                        width={screens.xxl ? 'auto' : 240}
                        ellipsis
                    />
                    <Column<Organizer>
                        title={'Image'}
                        // dataIndex={'name'}
                        width={screens.xxl ? 'auto' : 240}
                        ellipsis
                        render={(_, record) => (
                            <Image src={record.image_url} width={"10rem"} height={"7rem"} />
                        )}
                    />
                    <Column<Organizer>
                        title={'Action'}
                        width={screens.xxl ? 'auto' : 162}
                        dataIndex={'is_active'}
                        render={(_, record) => (
                            <Space>
                                <Tooltip title="Edit">
                                    <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <Button shape="circle" loading={deleteLoading} icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(record)} />
                                </Tooltip>
                                <Tooltip title="Is Active">
                                    <Switch
                                        defaultChecked={record.is_active}
                                        checked={record.is_active}
                                        loading={statusLoading}
                                        onChange={(isChecked) => {
                                            handleUpdateStatus(record.id, isChecked);
                                            record.is_active = isChecked;
                                        }}

                                    />
                                </Tooltip>
                            </Space>
                        )}
                    />
                </Table>

            </StyledTableWrapper>
        </>
    );
};

