export const eventsQueryKeys = {
  all: ['fields'] as const,
  lists: () => [...eventsQueryKeys.all, 'list'] as const,
  list: (filters: object) => [...eventsQueryKeys.lists(), filters] as const,
  details: () => [...eventsQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...eventsQueryKeys.details(), id] as const,
};

export const shopCatQueryKeys = {
  all: ['sdfd'] as const,
  lists: () => [...eventsQueryKeys.all, 'list'] as const,
  list: (filters: object) => [...eventsQueryKeys.lists(), filters] as const,
  details: () => [...eventsQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...eventsQueryKeys.details(), id] as const,
};
