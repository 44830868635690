import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { eventsQueryKeys } from 'features/events';
import { queryClient } from 'providers';

import { UserApiService, VendingDTO } from '../api/vending-machine-api.service';


const { createVending } = UserApiService();

export const useUpdateVending = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: VendingDTO) => {
      return createVending(payload as any);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);
        message.error(uiMessage);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: eventsQueryKeys.all,
        });
        message.success("Vending Update successfully");
      },
    },
  );
};
