import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyType } from 'types';

import { AddCategoryModal } from '../modal/addcategory';
import { CategoryFilterForm } from '../shop-filter-form/category-filter-form';
import { CategoryFormFieldValue } from '../shop-filter-form/type';

type UsersTopBarProps = {
  getInitialFilterValues: CategoryFormFieldValue;
  handleFilter: (values: CategoryFormFieldValue) => void;
};

const { useBreakpoint } = Grid;

export const CategoryListTopBar = ({
  getInitialFilterValues,
  handleFilter,
}: UsersTopBarProps) => {
  useState<CompanyType>();
  const { t } = useTranslation();
  const formId = 'users-filters-form';
  const screens = useBreakpoint();
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const handleClose = () => {
    setShowAddUserModal(false)
    document.body.style.overflow = 'visible';
  }

  const handleOpen = () => {
    setShowAddUserModal(true)
    document.body.style.overflow = 'hidden';
  }

  return (
    <>
      <div className={`cstmrightmdl ${showAddUserModal && "show"}`}>
        <AddCategoryModal onClose={handleClose} />
      </div>

      <ContentTopBar
        title={t('shops.categorylist.heading')}
        renderButtons={
          <>
            <Button
              icon={!screens.xs ? <PlusOutlined /> : undefined}
              onClick={handleOpen}
              type='primary'
            >
              {t('shops.addCategory')}
            </Button>



          </>
        }
      />
      <ContentFilters formId={formId}>
        <CategoryFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
