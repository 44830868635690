export enum CountriesFormField {
    Id = 'id',
    Name = 'title',
    NameAr = 'title_arabic',
    IsActive = 'is_active',
}

export type Countries = {
    Id: string | undefined;
    Name: string;
    isActive: boolean;
    NameAr: string;
};

export type CountriesFormValues = {
    [CountriesFormField.Id]: string | undefined;
    [CountriesFormField.Name]: string;
    [CountriesFormField.NameAr]: string;
    [CountriesFormField.IsActive]: boolean;
};