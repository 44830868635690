import { Button, Form, Input, message } from 'antd';
import { useCreateBibAllocation } from 'features/events/use-cases/create-bib-allocation';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// import { useParams } from 'react-router-dom';
import { BibAllowFormField, BibAllowFormValues } from './types';


export const AddBibModal = ({ onClose, showAddEventModal, organizerData, refetch }: { onClose: () => void; showAddEventModal: boolean, organizerData: any, refetch: () => void }) => {
    const { t } = useTranslation();
    const formName = 'add-bib-allocation-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<BibAllowFormValues>();

    const { isLoading, mutate } = useCreateBibAllocation();

    const getInitialValues = {
        [BibAllowFormField.EventId]: undefined,
        [BibAllowFormField.TicketCategoryId]: undefined,
        [BibAllowFormField.StartNo]: undefined,
        [BibAllowFormField.EndNo]: undefined,
    };

    const handleUpdate = (formData: BibAllowFormValues) => {
        formData[BibAllowFormField.EventId] = organizerData?.i_event_id;
        formData[BibAllowFormField.TicketCategoryId] = organizerData?.Id;
        formData[BibAllowFormField.StartNo] = +formData[BibAllowFormField.StartNo];
        formData[BibAllowFormField.EndNo] = +formData[BibAllowFormField.EndNo];
        mutate(formData, {
            onSuccess: (data: any) => {
                const { ResponseStatus, ResponseMessage } = data;

                if (ResponseStatus === 1) {
                    message.success(ResponseMessage);
                    handleSuccess();
                } else {
                    message.error(ResponseMessage);
                }
            },
        });
    };

    const handleSuccess = () => {
        onClose();
        refetch()
    }

    useEffect(() => {
        if (organizerData) {
            form.setFieldsValue(organizerData);
        } else {
            form.resetFields();
        }
    }, [organizerData, showAddEventModal])

    return (
        <>
            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={onClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{organizerData ? "Update Bib No" : "Update Bib No"}</h4>
                </div>
                <div className='modal_body'>
                    <Form<BibAllowFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <FormSectionsSeparator title={""}>
                            <Form.Item<BibAllowFormField>
                                label={"Start Number"}
                                name={BibAllowFormField.StartNo}

                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Start Number"}

                                />
                            </Form.Item>

                            <Form.Item<BibAllowFormField>
                                label={"End Number"}
                                name={BibAllowFormField.EndNo}

                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"End Number"}

                                />
                            </Form.Item>


                        </FormSectionsSeparator>
                    </Form>
                    <div className='button-group'>
                        <Button loading={isLoading} htmlType='submit' onClick={form.submit} className='btn'>Save</Button>
                    </div>

                </div>
            </div>
        </>
    )
};
