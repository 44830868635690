import {
    QueryFunction,
    QueryKey,
    useQuery,
    UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { EventsApiService, eventsQueryKeys } from 'features/events';
import { EventsFormData } from 'types/eventsformdata';

const { getExpoRegistraionForm } = EventsApiService();

const getUserQuery = (
    id: string,
    options?: UseQueryOptions<EventsFormData>,
): {
    queryKey: QueryKey;
    queryFn: QueryFunction<EventsFormData>;
    options?: UseQueryOptions<EventsFormData>;
} => ({
    queryKey: eventsQueryKeys.detail(id),
    queryFn: ({ signal }) => getExpoRegistraionForm(id, { config: { signal } }),
    ...options,
});

export const useGetExpoRegistraionForm = (id: string, options?: UseQueryOptions<EventsFormData>) => {

    const { message } = App.useApp();

    return useQuery<EventsFormData, Error>(
        getUserQuery(id, {
            onError: (error: unknown) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            ...options,
        }),
    );
};
