import { Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import { useState } from 'react';

import Organizer from '../../event-organizer/organizer';
import OutletComponent from '../event-outlet/outlet';
import EventSponser from '../event-sponser/event-sponser';
import { DisclaimerComponent } from './disclaimer';
import FAQComponent from './faq';
import TipsAdviceComponent from './tips-advice';



const EventCMSTab = () => {

  const [tabs, setTabs] = useState<any>("1")

  const handleTabChange = (activeKey: any) => {
    setTabs(activeKey)
  }

  return (

    <>
      <Tabs className='gallery_tab' defaultActiveKey='1' onChange={handleTabChange}>
        <TabPane className='cltb' tab="Organizer" key="1">
          <Organizer tabs={tabs} />
        </TabPane>
        <TabPane className='cltb' tab="Sponsors" key="2">
          <EventSponser tabs={tabs} />
        </TabPane>
        <TabPane className='cltb' tab="Outlet" key="3">
          <OutletComponent tabs={tabs} />
        </TabPane>
        {/* <TabPane className='cltb' tab="Volunteer" key="4">
          <VolunteerComponent tabs={tabs} />
        </TabPane> */}
        <TabPane className='cltb' tab="Tips & Advice" key="5">
          <TipsAdviceComponent tabs={tabs} />
        </TabPane>
        <TabPane className='cltb' tab="Disclaimer" key="6">
          <DisclaimerComponent />
        </TabPane>
        {/* <TabPane className='cltb' tab="Expo" key="7">
          <EventExpo />
        </TabPane> */}
        {/* <TabPane className='cltb' tab="Payment Terms" key="8">
          <PaymentTermsComponent />
        </TabPane> */}
        <TabPane className='cltb' tab="FAQ'S" key="9">
          <FAQComponent tabs={tabs} />
        </TabPane>
      </Tabs>
    </>
  );
};
export default EventCMSTab;
