import { Button, Form, Image } from 'antd';
import Input from 'antd/es/input/Input';
import TextArea from 'antd/es/input/TextArea';
import { useCreateTipsAdvice } from 'features/events/use-cases/create-tips-advice';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TipsFormField, TipsFormValues } from './types';


export const AddTipsModal = ({ id, onClose, showAddEventModal, outletdata, refetch }: { id: any, outletdata: any, onClose: () => void; showAddEventModal: boolean, refetch: () => void }) => {
    const { t } = useTranslation();
    const formName = 'add-tips-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<TipsFormValues>();


    const [fileData, setFileData] = useState<File | any>(null);
    const [imageData, setImageData] = useState<File | any>(null);

    const handleFileChange = (e: any) => {
        setFileData(e.target.files[0]);
    };

    const handleImageChange = (e: any) => {
        setImageData(e.target.files[0]);
    };

    const { isLoading, mutate } = useCreateTipsAdvice(outletdata?.Id);
    const getInitialValues = {
        [TipsFormField.Id]: undefined,
        [TipsFormField.Url]: undefined,
        [TipsFormField.Description]: undefined,
        [TipsFormField.File]: undefined,
        [TipsFormField.Image]: undefined,
        [TipsFormField.EventId]: undefined,

    };


    const handleSuccess = () => {
        handleClose();
        refetch();
    }

    const handleClose = () => {
        onClose();
        setFileData(null);
        setImageData(null);
    }

    const handleUpdate = (formData: TipsFormValues) => {
        // formData[TipsFormField.Id] = outletdata?.Id
        // formData[TipsFormField.EventId] = id;
        // formData[TipsFormField.File] = fileData[0]?.originFileObj;
        // formData[TipsFormField.Image] = imageData[0]?.originFileObj;
        const formDataWithFiles = new FormData();
        formDataWithFiles.append(TipsFormField.Description, formData[TipsFormField.Description]);
        if (fileData) formDataWithFiles.append(TipsFormField.File, fileData);
        if (imageData) formDataWithFiles.append(TipsFormField.Image, imageData);
        if (outletdata?.Id) formDataWithFiles.append(TipsFormField.Id, outletdata?.Id);
        formDataWithFiles.append(TipsFormField.EventId, id)
        mutate(formDataWithFiles, { onSuccess: handleSuccess });
    };

    useEffect(() => {
        if (outletdata) {
            form.setFieldsValue(outletdata);
        } else {
            form.resetFields();
        }
    }, [outletdata, showAddEventModal])


    return (

        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={handleClose} className='close-btn'>
                    &times;
                </button>
                <h4>{outletdata ? 'Edit Tips & Advice' : "Add Tips & Advice"}</h4>
            </div>
            <div className='modal_body'>
                <Form<TipsFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    // requiredMark={'optional'}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <FormSectionsSeparator title={""}>

                        <Form.Item<TipsFormField>
                            label={"Description"}
                            name={TipsFormField.Description}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                        {!imageData && outletdata?.Image && <Image
                            src={outletdata?.Image}
                        />}

                        <Form.Item<TipsFormField>
                            label={"Image"}
                            name={TipsFormField.Image}
                            rules={[
                                {
                                    required: !outletdata?.Id,
                                    // whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            {/* <Upload
                                fileList={imageData}
                                onChange={handleImageChange} // Change here
                                maxCount={1}
                                beforeUpload={() => false}
                                accept=".png,.jpg,.jpeg"
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>

                            </Upload> */}
                            <Input type="file" onChange={handleImageChange} accept=".png,.jpg,.jpeg" />
                        </Form.Item>

                        {!fileData && outletdata?.File && <h1>{outletdata?.File}</h1>}
                        <Form.Item<TipsFormField>
                            label={"File"}
                            name={TipsFormField.File}
                            rules={[
                                {
                                    required: !outletdata?.Id,
                                    // whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            {/* <Upload
                                fileList={fileData}
                                onChange={handleFileChange} // Change here
                                maxCount={1}
                                beforeUpload={() => false}
                                accept=".pdf,.xlsx,.xls"
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>

                            </Upload> */}
                            <Input type="file" onChange={handleFileChange} accept=".pdf,.xlsx,.xls" />
                        </Form.Item>
                    </FormSectionsSeparator>
                </Form>
                <div className='button-group'>
                    <Button loading={isLoading} htmlType='submit' onClick={form.submit} className='btn'>Save</Button>
                </div>

            </div>
        </div>
    )
};

