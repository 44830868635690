import { Button, Checkbox } from "antd";
import { PageLoader } from "components/page-loader/page-loader";
import { useUpdateAttributeTshirtSize } from "features/events/use-cases/create-attribute-tshirt-size";
import { useGetAttributeTshirtSize } from "features/events/use-cases/get-attribute-tshirt-size";
import { usePaginationParams } from "hooks";
import { useEffect, useState } from "react";

const ShowHideTshirtSize = ({ id, tab }: { id: string; tab: any }) => {

    const { params } =
        usePaginationParams<any>({
            page: 1,
            size: 10,
            order_by: '-rank',
            event_id: id,
        });

    const { data: tshirtData, isFetching, refetch } = useGetAttributeTshirtSize(params, { retry: false });

    const { isLoading, mutate } = useUpdateAttributeTshirtSize();

    const [tcheckboxes, setTCheckboxes] = useState<any[]>([]);

    const handleCheckboxChange = (event: any, cId: any) => {
        const { checked } = event.target;
        setTCheckboxes((prevCheckboxes) =>
            prevCheckboxes.map((checkbox) =>
                checkbox.tshirt_size_master_id === cId ? { ...checkbox, is_active: checked } : checkbox
            )
        );
    };


    useEffect(() => {
        if (tab === "1") {
            refetch();
        }
    }, [tab]);

    useEffect(() => {
        if (tab === "1" && tshirtData && tshirtData.items) {
            setTCheckboxes(tshirtData.items);
        }
    }, [tshirtData, tab]);

    const handleSave = () => {
        const checkedItems: any[] = tcheckboxes
            ?.filter(item => item.is_active) // Filter items where is_active is true
            .map(item => ({
                size_master_id: item.tshirt_size_master_id
            }));

        const formData = {
            event_id: id,
            sizeMasterIds: checkedItems,
        }
        mutate(formData);
    }

    if (isFetching) return <PageLoader />;

    return (

        <div className="t_shirt">
            <div className="t_slist">
                {

                    tcheckboxes?.map((item: any, i: number) => (
                        <div className="ts_wrap tshrt_size" key={i}>
                            <div key={i} className="t_sbx">
                                <Checkbox
                                    checked={item.is_active}
                                    onChange={(e) =>
                                        handleCheckboxChange(e, item.tshirt_size_master_id)
                                    }
                                />
                                <span>{item.tshirt_size}</span>
                            </div>



                        </div>
                    ))}
            </div>

            <div>
                <Button loading={isLoading} onClick={handleSave} type="primary">Save</Button>
            </div>
        </div>
    )
}

export default ShowHideTshirtSize
