import { Button, Checkbox } from "antd";
import { PageLoader } from "components/page-loader/page-loader";
import { useUpdateAttributeItemList } from "features/events/use-cases/create-attribute-item-list";
import { useGetAttributeItemList } from "features/events/use-cases/get-attribute-item-list";
import { usePaginationParams } from "hooks";
import { useEffect, useState } from "react";

const ShowhideItemList = ({ id, tab }: { id: string; tab: string }) => {

    const { params } =
        usePaginationParams<any>({
            page: 1,
            size: 10,
            order_by: '-rank',
            event_id: id,
        });
    const { data, refetch, isFetching } = useGetAttributeItemList(params, { retry: false });

    const { mutate } = useUpdateAttributeItemList();

    const [checkboxes, setCheckboxes] = useState<any[]>([]);


    useEffect(() => {
        if (tab === "4") {
            refetch();
        }
    }, [tab]);

    useEffect(() => {
        if (tab === "4" && data && data.items) {
            setCheckboxes(data.items);
        }
    }, [data, tab]);


    const handleCheckboxChange = (event: any, sId: any) => {
        const { checked } = event.target;
        setCheckboxes((prevCheckboxes) =>
            prevCheckboxes.map((checkbox) =>
                checkbox.shop_item_id === sId ? { ...checkbox, is_active: checked } : checkbox
            )
        );
    };


    const handleSave = () => {
        const checkedItems: any[] = checkboxes
            ?.filter(item => item.is_active)
            .map(item => ({
                shop_item_id: item.shop_item_id,
                image_url: ""
            }));
        const formData = {
            event_id: id,
            eventShopItems: checkedItems,
        }
        mutate(formData);
    }

    if (isFetching) return <PageLoader />;

    return (

        <div className="t_shirt">
            <div className="t_slist">
                {
                    checkboxes?.map((item: any, i: number) => (
                        <div className="ts_wrap" key={i}>
                            <div className="t_sbx">
                                <img src={item.image_url} width={"100px"} />


                            </div>
                            <div className="t_lbl">
                                <Checkbox
                                    checked={item.is_active}
                                    onChange={(e) =>
                                        handleCheckboxChange(e, item.shop_item_id)
                                    }
                                />
                                Attach with this event
                            </div>
                        </div>
                    ))}

            </div>
            <div>
                <Button onClick={handleSave} type="primary">Save</Button>
            </div>
        </div>
    )
}

export default ShowhideItemList


