
export enum FaqFormField  {
    id="id",
    event_id="event_id",
    question="question",
    answer="answer",
    is_active= "is_active"
};

export type FaqFormValues = {
    [FaqFormField.id]: string;
    [FaqFormField.event_id]: any;
    [FaqFormField.question]: string;
    [FaqFormField.answer]: string;
    [FaqFormField.is_active]: string;
};