import { DownOutlined, IdcardOutlined, UserOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { handleKeyAmount, handleKeyDownThree } from 'features/events';
import { CouponFormField, CouponFormValues } from 'features/events/components/event-details/show-hide-attributes/coupon/coupon-management/Add-coupon-madal/types';
import { useCreateCouponMaster } from 'features/events/use-cases/create-coupon-master';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { payFormatDate } from 'utils/payload-date-';

export const AddShopCouponModal = ({ onClose, showAddItemModal, couponEditData }: { onClose: () => void; showAddItemModal: boolean, refetch: () => void, couponEditData: any }) => {

    const formName = 'add-shop-coupon-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<CouponFormValues>();
    const { isLoading, mutate } = useCreateCouponMaster();

    const handleClose = () => {
        onClose();
        form.resetFields();
    }
    const { t } = useTranslation();

    const valueType = [
        { value: 1, label: 'Flat' },
        { value: 2, label: 'Percent' },
    ];

    const getInitialValues = {
        [CouponFormField.Id]: undefined,
        [CouponFormField.Title]: undefined,
        [CouponFormField.CouponType]: undefined,
        [CouponFormField.couponCode]: undefined,
        [CouponFormField.ValueType]: undefined,
        [CouponFormField.Value]: undefined,
        [CouponFormField.StartDate]: undefined,
        [CouponFormField.EndDate]: undefined,
        [CouponFormField.EventId]: undefined,
        [CouponFormField.Limit]: undefined,
        [CouponFormField.Prefix]: undefined,
        [CouponFormField.NoOfCoupons]: undefined,
        [CouponFormField.IsActive]: true,
    };

    useEffect(() => {
        if (couponEditData) {
            const { start_date, end_date, ...rest } = couponEditData;
            form.setFieldsValue({
                ...rest,
                start_date: start_date ? dayjs(start_date).format('MM/DD/YYYY') : null,
                end_date: end_date ? dayjs(end_date).format('MM/DD/YYYY') : null
            });
            form.setFieldValue(CouponFormField.StartDate, start_date ? dayjs(start_date) : null);
            form.setFieldValue(CouponFormField.EndDate, end_date ? dayjs(end_date) : null);
        } else {
            form.resetFields();
        }
    }, [couponEditData, showAddItemModal]);


    const handleUpdate = (formData: CouponFormValues) => {
        formData[CouponFormField.Id] = couponEditData?.id;
        formData[CouponFormField.StartDate] = payFormatDate(formData.start_date);
        formData[CouponFormField.EndDate] = payFormatDate(formData.end_date);
        formData[CouponFormField.IsActive] = true;
        formData[CouponFormField.Flag] = "SHOPITEMS_COUPONS";
        mutate(formData, { onSuccess: hanldeSuccess });
    };

    const hanldeSuccess = () => {
        handleClose();
        // if (!couponEditData?.id) refetch();
    }



    return (
        <>
            <div className='popup'>
                <div className='modal_head'>
                    <h4>{couponEditData ? "Edit Coupon" : "Add Coupon"}</h4>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>

                </div>
                <div className='modal_body' >
                    <Form<CouponFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <FormSectionsSeparator title={""}>
                            <Form.Item<CouponFormField>
                                label={"Coupon Title"}
                                name={CouponFormField.Title}

                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Title"}
                                    prefix={<UserOutlined className='input-prefix-icon' />}
                                />
                            </Form.Item>



                            <Form.Item<CouponFormField>
                                label={"Prefix"}
                                name={CouponFormField.Prefix}

                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                    {
                                        min: 2,
                                        max: 2,
                                        message: 'Prefix must be exactly 2 characters long',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Prefix"}
                                    maxLength={2}
                                    disabled={couponEditData?.id}
                                />
                            </Form.Item>


                            {!couponEditData?.id && <Form.Item<CouponFormField>
                                label={"No of coupons"}
                                name={CouponFormField.NoOfCoupons}

                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"No of coupons"}
                                    onKeyDown={handleKeyDownThree}
                                />
                            </Form.Item>}


                            <Form.Item<CouponFormField>
                                label={"No of People Used"}
                                name={CouponFormField.Limit}

                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Limit Usage"}
                                    onKeyDown={handleKeyDownThree}
                                />
                            </Form.Item>


                            {couponEditData?.id &&
                                <Form.Item<CouponFormField>
                                    label={"Coupon Code"}
                                    name={CouponFormField.couponCode}

                                // rules={[
                                //     {
                                //         required: true,
                                //         whitespace: true,
                                //         message: t('validateMessages.custom.nomsg'),
                                //     },
                                // ]}
                                >
                                    <Input
                                        placeholder={""}
                                        disabled={couponEditData?.id}
                                    />
                                </Form.Item>
                            }

                            <Form.Item<CouponFormField>
                                label={"Value Type"}
                                name={CouponFormField.ValueType}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Select
                                    options={valueType}
                                    placeholder={"select Value type"}
                                    className='has-prefix-icon'
                                    suffixIcon={
                                        <>
                                            <IdcardOutlined className='prefix-icon' />
                                            <DownOutlined className='suffix-icon' />
                                        </>
                                    }
                                />
                            </Form.Item>

                            <Form.Item<CouponFormField>
                                label={"Value"}
                                name={CouponFormField.Value}

                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Value"}
                                    onKeyDown={handleKeyAmount}
                                />
                            </Form.Item>

                            <Form.Item<CouponFormField>
                                label={"Start Date"}
                                name={CouponFormField.StartDate}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item<CouponFormField>
                                label={"End Date"}
                                name={CouponFormField.EndDate}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>

                        </FormSectionsSeparator>
                        <div className='form-button'>
                            <Button htmlType='submit' className='btn' type='primary'>Save</Button>
                        </div>
                    </Form>
                </div>
            </div >
        </>
    )
};

