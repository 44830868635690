import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

import { CMSApiService, CraeteTshirtDTO } from '../api/cms-api.service';
import { eventsQueryKeys } from './query-keys';

const { createTshirtSize } = CMSApiService();

export const useCreateTshirtSize = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: CraeteTshirtDTO) => {
            return createTshirtSize(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
                message.success(i18n.t('fields.create.successfulCreate'));
            },
        },
    );
};
