import { Button } from "antd"
import { Loader } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "routes/constants";

import defaultImg from "../../../assets/EventDefaultImage.svg";
import { useAcceptWaitingPayment } from "../use-cases/accept-waiting-pay";
import { useCreatePaymentLink } from "../use-cases/create-payment-link";
import { useGetInvoiceDetails } from "../use-cases/get-invoice-details";
import { useRejectPayment } from "../use-cases/rejectPayment";
import { formatDateTime, formateAmount } from "../utills/utiils";

export const WaitingListDetailsView = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const { data, isLoading, refetch } = useGetInvoiceDetails(id as string, { retry: true });
    const { mutate, isLoading: rejectLoading } = useRejectPayment();
    const { mutate: acceptMutate, isLoading: acceptLoading } = useAcceptWaitingPayment();

    const CurrencyName = data?.all_participant[0]?.currency_name || "KD";



    const { mutate: generateLinkMutate, isLoading: linkLoading } = useCreatePaymentLink();


    const handleReject = () => {
        const formData = {
            order_no: data?.id,
        }
        mutate(formData, { onSuccess: () => refetch });
    };

    const handleAccept = () => {
        const formData = {
            booking_id: id,
        }

        acceptMutate(formData, {
            onSuccess: (res: any) => {
                if (res.ResponseStatus == 1) {
                    navigate(ROUTES.TRANSACTION_INVOICE_LIST)
                }
            }
        })
    }





    if (isLoading) return <Loader />;
    if (!data) return null;


    const handleGeneratePayLink = () => {

        const formData = {
            booking_id: data?.id,
            source: "web"
        }
        generateLinkMutate(formData, { onSuccess: () => refetch });
    };

    return (
        <>

            <div className="ivce_list_wrap">
                <div className="ivce_list">
                    {/* <div className="snd_btn">
                        <Button type="primary" loading={loading} onClick={handleSend}>Send Invoice</Button>
                    </div> */}
                    <div className="img_details">

                        {data?.event_detail?.receipt_image_url ? <img
                            src={data?.event_detail?.receipt_image_url}
                            onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.src = 'https://qa.suffix.events/assets/images/em_hd.jpg';
                            }}
                            // onError={(e: any) => { e.target.src = 'https://qa.suffix.events/assets/images/em_hd.jpg'; }}
                            alt=""
                        />
                            :
                            <img
                                src={'https://qa.suffix.events/assets/images/em_hd.jpg'}

                            />
                        }
                    </div>
                    <div className="evt_dtls">
                        <div className="evt_d_head">
                            <h2>{data?.event_detail?.event_id ? "Event Confirmation" : "Order Confirmation"}</h2>
                            {data?.payment_method && <span>Payment Type : {data?.payment_method}</span>}
                        </div>
                        <div className="bokng_dtls">
                            <div className="bokg_info">
                                <h4>Booking Date :</h4>
                                <span>{data?.order_date && (formatDateTime(data.order_date))}</span>
                            </div>
                            <div className="bokg_info">
                                <h4>Registration no. :</h4>
                                <span>{data?.invoice_no}</span>
                            </div>
                            <div className="bokg_info">
                                <h4>Name :</h4>
                                <span>{data?.full_name}</span>
                            </div>
                            <div className="bokg_info">
                                <h4>Email :</h4>
                                <span>{data?.email_address}</span>
                            </div>
                            <div className="bokg_info">
                                <h4>Mobile no. :</h4>
                                <span>{data?.phone_number}</span>
                            </div>
                            <div className="bokg_info">
                                <h4>Status :</h4>
                                <span className="success">{data?.payment_status}</span>
                            </div>

                        </div>
                        <div className="wait_btn">
                            {(data?.booking_status !== "CANCELED" && data?.booking_status !== "SUCCESS" && data?.booking_status !== "CAPTURED" && data?.booking_status !== "rejectbyadmin" && data?.total_price !== 0) &&
                                <Button style={{ backgroundColor: "green", color: "white" }} loading={linkLoading} onClick={handleGeneratePayLink}>Generate Payment Link</Button>
                            }

                            {data?.total_price === 0 &&
                                <Button style={{ backgroundColor: "green", color: "white" }} loading={acceptLoading} onClick={handleAccept}>Accept</Button>
                            }

                            {(data?.booking_status !== "CANCELED" && data?.booking_status !== "SUCCESS" && data?.booking_status !== "CAPTURED" && data?.booking_status !== "rejectbyadmin") &&
                                <Button type="primary" loading={rejectLoading} onClick={handleReject}>Reject</Button>
                            }
                        </div>


                        {data?.event_detail?.event_id && <div className="particpant_dtls">

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{ width: '20%' }}>Event name</th>
                                        <th style={{ width: '16%' }}>Participant ID	</th>
                                        <th style={{ width: '16%' }}>Participant name	</th>
                                        <th style={{ width: '14%' }}>Amount</th>
                                        <th style={{ width: '15%' }}>Refund Booking</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="event_img">
                                                <div className="e_img">
                                                    <img src={data?.event_detail?.image_url}
                                                        alt=""
                                                        onError={(e) => {
                                                            const target = e.target as HTMLImageElement;
                                                            target.src = defaultImg;
                                                        }}
                                                    />
                                                </div>
                                                <h3>{data?.event_detail?.event_name}</h3>
                                                <span>Event date:{data?.event_detail?.event_date && formatDateTime(data?.event_detail?.event_date)}</span>
                                                <span>{data?.event_detail?.category_name}</span>
                                            </div>
                                        </td>

                                        <td colSpan={4}>
                                            <table>
                                                {data?.all_participant.map((item, i) => (
                                                    <tr key={i}>
                                                        <td style={{ width: '16%' }}>{item?.participant_id}</td>
                                                        <td style={{ width: '18%' }}>
                                                            <div className="parti_nme">
                                                                <h5>{item?.first_name} {item?.last_name}</h5>
                                                                <span>
                                                                    {(item?.age > 0) ? (<>{item?.age} yrs</>) : ''}
                                                                    {/* {item?.age && <>{item?.age} yrs , <br /> </>} */}
                                                                    {item?.tshirt_size && <span> T-Shirt size {item?.tshirt_size} </span>}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: '14%' }}>{item?.price && formateAmount(item.price)} {item?.currency_name}</td>
                                                        <td style={{ width: '14%' }}>{item?.refund_protect_price && formateAmount(item.refund_protect_price)} {item?.currency_name}</td>

                                                    </tr>))}
                                            </table>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>}
                        {data && data.item_list.length > 0 && <div className="shop_item">
                            <h2>Shop Items</h2>
                            <div className="shop_list">
                                {data?.item_list?.map((item, i) => (
                                    <div className="shop_lst_bx" key={i}>

                                        <div className="pr_dtls">
                                            <div className="pr_img">
                                                <img src={item?.image_url} alt="" />
                                            </div>
                                            <div className="pr_nme">
                                                <h4>{item.item_name}</h4>
                                                <span>Item size : {item.size_name}</span>
                                                <span >Color  : <span style={{
                                                    display: 'inline-block',
                                                    width: '20px',
                                                    height: '10px',
                                                    backgroundColor: item.color_code,
                                                    border: '1px solid #000',
                                                    marginLeft: '5px',
                                                    verticalAlign: 'middle'
                                                }} /></span>
                                                <span>Qty : {item.quantity}</span>

                                            </div>
                                        </div>
                                        <div className="pr_price">
                                            <span>
                                                {item?.base_price && formateAmount(item.base_price * item.quantity)} {CurrencyName}
                                            </span>
                                        </div>
                                    </div>))}
                            </div>
                        </div>}

                        {data?.delivery_address != null && data?.delivery_address.id != 0 && <div className="delvry_addrs">
                            <h6>Delivery Address :</h6>

                            <div className="del_nme">
                                <h4>Name: {data?.delivery_address?.full_name}
                                </h4>
                                <h4>Phone Number : {data?.delivery_address?.phone_number
                                }</h4>
                                <p>
                                    {data?.delivery_address?.appartment_no},  {data?.delivery_address?.block}
                                    ,  {data?.delivery_address?.street},  {data?.delivery_address?.city_name},
                                    {data?.delivery_address?.country_name},   {data?.delivery_address?.avenue}
                                </p>
                            </div>
                        </div>}

                        <div className="ivoice_lst_wrap">
                            {data.refundable_amount > 0 &&
                                <div className="invce_list">
                                    <span>Refundable Amount</span>
                                    <span>{formateAmount(data?.refundable_amount)}  {CurrencyName}</span>
                                </div>}

                            {data.total_participant_price > 0 &&
                                <div className="invce_list">
                                    <span>Total Fee Price</span>
                                    <span>{formateAmount(data.total_participant_price)}  {CurrencyName}</span>
                                </div>}

                            {data.refund_booking_amount > 0 &&
                                <div className="invce_list">
                                    <span>Refund Booking</span>
                                    <span>{formateAmount(data.refund_booking_amount)}  {CurrencyName}</span>
                                </div>}

                            {data.total_item_price > 0 &&
                                <div className="invce_list">
                                    <span>Total Item</span>
                                    <span>{formateAmount(data.total_item_price)} {CurrencyName}</span>
                                </div>}

                            {data.total_addons > 0 &&
                                <div className="invce_list">
                                    <span>Add-ons Total</span>
                                    <span> {data?.total_addons && formateAmount(data.total_addons)} {CurrencyName}</span>
                                </div>}

                            {data.delivery_price > 0 &&
                                <div className="invce_list">
                                    <span>Total Delivery Amount</span>
                                    <span>{data?.delivery_price && formateAmount(data.delivery_price)} {CurrencyName}</span>
                                </div>}


                            <div className="invce_list">

                                <span>Sub total</span>
                                <span>{formateAmount(data.total_participant_price + data.total_item_price + data.total_addons + data.delivery_price + data.refund_booking_amount + data?.refundable_amount)
                                }  {CurrencyName}</span>
                            </div>

                            {data.total_discount > 0 &&
                                <div className="invce_list">
                                    <span>Total Discount Amount</span>
                                    <span>{data?.total_discount && formateAmount(data.total_discount)} {CurrencyName}</span>
                                </div>}

                            <div className="invce_list">
                                <h3>Grand total</h3>
                                <h3>{data?.total_price ? formateAmount(data.total_price) : 0} {CurrencyName}</h3>
                            </div>

                        </div>

                        <div className="nte">
                            <p>The registration is nonrefundable and nontransferable. For more information please contact Suffix Management</p>
                        </div>

                    </div>


                </div>

            </div>


        </>
    )
}
