import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { EventsApiService } from 'features/events';
import { queryClient } from 'providers';

import { MarketingCodeDTO } from '../components/event-details/event-marketing/event marketing-code/add-marketing-code-modal/types';
import { eventsQueryKeys } from './query-keys';

const { createMarketingCode } = EventsApiService();

export const useCreateMarketingCode = (id: any) => {
    const { message } = App.useApp();

    return useMutation(
        (payload: MarketingCodeDTO) => {
            return createMarketingCode(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
                if (id) {
                    message.success("Marketing Code has been updated successfully!!");
                } else {
                    message.success("Marketing Code has been created successfully!!");
                }
            },
        },
    );
};
