import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { eventsQueryKeys } from 'features/events';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

import { CraeteTitleCmsrDTO, ShopApiService } from '../api/shop-api.service';

const { UpdateCategory } = ShopApiService();
export const useUpdateCategory = () => {
  const { message } = App.useApp();
  
return useMutation(
    (payload: CraeteTitleCmsrDTO) => {
      return UpdateCategory(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: eventsQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: eventsQueryKeys.detail(variables.id as any),
          }),
        ]);
        message.success(i18n.t('shops.category.successfulUpdate'));
      },
    },
  );
};
