export enum TshirtFormField {
    Id = 'id',
    Title = 'title',
    VendorPartNo = 'vendor_part_no',
    ShortOrder='sort_order',
    IsActive = 'is_active',
}

export type Tshirt = {
    Id: string | undefined;
    Title: string;
    isActive: boolean;
    VendorPartNo: string;
};
export const shortOrderVale=[
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50']

export type TshirtFormValues = {
    [TshirtFormField.Id]: string | undefined;
    [TshirtFormField.Title]: string;
    [TshirtFormField.VendorPartNo]: string;
    [TshirtFormField.ShortOrder]: any;
    [TshirtFormField.IsActive]: boolean;
};