import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Modal } from "antd";
import { useUploadBulkExcel } from "features/transaction_list/use-cases/upload-bulk-excel";
import { FormSectionsSeparator } from "features/users/components/modals/form-sections-separator";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from 'xlsx'; // Import XLSX library

import SelectEventSample from "./select-event-sample";
import { BulkUploadFormField, BulkUploadFormValues } from "./types";

interface props {
    onClose: () => void,
    isOpen: boolean,
}

interface ApiDataItem {
    key: string;
    options: string[] | null;
    isrequired: string | null;
    ishidden: string | null;
}

interface TransformedData {
    [key: string]: {
        isRequired: boolean;
        options: string[];
    };
}

export const AddUploadModal = ({ onClose, isOpen }: props) => {

    const formName = 'add-bulk-upload-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<BulkUploadFormValues>();
    const { t } = useTranslation();
    const getInitialValues = {
        [BulkUploadFormField.EventId]: undefined,
        [BulkUploadFormField.Email]: undefined,
        [BulkUploadFormField.PersonName]: undefined,
        [BulkUploadFormField.PersonPhone]: undefined,
        [BulkUploadFormField.GroupName]: undefined,
        [BulkUploadFormField.File]: undefined,
    };

    const [fileList, setFileList] = useState<any[]>([]);
    const [errorMessages, setErrorMessages] = useState<any[]>([]);
    const [eventId, setEventId] = useState<string>("");
    const [eventNameSelected, setEventNameSelected] = useState<boolean>(false);
    const [eventName, setEventName] = useState<any>("");
    const [fullData, setFullData] = useState<ApiDataItem[]>([]);
    const [link, setLink] = useState<string>("");

    const { mutate, isLoading } = useUploadBulkExcel();

    useEffect(() => {
        form.resetFields();
        setErrorMessages([]);
        setFileList([]);
    }, [isOpen])

    const transformApiData = (apiData: ApiDataItem[]): TransformedData => {
        const transformedData: TransformedData = {};
        apiData.forEach(item => {
            transformedData[item.key.toLowerCase().trim().replace(/\s+/g, '')] = {
                isRequired: item.isrequired === "True",
                options: item.options || []
            };
        });

        return transformedData;
    };



    const showValidationErrors = (errors: string[]) => {
        Modal.error({
            title: 'Validation Errors',
            content: (
                <ul>
                    {errors.map((error, index) => (
                        <li key={index}><b>{error}</b></li>
                    ))}
                </ul>
            ),
            icon: <ExclamationCircleOutlined />,
            okText: 'OK',
        });
    };


    const handleUpload = async (e: any) => {
        setFileList(e.target.files[0]);
        const file = e.target.files[0];
        setErrorMessages([]);

        const isExcel =
            file.type === 'application/vnd.ms-excel' ||
            file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        if (!isExcel) {
            message.error('You can only upload Excel files!');
            form.resetFields([BulkUploadFormField.File]);

            return false;
        }

        const allowedHeaders = transformApiData(fullData);

        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
            const data = event.target?.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            if (jsonData.length <= 1) {
                message.error('The Excel file does not contain any data.');
                form.resetFields([BulkUploadFormField.File]);

                return;
            }

            const header = jsonData[0] as string[];
            const lowerCaseHeader = header.map((col) => col.toLowerCase().trim().replace(/\s+/g, ''));
            const lowerCaseRequiredColumns = Object.keys(allowedHeaders);
            const missingColumns = lowerCaseRequiredColumns.filter((col) => !lowerCaseHeader.includes(col));
            const extraColumns = lowerCaseHeader.filter((col) => !lowerCaseRequiredColumns.includes(col));
            const errors: string[] = [];

            // Check for missing columns
            if (missingColumns.length > 0) {
                missingColumns.forEach((column) => {
                    const originalColumn = Object.keys(allowedHeaders).find(key => key.toLowerCase().trim().replace(/\s+/g, '') === column);
                    if (originalColumn) {
                        errors.push(`Missing column: "${originalColumn}".`);
                    }
                });
            }

            if (extraColumns.length > 0) {
                extraColumns.forEach((column) => {
                    errors.push(`Extra column found: "${column}".`);
                });
            }

            jsonData.slice(1).forEach((row: any, rowIndex) => {
                // Iterate over each cell in the row
                row.forEach((cellValue: any, colIndex: number) => {
                    // const col = lowerCaseHeader[colIndex];
                    // const headerInfo = allowedHeaders[col];

                    // Check for extra values in columns beyond headers
                    if (colIndex >= lowerCaseHeader.length && cellValue) {
                        errors.push(`Extra value "${cellValue}" found in column without header at row ${rowIndex + 2}.`);
                    }
                })
            })

            // Check each row for errors
            jsonData.slice(1).forEach((row: any, rowIndex) => {
                lowerCaseHeader.forEach((col, colIndex) => {
                    const headerInfo = allowedHeaders[col];
                    const cellValue = row[colIndex];

                    if (!col && cellValue) {
                        errors.push(`Extra value "${cellValue}" found in blank header at row ${rowIndex + 2}.`);
                    }

                    if (headerInfo) {
                        // const cellValue = row[colIndex];
                        if (headerInfo.isRequired && !cellValue) {
                            errors.push(`Missing required value in column "${header[colIndex]}" at row ${rowIndex + 2}.`);
                        }

                        if (headerInfo.options.length > 0 && cellValue && !headerInfo.options.map(option => option.toLowerCase()).includes(cellValue.toLowerCase())) {
                            errors.push(`Invalid value "${cellValue}" in column "${header[colIndex]}" at row ${rowIndex + 2}.`);
                            // Allowed values are: ${headerInfo.options.join(', ')}
                        }

                        if (col === 'email' && !validateEmail(cellValue)) {
                            errors.push(`Invalid email format in column "${header[colIndex]}" at row ${rowIndex + 2}.`);
                        }

                        if (col === 'phone' && !validatePhone(cellValue)) {
                            errors.push(`Invalid phone number in column "${header[colIndex]}" at row ${rowIndex + 2}.`);
                        }
                    }
                });
            });

            if (errors.length > 0) {
                setErrorMessages(errors);
                form.resetFields([BulkUploadFormField.File]);
                showValidationErrors(errors);

                return false;
            }
        };

        reader.readAsBinaryString(file);

        return false;
    };


    const validateEmail = (email: string) => {
        // Regex for email validation
        const re = /\S+@\S+\.\S+/;

        return re.test(email);
    };

    const validatePhone = (phone: string) => {
        // Regex for phone number validation
        const re = /^[0-9]+$/;

        return re.test(phone);
    };

    // if (loading) return <Loader />;

    const handleSubmit = (formData: BulkUploadFormValues) => {
        if (errorMessages.length !== 0) {
            message.error('Please fix the errors before submitting.');

            return;
        }

        formData[BulkUploadFormField.EventId] = eventId;
        formData[BulkUploadFormField.File] = fileList;
        mutate(formData,
            { onSuccess: handleClose }
        );
    };

    const fileValidator = async () => {
        if (errorMessages.length !== 0) {

            return Promise.reject(new Error('There are errors in the Excel File.'));
        }

        return Promise.resolve();
    };

    const handleClose = () => {
        onClose();
        setEventId("");
        setLink("");
        setEventNameSelected(false);
        form.resetFields();
    }

    const handleKeyDown = (event: any) => {
        const key = event.key;

        // Allow control keys: backspace, delete, tab, escape, enter, arrow keys
        const controlKeys = ["Backspace", "Delete", "Tab", "Escape", "Enter", "ArrowLeft", "ArrowRight"];
        const isControlKey = controlKeys.includes(key);

        // Allow Ctrl/Command+A, Ctrl/Command+C, Ctrl/Command+V, Ctrl/Command+X
        const isCtrlCommand = (key === 'a' || key === 'c' || key === 'v' || key === 'x') && (event.ctrlKey || event.metaKey);

        // Allow numeric keys and the "+" sign at the start
        const isNumericKey = /^[0-9]$/.test(key);
        const isPlusSign = key === '+' && event.target.selectionStart === 0;

        if (!isControlKey && !isCtrlCommand && !isNumericKey && !isPlusSign) {
            event.preventDefault();
        }
    };


    return (

        <>
            <div className='popup'>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>Add Bulk Upload</h4>
                </div>
                <div className='modal_body'>
                    {!eventNameSelected ?
                        <SelectEventSample
                            setEventNameSelected={setEventNameSelected}
                            setEventName={setEventName}
                            isOpen={isOpen}
                            setEventId={setEventId}
                            setFullData={setFullData}
                            link={link}
                            setLink={setLink}
                        />
                        :
                        <div >
                            <Form<BulkUploadFormValues>
                                id={formName}
                                name={formName}
                                form={form}
                                layout='vertical'
                                initialValues={getInitialValues}
                                onFinish={handleSubmit}
                                // requiredMark={'optional'}
                                onFinishFailed={() => {
                                    setValidateTrigger(['onChange']);
                                }}
                                validateTrigger={validateTrigger}
                                disabled={isLoading}
                            >
                                <FormSectionsSeparator title={""}>
                                    <Form.Item<BulkUploadFormField>
                                        label={"Event Name"}
                                    >

                                        <Input disabled value={eventName.label} />
                                    </Form.Item>

                                    <Form.Item<BulkUploadFormField>
                                        label={"Group Name"}
                                        name={BulkUploadFormField.GroupName}
                                        rules={[
                                            {
                                                required: true,
                                                // whitespace: true,
                                                message: t('validateMessages.custom.nomsg'),
                                            },

                                        ]}
                                    >
                                        <Input
                                            placeholder={"Enter Group Name"}

                                        />
                                    </Form.Item>
                                    <Form.Item<BulkUploadFormField>
                                        label={"Contact Person Name"}
                                        name={BulkUploadFormField.PersonName}
                                        rules={[
                                            {
                                                required: true,
                                                // whitespace: true,
                                                message: t('validateMessages.custom.nomsg'),
                                            },

                                        ]}
                                    >
                                        <Input
                                            placeholder={"Enter Contact Person Name"}

                                        />
                                    </Form.Item>
                                    <Form.Item<BulkUploadFormField>
                                        label={"Contact Person Mobile Number"}
                                        name={BulkUploadFormField.PersonPhone}
                                        rules={[
                                            {
                                                required: true,
                                                // whitespace: true,
                                                message: t('validateMessages.custom.nomsg'),
                                            },
                                            {
                                                pattern: /^\+?[0-9\s]{1,16}$/,
                                                message: 'Please enter a valid phone number (up to 16 digits)',
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={"Enter Contact Person Mobile Number"}
                                            maxLength={16}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Form.Item>

                                    <Form.Item<BulkUploadFormField>
                                        label={"Contact Person Email"}
                                        name={BulkUploadFormField.Email}
                                        rules={[
                                            {
                                                required: true,

                                                message: t('validateMessages.custom.nomsg'),
                                            },
                                            {
                                                type: "email",
                                                message: "Please enter valid email",
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={"Enter Contact Person Email"}
                                        />
                                    </Form.Item>


                                    <Form.Item<BulkUploadFormField>
                                        label={"Upload"}
                                        name={BulkUploadFormField.File}
                                        rules={[
                                            {
                                                required: true,
                                                // message: t('validateMessages.custom.nomsg'),

                                            },
                                            {
                                                validator: fileValidator
                                            }
                                        ]}
                                    >
                                        <Input type="file" max={1} onChange={handleUpload} accept=".xls, .xlsx" />

                                    </Form.Item>
                                </FormSectionsSeparator>

                                <div className='button-group'>
                                    <Button loading={isLoading} className='btn' htmlType='submit'>Save</Button>
                                </div>
                            </Form>

                        </div>}
                </div>
            </div>
        </>
    );
};
