
import { ContentTopBar } from 'components';
import { Outlet } from 'react-router-dom';

import DisclaimerEditor from '../components/global-disclaimer/disclaimer-editor';


export const GlobalDisclaimerView = () => {

    return (
        <>
            <ContentTopBar
                title={"Global Disclaimer"}
            />

            <DisclaimerEditor />
            <Outlet />
        </>
    );
};


