import { App } from 'antd';
import { EventsApiService } from 'features/events';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';
import { useState } from 'react';

import { bannerQueryKeys } from './bannerQueryKeys';

const { uploadGalllaryReceiptImg } = EventsApiService();

export const useUploadGalllaryReceiptImg = () => {

    const { message } = App.useApp();
    const [loading, setLoading] = useState<boolean>(false);

    const uploadImage = async (formData: FormData, onSuccess: () => void) => {
        setLoading(true); // Set loading to true when starting upload
        try {
            await uploadGalllaryReceiptImg(formData);
            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: bannerQueryKeys.lists(),
                }),

            ]);
            onSuccess();
            message.success("Gallery Images Uploaded Successfully");
        } catch (error) {
            // const uiMessage = handleError(error);
            message.warning(i18n.t('fields.update.errorImageUpload'));
        } finally {
            setLoading(false); // Set loading to false after upload completes or fails
        }
    };

    return {
        uploadImage,
        loading

    };
};
