import { Button, Form, Input, message, Radio, Select } from 'antd';
import { PartnersFormValues } from 'features/cms/components/manage-partners/add-partners-modal/types';
import { ShopApiService } from 'features/shop-item/api/shop-api.service';
import { useCreateSize } from 'features/shop-item/use-cases/craete-size';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ItemFormField, SizeFormField } from './type';

export const EditItemSizeModal = ({ onClose, itemdataModal, setSizeValue, tabItemSize }: { itemdataModal: any, onClose: () => void; showEditItemModal: boolean, defaultValue: any, setSizeValue: any, tabItemSize: () => void }) => {

    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);

    const [form] = Form.useForm<PartnersFormValues>();
    const { id } = useParams();

    const { mutate } = useCreateSize();

    const [size, setSize] = useState({
        items: []
    } as any)

    const [Color, setColor] = useState({
        items: []
    } as any)

    const initialSize = async () => {
        try {
            const apiRes = await ShopApiService().getItemSize('' as any)
            setSize(apiRes)
        } catch (error) {
            message.error('')
        }
    }

    const initialColor = async () => {
        try {
            const apiRes = await ShopApiService().getItemColor('' as any)
            setColor(apiRes)
        } catch (error) {
            message.error('')
        }
    }
    const handleUpdate = (formData: any) => {

        const updatedFormData = {
            ...formData,
            id: +itemdataModal.id!,
            shop_item_id: +id!,
            total_quantity: +formData.total_quantity,
            is_active: true
        };
        setSizeValue((prevData: any) => ({
            ...prevData,
            items: prevData?.items.map((item: any) =>
                item.shop_item_id === id ? updatedFormData : item
            )
        }));
        mutate(updatedFormData, {
            onSuccess: () => {
                onClose();
                tabItemSize();
            }
        });
    };

    useEffect(() => {
        if (itemdataModal) {
            form.setFieldsValue(itemdataModal);
            initialSize()
            initialColor()
        }
    }, [itemdataModal])


    return (
        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={onClose} className='close-btn'>
                    &times;
                </button>
                <h4>Edit Size</h4>
            </div>
            <div className='modal_body'>
                <Form<PartnersFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    onFinish={handleUpdate}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                // disabled={isLoading}
                >
                    <FormSectionsSeparator title={"Edit Size"}>
                        <Form.Item<SizeFormField>
                            label={"Size"}
                            name={SizeFormField.size_master_id}


                        >
                            <Select
                                placeholder={itemdataModal.size_value}
                                disabled
                                options={size?.items?.map((res: any) => ({
                                    label: res.size_name,
                                    value: res.id,
                                }))}
                            />
                        </Form.Item>
                        <Form.Item<ItemFormField>
                            label={"Color"}
                            name={SizeFormField.color_id}
                        >
                            <Select
                                placeholder={itemdataModal.color_title}
                                disabled
                                options={Color?.items?.map((res: any) => ({ label: res.title, value: res.id }))}
                            />
                        </Form.Item>
                        <Form.Item<ItemFormField>
                            label={"Total Quantity"}
                            name={SizeFormField.total_quantity}
                        // rules={[
                        //     {
                        //         required: true,
                        //         whitespace: true,
                        //     },
                        // ]}
                        >
                            <Input
                                onKeyPress={(e) => {
                                    // Get the current input value
                                    const inputValue = (e.target as HTMLInputElement).value || '';

                                    // Get the key that was pressed
                                    const key = e.key;

                                    // Allow only numbers, single decimal point, and Backspace
                                    const isValidKey = /^[\d.]$/.test(key) || key === 'Backspace';

                                    // If the entered key is not valid or there are already two decimal places
                                    if (!isValidKey || (inputValue.includes('.') && key === '.')) {
                                        e.preventDefault();
                                    }
                                }}

                            />
                        </Form.Item>
                        <Form.Item<ItemFormField>
                            label={"Order"}
                            name={SizeFormField.sort_order}
                        >
                            <Input
                                onKeyPress={(e) => {
                                    // Get the current input value
                                    const inputValue = (e.target as HTMLInputElement).value || '';

                                    // Get the key that was pressed
                                    const key = e.key;

                                    // Allow only numbers, single decimal point, and Backspace
                                    const isValidKey = /^[\d.]$/.test(key) || key === 'Backspace';

                                    // If the entered key is not valid or there are already two decimal places
                                    if (!isValidKey || (inputValue.includes('.') && key === '.')) {
                                        e.preventDefault();
                                    }
                                }}

                            />
                        </Form.Item>
                        <Form.Item name={SizeFormField.is_featured} label="Featured">
                            <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </FormSectionsSeparator>
                </Form>
                <div className='button-group'>
                    <Button htmlType='submit' onClick={form.submit} className='btn'>Save</Button>
                </div>

            </div>
        </div>

    )
};

