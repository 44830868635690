import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

import { CMSApiService, CraeteFaqDTO } from '../api/cms-api.service';
import { eventsQueryKeys } from './query-keys';

const { createFaq } = CMSApiService();

export const useCreateFaq = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: CraeteFaqDTO) => {
            return createFaq(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
                message.success(i18n.t('fields.create.successfulCreate'));
            },
        },
    );
};
