import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { WaitingListTopBar } from '../component/top-bar/waiting-list-bar';
import { WaitingFilterFormValuesPaginated } from '../component/waiting-filter-form/types';
import { WaitingList } from '../component/waiting-list-table/waiting-list';
import { useGetWaitingList } from '../use-cases/get-waiting-list';

const currentYear = new Date().getFullYear();

const getInitialFilterValues: any = {
    invoice_number: undefined,
    name: undefined,
    MobileNumber: undefined,
    email: undefined,
    paymentMethod: undefined,
    tansactionStatus: undefined,
    bookingDate: undefined,
    tranactionId: undefined,
    RefrenceNumber: undefined,
    AuthId: undefined,
    currency: undefined,
    userType: undefined,
    year: currentYear
};

export const WaitingListView = () => {

    const { params, updateParams, resetPage } = usePaginationParams<any>({
        page: 1,
        size: 10,
        ...getInitialFilterValues,
    });

    const { data, refetch, isFetching } = useGetWaitingList(params, { retry: false });

    const handleFiltersChange = (values: WaitingFilterFormValuesPaginated) => {
        updateParams(values);
        resetPage();
        refetch()
    };

    return (

        <>
            <WaitingListTopBar
                handleFilter={handleFiltersChange}
                getInitialFilterValues={getInitialFilterValues}
                refetch={refetch}
            />

            <WaitingList

                isSocialListLoading={isFetching}
                DeliveryList={data}
                tableParams={params as any}
                updateParams={updateParams}
                refetch={refetch}
                isFetching={isFetching}

            />
            <Outlet />
        </>
    );
};
