import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useCreateConversionCurrency } from 'features/cms/use-cases/create-conversion-currency';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CurrencyConversionFormField, CurrencyConversionFormValues } from './types';

export const AddCurrencyConversionModal = ({ onClose, showCurrencyModal, refetch, currencyEditData }: { onClose: () => void; showCurrencyModal: boolean, refetch: () => void, currencyEditData: any }) => {
    const formName = 'add-conversion-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<CurrencyConversionFormValues>();
    const { isLoading, mutate } = useCreateConversionCurrency();

    const { t } = useTranslation();

    const getInitialValues = {
        [CurrencyConversionFormField.Id]: undefined,
        [CurrencyConversionFormField.CurrencyName]: undefined,
        [CurrencyConversionFormField.ToCurrency]: undefined,
        [CurrencyConversionFormField.ConvertValue]: undefined,
        [CurrencyConversionFormField.IsActive]: undefined,
    };

    const handleUpdate = (formData: CurrencyConversionFormValues) => {
        formData[CurrencyConversionFormField.Id] = currencyEditData?.Id;
        formData[CurrencyConversionFormField.IsActive] = true;
        mutate(formData, { onSuccess: hanldeSuccess });
    };

    const hanldeSuccess = () => {
        onClose();
        refetch();
    }

    useEffect(() => {
        if (currencyEditData) {
            form.setFieldsValue(currencyEditData);
        } else {
            form.resetFields();
        }
    }, [currencyEditData])

    return (

        <div className={showCurrencyModal ? 'popup' : 'popup'}>
            <div className='modal_head'>
                <button onClick={onClose} className='close-btn'>
                    &times;
                </button>
                <h4>{currencyEditData ? "Edit Currency Conversion" : "Add Currency Conversion"}</h4>
            </div>
            <div className='modal_body' style={{
                height: "90vh",
                overflowY: "auto"
            }}>
                <Form<CurrencyConversionFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    requiredMark={'optional'}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <div>
                        <FormSectionsSeparator title={""}>

                            <Form.Item<CurrencyConversionFormField>
                                label={"Currency Name"}
                                name={CurrencyConversionFormField.CurrencyName}

                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Currency Name"}
                                    prefix={<UserOutlined className='input-prefix-icon' />}
                                    disabled={currencyEditData?.Id}
                                />
                            </Form.Item>



                            <Form.Item<CurrencyConversionFormField>
                                label={"To Currency"}
                                name={CurrencyConversionFormField.ToCurrency}

                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"To Currency"}
                                    disabled={currencyEditData?.Id}
                                />
                            </Form.Item>


                            <Form.Item<CurrencyConversionFormField>
                                label={"Convert Value"}
                                name={CurrencyConversionFormField.ConvertValue}

                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Convert Value"}
                                />
                            </Form.Item>

                        </FormSectionsSeparator>
                    </div>
                </Form>
                <div className='button-group'>
                    <Button htmlType='submit' onClick={form.submit} loading={isLoading} className='btn'>Save</Button>
                </div>

            </div>
        </div>

    )
};

