import { Button, Form, Grid, Input } from 'antd';
import { ContentFilters } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
    formId: string;
    handleFilter: (values: any) => void;
    initialValues: any;
    closeModal?: () => void;
};

export const CouponTabFilterForm = ({
    formId,
    handleFilter,
    initialValues,
    closeModal,
}: UsersFilterFormProps) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<any>();
    const { t } = useTranslation();
    const screens = useBreakpoint();
    const onReset = () => {
        form.resetFields();
        handleFilter({ ...initialValues });
    };

    const onFinish = (formValues: any) => {
        const formattedValues = {
            ...formValues,
        };
        handleFilter(formattedValues);
        closeModal?.();
    };



    return (

        <Form
            id={formId}
            name={formId}
            className='filters-form'
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            onFinishFailed={() => {
                setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
        >
            <ContentFilters.FormItemsWrapper>

                <div className='invoice_list cupn_img'>

                    <Form.Item
                        label={'Coupon Title'}
                        name={"coupon_title"}
                    >
                        <Input placeholder={'Enter Coupon Name'} />
                    </Form.Item>

                    <Form.Item
                        label={'Coupon Code'}
                        name={"coupon_code"}
                    >
                        <Input placeholder={'Enter Coupon Code'} />
                    </Form.Item>

                    {screens.lg && (
                        <ContentFilters.ButtonsWrapper className='filter_btn'>
                            <Button type='primary' htmlType='submit'>
                                {t('common.buttons.filter', { count: 1 })}
                            </Button>
                            <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
                        </ContentFilters.ButtonsWrapper>
                    )}

                </div>

            </ContentFilters.FormItemsWrapper>

        </Form>
    );
};
