import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { EventsApiService, EventUpdateDTO } from 'features/events';
import { queryClient } from 'providers';

import { eventsQueryKeys } from './query-keys';

const { eventUpdateStatus } = EventsApiService();

export const useEventUpdateStatus = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: EventUpdateDTO) => {
      return eventUpdateStatus(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data: any) => {
        const { ResponseMessage, ResponseStatus } = data;

        if (ResponseStatus === 1) {
          Promise.all([
            queryClient.invalidateQueries({
              queryKey: eventsQueryKeys.all,
            }),
          ]);
          message.success(ResponseMessage);
        } else if (ResponseStatus === 2) {
          message.error(ResponseMessage);
        } else {
          message.warning(ResponseMessage);
        }
      },
    },
  );
};
