import { UploadOutlined } from '@ant-design/icons';
import { Grid, Pagination } from 'antd';
import { Button, ContentTopBar, StyledPaginationWrapper } from 'components';
import { PageLoader } from 'components/page-loader/page-loader';
import { getSuffixUserId } from 'features/auth';
import { useGetEventGallery } from 'features/events/use-cases/get-event-gallery';
import { usePaginationParams } from 'hooks';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EventsFilterFormValues } from '../../events-filter-form';
import { EventGallaryModal } from './event-gallary-modal';
import EventGalleryImgView from './event-gallery-img-view';


const EventGallary = ({ activeTab }: { activeTab: string }) => {
    const { id } = useParams();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    const [gallaryModal, setGallaryModal] = useState<boolean>(false);

    const suffixUserId = getSuffixUserId();

    const initialFilterValues: EventsFilterFormValues = {
        field_name: undefined,
    };
    const [page, setPage] = useState<any>(1);
    const [totalPage, setTotalPage] = useState<any>(0);
    const [currentPage, setCurrentPage] = useState<any>(1);

    // Get initial params
    const initialParams = {
        user_id: suffixUserId,
        event_id: id,
        order_by: '-rank',
        size: 12,
        flag: activeTab === "2" ? "banner" : activeTab === "1" ? "Event" : "",
        ...initialFilterValues,
    };





    const handleCloseModal = () => {
        setGallaryModal(false)
        document.body.style.overflow = 'visible';
    }

    const { params, setParams } = usePaginationParams<any>({
        ...initialParams,
        page: page,
    });


    useEffect(() => {
        setParams({ ...params, page: page });
    }, [page]);


    const { data, isFetching, refetch } = useGetEventGallery(params);

    useEffect(() => {
        setTotalPage(data?.total);
        setCurrentPage(data?.page)
    }, [data])

    useEffect(() => {
        refetch();
    }, [params]);

    useEffect(() => {
        setPage(1)
    }, [activeTab])

    if (!id) return null;

    const handleChangePagination = (val: any) => {
        setPage(val);
    }

    const handleOpenModal = () => {
        setGallaryModal(true);
        document.body.style.overflow = 'hidden';
    }


    return (
        <>
            <ContentTopBar
                title={''}
                renderButtons={
                    <Button icon={!screens.xs ? <UploadOutlined /> : undefined} className='btn tpmrng' onClick={handleOpenModal}>
                        {"Upload"}
                    </Button>
                }
            />
            {isFetching ?
                <PageLoader />
                :
                <>
                    <EventGalleryImgView
                        data={data}
                        // isLoading={isFetching}
                        refetch={refetch}
                    // tableParams={params}
                    //     updateParams={updateParams}
                    />
                    <StyledPaginationWrapper>
                        < Pagination
                            total={totalPage} // Total number of items
                            pageSize={12} // Number of items per page
                            current={currentPage} // Current page number
                            onChange={handleChangePagination} // Function to handle page change
                        // showSizeChanger // Enable the option to change page size
                        // onShowSizeChange={handlePageChange} // Function to handle page size change
                        // pageSizeOptions={['10', '20', '50', '100']} // Options for page size
                        /// showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} // Function to display total item count
                        />
                    </StyledPaginationWrapper>
                </>
            }
            <div className={`cstmrightmdl upld_modl ${gallaryModal && "show"}`}>
                <EventGallaryModal id={id} activeTab={activeTab} onClose={handleCloseModal} gallaryModal={gallaryModal} refetch={refetch} />
            </div>

        </>
    )
}

export default EventGallary;
