import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import {
  AuthApiService,
  LoginDTO,
  setAccessToken,
  setRefreshToken,
} from 'features/auth';

import { handleError } from '../../../api';


const { login } = AuthApiService();

type UseLoginPayload = LoginDTO & { persistSession: boolean };

export const useLogin = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: UseLoginPayload) => {
      const { email_id, password } = payload;

      return login({ email_id, password });
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {

        setAccessToken(data.access_token, variables.persistSession);
        setRefreshToken(data.refresh_token, variables.persistSession);
      },
    },
  );
};
