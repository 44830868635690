import { DeleteOutlined, EditOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Empty, Skeleton, Space, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VendingMachine } from 'types';

import { AddNotiModalList } from '../modal/AddModalList';
import { SendNotificationModal } from '../modal/send-notification-modal';
import { NotificationFilterFormValuesPaginated } from '../type';

type NotificationTableProps = {
  isUsersListLoading: boolean;
  List: VendingMachine[];
  itemsCount: number;
  tableParams: NotificationFilterFormValuesPaginated;
  refetch: any,
  EditData: any,
  setEditData: any,
  updateParams: Dispatch<SetStateAction<NotificationFilterFormValuesPaginated>>;
};

export const NotificationListTable = ({
  List,
  isUsersListLoading,
  itemsCount,
  refetch,
  EditData,
  setEditData,
  tableParams,
  updateParams,
}: NotificationTableProps) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const [showSendNoti, setShowSendNoti] = useState<boolean>(false);
  const [sendNotiData, setSendNotiData] = useState<any>(null);

  const handleCloseModal = () => {
    setShowModal(false);
    setEditData(undefined);
  }

  const handleOpenModal = (record: any) => {
    setShowModal(true);
    setEditData(record);
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<VendingMachine> | SorterResult<VendingMachine>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'
          : '-name',
    });
  };

  const handleCloseSendNoti = () => {
    setShowSendNoti(false);
    setSendNotiData(null);
  }

  const handleOpenSendNoti = (record: any) => {
    setShowSendNoti(true);
    setSendNotiData(record)
  }


  return (
    <StyledTableWrapper>

      <div className={`cstmrightmdl ${showModal && "show"}`}>
        <AddNotiModalList showModal={showModal} refetch={refetch} onClose={handleCloseModal} EditData={EditData} />
      </div>

      <div className={`cstmrightmdl ${showSendNoti && "show"}`}>
        <SendNotificationModal showModal={showSendNoti} refetch={refetch} onClose={handleCloseSendNoti} EditData={sendNotiData} />
      </div>

      <StyledTableHeader>
        <RecordCountBadge
          text={t('vendingMachine.foundRecord')}
          count={isUsersListLoading ? 0 : itemsCount || 0}
        />
      </StyledTableHeader>
      <Table<VendingMachine>
        // loading={isUsersListLoading}
        // dataSource={List}
        className="cstltblwthldr"
        locale={{
          emptyText: isUsersListLoading ? <Skeleton active={true} /> : <Empty />
        }}
        loading={isUsersListLoading && { indicator: <div><TableLoader /></div> }}
        dataSource={isUsersListLoading ? [] : List}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: itemsCount,
        }}
      >
        <Column<VendingMachine>
          title={'Title'}
          dataIndex={'title'}
        />

        <Column<VendingMachine>
          title={'Type '}
          dataIndex={'announcement_type'}
        />

        <Column<VendingMachine>
          title={'Content'}
          dataIndex={'content'}
        />
        <Column
          title="Action"
          dataIndex="email_address"
          render={(_, record: any) => (
            <Space>
              <Tooltip title="">
                <Button
                  shape="circle"
                  icon={<SendOutlined />}
                  onClick={() => handleOpenSendNoti(record)} />

              </Tooltip>

              <Tooltip title="Edit">
                <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
              </Tooltip>

              <Tooltip title="Delete">
                <Button
                  shape="circle"
                  // onClick={() => showDeleteConfirm(record)}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
