import {
    DownOutlined,
    IdcardOutlined,
} from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Form, message, Select } from 'antd';
import { UserApiService } from 'features/users';
import {
    AddEventFormField,
    AddEventFormValues
} from 'features/users/components/users-filter-form';
import { useEventWithAgent } from 'features/users/use-cases/create-user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { getVendingEvents } = UserApiService();

type eventListType = {
    Id: string;
    Text: string;
    Value: string;
}

type addAgentModal = {
    agentId: number | undefined;
    onClose: () => void;
    agentId_: string;
    eventRefetch: () => void;
}


export const AddAgentEventModal = ({ agentId, agentId_, onClose, eventRefetch }: addAgentModal) => {
    const formName = 'add-event-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddEventFormValues>();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [eventList, setEventList] = useState<eventListType[]>([]);
    const { isLoading, mutate } = useEventWithAgent();

    const handleClose = () => {
        onClose();
        form.resetFields();
        eventRefetch();
    }

    const getInitialValues = {
        [AddEventFormField.EventName]: undefined,
    };


    const EventList = async () => {
        try {
            const apiRes = await getVendingEvents(`Flag=${'Events'}`);
            setEventList(apiRes.items);

        } catch (error) {
            message.error("error");

        }

    }

    useEffect(() => {
        EventList();
    }, []);

    const handleUpdate = (formData: AddEventFormValues) => {
        const payload = {
            agent_id: agentId,
            event_id: formData.event_id.value,
            event_name: formData.event_id.label
        };


        mutate(payload, { onSuccess: handleClose });
        queryClient.invalidateQueries(['agentEventList', agentId_]);
    };

    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>Events</h4>
                </div>
                <div className='modal_body'>
                    <Form<AddEventFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <Form.Item name={AddEventFormField.EventName} label="Event Master" rules={[{ required: true }]}>
                            <Select
                                options={eventList.map((res: any) => ({ label: res.Text, value: res.Id }))}
                                placeholder={t('volunteer.field.assign_event.placeholder')}
                                className='has-prefix-icon'
                                labelInValue
                                suffixIcon={
                                    <>
                                        <IdcardOutlined className='prefix-icon' />
                                        <DownOutlined className='suffix-icon' />
                                    </>
                                }
                            />

                        </Form.Item>

                        <div className='form-button'>
                            <Button type="primary" htmlType='submit' className="btn" >Save</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

