import { Button, Form } from 'antd';
import { useCreateCouponMaster } from 'features/events/use-cases/create-coupon-master';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { payFormatDate } from 'utils/payload-date-';

import AddCouponForm from './add-coupon-form';
import { CouponFormField, CouponFormValues } from './types';

export const AddCouponModal = ({ onClose, couponEditData }: { onClose: () => void; showCouponModal: boolean, refetch: () => void, couponEditData: any }) => {
    const { id } = useParams();

    const formName = 'add-Coupon-2';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<CouponFormValues>();
    const { isLoading, mutate } = useCreateCouponMaster();

    const handleClose = () => {
        onClose();
        form.resetFields();
    }

    const getInitialValues = {
        [CouponFormField.Id]: undefined,
        [CouponFormField.Title]: undefined,
        [CouponFormField.CouponType]: undefined,
        [CouponFormField.couponCode]: undefined,
        [CouponFormField.ValueType]: undefined,
        [CouponFormField.Value]: undefined,
        [CouponFormField.StartDate]: undefined,
        [CouponFormField.EndDate]: undefined,
        [CouponFormField.EventId]: undefined,
        [CouponFormField.Limit]: undefined,
        [CouponFormField.Prefix]: undefined,
        [CouponFormField.NoOfCoupons]: undefined,
        [CouponFormField.IsActive]: true,
    };

    useEffect(() => {
        if (couponEditData) {
            form.setFieldsValue(couponEditData);
        } else {
            form.resetFields();
        }
    }, [couponEditData])

    if (!id) return null;


    const handleUpdate = (formData: CouponFormValues) => {
        formData[CouponFormField.Id] = couponEditData?.id;
        formData[CouponFormField.EventId] = id;
        formData[CouponFormField.StartDate] = formData.start_date ? payFormatDate(formData.start_date) : "";
        formData[CouponFormField.EndDate] = formData.end_date ? payFormatDate(formData.end_date) : "";
        formData[CouponFormField.IsActive] = true;
        formData[CouponFormField.Flag] = "EVENT_COUPONS";
        mutate(formData, { onSuccess: hanldeSuccess });
    };

    const hanldeSuccess = () => {
        handleClose();
        // refetch();
    }



    return (
        <>
            <div className='popup'>
                <div className='modal_head'>
                    <h4>{couponEditData ? "Edit Coupon" : "Add Coupon"}</h4>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>

                </div>
                <div className='modal_body' >
                    <Form<CouponFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <AddCouponForm couponEditData={couponEditData} form={form} />
                        <div className='form-button'>
                            <Button htmlType='submit' className='btn' type='primary'>Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
};

