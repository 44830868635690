import { Button, DatePicker, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WalletFilterFormField, WalletFilterFormValues } from './types';
const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
    formId: string;
    handleFilter: (values: WalletFilterFormValues) => void;
    initialValues: WalletFilterFormValues;
    closeModal?: () => void;
};

export const WalletFilterForm = ({
    formId,
    handleFilter,
    initialValues,
    closeModal,
}: UsersFilterFormProps) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<WalletFilterFormValues>();
    const { t } = useTranslation();
    const screens = useBreakpoint();
    const onReset = () => {
        form.resetFields();
        handleFilter({ ...initialValues });
    };

    function payLoadDate(valDate: any) {
        if (valDate) {
            const selectedDate = new Date(valDate);
            selectedDate.setHours(selectedDate.getHours() + 5);
            selectedDate.setMinutes(selectedDate.getMinutes() + 30);

            return selectedDate;
        }
        else {
            return undefined
        }
    }

    const onFinish = (formValues: WalletFilterFormValues) => {
        formValues[WalletFilterFormField.Date] = payLoadDate(formValues.date);
        handleFilter(formValues);
        closeModal?.();
    };

    return (

        <Form<WalletFilterFormValues>
            id={formId}
            name={formId}
            className='filters-form'
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            onFinishFailed={() => {
                setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
        >
            <ContentFilters.FormItemsWrapper>
                <StandardItemsWrapper>
                    <div className='invoice_list'>


                        <Form.Item<WalletFilterFormValues>
                            // label={t('shops.formfield.fieldName.label')}
                            label={'Name'}
                            name={WalletFilterFormField.Name}
                        >
                            <Input placeholder={'Enter Name'} />
                        </Form.Item>
                        <Form.Item<WalletFilterFormValues>
                            // label={t('shops.formfield.fieldName.label')}
                            label={'Mobile'}
                            name={WalletFilterFormField.MobileNo}
                        >
                            <Input placeholder={'Enter Phone No'} />
                        </Form.Item>
                        <Form.Item<WalletFilterFormValues>
                            // label={t('shops.formfield.fieldName.label')}
                            label={'EMAIL'}
                            name={WalletFilterFormField.Email}
                        >
                            <Input placeholder={'Enter Email'} />
                        </Form.Item>
                        <Form.Item<WalletFilterFormValues>
                            // label={t('shops.formfield.fieldName.label')}
                            label={'Pay Type'}
                            name={WalletFilterFormField.PayType}
                        >
                            <Select
                                defaultValue="Select Pay Type"
                                allowClear
                                options={[
                                    { value: 'Cash', label: 'Cash' },
                                    { value: 'Knet', label: 'Knet' },
                                    { value: 'Card', label: 'Card' },
                                    { value: 'Wallet', label: 'Wallet' },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item<WalletFilterFormValues>
                            // label={t('shops.formfield.fieldName.label')}
                            label={'Transaction Status'}
                            name={WalletFilterFormField.TransactionStatus}
                        >
                            <Select
                                defaultValue="Select Transaction Status"
                                style={{ width: 180 }}
                                allowClear
                                options={[
                                    { value: 'Captured', label: 'Captured' },
                                    { value: 'uncaptured', label: 'Uncaptured' },
                                    { value: 'paid', label: 'Paid' },
                                    { value: 'success', label: 'Success' },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item<WalletFilterFormValues>
                            label={'Date'}
                            name={WalletFilterFormField.Date}
                        >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item<WalletFilterFormValues>
                            // label={t('shops.formfield.fieldName.label')}
                            label={'Transaction Id'}
                            name={WalletFilterFormField.TransactionId}
                        >
                            <Input placeholder={'Enter Transaction Id'} />
                        </Form.Item>
                        <Form.Item<WalletFilterFormValues>
                            // label={t('shops.formfield.fieldName.label')}
                            label={'Auth Id'}
                            name={WalletFilterFormField.AuthId}
                        >
                            <Input placeholder={'Enter Auth Id No'} />
                        </Form.Item>
                        {screens.lg && (
                            <ContentFilters.ButtonsWrapper className='filter_btn'>
                                <Button type='primary' htmlType='submit'>
                                    {t('common.buttons.filter', { count: 1 })}
                                </Button>
                                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
                            </ContentFilters.ButtonsWrapper>
                        )}
                    </div>
                </StandardItemsWrapper>
            </ContentFilters.FormItemsWrapper>

        </Form>
    );
};
