export const headerHeight = '4.6rem';

export const smallMobileContentPadding = () => {
  const top = '20px' as const;
  const right = '20px' as const;
  const bottom = '20px' as const;
  const left = '20px' as const;
  const shorthand = `${top} ${right} ${bottom} ${left}` as const;

  return {
    top,
    right,
    bottom,
    left,
    shorthand,
  };
};

export const contentPadding = () => {
  const top = '20px' as const;
  const right = '20px' as const;
  const bottom = '20px' as const;
  const left = '20px' as const;
  const shorthand = `${top} ${right} ${bottom} ${left}` as const;

  return {
    top,
    right,
    bottom,
    left,
    shorthand,
  };
};
