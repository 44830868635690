import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import {
    MainFilters,
    MainFiltersParams,
} from 'components/main-filters/main-filters';
import {
    EventsFilterForm,
    EventsFilterFormPaginated,
    EventsFilterFormValues,
} from 'features/events';
import { usePermissions } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { FaqTabel } from '../components/faq-management';
import { AddFaqModal } from '../components/faq-management/Add-faq-madal/add-faq-modal';
import { useGetFaq } from '../use-cases/get-faq';

const { useBreakpoint } = Grid;

const initialFilterValues: EventsFilterFormValues = {
    field_name: undefined,
};

export const FaqView = () => {
    const screens = useBreakpoint();
    const permissions = usePermissions();
    const { params, updateParams, resetPage } =
        usePaginationParams<EventsFilterFormPaginated>({
            page: 1,
            size: 10,
            order_by: '-rank',
            ...initialFilterValues,
        });

    const { data, isFetching, refetch } = useGetFaq(params, { retry: false });
    const [faqEditData, setFaqEditData] = useState<any>(undefined);
    const [showFaqModal, setShowFaqModal] = useState(false);

    const shouldHandleFieldCreation = permissions?.fields.add;

    const handleFiltersChange = (values: EventsFilterFormValues) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    const handleMainFiltersChange = (mainParams: MainFiltersParams) => {
        updateParams(mainParams);
        resetPage();
    };
    const formId = 'events-filters-form';

    const handleCloseModal = () => {
        setShowFaqModal(false);
    }

    return (
        <>
            <ContentTopBar
                title={"FAQ"}
                renderHeadingContent={
                    <MainFilters onChange={handleMainFiltersChange} />
                }
                renderButtons={
                    <>
                        {shouldHandleFieldCreation ? (
                            <>
                                <Button
                                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                                    onClick={() => setShowFaqModal(true)}
                                >
                                    {"Add FAQ"}
                                </Button>
                                <div className={`cstmrightmdl ${showFaqModal && "show"}`}>

                                    <AddFaqModal showFaqModal={showFaqModal} refetch={refetch} onClose={handleCloseModal} faqEditData={faqEditData} />
                                </div>

                            </>
                        ) : null}
                    </>
                }
            />

            <ContentFilters formId={formId}>
                <EventsFilterForm
                    formId={formId}
                    handleFilter={handleFiltersChange}
                    initialValues={initialFilterValues}
                />
            </ContentFilters>

            <FaqTabel
                data={data}
                isLoading={isFetching}
                tableParams={params}
                updateParams={updateParams}
                refetch={refetch}
                faqEditData={faqEditData}
                setFaqEditData={setFaqEditData}
            />
            <Outlet />
        </>
    );
};

