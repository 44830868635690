import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, Skeleton, Space, Switch, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, Table } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import { useStatusResponse } from 'features/shop-item/use-cases/update-status-response-msg';
import { formatDateDisplay } from 'features/transaction_list/utills/utiils';
import { usePermissions } from 'features/users';
import { EndUserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'types';
import { AllCoupon } from 'types/all-coupon';

import { AddShopCouponModal } from '../modal/AddShopCouponModal';

type UsersTableProps = {
    isSocialListLoading: boolean;
    data: any;
    // itemsCount: number;
    tableParams: EndUserFilterFormValuesPaginated;
    updateParams: Dispatch<SetStateAction<EndUserFilterFormValuesPaginated>>;
    refetch: () => void;
};


export const CouponListTable = ({
    data,
    isSocialListLoading,
    // itemsCount,
    tableParams,
    updateParams,
    refetch
}: UsersTableProps) => {
    const { t } = useTranslation();
    const permissions = usePermissions();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        sorter: SorterResult<User> | SorterResult<User>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: Array.isArray(sorter)
                ? undefined
                : sorter.order === 'ascend'
                    ? 'name'
                    : '-name',
        });
    };


    const [showAddItemModal, setShowAddItemModal] = useState<boolean>(false);
    const [couponEditData, setCouponEditData] = useState<boolean>(false);

    const { mutate: updateStatusMutate, isLoading: statusLoading } = useStatusResponse();

    const handleOpenModal = (record: any) => {

        setCouponEditData(record);
        setShowAddItemModal(true);
        document.body.style.overflow = 'hidden';
    }

    const handleCloseModal = () => {
        setShowAddItemModal(false)
        document.body.style.overflow = 'visible';
    }


    const handleDelete = (record: any) => {
        const deletedFormData = {
            id: record.id,
            status: false,
            flag: "COUPONS_DELETE"
        }
        updateStatusMutate(deletedFormData);
    }

    const { confirm } = Modal;

    const showDeleteConfirm = (event: any, record: any) => {
        event.stopPropagation();
        confirm({
            title: 'Are you sure you want to delete this Coupon?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record);
            },
        });
    };



    const handleUpdateStatus = (id: any, isChecked: any) => {
        const statusFormData = {
            id: id,
            status: isChecked,
            flag: "COUPONS_SET_STATUS"
        }
        updateStatusMutate(statusFormData);
    }

    if (!permissions) return null;


    return (
        <>
            <div className={`cstmrightmdl ${showAddItemModal && "show"}`}>
                <AddShopCouponModal onClose={handleCloseModal} refetch={refetch} showAddItemModal={showAddItemModal} couponEditData={couponEditData} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    {/* <RecordCountBadge text={t('social.foundSocial')} count={itemsCount} /> */}
                </StyledTableHeader>
                <Table<User>
                    className="cstltblwthldr"
                    locale={{
                        emptyText: isSocialListLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isSocialListLoading && { indicator: <div><TableLoader /></div> }}
                    dataSource={isSocialListLoading ? [] : data?.items}
                    rowKey='id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.page,
                        defaultPageSize: tableParams.size,
                        total: data?.total,
                    }}
                    scroll={{ x: 1440 }}

                >
                    <Column<AllCoupon>
                        title={t('shops.category.coupon.table.title')}
                        dataIndex={'title'}
                        //   render={(_, record) => getUserFullName(record)}
                        sorter={true}
                        sortDirections={['ascend', 'descend', 'ascend']}
                        defaultSortOrder={'ascend'}
                    />
                    <Column<AllCoupon>
                        title={t('shops.category.coupon.table.code')}
                        dataIndex={'coupon_code'}
                    //   render={(_, record) => getUserFullName(record)}
                    // sorter={true}
                    // sortDirections={['ascend', 'descend', 'ascend']}
                    // defaultSortOrder={'ascend'}
                    />

                    <Column<AllCoupon>
                        title={"Start Date"}
                        // dataIndex={'shop_category_name'}
                        render={(_, record) => record?.start_date && formatDateDisplay(record?.start_date)}

                    />
                    <Column<AllCoupon>
                        title={"End Date"}
                        render={(_, record) => record?.end_date && formatDateDisplay(record?.end_date)}

                    />
                    <Column<AllCoupon>
                        title={t('shops.category.coupon.table.value_type')}
                        dataIndex={'shop_category_name'}
                        render={(_, record) => record?.value_type === 1 ? "Flat" : "Percent"}
                    // sorter={true}
                    // sortDirections={['ascend', 'descend', 'ascend']}
                    // defaultSortOrder={'ascend'}
                    />
                    <Column<AllCoupon>
                        title={t('shops.category.coupon.table.value')}
                        dataIndex={'value'}

                    />
                    <Column<AllCoupon>
                        title={t('shops.category.coupon.table.status')}
                        dataIndex={'coupan_status'}
                    //   render={(_, record) => getUserFullName(record)}
                    />

                    <Column<AllCoupon>
                        title={"No of Person"}
                        dataIndex={'limit'}
                    />

                    <Column
                        title={t('shops.category.table.action')}
                        render={(_, record: any) => (
                            <Space>
                                <Tooltip title="Edit">
                                    <Button shape="circle" onClick={() => handleOpenModal(record)}
                                        icon={<EditOutlined />} />
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <Button shape="circle" loading={statusLoading} icon={<DeleteOutlined />} onClick={(event) => showDeleteConfirm(event, record)} />
                                </Tooltip>
                                <Tooltip title="Is Active">
                                    <Switch
                                        defaultChecked={record.is_active}
                                        checked={record.Status}
                                        loading={statusLoading}
                                        onChange={(isChecked) => handleUpdateStatus(record.id, isChecked)}
                                    />
                                </Tooltip>
                            </Space>
                        )}
                    />
                </Table>
            </StyledTableWrapper>
        </>
    );
};
