import { CloseCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Card, Grid, } from 'antd';
import { Button, } from 'components';
import { AddAgentEventModal } from 'features/users/components/modals/add-event-modal';
import { ProfileImageUpload } from 'features/users/components/modals/profile_image_upload_modal';
import { UpdateAdminUserModal } from 'features/users/components/modals/update-admin-user-modal';
import { useDeleteAgentEvent, useGetAgentEventList, useGetUser } from 'features/users/use-cases';
import { useState } from 'react';
import { CompanyType } from 'types';

const { useBreakpoint } = Grid;

const initialValue = '/static/media/No_Image_Available.e1ce751ef1b590f719f9.jpg';


export const AgentDetail = ({
  id
}: { id: string }) => {
  useState<CompanyType>();

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [profileModal, setProfileModal] = useState<boolean>(false);
  // const [bankModal, setBankModal] = useState<boolean>(false);
  const [addEvent, setAddEvent] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<string | undefined>(undefined);

  const screens = useBreakpoint();
  // const formId = 'users-filters-form';
  const queryClient = useQueryClient();



  //const roles : Roles []= [];



  // Update User Modal

  const handleCloseModal = () => {
    setShowAddUserModal(false);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = () => {
    setShowAddUserModal(true);
    document.body.style.overflow = 'hidden';
  }

  // Profile Modal
  const handleOpenProfileModal = () => {
    setProfileModal(true);
    document.body.style.overflow = 'hidden';
  }

  const handleCloseProfileModal = () => {
    setProfileModal(false);
    document.body.style.overflow = 'visible';
  }

  //  Add Event Modal 
  const handleOpenAddEventModal = () => {
    setAddEvent(true);
    document.body.style.overflow = 'hidden';
  }

  const handleCloseEventModal = () => {
    setAddEvent(false);
    document.body.style.overflow = 'visible';
  }

  // Add - update bank detail modal /

  // const handleOpenBankModal = () => {
  //   setBankModal(true);
  //   document.body.style.overflow = 'hidden';
  // }

  // const handleCloseBankModal = () => {
  //   setBankModal(false);
  //   document.body.style.overflow = 'visible';
  // }

  const userQueryResult = useGetUser(id, {
    cacheTime: 0, //workaround to invalidate initialValues of client data form
  });

  const { mutate: deleteAgentEvent } = useDeleteAgentEvent();

  const useAgentData = useGetAgentEventList(id);
  const { data: agentEventList, refetch: eventRefetch } = useAgentData;

  const { data: userData } = userQueryResult;

  const handleEventList = (eventId: number) => {
    const payload = {
      agent_id: userData?.user_id,
      event_id: eventId
    };

    deleteAgentEvent(payload);
    queryClient.invalidateQueries(['agentEventList', id]);
  }

  return (
    <>
      <Card className='prfle_crd' title="My Profile" extra={
        <Button onClick={handleOpenModal}> Edit </Button>}  >

        <div className='profile_data prf_btn'>
          <div className='prf_img'>
            <div className='prf_img_bx'>
              <img src={profileImage === undefined ? ((userData?.profile_picture === "") ? initialValue : (userData?.profile_picture)) : profileImage} alt="" />
            </div>
            <Button icon={!screens.xs ? <UploadOutlined /> : undefined} className='btn tpmrng' onClick={handleOpenProfileModal}>
              {"Upload Profile Image"}
            </Button>

          </div>
          <div className='user_d'>
            <p>First Name : <span>{userData?.first_name}</span></p>
            <p>Last Name : <span>{userData?.last_name}</span></p>
            <p>Mobile No : <span>{userData?.phone_number}</span></p>
            <p>Email : <span>{userData?.email}</span></p>
            <p>Address : <span>{userData?.address}</span></p>
          </div>
        </div>
      </Card>

      {/* <Card className='prfle_crd'  title="Bank Detail" extra={
<Button onClick={handleOpenBankModal}> Edit </Button>}  >
              <div className='profile_data'>
              <div className='user_d'>
                  <p>Bank Name : <span>{userData?.first_name}</span></p>
                  <p>Account No : <span>{userData?.last_name}</span></p>
                  <p>Account Name : <span>{userData?.phone_number}</span></p>
                  <p>Iban Code : <span>{userData?.email}</span></p>
                  <p>Swift Code : <span>{userData?.address}</span></p>
              </div>
              </div>
  </Card> */}


      <Card title="Event List" className='role_card' extra={
        <Button onClick={handleOpenAddEventModal}><PlusOutlined /> Add Event </Button>} >


        <div className='role_list_agent'>
          {agentEventList && agentEventList.map((menu, index) => (

            <div className='agnt_nme' key={index}><CloseCircleOutlined onClick={() => handleEventList(menu.event_id)} /> {menu.event_name}</div>

          ))}
        </div>

      </Card>

      {/* Modal *  */}
      {showAddUserModal ? (
        <div className={`cstmrightmdl ${showAddUserModal && "show"}`}>
          <UpdateAdminUserModal id={id} onClose={handleCloseModal} onSuccess={handleCloseModal} />
        </div>
      ) : ''}

      <div className={`cstmrightmdl upld_modl ${profileModal && "show"}`}>
        <ProfileImageUpload id={id} onClose={handleCloseProfileModal} gallaryModal={profileModal} setProfileImage={setProfileImage} />
      </div>

      {/* <div className={`cstmrightmdl upld_modl ${bankModal && "show"}`}>
                <AddBankModal onClose={handleCloseBankModal}  />
</div> */}

      <div className={`cstmrightmdl upld_modl ${addEvent && "show"}`}>
        <AddAgentEventModal onClose={handleCloseEventModal} agentId={userData?.user_id} agentId_={id} eventRefetch={eventRefetch} />
      </div>
    </>
  );
};
