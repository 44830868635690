import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { axiosInstance } from 'api/axios-instance';
import { AxiosResponse } from 'axios';
import { CONFIG } from 'config';
import { Gender, User, Volunteer } from 'types';

type userMenu = {
  id: string;
  menu: string;
  isPermission: boolean;
}
export interface GetUserListResponse extends User {
  "Menu": userMenu[]
}
export interface UserListParams extends PaginatedRequestParams {
  full_user_name?: string;
  phone_number?: string;
  roles?: string[];
  company_id?: string;
  created_at_from?: string; // ISO8601_FORMAT
  created_at_to?: string; // ISO8601_FORMAT
}

export interface VolunteerListParams extends PaginatedRequestParams {
  Name?: string;
  PhoneNo?: string;
  Email?: string;
}

export type VolunteerUserDTO = {
  Name: string;
  EventId: string;
  Email: string;
  PhoneNo: string;
  Password: string;
  IsRefundAllowed: boolean;
  Status: boolean;
};


export type CreateUserDTO = {
  company_id?: string;
  email?: string | null;
  first_name: string;
  gender?: Gender | null;
  last_name: string;
  password: string;
  phone_number: string;
  role: string;
  is_active: boolean;
  address: string;

};
export type UpdateUserRoleDTO = {
  user_id: string | undefined;
  MenuIds: string[];
}

export type UpdateUserDTO = {
  company_id?: string;
  email?: string | null;
  first_name?: string;
  gender?: Gender | null;
  id: string;
  last_name?: string;
  password?: string;
  phone_number?: string;
  is_active?: boolean;
  role?: string;
  address?: string;
};

export type UserResponse = PaginatedResponse<User>;

export type EventList = {
  Id: string;
  Text: string;
  Value: string;
}

export type CraeteTitleCmsrDTO = {
  id?: string,
  title: string,
  is_active: boolean,
}

export type EventListResponse = PaginatedResponse<EventList>;

export type VolunteerResponse = PaginatedResponse<Volunteer>;

interface AgentEventList {
  agent_id: number,
  event_id: number;
  event_name: string;
}

interface ApiResponse<T> {
  data: T;
  status: number;
}


export const UserApiService = () => {
  const createUser = async (
    payload: CreateUserDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<User>({
      ...callConfig,
      endpoint: `/admin/users`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const createVolunteer = async (
    payload: VolunteerUserDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/externalapp/register`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  // const getUser = async (id: string, callConfig?: Partial<ApiClientConfig>) => {
  //   const response = await apiClient<GetUserListResponse>({
  //     ...callConfig,
  //     endpoint: `/admin/users/${id}`,
  //   });

  //   return response.data;
  // };

  const getUser = async (id: string, callConfig?: Partial<ApiClientConfig>): Promise<ApiResponse<User>> => {
    const response: AxiosResponse<User> = await apiClient<GetUserListResponse>({
      ...callConfig,
      endpoint: `/admin/users/${id}`,
    });

    return {
      data: response.data,
      status: response.status,
    };
  };

  const getUsers = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<UserResponse>({
      ...callConfig,
      endpoint: `/admin/users`,
      params,
    });

    return response.data;
  };

  const getVolunteerUsers = async (
    params: VolunteerListParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<VolunteerResponse>({
      ...callConfig,
      endpoint: `/volunteer/getvolunteer`,
      params,
    });

    return response.data;
  };

  const updateUser = async (
    payload: UpdateUserDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<User>({
      ...callConfig,
      endpoint: `/admin/users/${id}`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };

  const getVendingEvents = async (Flag: string) => {
    const response = await apiClient<EventListResponse>({
      endpoint: `admin/dropdownlist?${Flag}`
    })

    return response.data
  }

  // const getRole = async (
  //   id: string,
  //   callConfig?: Partial<ApiClientConfig>,
  // ) => {
  //   const response = await apiClient<User>({
  //     ...callConfig,
  //     endpoint: `/admin/rolelist`,
  //   });

  //   return response.data;
  // };

  // const replaceSubstring = (
  //   input: string,
  //   target: string,
  //   replacement: string,
  // ): string => {
  //   const startIndex = input.indexOf(target);
  //   const endIndex = startIndex + target.length;
  //   const replacedStr =
  //     input.slice(0, startIndex) + replacement + input.slice(endIndex);

  //   return replacedStr;
  // };

  // const getRole = async (callConfig?: Partial<ApiClientConfig>) => {
  //   const response = await apiClient<User>({
  //     ...callConfig,
  //     endpoint: `/identity/api/v1/admin/rolelist`,
  //   });

  //   return response.data;
  // };

  // const removeDuplicateApiV1 = (endpoint: string) => {
  //   return endpoint.replace('https://suffixapi.d.li3ib.com/identity/api/v1/admin/rolelist', '');
  // };

  const getRole = async () => {
    const response = await apiClient({
      endpoint: "https://suffixapi.d.li3ib.com/identity/api/v1/admin/rolelist"
    });

    return response.data;
  };

  const updateUserRole = async (
    payload: UpdateUserRoleDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { ...data } = payload;

    const response = await apiClient({
      ...callConfig,
      endpoint: `/admin/users/updatemenupermissions`,
      method: 'POST',
      data,
    });

    return response.data;
  };

  const uploadUserProfileImage = async (formData: FormData) => {
    const response = await axiosInstance.post(
      `${CONFIG.SUFFIX_API_BASE_URL}/v1/users/profile_picture`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const updateStatus = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/admin/events/updatestatus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const updateUserStatus = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/admin/users/updateuserstatus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const saveEventWithAgent = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/admin/users/mapagentevent`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const agentEventList = async (agent_id: string) => {

    const response = await apiClient<Promise<AgentEventList[]>>({
      endpoint: `/admin/users/getagentevents/${agent_id}`,
      method: 'GET'
    });

    return response.data;
  };

  const deleteAgentEventList = async (payload: any) => {

    const response = await apiClient<Promise<AgentEventList[]>>({
      endpoint: `/admin/users/deleteeventagentmapping/${payload.agent_id}/${payload.event_id}`,
      method: 'DELETE'
    });

    return response.data;

  };

  const getLogsUsers = async (
    params: VolunteerListParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<VolunteerResponse>({
      ...callConfig,
      endpoint: `/admin/userlogs`,
      params,
    });

    return response.data;
  };

  return {
    createUser,
    getUser,
    getUsers,
    updateUser,
    getRole,
    getVolunteerUsers,
    getVendingEvents,
    createVolunteer,
    updateUserRole,
    uploadUserProfileImage,
    updateStatus,
    updateUserStatus,
    saveEventWithAgent,
    agentEventList,
    deleteAgentEventList,
    getLogsUsers
  };
};
