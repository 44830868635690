import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { LogsUsersTopBar } from '../components/top-bar/logs-users-top-bar';
import { LogsUsersTable } from '../components/users/logs-users-table';
import { LogsUserFilterFormValues, LogsUserFilterFormValuesPaginated } from '../components/users-filter-form/logs-types';
import { useGetLogsUsers } from '../use-cases/get-user-logs';

const getInitialFilterValues: LogsUserFilterFormValues = {
    full_name: undefined,
    phone: undefined,
    email: undefined,
};

export const UserLogsView = () => {

    const { params, updateParams, resetPage } =
        usePaginationParams<LogsUserFilterFormValuesPaginated>({
            page: 1,
            size: 10,
            order_by: 'name',
            ...getInitialFilterValues,
        });

    const { data, refetch, isFetching } = useGetLogsUsers((params), {
        retry: false,
    });


    const handleFiltersChange = (values: LogsUserFilterFormValuesPaginated) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    return (
        <>
            <LogsUsersTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
            />
            <LogsUsersTable
                isLoading={isFetching}
                data={data}
                tableParams={params}
                updateParams={updateParams}

            />
            <Outlet />
        </>
    );
};
