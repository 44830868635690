
const BoothHtmlBy752Id = () => {
  return (
    <>

      <g id="Group 1171275072">
        <rect id="WhatsApp-Image-2024-08-05-at-7.07 1" width="1135" height="1599"
          fill="url(#pattern0_109_12318)" />
        <g id="Frame 1171276158">
          <g id="booth_47" className="booth">
            <path id="Vector_2" d="M661 473H619V515H661V473Z" />
            <text id="47" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="628" y="499.727">47 </tspan>
            </text>
          </g>
          <g id="booth_48" className="booth">
            <path id="Vector_3" d="M705 473H662V515H705V473Z" />
            <text id="48" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="671.5" y="499.727">48 </tspan>
            </text>
          </g>
          <g id="booth_45" className="booth">
            <path id="Vector_4" d="M661 516H619V559H661V516Z" />
            <text id="49" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="627.5" y="543.227">45</tspan>
            </text>
          </g>
          <g id="booth_46" className="booth">
            <path id="Vector_5" d="M705 516H662V559H705V516Z" />
            <text id="50" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="671.5" y="543.227">46 </tspan>
            </text>
          </g>
        </g>

        <g id="Frame 1171276161">
          <g id="booth_36" className="booth">
            <path id="Vector_10_2" d="M814 631H772V673H814V631Z" />
            <text id="47_3_2" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="781" y="657.727">36</tspan>
            </text>
          </g>
          <g id="booth_35" className="booth">
            <path id="Vector_11_2" d="M858 631H815V673H858V631Z" />
            <text id="48_3_2" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="824.5" y="657.727">35</tspan>
            </text>
          </g>
          <g id="booth_33" className="booth">
            <path id="Vector_12_2" d="M814 674H772V717H814V674Z" />
            <text id="49_3_2" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="780.5" y="701.227">33 </tspan>
            </text>
          </g>
          <g id="booth_34" className="booth">
            <path id="Vector_13_2" d="M858 674H815V717H858V674Z" />
            <text id="50_3_2" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="824.5" y="701.227">34 </tspan>
            </text>
          </g>
        </g>
        <g id="Frame 1171276162">
          <g id="booth_32" className="booth">
            <path id="Vector_10_3" d="M816 785H774V827H816V785Z" />
            <text id="47_3_3" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="783" y="811.727">32</tspan>
            </text>
          </g>
          <g id="booth_31" className="booth">
            <path id="Vector_11_3" d="M860 785H817V827H860V785Z" />
            <text id="48_3_3" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="826.5" y="811.727">31</tspan>
            </text>
          </g>
          <g id="booth_29" className="booth">
            <path id="Vector_12_3" d="M816 828H774V871H816V828Z" />
            <text id="49_3_3" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="782.5" y="855.227">29 </tspan>
            </text>
          </g>
          <g id="booth_30" className="booth">
            <path id="Vector_13_3" d="M860 828H817V871H860V828Z" />
            <text id="50_3_3" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="826.5" y="855.227">30</tspan>
            </text>
          </g>
        </g>
        <g id="Frame 1171276163">
          <g id="booth_28" className="booth">
            <path id="Vector_10_4" d="M660 785H618V827H660V785Z" />
            <text id="47_3_4" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="627" y="811.727">28</tspan>
            </text>
          </g>
          <g id="booth_27" className="booth">
            <path id="Vector_11_4" d="M704 785H661V827H704V785Z" />
            <text id="48_3_4" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="670.5" y="811.727">27</tspan>
            </text>
          </g>
          <g id="booth_25" className="booth">
            <path id="Vector_12_4" d="M660 828H618V871H660V828Z" />
            <text id="49_3_4" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="626.5" y="855.227">25</tspan>
            </text>
          </g>
          <g id="booth_26" className="booth">
            <path id="Vector_13_4" d="M704 828H661V871H704V828Z" />
            <text id="50_3_4" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="670.5" y="855.227">26 </tspan>
            </text>
          </g>
        </g>
        <g id="Frame 1171276164">
          <g id="booth_12" className="booth">
            <path id="Vector_10_5" d="M661 940H619V982H661V940Z" />
            <text id="47_3_5" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="629" y="967.409">12</tspan>
            </text>
          </g>
          <g id="booth_11" className="booth">
            <path id="Vector_11_5" d="M705 940H662V982H705V940Z" />
            <text id="48_3_5" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="674" y="967.409">11</tspan>
            </text>
          </g>
          <g id="booth_09" className="booth">
            <path id="Vector_12_5" d="M661 983H619V1026H661V983Z" />
            <text id="49_3_5" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="634" y="1011.41">9 </tspan>
            </text>
          </g>
          <g id="booth_10" className="booth">
            <path id="Vector_13_5" d="M705 983H662V1026H705V983Z" />
            <text id="50_3_5" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="671.5" y="1010.23">10 </tspan>
            </text>
          </g>
        </g>
        <g id="Frame 1171276165">
          <g id="booth_16" className="booth">
            <path id="Vector_10_6" d="M816 940H774V982H816V940Z" />
            <text id="47_3_6" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="783" y="966.727">16</tspan>
            </text>
          </g>
          <g id="booth_15" className="booth">
            <path id="Vector_11_6" d="M860 940H817V982H860V940Z" />
            <text id="48_3_6" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="826.5" y="966.727">15</tspan>
            </text>
          </g>
          <g id="booth_13" className="booth">
            <path id="Vector_12_6" d="M816 983H774V1026H816V983Z" />
            <text id="49_3_6" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="782.5" y="1010.23">13 </tspan>
            </text>
          </g>
          <g id="booth_14" className="booth">
            <path id="Vector_13_6" d="M860 983H817V1026H860V983Z" />
            <text id="50_3_6" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="826.5" y="1010.23">14 </tspan>
            </text>
          </g>
        </g>
        <g id="Frame 1171276166">
          <g id="booth_24" className="booth">
            <path id="Vector_10_7" d="M523 785H481V827H523V785Z" />
            <text id="47_3_7" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="490" y="811.727">24</tspan>
            </text>
          </g>
          <g id="booth_23" className="booth">
            <path id="Vector_11_7" d="M567 785H524V827H567V785Z" />
            <text id="48_3_7" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="533.5" y="811.727">23</tspan>
            </text>
          </g>
          <g id="booth_21" className="booth">
            <path id="Vector_12_7" d="M523 828H481V871H523V828Z" />
            <text id="49_3_7" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="489.5" y="855.227">21 </tspan>
            </text>
          </g>
          <g id="booth_22" className="booth">
            <path id="Vector_13_7" d="M567 828H524V871H567V828Z" />
            <text id="50_3_7" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="533.5" y="855.227">22 </tspan>
            </text>
          </g>
        </g>
        <g id="Frame 1171276167">
          <g id="booth_20" className="booth">
            <path id="Vector_10_8" d="M367 784H325V826H367V784Z" />
            <text id="47_3_8" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="334" y="810.727">20</tspan>
            </text>
          </g>
          <g id="booth_19" className="booth">
            <path id="Vector_11_8" d="M411 784H368V826H411V784Z" />
            <text id="48_3_8" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="377.5" y="810.727">19</tspan>
            </text>
          </g>
          <g id="booth_17" className="booth">
            <path id="Vector_12_8" d="M367 827H325V870H367V827Z" />
            <text id="49_3_8" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="333.5" y="854.227">17 </tspan>
            </text>
          </g>
          <g id="booth_18" className="booth">
            <path id="Vector_13_8" d="M411 827H368V870H411V827Z" />
            <text id="50_3_8" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="377.5" y="854.227">18 </tspan>
            </text>
          </g>
        </g>
        <g id="Frame 1171276168">
          <g id="booth_04" className="booth">
            <path id="Vector_10_9" d="M366 941H324V983H366V941Z" />
            <text id="47_3_9" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="338" y="968.409">4</tspan>
            </text>
          </g>
          <g id="booth_03" className="booth">
            <path id="Vector_11_9" d="M410 941H367V983H410V941Z" />
            <text id="48_3_9" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="382" y="968.409">3</tspan>
            </text>
          </g>
          <g id="booth_02" className="booth">
            <path id="Vector_12_9" d="M366 984H324V1027H366V984Z" />
            <text id="49_3_9" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="339" y="1012.41">2 </tspan>
            </text>
          </g>
          <g id="booth_01" className="booth">
            <path id="Vector_13_9" d="M410 984H367V1027H410V984Z" />
            <text id="50_3_9" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="384" y="1012.41">1 </tspan>
            </text>
          </g>
        </g>
        <g id="Frame 1171276169">
          <g id="booth_08" className="booth">
            <path id="Vector_10_10" d="M523 940H481V982H523V940Z" />
            <text id="47_3_10" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="496" y="967.409">8</tspan>
            </text>
          </g>
          <g id="booth_07" className="booth">
            <path id="Vector_11_10" d="M567 940H524V982H567V940Z" />
            <text id="48_3_10" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="540" y="967.409">7</tspan>
            </text>
          </g>
          <g id="booth_05" className="booth">
            <path id="Vector_12_10" d="M523 983H481V1026H523V983Z" />
            <text id="49_3_10" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="496" y="1011.41">5</tspan>
            </text>
          </g>
          <g id="booth_06" className="booth">
            <path id="Vector_13_10" d="M567 983H524V1026H567V983Z" />
            <text id="50_3_10" fill="white" fontFamily="Inter" fontSize="19" letterSpacing="0em">
              <tspan x="539" y="1011.41">6</tspan>
            </text>
          </g>
        </g>
      </g>
    </>
  )
}

export default BoothHtmlBy752Id