import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { EventsApiService } from 'features/events';
import { queryClient } from 'providers';

import { eventsQueryKeys } from './query-keys';

const { createBibAllocation } = EventsApiService();

export const useCreateBibAllocation = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: any) => {
            return createBibAllocation(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
                // message.success("Bib Allocation has been updated successfully!!");
            },
        },
    );
};
