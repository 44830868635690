import {
    UserOutlined,
} from '@ant-design/icons';
import { Form, Input } from 'antd';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useTranslation } from 'react-i18next';

import { OrganizerFormField } from './types';


export const AddOrganizerForm = () => {
    const { t } = useTranslation();

    return (

        <FormSectionsSeparator title={""}>
            <Form.Item<OrganizerFormField>
                label={"Name"}
                name={OrganizerFormField.name}

                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Input
                    placeholder={"Organizer Name"}
                    prefix={<UserOutlined className='input-prefix-icon' />}
                />
            </Form.Item>
            <Form.Item<OrganizerFormField>
                label={"Link"}
                name={OrganizerFormField.link}
            // rules={[
            //     {
            //         required: true,
            //         whitespace: true,
            //         message: t('validateMessages.custom.nomsg'),
            //     },
            // ]}
            >
                <Input
                    placeholder={"Link"}
                    prefix={<UserOutlined className='input-prefix-icon' />}
                />
            </Form.Item>


            {/* <Form.Item<OrganizerFormField>
                label={"Photo"}
                name={OrganizerFormField.image_url}
            // rules={[
            //     {
            //         required: true,
            //         whitespace: true,
            //     },
            // ]}
            >
                <Upload>
                    <Input
                        placeholder={"Photo"}
                        prefix={<UserOutlined className='input-prefix-icon' />}
                    />
                </Upload>
            </Form.Item> */}

            <Form.Item<OrganizerFormField>
                label={"Contact No"}
                name={OrganizerFormField.contact_no}
            // rules={[
            //     {
            //         required: true,
            //         whitespace: true,
            //         message: t('validateMessages.custom.nomsg'),
            //     },
            // ]}
            >
                <Input
                    placeholder={"Contact No"}
                    prefix={<UserOutlined className='input-prefix-icon' />}
                />
            </Form.Item>

            <Form.Item<OrganizerFormField>
                label={"Email"}
                name={OrganizerFormField.email}
            // rules={[
            //     {
            //         required: true,
            //         whitespace: true,
            //         message: t('validateMessages.custom.nomsg'),
            //     },
            // ]}
            >
                <Input
                    placeholder={"Email"}
                    prefix={<UserOutlined className='input-prefix-icon' />}
                />
            </Form.Item>

            <Form.Item<OrganizerFormField>
                label={"Address"}
                name={OrganizerFormField.address}
            // rules={[
            //     {
            //         required: true,
            //         whitespace: true,
            //         message: t('validateMessages.custom.nomsg'),
            //     },
            // ]}
            >
                <Input
                    placeholder={"Address"}
                    prefix={<UserOutlined className='input-prefix-icon' />}
                />
            </Form.Item>

        </FormSectionsSeparator>
    );
};
