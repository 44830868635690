import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { EventResultDTO, EventsApiService } from 'features/events';
import { queryClient } from 'providers';

import { eventsQueryKeys } from './query-keys';


const { createEventResult } = EventsApiService();

export const useCreateEventParticipant = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: EventResultDTO) => {
            return createEventResult(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
                message.success("Participant Url has been created successfully!!");
            },
        },
    );
};
