import { Button, Form, Input, message, Select } from 'antd';
import { handleKeyAmount } from 'features/events';
import { ShopApiService } from 'features/shop-item/api/shop-api.service';
import { useUpdateDelivery } from 'features/shop-item/use-cases/update-delivery';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';

import { deliveryFormField, deliveryFormValues } from './type';
export const EditDeliveryModal = ({ onClose, showCategorysModal, deliveryEditData }: { onClose: () => void; showCategorysModal: boolean, refetch: () => void, deliveryEditData: any }) => {
    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<deliveryFormValues>();
    const { isLoading, mutate } = useUpdateDelivery();
    const [country, setCountry] = useState({
        items: []
    })
    const [area, setarea] = useState({
        items: []
    })

    const getInitialValues = {
        [deliveryFormField.Id]: deliveryEditData?.id,
        [deliveryFormField.country_id]: undefined,
        [deliveryFormField.area_id]: undefined,
        [deliveryFormField.delivery_charges]: undefined,
        [deliveryFormField.expr_delivery_charges]: undefined,

    };
    const handleUpdate = (formData: any) => {
        const data: any = {
            id: deliveryEditData?.id,
            country_id: formData?.country_id.value || formData.country_id,
            area_id: formData?.area_id.value || formData.area_id,
            delivery_charges: +formData?.delivery_charges,
            expr_delivery_charges: +formData?.expr_delivery_charges
        }


        mutate(data as any, { onSuccess: onClose });
    };
    const initialvalue = async () => {
        try {
            const apiRes = await ShopApiService().getDropdownList('AddressCountry')
            setCountry(apiRes as any)
        } catch (error) {
            message.error(error as any)
        }
    }

    const initialArea = async (value: any) => {
        try {
            const apiRes = await ShopApiService().getDropdownList(`AddressCity&cascadeId=${value}`)
            setarea(apiRes as any)
        } catch (error) {
            message.error(error as any)
        }
    }

    // const hanldeSuccess = () => {
    //     onClose();
    //     refetch();
    // }

    useEffect(() => {
        if (deliveryEditData) {
            initialArea(deliveryEditData?.country_id);
            form.setFieldsValue(deliveryEditData);
        } else {
            form.resetFields();
        }
    }, [deliveryEditData])

    useEffect(() => {
        if (showCategorysModal) {
            initialvalue()
        }
    }, [showCategorysModal])

    return (

        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={onClose} className='close-btn'>
                    &times;
                </button>
                <h4>Edit Delivery</h4>
            </div>
            <div className='modal_body'>
                <Form<deliveryFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <FormSectionsSeparator title={""}>
                        <Form.Item<deliveryFormField>
                            label={"Country"}
                            name={deliveryFormField.country_id}
                            className='w-100'

                        >
                            <Select
                                labelInValue
                                placeholder="Please Select Country"
                                style={{ width: '100%' }}
                                options={country.items.map((res: any) => ({ label: res.Text, value: res.Id }))}
                                onChange={(value: any) => initialArea(value.value)}
                                allowClear
                                disabled={deliveryEditData?.id}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"Area"}
                            name={deliveryFormField.area_id}
                            className='w-100'

                        >
                            <Select
                                labelInValue
                                placeholder="Please Select Area Name"
                                style={{ width: '100%' }}
                                options={area.items.map((res: any) => ({ label: res.Text, value: res.Id }))}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                showSearch
                                allowClear
                                disabled={deliveryEditData?.id}
                            />
                        </Form.Item>
                        <Form.Item<deliveryFormField>
                            label={"Standard Delivery"}
                            name={deliveryFormField.delivery_charges}
                            className='w-100'

                        >
                            <Input
                                onKeyDown={handleKeyAmount}
                            />
                        </Form.Item>
                        <Form.Item<deliveryFormField>
                            label={"Express Delivery"}
                            name={deliveryFormField.expr_delivery_charges}
                            className='w-100'

                        >
                            <Input
                                onKeyDown={handleKeyAmount}
                            />
                        </Form.Item>
                    </FormSectionsSeparator>
                </Form>
                <div className='button-group'>
                    <Button htmlType='submit' loading={isLoading} onClick={form.submit} className='btn'>Save</Button>
                </div>

            </div>
        </div>

    )
};

