import { App, Button, Empty, Grid, Skeleton, Space, Table, TablePaginationConfig, Tooltip } from 'antd';
import { Column } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
    EventsFilterFormPaginated,
} from 'features/events';
import { useCreateBibRequest } from 'features/events/use-cases/bib-request-send';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';

import { Organizer } from '../../event-organizer/types';
import { AddBibModal } from './add-bib-allocation-modal';

const { useBreakpoint } = Grid;

type OrganizerTableProps = {
    isLoading: boolean;
    data?: any;
    tableParams: EventsFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<EventsFilterFormPaginated>>;
    organizerData: any;
    setOrganizerData: any;
    refetch: () => void;
};

export const BibAllocationTable = ({
    data,
    isLoading,
    tableParams,
    updateParams,
    organizerData,
    setOrganizerData,
    refetch
}: OrganizerTableProps) => {

    const screens = useBreakpoint();
    const { message } = App.useApp();
    const permissions = usePermissions();

    const { mutate } = useCreateBibRequest();

    const handleStartBib = (record: any) => {
        if (record.bib_allocation === 2) {
            const formData = {
                "ticket_category_id": record.Id,
                "start_no": record.i_start_number,
                "end_no": record.i_end_number
            }

            mutate(formData);


        } else {
            message.warning("Bib Allocation allowed only automatic mode!")
        }

    };

    // const { mutate } = useEventDeleteStatus();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // filters: Record<string, FilterValue | null>,
        // sorter: SorterResult<Organizer> | SorterResult<Organizer>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: "",
        });
    };

    const tableWidth = screens.xxl ? { x: 1000 } : { x: 1000 };

    const [showAddEventModal, setShowAddEventModal] = useState<boolean>(false);

    if (!permissions) return null;

    const handleCloseModal = () => {
        setShowAddEventModal(false);
        setOrganizerData(undefined);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = (record: any) => {
        setShowAddEventModal(true);
        setOrganizerData(record);
        document.body.style.overflow = 'hidden';
    }







    return (
        <>
            <div className={`cstmrightmdl ${showAddEventModal && "show"}`}>
                <AddBibModal showAddEventModal={showAddEventModal} onClose={handleCloseModal} organizerData={organizerData} refetch={refetch} />
            </div>


            <StyledTableWrapper>
                <StyledTableHeader>
                    {/* <RecordCountBadge
                        text={"Bib Allocation found"}
                        count={data?.total || 0}
                    /> */}
                </StyledTableHeader>
                <Table<Organizer>
                    className="cstltblwthldr"
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading && { indicator: <div><TableLoader /></div> }}
                    dataSource={isLoading ? [] : data?.items}
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.page,
                        defaultPageSize: tableParams.size,
                        total: data?.total,
                    }}
                    scroll={tableWidth}

                >

                    <Column<Organizer>
                        title={'Category'}
                        dataIndex={'v_category'}
                        sorter={true}
                        sortDirections={['ascend', 'descend', 'ascend']}
                        defaultSortOrder={'descend'}
                        width={screens.xxl ? 100 : 162}
                        ellipsis

                    />
                    <Column<Organizer>
                        title={'Start No'}
                        dataIndex={'i_start_number'}
                        width={screens.xxl ? 80 : 162}
                        ellipsis
                    />
                    <Column<Organizer>
                        title={'End No'}
                        dataIndex={'i_end_number'}
                        width={screens.xxl ? 80 : 162}
                        className='cell-direction-rtl'
                    />


                    <Column<Organizer>
                        title={'Last Bib No'}
                        dataIndex={'last_bib_no'}
                        width={screens.xxl ? 80 : 162}
                    />

                    <Column<Organizer>
                        title={"Action"}
                        width={screens.xxl ? 250 : 270}
                        dataIndex={'is_active'}
                        render={(_, record) => (
                            <>
                                <Space>

                                    <Tooltip title="">
                                        <Button type='primary' onClick={() => handleOpenModal(record)} >Add range </Button>
                                    </Tooltip>

                                    <Tooltip title="">
                                        <Button className='brder_btn' onClick={() => handleStartBib(record)} >Start Bib allocation </Button>
                                    </Tooltip>
                                </Space>
                            </>
                        )}
                    />

                </Table>
            </StyledTableWrapper>
        </>
    );
};

