import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { UpdateItemStatusModal } from "../component/update-item-status-modal/update-item-status-modal";
import { useGetItemSalesDetails } from "../use-cases/get-item-sales-details";
import { formateAmount } from "../utills/utiils";


const ItemSalesDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const params = {
        order_no: id
    }

    const { data, refetch } = useGetItemSalesDetails(params as any, { retry: false });

    const [isOpen, setIsOpen] = useState(false);


    if (!id) return null;




    const handleCloseModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    }

    return (
        <>
            <div className="itm_sle_wrap">
                {/* <h1>Items sales Details {id}</h1> */}

                <div className="itm_sle_dtls">
                    <div className="sle_dtls_head">
                        <div className="arr_i">
                            <span><LeftOutlined onClick={() => navigate(-1)} /></span>
                            <h4>Details</h4>
                        </div>

                    </div>
                    <div className="cstmer_dtls">

                        <div className="cstmer_lst">
                            <span>Invoice Id :</span>
                            <p>{data?.invoice_no} </p>
                        </div>
                        <div className="cstmer_lst">
                            <span>Order Id :</span>
                            <p>{data?.booking_id}</p>
                        </div>
                        <div className="cstmer_lst">
                            <span>Customer name :</span>
                            <p>{data?.first_name} {data?.last_name}</p>
                        </div>
                        <div className="cstmer_lst">
                            <span>DeliveryType :</span>
                            <p>{data?.delivery_type}</p>
                        </div>
                        <div className="cstmer_lst">
                            <span>Order Date :</span>
                            <p>{data?.order_date}</p>
                        </div>
                        <div className="cstmer_lst">
                            <span>Email : </span>
                            <p>{data?.email_address}</p>
                        </div>
                        <div className="cstmer_lst">
                            <span>Delivery Status </span>
                            <p>{data?.order_status}</p>
                        </div>
                        <div className="cstmer_lst">
                            <span>Payment Type : </span>
                            <p>{data?.payment_method}</p>
                        </div>
                        <div className="cstmer_lst">
                            <span>Mobile No :</span>
                            <p>{data?.phone_number}</p>
                        </div>
                        <div className="cstmer_lst">
                            <span>Payment Status</span>
                            <p>{data?.booking_status}</p>
                        </div>
                        <div className="cstmer_lst addrs_f">
                            <span>Delivery Address: </span>
                            <p>{data?.delivery_address}</p>
                        </div>

                    </div>
                </div>

                <div className="itm_sle_dtls">
                    <div className="sle_dtls_head">

                        <h4>Shop Item</h4>
                    </div>
                    <div className="item_lst_wrap">
                        {data?.item_sales_details.length > 0 &&
                            data?.item_sales_details.map((item: any, i: number) => (
                                <div className="item_lst" key={i}>
                                    <div className="item_img">
                                        <img src={item?.image_url} alt="" />
                                    </div>
                                    <div className="item_dtls">
                                        <span>Item name : {item?.item_name}</span>
                                        <span>Sku : {item?.item_id}</span>
                                        <span>Qty : {item?.quantity}</span>
                                        <span className="clr_bx">Color :  <span
                                            style={{
                                                // display: 'inline-block',
                                                width: '20px',
                                                height: '20px',
                                                backgroundColor: item?.color_code,
                                                // marginLeft: '8px',
                                                border: '1px solid #000', // Optional: adds a border to the color box
                                            }}
                                        ></span></span>
                                        <span>Price : {item?.base_price && formateAmount(item.base_price)}</span>
                                        <span>Size : {item?.size_name}</span>
                                    </div>
                                </div>
                            ))
                        }

                        <div className="ttl_pr">

                            <p>Total Price: {data?.total_price && formateAmount(data.total_price)}</p>
                            <p>Total Discount Amount: {data?.total_coupon_price && formateAmount(data.total_coupon_price)}</p>
                            <p>Total Delivery Amount: {data?.total_delivery_charges && formateAmount(data.total_delivery_charges)}</p>
                            <div className="gr_ttl">
                                <p>Grand Total: {data?.grand_total && formateAmount(data.grand_total)}</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="itm_sle_dtls">
                    <div className="sle_dtls_head">

                        <h4> Order Details</h4>
                        <Button onClick={handleOpenModal}>Add Action</Button>
                    </div>

                    <div className="order_dtls">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Delivery Status</th>
                                    <th>Description</th>
                                    <th>DateTime</th>
                                </tr>
                            </thead>
                            {data?.item_list.length > 0 &&
                                data?.item_list.map((item: any, index: number) => (
                                    <tbody key={index}>
                                        <tr >
                                            <td>{item?.order_status}</td>
                                            <td>{item?.comment}</td>
                                            <td>{item?.updated_on}</td>
                                        </tr>
                                    </tbody>
                                ))}
                        </table>
                    </div>
                </div>

            </div>


            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <UpdateItemStatusModal onClose={handleCloseModal} isOpen={isOpen} refetch={refetch} id={id} />
            </div>
        </>
    )
}

export default ItemSalesDetails
