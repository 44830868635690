import { Typography } from "antd"
import { StyledHeadingWrapper, StyledTitleWrapper } from "components/content-top-bar/top-content-bar.styles"
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// import { StyledWrapper } from "../time-price-input/time-price-styles"

const EventDetailsTopbar = ({ name }: { name: string }) => {

    const [eventName] = useState<string>(name);

    const navigate = useNavigate();
    const handleBreacum = () => {
        navigate("/events")
    }

    return (
        <div className='cstm_head'>
            <StyledHeadingWrapper className='hed_ttl'>
                <StyledTitleWrapper className='hht_data'>
                    <Typography.Title className='title'>
                        <span className="btn" onClick={handleBreacum}>Event List</span>
                        <span>/</span>
                        <span>{eventName}</span>
                    </Typography.Title>
                </StyledTitleWrapper>
            </StyledHeadingWrapper>
        </div>

    )
}

export default EventDetailsTopbar;

