import "./module.css";

import { Button } from "antd";
import { useCreateEventDiscription } from "features/events/use-cases/update-event-discription";
import ReactQuill from "react-quill";

interface props {
    setCode: any,
    code: string,
    showModal: boolean,
    onClose: () => void,
    id: string,
    fetchEventDiscriptionData: () => void;
    setArebicDescr: any,
    arebicDescr: string,
}


const BasicInfoTextEditor = ({ setCode, code, onClose, id, fetchEventDiscriptionData, arebicDescr, setArebicDescr }: props) => {
    const { isLoading, mutate } = useCreateEventDiscription();

    const handleProcedureContentChange = (
        content: React.SetStateAction<string>,
        // delta: any,
        // source: any,
        // editor: any
    ) => {
        setCode(content);
    };

    const formData = {
        event_id: id,
        event_description: code,
        event_description_ar: arebicDescr
    }


    const handleCraeteDiscription = () => {
        mutate(formData, {
            onSuccess() {
                fetchEventDiscriptionData();
                onClose();
            },
        });
    }

    const handleArebicContentChange = (
        content: React.SetStateAction<string>,
        // delta: any,
        // source: any,
        // editor: any
    ) => {
        setArebicDescr(content);
    };

    return (
        <>
            <div className='popup'>
                <div className='modal_head'>
                    <h4>{"Edit Description"}</h4>
                    <button onClick={onClose} className='close-btn'>
                        &times;
                    </button>

                </div>
                <div className='modal_body'>
                    <div className="frmscrll">
                        <div className="des_editr">
                            <h2>English Description</h2>
                            <ReactQuill
                                className="editor-large"
                                theme="snow"
                                value={code || ''}
                                onChange={handleProcedureContentChange}
                                modules={BasicInfoTextEditor.modules}
                                formats={BasicInfoTextEditor.formats}
                            // dangerouslySetInnerHTML={{ __html: code }}
                            // readOnly={true}
                            />
                        </div>
                        <div className="des_editr">

                            <h2>Arabic Description</h2>
                            <ReactQuill
                                className="editor-large"
                                theme="snow"
                                value={arebicDescr || ''}
                                onChange={handleArebicContentChange}
                                modules={BasicInfoTextEditor.modules}
                                formats={BasicInfoTextEditor.formats}
                            // dangerouslySetInnerHTML={{ __html: code }}
                            // readOnly={true}
                            />

                        </div>
                    </div>



                    <div className='form-button'>
                        <Button loading={isLoading} onClick={handleCraeteDiscription} className='btn' type="primary">Save</Button>
                    </div>

                </div>
            </div>
        </>
    );
};

BasicInfoTextEditor.modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, false] }], // Select dropdown for heading
        [{ 'font': [] }], // Select dropdown for font size
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'align': [] }], // Select dropdown for text alignment
        [{ 'color': [] }, { 'background': [] }], // Select dropdowns for text and background color
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link'],
        ['clean']
    ],
    clipboard: {
        matchVisual: true,
    }
};

BasicInfoTextEditor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'align', 'color', 'background'
];

export default BasicInfoTextEditor;