import {
    QueryFunction,
    QueryKey,
    useQuery,
    UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { eventsQueryKeys } from 'features/events';

import { ShopApiService } from '../api/shop-api.service';




const { getItemShopImage } = ShopApiService();

const getEventImageQuery = (
    params: any,
    options?: UseQueryOptions<any>,
): {
    queryKey: QueryKey;
    queryFn: QueryFunction<any>;
    options?: UseQueryOptions<any>;
} => ({
    queryKey: eventsQueryKeys.list(params),
    queryFn: ({ signal }) => getItemShopImage(params, { config: { signal } }),
    ...options,
});

export const useGetEventImage = (
    params: any,
    options?: UseQueryOptions<any>,
) => {
    const { message } = App.useApp();

    return useQuery<any, Error>(
        getEventImageQuery(params, {
            onError: (error: unknown) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            ...options,
        }),
    );
};



