import { Button, Form, Input } from 'antd';
import { useCreateRacekit } from 'features/cms/use-cases/create-racekit';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { RacekitFormField, RacekitFormValues } from './types';


export const AddRacekitmodal = ({ onClose, racekitEditData }: { onClose: () => void; showAddRacekitModal: boolean, refetch: () => void, racekitEditData: any }) => {
    const { id } = useParams();
    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<RacekitFormValues>();
    const { t } = useTranslation();
    const { isLoading, mutate } = useCreateRacekit();

    const getInitialValues = {
        [RacekitFormField.Id]: id,
        [RacekitFormField.Title]: undefined,
        [RacekitFormField.File]: undefined,
        [RacekitFormField.IsActive]: undefined,
        [RacekitFormField.ImageUrl]: undefined,
    };
    const [fileData, setFileData] = useState<File | any>(null);

    const [uploadImages, setUploadImages] = useState<{ file: File, dataURL: string }[]>([]);

    const [error, setError] = useState<any[]>([]);

    const handleUpdate = (formData: RacekitFormValues) => {
        formData[RacekitFormField.Id] = racekitEditData?.id;
        formData[RacekitFormField.File] = uploadImages[0]?.file;
        formData[RacekitFormField.ImageUrl] = "";

        mutate(formData, { onSuccess: hanldeSuccess });
    };

    const handleClose = () => {
        onClose();
        setFileData(null);
        setUploadImages([]);
    }

    const hanldeSuccess = () => {
        handleClose();
        // refetch();
    }

    useEffect(() => {
        if (racekitEditData) {
            form.setFieldsValue(racekitEditData);
        } else {
            form.resetFields();
        }
    }, [racekitEditData])


    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const selectedFiles = e.target.files;
        const defaultWidth = 600;
        const defaultHeight = 545;
        // Check if files are selected
        if (selectedFiles && selectedFiles.length > 0) {
            const newImages: { file: File, dataURL: string }[] = [];
            const errors: string[] = [];

            Array.from(selectedFiles).forEach((file) => {
                // Check if file is an image
                if (file.type.startsWith('image/')) {
                    const reader = new FileReader();

                    reader.readAsDataURL(file);

                    reader.onload = (event) => {
                        const img = new Image();
                        img.src = event?.target?.result as string;

                        img.onload = () => {
                            const { width, height } = img;
                            if (width < defaultWidth || height < defaultHeight) {
                                errors.push(`${file.name} - image is not recommended size.`);
                                form.resetFields([RacekitFormField.File])
                            } else {
                                const canvas = document.createElement('canvas');
                                const ctx = canvas.getContext('2d');
                                const newHeight = (width * 90.83) / 100;
                                canvas.width = width;
                                canvas.height = newHeight;
                                ctx?.drawImage(img, 0, 0, width, newHeight);
                                const resizedImgDataUrl = canvas.toDataURL('image/jpeg');
                                const resizedFile = dataURLtoFile(resizedImgDataUrl, file.name);
                                newImages.push({ file: resizedFile, dataURL: resizedImgDataUrl });
                            }

                            if (newImages.length + errors.length === selectedFiles.length) {

                                setError(errors);
                                setUploadImages(newImages); // Add this line
                            }
                        };
                    };
                } else {
                    errors.push(`${file.name} - is not an image file.`);
                    form.resetFields([RacekitFormField.File])
                }
            });
        }
    };

    const dataURLtoFile = (dataURL: string, fileName: string) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });
    };


    return (

        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={onClose} className='close-btn'>
                    &times;
                </button>
                <h4>{racekitEditData ? "Edit Racekit" : "Add Racekit"}</h4>
            </div>
            <div className='modal_body'>
                <Form<RacekitFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <FormSectionsSeparator title={""}>
                        <Form.Item<RacekitFormField>
                            label={"Racekit Name"}
                            name={RacekitFormField.Title}

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Racekit Name"}
                            // prefix={<UserOutlined className='input-prefix-icon' />}
                            />
                        </Form.Item>

                        {!fileData && racekitEditData?.image_url &&
                            <img
                                src={racekitEditData?.image_url}
                                width={"500px"}
                            // preview={false}
                            />}

                        <Form.Item<RacekitFormField>
                            label={"Photo"}
                            name={RacekitFormField.File}
                            rules={[
                                {
                                    required: !racekitEditData?.id,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >


                            {/* <Upload fileList={fileData} onChange={handleFileChange}  accept="image/jpeg,image/png,image/gif" maxCount={1} beforeUpload={() => false}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload> */}
                            <Input type="file" max={1} onChange={handleFileChange} accept=".png,.jpg,.jpeg" />

                        </Form.Item>

                        <div style={{ marginBottom: "10px" }}>
                            <span>JPEG, PNG formats, minimum size 600x545 </span>

                            {error?.map((item, i) => (
                                <p key={i} style={{ color: "red" }}>{item}</p>
                            ))}
                        </div>
                    </FormSectionsSeparator>
                </Form>
                <div className='button-group'>
                    <Button htmlType='submit' onClick={form.submit} loading={isLoading} className='btn'>Save</Button>
                </div>

            </div>
        </div>

    )
};

