export enum DiscountFormField {
    EventId = 'event_id',
    CategoryId = 'category_id',
    PriceType = 'price_type',
    DateFrom = 'date_from',
    DateTo = 'date_to',
    IndividualPrice = 'individual_price',
    TeamPrice = 'team_price',
}

export type DiscountyFormValues = {
    [DiscountFormField.EventId]: string;
    [DiscountFormField.CategoryId]: string;
    [DiscountFormField.PriceType]: string;
    [DiscountFormField.DateFrom]: string;
    [DiscountFormField.DateTo]: string;
    [DiscountFormField.IndividualPrice]: string;
    [DiscountFormField.TeamPrice]: string;
};

type Item = {
    id: number;
    Text: string;
    // Add more properties as needed
};

export type CurrencyCategory = {
    items: Item[];
};

