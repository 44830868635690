import { Button, Form, Input, message, Select } from 'antd';
import { SportsCatFormField } from 'features/cms/components/sports-category-table/add-sports-category-modal/types';
import { handleKeyAmount } from 'features/events';
import { ShopApiService } from 'features/shop-item/api/shop-api.service';
import { useCreateDelivery } from 'features/shop-item/use-cases/create-delivery';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const AddDeliverymodal = ({ onClose, showAddUserModal }: { onClose: () => void; showAddUserModal: boolean, refetch: any }) => {
    const { id } = useParams();
    const formName = 'add-delivery-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm();
    const [country, setCountry] = useState({
        items: []
    })

    const [area, setarea] = useState({
        items: []
    })

    const { t } = useTranslation();
    const { isLoading, mutate } = useCreateDelivery();

    const getInitialValues = {
        [SportsCatFormField.Id]: id,
        [SportsCatFormField.CategoryName]: undefined,
        [SportsCatFormField.File]: undefined,
        [SportsCatFormField.IsActive]: undefined,
        [SportsCatFormField.ImageUrl]: undefined,
    };

    const handleUpdate = (formData: any) => {
        const data: any = {
            country_id: formData?.Country?.value,
            area_id: formData?.Area?.value,
            delivery_charges: +formData?.delivery_charges,
            expr_delivery_charges: +formData?.expr_delivery_charges
        }
        mutate(data, { onSuccess: hanldeSuccess });
    };

    const initialvalue = async () => {
        try {
            const apiRes = await ShopApiService().getDropdownList('AddressCountry')
            setCountry(apiRes as any)
        } catch (error) {
            message.error(error as any)
        }
    }

    const initialArea = async (value: any) => {
        try {
            const apiRes = await ShopApiService().getDropdownList(`AddressCity&cascadeId=${value}`)
            setarea(apiRes as any)
        } catch (error) {
            message.error(error as any)
        }
    }
    const hanldeSuccess = () => {
        onClose();
        // refetch();
    }


    useEffect(() => {
        if (showAddUserModal) {
            initialvalue()
        }
    }, [showAddUserModal])


    useEffect(() => {
        form.resetFields();
    }, [showAddUserModal])

    return (
        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={onClose} className='close-btn'>
                    &times;
                </button>
                <h4>Add Delivery</h4>
            </div>
            <div className='modal_body'>
                <Form
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <Form.Item
                        label={"Country"}
                        name={'Country'}
                        className='w-100'
                        rules={[
                            {
                                required: true,
                                message: t('validateMessages.custom.nomsg'),
                            },
                        ]}
                    >
                        <Select
                            labelInValue
                            placeholder="Please Select Country"
                            style={{ width: '100%' }}
                            options={country.items.map((res: any) => ({ label: res.Text, value: res.Id }))}
                            onChange={(value: any) => initialArea(value.value)}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item
                        label={"Area Name"}
                        name={'Area'}
                        className='w-100'
                        rules={[
                            {
                                required: true,
                                message: t('validateMessages.custom.nomsg'),
                            },
                        ]}
                    >
                        <Select
                            labelInValue
                            placeholder="Please Select Area Name"
                            style={{ width: '100%' }}
                            options={area.items.map((res: any) => ({ label: res.Text, value: res.Id }))}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            showSearch
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item
                        label={"Standard Delivery"}
                        name="delivery_charges"
                        className='w-100'
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: t('validateMessages.custom.nomsg'),
                            },
                        ]}
                    >
                        <Input placeholder='Enter standard Delivery'
                            onKeyDown={handleKeyAmount}
                        />
                    </Form.Item>
                    <Form.Item
                        label={"Express Delivery"}
                        name={'expr_delivery_charges'}
                        className='w-100'
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: t('validateMessages.custom.nomsg'),
                            },
                        ]}
                    >
                        <Input placeholder='Enter Express Delivery'
                            onKeyDown={handleKeyAmount}
                        />
                    </Form.Item>
                    <Form.Item className='button-group'>
                        <Button htmlType="submit" className='btn'>Save</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>

    )
};

