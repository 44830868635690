import './module.css';

import { CalendarOutlined, CheckSquareOutlined, FontSizeOutlined, MailOutlined, MenuOutlined, MinusCircleOutlined, MobileOutlined, PicLeftOutlined, ProfileOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React, { useState } from 'react';
const ItemTypes = {
    BOX: 'Box',
    NAME: 'Name',
    TEXT: 'text',
    DECISIONBOX: 'decisionbox',
    CHECKBOX: 'checkbox',
    RADIO: 'radio',
    TEXTAREA: 'textarea',
    DROPDOWN: 'dropdown',
    BUTTON: 'button',
    FILE: 'file',
    DATE: 'date',
    EMAIL: 'email',
    PHONE: 'phone',
    DOB: 'dob',
};

export default function FormDefaultComponets({ droppedItems }: { droppedItems: any[] }) {
    const [nameError, setNameError] = useState("")

    // const handleDragStart = (event: React.DragEvent<HTMLDivElement>, type: string) => {
    //     event.dataTransfer.setData('type', type);
    // };
    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, type: string) => {
        event.dataTransfer.setData('type', type);
        if (type === "Name") {
            const filterdName = droppedItems?.filter((item) => item.type === "Name")?.length;
            if (filterdName >= 1) {
                setNameError("You Can Use Name Feild Only One Time")
                message.error("You Can Use Name Feild Only One Time")
                event.preventDefault();

                return
            }
        }
        // Define default properties based on the type of field being dragged
        let labelEn = "";
        const labelAr = "";
        const placeholder = "";
        let labelKey = "";
        const required = false;
        let options: any[] = [];
        const hidden = false;

        switch (type) {
            case ItemTypes.BOX:
                labelEn = "Box";
                break;
            case ItemTypes.NAME:
                labelEn = "Name";
                break;
            case ItemTypes.TEXT:
                labelEn = "Text";
                break;
            case ItemTypes.EMAIL:
                labelEn = "Email";
                // labelKey = "email_wa";
                break;
            case ItemTypes.PHONE:
                labelEn = "Phone";
                // labelKey = "phone_wa";
                break;
            case ItemTypes.DROPDOWN:
                labelEn = "Dropdown";
                options = ["Option 1", "Option 2", "Option 3"];
                break;
            case ItemTypes.TEXTAREA:
                labelEn = "Textarea";
                break;
            case ItemTypes.DECISIONBOX:
                labelEn = "Decision Box";
                break;
            case ItemTypes.CHECKBOX:
                labelEn = "Multi Checkbox";
                options = ["Choice 1", "Choice 2", "Choice 3"];
                break;
            case ItemTypes.RADIO:
                labelEn = "Radio";
                options = ["Choose A", "Choose B", "Choose C"];
                break;
            case ItemTypes.FILE:
                labelEn = "File Upload";
                break;
            case ItemTypes.DATE:
                labelEn = "Date";
                break;
            case ItemTypes.DOB:
                labelEn = "DOB";
                labelKey = "dob_wa";
                break;
            // Add cases for additional types if needed
            default:
                break;
        }

        // Create an object containing default properties
        const defaultProperties = { labelEn, labelAr, labelKey, placeholder, required, options, hidden, childrenFields: [] };
        // Store the object as JSON data in the dataTransfer
        event.dataTransfer.setData('defaultProperties', JSON.stringify(defaultProperties));
    };

    // const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    //     // console.log(event.dataTransfer.setData('typr',type))
    //     event.preventDefault()
    // };
    // const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    //     // console.log(event.dataTransfer.setData('typr',type))
    //     event.preventDefault()
    // };

    return (
        <div className="sell">
            <div className='rghtfrm'>
                <div className='head'>
                    <h2>Custom Fields</h2>
                </div>


                <div className="cslist" title={`${nameError}`}
                    draggable
                    onDragStart={(event) => handleDragStart(event, ItemTypes.NAME)}
                // onDragEnter={(event) => handleDragEnter(event, ItemTypes.NAME)}
                // onDragOver={(event) => handleDragOver(event, ItemTypes.NAME)}
                >
                    <UserOutlined />  Name
                </div>
                <div className="cslist"
                    draggable
                    onDragStart={(event) => handleDragStart(event, ItemTypes.TEXT)}
                // onDragEnter={(event) => handleDragEnter(event, ItemTypes.TEXT)}
                // onDragOver={(event) => handleDragOver(event, ItemTypes.TEXT)}
                >
                    <FontSizeOutlined />  Text
                </div>
                {/* <div className="cslist"
                    draggable
                    onDragStart={(event) => handleDragStart(event, ItemTypes.DOB)}
                // onDragEnter={(event) => handleDragEnter(event, ItemTypes.TEXT)}
                // onDragOver={(event) => handleDragOver(event, ItemTypes.TEXT)}
                >
                    <CalendarOutlined />  Date of Birth
                </div> */}
                <div className="cslist"
                    draggable
                    onDragStart={(event) => handleDragStart(event, ItemTypes.EMAIL)}
                >
                    <MailOutlined />   Email
                </div>
                <div className="cslist"
                    draggable
                    onDragStart={(event) => handleDragStart(event, ItemTypes.PHONE)}
                >
                    <MobileOutlined />  Phone
                </div>
                <div className="cslist"
                    draggable
                    onDragStart={(event) => handleDragStart(event, ItemTypes.DROPDOWN)}
                >
                    <MenuOutlined />  Dropdown
                </div>
                <div className="cslist"
                    draggable
                    onDragStart={(event) => handleDragStart(event, ItemTypes.TEXTAREA)}
                >
                    <PicLeftOutlined />   TEXTAREA
                </div>
                <div className="cslist"
                    draggable
                    onDragStart={(event) => handleDragStart(event, ItemTypes.DECISIONBOX)}
                >
                    <CheckSquareOutlined />  Decision box
                </div>
                <div className="cslist"
                    draggable
                    onDragStart={(event) => handleDragStart(event, ItemTypes.CHECKBOX)}
                >
                    <CheckSquareOutlined />  Checkbox
                </div>
                <div className="cslist"
                    draggable
                    onDragStart={(event) => handleDragStart(event, ItemTypes.RADIO)}
                >
                    <MinusCircleOutlined />  Radio
                </div>
                <div className="cslist"
                    draggable
                    onDragStart={(event) => handleDragStart(event, ItemTypes.FILE)}
                >
                    <UploadOutlined /> File Upload
                </div>
                <div className="cslist"
                    draggable
                    onDragStart={(event) => handleDragStart(event, ItemTypes.DATE)}
                >
                    <CalendarOutlined />  Date
                </div>

                <div className="cslist"
                    draggable
                    onDragStart={(event) => handleDragStart(event, ItemTypes.BOX)}
                    // onDragEnter={(event) => handleDragEnter(event)}
                    // onDragOver={(event) => handleDragOver(event)}
                    style={{ marginBottom: '10px' }}
                >
                    <ProfileOutlined />  Box
                </div>
            </div>

            {/* <div className="draggable-item"
        draggable
        onDragStart={(event) => handleDragStart(event, ItemTypes.BUTTON)}
      >
        Button
      </div> */}
        </div>
    );
};


