import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { AddAdminUserModal } from 'features/users/components/modals/add-admin-user-modal';
import { UserFilterFormValues } from 'features/users/components/users-filter-form/types';
import { UsersFilterForm } from 'features/users/components/users-filter-form/users-filter-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyType } from 'types';

const { useBreakpoint } = Grid;

type UsersTopBarProps = {
  getInitialFilterValues: UserFilterFormValues;
  handleFilter: (values: UserFilterFormValues) => void;
  adminUserEditData:any ;
};

export const UsersTopBar = ({
  getInitialFilterValues,
  handleFilter,
  adminUserEditData
}: UsersTopBarProps) => {
  useState<CompanyType>();
  const { t } = useTranslation();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const screens = useBreakpoint();
  const formId = 'users-filters-form';

  const handleCloseModal = () => {
    setShowAddUserModal(false);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = () => {
    setShowAddUserModal(true);
    document.body.style.overflow = 'hidden';
  }

  return (
    <>
      <div className={`cstmrightmdl ${showAddUserModal && "show"}`}>
        <AddAdminUserModal onClose={handleCloseModal} adminUserEditData={adminUserEditData}/>
      </div>

      <ContentTopBar
        title={t('adminUsers.heading.admin')}
        renderButtons={
          <>
            <Button
               icon={!screens.xs ? <PlusOutlined /> : undefined}
              onClick={handleOpenModal}
              type='primary'
            >
              {t('adminUsers.addAdminUser')}
            </Button>
          </>
        }
      />
      <ContentFilters formId={formId}>
        <UsersFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
