import { Button, Checkbox, Form, Input } from 'antd';
import { useCreateType } from 'features/notification/use-cases/create.type';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationFilterFormField, NotificationFilterFormValues } from '../type';

export const EditNotificationModal = ({ onClose, refetch, editData }: { onClose: () => void; showAddItemModal: boolean, refetch: () => void, editData: any }) => {

    const formName = 'add-shop-coupon-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<NotificationFilterFormValues>();
    const { isLoading, mutate } = useCreateType();
    const handleClose = () => {
        onClose();
    }
    const { t } = useTranslation();
    const getInitialValues = {
        [NotificationFilterFormField.TITLE]: undefined,
        [NotificationFilterFormField.is_active]: Boolean,
    };
    const handleUpdate = (formData: any) => {
        formData[NotificationFilterFormField.ID] = editData?.id;
        mutate(formData, { onSuccess: hanldeSuccess });
    };

    const hanldeSuccess = () => {
        handleClose();
        refetch();
    }
    useEffect(() => {
        if (editData) {
            form.setFieldsValue(editData)
        } else {
            form.resetFields()
        }
    }, [editData])

    return (
        <>
            <div className='popup'>
                <div className='modal_head'>
                    <h4> Edit Type</h4>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                </div>
                <div className='modal_body' >
                    <Form<NotificationFilterFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <FormSectionsSeparator title={""}>
                            <Form.Item<NotificationFilterFormField>
                                label={"Type"}
                                name={NotificationFilterFormField.TITLE}

                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Enter type"}
                                />
                            </Form.Item>
                            <Form.Item<NotificationFilterFormField>
                                name={NotificationFilterFormField.is_active}
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Checkbox>Is Active</Checkbox>
                            </Form.Item>
                        </FormSectionsSeparator>
                        <div className='form-button'>
                            <Button htmlType='submit' className='btn' type='primary'>Save</Button>
                        </div>
                    </Form>
                </div>
            </div >
        </>
    )
};

