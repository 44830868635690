import { PaginatedRequestParams } from 'api';
import { MainFiltersParams } from 'components/main-filters/main-filters';

export enum CurrencyFilterFormField {
  FieldName = 'name',
}

export type CurrencyFilterFormValues = {
  [CurrencyFilterFormField.FieldName]: string | undefined;
};

export type CurrencyFilterFormPaginated = CurrencyFilterFormValues &
  MainFiltersParams &
  PaginatedRequestParams;
