import { Button, Form } from 'antd';
import { useCreateCategory } from 'features/shop-item/use-cases/craete-category';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CategoryInfoFormItems } from './addcategoryinfo';

export const AddCategoryModal = ({ onClose }: { onClose: () => void }) => {
  const formName = 'add-user-form';
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { isLoading, mutate } = useCreateCategory();

  const handleClose = () => {
    onClose();
    form.resetFields();
  }

  const handleUpdate = (formData: any) => {
    // Assuming formData is an object
    const updatedFormData = {
      ...formData, // Copying existing formData
      is_active: true // Adding or updating the is_active field
    };

    mutate(updatedFormData, { onSuccess: handleClose });
    form.resetFields();
  };

  return (
    <>
      <div className='popup'>
        <div className='modal_head'>
          <h4>{t('shops.buttons.addCategory')}</h4>
          <button onClick={handleClose} className='close-btn'>
            &times;
          </button>

        </div>
        <div className='modal_body' >
          <Form
            id={formName}
            name={formName}
            form={form}
            layout='vertical'
            //   initialValues={getInitialValues}
            onFinish={handleUpdate}
            onFinishFailed={() => {
              setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
            disabled={isLoading}
          >
            <CategoryInfoFormItems form={form} />
            <div className='form-button'>
              <Button htmlType='submit' className='btn' type='primary'>Save</Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  )
};


