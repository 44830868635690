import { DeleteOutlined, DownloadOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { App, Button, Collapse, CollapseProps, Empty, Modal, Space, Switch, Tooltip, Upload } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import { RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import {
    EventsFilterFormPaginated,
} from 'features/events';
import { useFaqUploadBulk } from 'features/events/use-cases/bulkupload-event-faq';
import { useEventDeleteStatus } from 'features/events/use-cases/event-delete-status';
import { UpdateStatus } from 'features/events/use-cases/update-status';
import { Dispatch, SetStateAction, useState } from 'react';
import * as XLSX from 'xlsx'; // Import XLSX library

import { AddEventFaqModal } from './add-faq-modal';



type OrganizerTableProps = {
    id: any;
    isLoading: boolean;
    allData?: any;
    tableParams: EventsFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<EventsFilterFormPaginated>>;
    FaqData: any;
    setFaqData: any;
    refetch: () => void;
    // beforeUpload: any,
    // handleUpload: any
};

export const FaqViewTable = ({
    id,
    allData,
    FaqData,
    setFaqData,
    refetch,
    // beforeUpload,
    // handleUpload
}: OrganizerTableProps) => {

    const { message } = App.useApp();

    const { mutate: mutateUpdateItem, isLoading: isLoadingUpdateSocial } =
        UpdateStatus();

    const { mutate: deleteFaq } = useEventDeleteStatus();


    const { confirm } = Modal;

    const showDeleteConfirm = (val: any) => {
        confirm({
            title: 'Are you sure you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(val);
            },
        });
    };
    const handleDelete = async (deleteId: any) => {
        // Simulate the loading state
        // Simulate the loading state
        deleteFaq({
            id: deleteId,
            flag: "FAQ_DELETE",
            status: true
        }, {
            onSuccess: () => {
                refetch();
            }
        })
    };

    const [showAddEventModal, setShowAddEventModal] = useState<boolean>(false);


    const handleCloseModal = () => {
        setShowAddEventModal(false);
        setFaqData(undefined);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = (record: any) => {
        setShowAddEventModal(true);
        setFaqData(record);
        document.body.style.overflow = 'hidden';
    }
    const items: CollapseProps['items'] = allData?.items?.map((res: any, index: number) => {
        return {
            key: index, // Assuming res.key contains the key for this item
            label: res.question, // Assuming res.label contains the label for this item
            children: <p>{res.answer}</p>, // Assuming res.text contains the text for this item
            extra: <Space>
                <Tooltip title="Edit">
                    <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(res)} />
                </Tooltip>
                <Tooltip title="Delete">
                    <Button shape="circle" icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(res.id)} />
                </Tooltip>
                <Tooltip title="Is Active">
                    <Switch
                        defaultChecked={res.is_active}
                        checked={res.is_active}
                        loading={isLoadingUpdateSocial}
                        onChange={(isChecked) => {
                            mutateUpdateItem({
                                id: res.id,
                                flag: "FAQ_SET_STATUS",
                                status: isChecked,
                            });
                            // Update the is_active status locally
                            res.is_active = isChecked;
                        }}
                        onClick={(checked, event) => {
                            event.stopPropagation();
                            event.preventDefault();
                        }}
                    />
                </Tooltip>
            </Space>,
        };
    });
    const { mutate } = useFaqUploadBulk();
    const [fileList, setFileList] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleUpload = async (file: RcFile) => {
        const isExcel =
            file.type === 'application/vnd.ms-excel' ||
            file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isExcel) {
            message.error('You can only upload Excel files!');
            setFileList([]);

            return false;
        }

        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            const data = e.target?.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData: (string | number)[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            // Check if there are no rows other than header
            if (jsonData.length <= 1) {
                message.error('The Excel file does not contain any data.');
                setFileList([]);

                return false;
            }

            const header = jsonData[0] as string[];
            const requiredColumns = ['question', 'answer'];
            const lowerCaseHeader = header.map(col => col.toLowerCase());
            const lowerCaseRequiredColumns = requiredColumns.map(col =>
                col.toLowerCase()
            );

            // Check if required columns are present
            if (!lowerCaseRequiredColumns.every(col =>
                lowerCaseHeader.includes(col)
            )) {
                message.error('The Excel file must contain "question" and "answer" columns.');
                setFileList([]);

                return false;
            }

            const dataRows = jsonData.slice(1);
            if (dataRows.length === 0) {
                message.error('The Excel file contains only headers and no data.');
                setFileList([]);

                return false;
            }

            // Check if all rows have both 'question' and 'answer'
            for (const row of dataRows) {
                const rowObject = lowerCaseHeader.reduce((acc, col, index) => {
                    acc[col] = row[index];

                    return acc;
                }, {} as Record<string, any>);

                if (!rowObject.question || !rowObject.answer) {
                    message.error('Each row must contain both a question and an answer.');
                    setFileList([]);

                    return false;
                }
            }

            const formData = new FormData();
            formData.append('event_id', id);
            formData.append('file', file as File);
            handlePost(formData);
        };
        reader.readAsBinaryString(file);

        return false;
    };

    const handlePost = (formData: FormData) => {
        mutate({ formData }, {
            onSuccess() {
                refetch();
            },
        });
    };

    const handleOk = () => {
        setFileList([]);
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setFileList([]);
        setIsModalOpen(false);
    };

    const handleDownloadSample = () => {
        const faqs = [
            ['Question', 'Answer'],
            ['What time does the event start?', 'The event starts at 10:00 AM.'],
            ['Where is the event venue located?', 'The event venue is located at 123 Main Street.'],
            ['Is parking available at the venue?', 'Yes, parking is available at the venue.'],
            ['Are children allowed at the event?', 'Yes, children are welcome to attend the event.'],
            ['Do I need to bring my ticket to the event?', 'Yes, please bring your ticket to the event.'],
            ['Is there a dress code for the event?', 'The dress code for the event is business casual.'],
            ['Can I bring outside food and drinks to the event?', 'Outside food and drinks are not permitted at the event.'],
            ['Are there any discounts available for students/seniors/military personnel?', 'Yes, we offer discounts for students, seniors, and military personnel.'],
            ['Is there a designated smoking area at the event?', 'Yes, there is a designated smoking area at the venue.'],
            ['What COVID-19 safety measures are in place for the event?', 'We have implemented various COVID-19 safety measures, including mandatory mask-wearing and social distancing protocols.']
        ];

        const ws = XLSX.utils.aoa_to_sheet(faqs);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'FAQs');
        XLSX.writeFile(wb, 'sample_faq.xlsx');
    };

    <button onClick={handleDownloadSample}>Download Sample FAQ</button>;

    return (
        <>
            <Modal title="" open={isModalOpen} onCancel={handleCancel} onOk={handleOk} cancelButtonProps={{ style: { display: 'none' } }}>
                <p>Excel file must contain two columns question and answer</p>
            </Modal>


            <div className={`cstmrightmdl ${showAddEventModal && "show"}`}>
                <AddEventFaqModal showAddEventModal={showAddEventModal} onClose={handleCloseModal} FaqData={FaqData} refetch={refetch} />
            </div>

            {/* {isLoading ?
                <div className="cstltblwthldr">
                    <Skeleton />
                    <TableLoader />
                </div>
                : */}
            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={"FAQ'S Found"}
                        count={allData?.total || 0}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            icon={<DownloadOutlined />}
                            type='primary'
                            className='btn'
                            onClick={handleDownloadSample}
                            style={{ marginRight: 10 }}
                        >
                            Download Sample FAQ
                        </Button>

                        <Upload
                            beforeUpload={handleUpload}
                            // onChange={handleUpload}
                            fileList={fileList}
                            maxCount={1}
                            accept=".xls, .xlsx"
                        >
                            <Button icon={<UploadOutlined />} style={{ marginRight: '8px' }}>Upload Bulk</Button>
                        </Upload>
                        <Button
                            icon={<PlusOutlined />}
                            onClick={() => handleOpenModal("")} type='primary' className='btn'
                        >
                            Add FAQ
                        </Button>
                    </div>
                </StyledTableHeader>
                {(allData?.items && allData?.items?.length > 0) ?
                    <Collapse items={items} defaultActiveKey={[0]} />
                    :
                    <Empty />
                }
            </StyledTableWrapper>
        </>
    );
};

