import { UserOutlined } from '@ant-design/icons'
import {  Form, Input } from 'antd'
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator'
import { useTranslation } from 'react-i18next';

import { CurrencyFormField } from './types';

const AddCurrencyForm = () => {
    const { t } = useTranslation();

    return (
        <div>
            <FormSectionsSeparator title={""}>

                <Form.Item<CurrencyFormField>
                    label={"Currency Name"}
                    name={CurrencyFormField.CurrencyName}

                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Input
                        placeholder={"Currency Name"}
                        prefix={<UserOutlined className='input-prefix-icon' />}
                    />
                </Form.Item>



                <Form.Item<CurrencyFormField>
                    label={"Currency code"}
                    name={CurrencyFormField.CurrencyCode}

                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Input
                        placeholder={"Currency code"}
                    />
                </Form.Item>


                <Form.Item<CurrencyFormField>
                    label={"Currency Symbol"}
                    name={CurrencyFormField.CurrencySymbol}

                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Input
                        placeholder={"Currency Symbol"}
                    />
                </Form.Item>


                <Form.Item<CurrencyFormField>
                    label={"Basic Currency"}
                    name={CurrencyFormField.BasicCurrency}

                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Input
                        placeholder={"Basic Currency"}
                    />
                </Form.Item>

                <Form.Item<CurrencyFormField>
                    label={"Friction"}
                    name={CurrencyFormField.Friction}

                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Input
                        placeholder={"Friction"}
                    />
                </Form.Item>

            </FormSectionsSeparator>
        </div>
    )
}

export default AddCurrencyForm;
