import { Button, Form, Input } from 'antd';
import { useUpdateCategory } from 'features/shop-item/use-cases/update-category';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CategoryFormField, CategoryFormValues } from './type';
export const EditCategoryModal = ({ onClose, CategoryEditData }: { onClose: () => void; showCategorysModal: boolean, refetch: () => void, CategoryEditData: any }) => {
    const { id } = useParams();
    const formName = 'add-organizer-form';
    const { t } = useTranslation();
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<CategoryFormValues>();
    const { isLoading, mutate } = useUpdateCategory();
    const getInitialValues = {
        [CategoryFormField.Id]: id,
        [CategoryFormField.title]: undefined,
        [CategoryFormField.IsActive]: undefined,
    };
    const handleUpdate = (formData: CategoryFormValues) => {
        formData[CategoryFormField.Id] = CategoryEditData?.id;
        formData[CategoryFormField.title] = formData.title;
        formData[CategoryFormField.IsActive] = true;
        mutate(formData, { onSuccess: hanldeSuccess });
    };

    const hanldeSuccess = () => {
        onClose();
        // refetch();
    }

    useEffect(() => {
        if (CategoryEditData) {
            form.setFieldsValue(CategoryEditData);
        } else {
            form.resetFields();
        }
    }, [CategoryEditData])

    return (
        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={onClose} className='close-btn'>
                    &times;
                </button>
                <h4>Edit Title</h4>
            </div>
            <div className='modal_body'>
                <Form<CategoryFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    requiredMark={'optional'}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <FormSectionsSeparator title={""}>
                        <Form.Item<CategoryFormField>
                            label={"Title"}
                            name={CategoryFormField.title}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Enter Your the Tittle "}
                            />
                        </Form.Item>

                    </FormSectionsSeparator>
                </Form>
                <div className='button-group'>
                    <Button htmlType='submit' onClick={form.submit} className='btn'>Save</Button>
                </div>

            </div>
        </div>

    )
};

