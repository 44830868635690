import {
    QueryFunction,
    QueryKey,
    useQuery,
    UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';

import { EventsApiService } from '../api/events-api.service';

const { getEventDetailsData } = EventsApiService();

const getEventDetailsQuery = (
    id: any,
    options?: UseQueryOptions<any>,
): {
    queryKey: QueryKey;
    queryFn: QueryFunction<any>;
    options?: UseQueryOptions<any>;
} => ({
    queryKey: ['getEventDetailsData', id],
    queryFn: ({ signal }) => getEventDetailsData(id, { config: { signal } }),
    ...options,
});

export const useGetEventDetailsData = (
    id: any,
    options?: UseQueryOptions<any>,
) => {
    const { message } = App.useApp();

    return useQuery<any, Error>(
        getEventDetailsQuery(id, {
            onError: (error: unknown) => {
                const uiMessage = handleError(error);
                message.error(uiMessage);
            },
            onSuccess: (data) => {
                if (!data) {
                    message.error("No data found");
                }
            },
            ...options,
        }),
    );
};
