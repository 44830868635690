import { Button, ContentFilters, ContentTopBar } from 'components';
import { AddNotificationModal } from 'features/notification/components/modal/AddnotificationModal';
import { useState } from 'react';
import { CompanyType } from 'types';

import { NotificationTypeFilterForm } from '../notification-main-filter/notification-filter-type.form';
import { NotificationFilterFormValues } from '../type';
type VendingTopBarProps = {
  getInitialFilterValues: NotificationFilterFormValues;
  handleFilter: (values: NotificationFilterFormValues) => void;
  refetch: any,
};
export const NotificationTopBarType = ({
  getInitialFilterValues,
  handleFilter,
  refetch,

}: VendingTopBarProps) => {
  useState<CompanyType>();

  const formId = 'users-filters-form';
  const [showAddItemModal, setShowAddItemModal] = useState<boolean>(false);
  const handleOpenModal = () => {
    setShowAddItemModal(true)
    document.body.style.overflow = 'hidden';
  }

  const handleCloseModal = () => {
    setShowAddItemModal(false)
    document.body.style.overflow = 'visible';
  }

  return (
    <>
      <div className={`cstmrightmdl ${showAddItemModal && "show"}`}>
        <AddNotificationModal onClose={handleCloseModal} refetch={refetch} showAddItemModal={showAddItemModal} />
      </div>
      <ContentTopBar
        title={'Notification Type'}
        renderButtons={
          <>
            <Button
              type='primary'
              onClick={handleOpenModal}
            >
              Add Type
            </Button>
          </>
        }
      />

      <ContentFilters formId={formId}>
        <NotificationTypeFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
