import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import {
    MainFilters,
    MainFiltersParams,
} from 'components/main-filters/main-filters';
import { usePermissions } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { PartnersTable } from '../components';
import { AddPartnersModal } from '../components/manage-partners/add-partners-modal/add-partners-modal';
import { ManageFilterForm, ManageFilterFormPaginated, ManageFilterFormValues } from '../components/manage-partners-filter-form';
import { useGetPartners } from '../use-cases/get-partners';

// import { AddEventModal } from '../components/modals/add-event-modal';

const { useBreakpoint } = Grid;

const initialFilterValues: ManageFilterFormValues = {
    link: undefined,
};

export const ManagePartnersView = () => {
    const screens = useBreakpoint();
    const permissions = usePermissions();
    const { params, updateParams, resetPage } =
        usePaginationParams<ManageFilterFormPaginated>({
            page: 1,
            size: 10,
            order_by: '-rank',
            ...initialFilterValues,
        });

    const { data, isFetching, refetch } = useGetPartners(params, { retry: false });
    const [partnersEditData, setPartnersEditData] = useState<any>(undefined);
    const shouldHandleFieldCreation = permissions?.fields.add;
    const handleFiltersChange = (values: ManageFilterFormValues) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    const handleMainFiltersChange = (mainParams: MainFiltersParams) => {
        updateParams(mainParams);
        resetPage();
    };

    const [showPartnersModal, setShowPartnersModal] = useState(false);
    const formId = 'events-filters-form';

    const handleCloseModal = () => {
        setShowPartnersModal(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = () => {
        setShowPartnersModal(true);
        document.body.style.overflow = 'hidden';
    }

    return (
        <>
            <ContentTopBar
                title={"Manage Partners"}
                renderHeadingContent={
                    <MainFilters onChange={handleMainFiltersChange} />
                }
                renderButtons={
                    <>
                        {shouldHandleFieldCreation ? (
                            <>
                                <Button
                                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                                    type='primary'
                                    onClick={handleOpenModal}
                                >
                                    {"Add Manage Partners"}
                                </Button>
                                <div className={`cstmrightmdl ${showPartnersModal && "show"}`}>
                                    <AddPartnersModal showPartnersModal={showPartnersModal} refetch={refetch} onClose={handleCloseModal} partnersEditData={partnersEditData} />

                                </div>

                            </>
                        ) : null}
                    </>
                }
            />

            <ContentFilters formId={formId}>
                <ManageFilterForm
                    formId={formId}
                    handleFilter={handleFiltersChange}
                    initialValues={initialFilterValues}
                />
            </ContentFilters>

            <PartnersTable
                data={data}
                isLoading={isFetching}
                tableParams={params}
                updateParams={updateParams}
                refetch={refetch}
                partnersEditData={partnersEditData}
                setPartnersEditData={setPartnersEditData}
            />
            <Outlet />
        </>
    );
};



