

import EditItem from '../components/modal/edit-item';


export const ItemListEditView = () => {

    return (

        <>
            <EditItem />
        </>
    );
};
