import { Button, Form } from 'antd';
import { useCreateCurrency } from 'features/cms/use-cases/create-currency';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AddCurrencyForm from './add-currency-form';
import { CurrencyFormField, CurrencyFormValues } from './types';
export const AddCurrencyModal = ({ onClose, showCurrencyModal, refetch, currencyEditData }: { onClose: () => void; showCurrencyModal: boolean, refetch: () => void, currencyEditData: any }) => {
    const { id } = useParams();
    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<CurrencyFormValues>();
    const { isLoading, mutate } = useCreateCurrency();

    const getInitialValues = {
        [CurrencyFormField.Id]: id,
        [CurrencyFormField.CurrencyName]: undefined,
        [CurrencyFormField.CurrencyCode]: undefined,
        [CurrencyFormField.BasicCurrency]: undefined,
        [CurrencyFormField.CurrencySymbol]: undefined,
        [CurrencyFormField.Friction]: undefined,
        [CurrencyFormField.IsActive]: undefined,
    };

    const handleUpdate = (formData: CurrencyFormValues) => {
        formData[CurrencyFormField.Id] = currencyEditData?.id;
        formData[CurrencyFormField.IsActive] = true;
        mutate(formData, { onSuccess: hanldeSuccess });
    };

    const hanldeSuccess = () => {
        onClose();
        refetch();
    }

    useEffect(() => {
        if (currencyEditData) {
            form.setFieldsValue(currencyEditData);
        } else {
            form.resetFields();
        }
    }, [currencyEditData])

    return (

        <div className={showCurrencyModal ? 'popup' : 'popup'}>
            <div className='modal_head'>
                <button onClick={onClose} className='close-btn'>
                    &times;
                </button>
                <h4>{currencyEditData ? "Edit Currency" : "Add Currency"}</h4>
            </div>
            <div className='modal_body' style={{
                height: "90vh",
                overflowY: "auto"
            }}>
                <Form<CurrencyFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    requiredMark={'optional'}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <AddCurrencyForm />
                </Form>
                <div className='button-group'>
                    <Button htmlType='submit' onClick={form.submit} loading={isLoading} className='btn'>Save</Button>
                </div>

            </div>
        </div>

    )
};

