import PropTypes from 'prop-types';
import { Cell, Pie, PieChart, Tooltip, TooltipProps } from 'recharts';

const FeeCategory = ({ feeCategoryGraphData }: any) => {

    const data = feeCategoryGraphData;
    const CustomPieTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const total = data.reduce((sum: any, entry: any) => sum + entry.value, 0);
            const percentage = payload[0].value != undefined ? ((payload[0].value / total) * 100).toFixed(0) : 0;

            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
                    <p className="label">{`${payload[0].name} : ${percentage}%`}</p>
                    {/* <p className="extra">{`Extra Info: ${payload[0].payload.percentage}`}</p> ${payload[0].value} */}
                </div>
            );
        }

        return null;
    };

    CustomPieTooltip.propTypes = {
        active: PropTypes.bool,
        payload: PropTypes.array,
    };

    // Three colors for the datasets
    const COLORS = ['#0088FE', '#00C49F', '#FF8042'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <PieChart width={400} height={400}>
                <Pie
                    data={feeCategoryGraphData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {feeCategoryGraphData.map((entry: any, index: any) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
            <div style={{ marginTop: 20, display: 'flex' }}>
                <div style={{ display: 'flex', marginRight: 30 }}>
                    <div style={{ backgroundColor: COLORS[2], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span>Normal</span>
                </div>
                <div style={{ display: 'flex', marginRight: 30 }}>
                    <div style={{ backgroundColor: COLORS[0], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span>Early</span>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ backgroundColor: COLORS[1], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span>Late</span>
                </div>
            </div>
        </div>
    );
};

export default FeeCategory;
