import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { queryClient } from 'providers';

import { EventsApiService } from '../api/events-api.service';
import { eventsQueryKeys } from './query-keys';

const { faqUploadBulk } = EventsApiService();

export const useFaqUploadBulk = () => {
    const { message } = App.useApp();

    return useMutation(
        ({ formData }: { formData: FormData }) => {
            return faqUploadBulk(formData);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
                message.success("FAQ Bulk uploaded successfully");
            },
        },
    );
};
