import './main.css';

import { PageLoader } from 'components/page-loader/page-loader';
import { useGetEventFormData } from 'features/events/use-cases/get-register-form-data';
import { useEffect, useState } from 'react';

import FormCreatedComponents from './form-created-components/form-created-components';
import FormDefaultComponets from './form-default-Componets/form-default-Componets';

interface props {
  id: string | undefined;
  tabs: string
}

function CustomFormsContent({ id, tabs }: props) {


  const [droppedItems, setDroppedItems] = useState<any>([]);

  const userQueryResult = useGetEventFormData(id as any, {
    cacheTime: 0,
  });


  const {
    isLoading,
    // isFetching: isFetchingGetUser,
    data: userData,
    // isError: isErrorUser,
    refetch
  } = userQueryResult;


  useEffect(() => {
    if (tabs === "8") {
      refetch()
    }
  }, [tabs])

  if (!id) return null;
  if (isLoading) return <PageLoader />;

  return (
    <div className="cstmfrmscrion">
      {/* {isLoading ?
        <PageLoader />
        : */}
      <FormCreatedComponents userData={userData} droppedItems={droppedItems} setDroppedItems={setDroppedItems} />
      {/* } */}
      <FormDefaultComponets droppedItems={droppedItems} />

    </div>
  );
}

export default CustomFormsContent;
