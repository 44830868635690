import { DeleteFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { Checkbox, Image, Modal } from 'antd';
import { useEventUpdateStatus } from 'features/events/use-cases/event-update-status';

import NoImage from '../../../../../assets/No_Image_Available.jpg';

const EventGalleryImgView = ({ data, refetch }: { data: any, refetch: any }) => {

    const handleCheckboxChange = (id: string) => {
        const formData = {
            id: id,
            status: true,
            flag: "Gallery_Set_Default"
        }
        mutate(formData);
        setTimeout(() => {
            refetch();
        }, 1000)
    };


    const { mutate } = useEventUpdateStatus();

    const { confirm } = Modal;

    const showDeleteConfirm = (id: string, is_setdefault: boolean, status: string) => {
        confirm({
            title: 'Are you sure you want to delete this Image?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteImg(id, is_setdefault, status);
            },
        });
    };

    const handleDeleteImg = (id: string, is_setdefault: boolean, status: string) => {
        const deletedFormData = {
            id: id,
            status: is_setdefault,
            flag: status
        }
        mutate(deletedFormData,);
        setTimeout(() => {
            refetch();
        }, 1000)
    }

    return (
        <>
            <div className='glry_list'>
                {data.items !== null && data.items.length > 0 ?
                    data.items.map((curItem: { img_url: string; is_setdefault: boolean, id: string }, i: number) => (
                        <div key={i} className='glery_bx'> {/* Adding key prop to each rendered item */}
                            <Image
                                style={{ borderRadius: '6px', width: '100%', height: 'auto' }}
                                src={curItem.img_url}
                                placeholder={true}
                                preview={false}
                            // onError={(e: any) => { e.target.src = 'https://images.ecestaticos.com/-mBz1VmNnHUdV56hmZwRW8cJhLw=/0x115:2265x1390/1200x675/filters:fill(white):format(jpg)/f.elconfidencial.com%2Foriginal%2F8ec%2F08c%2F85c%2F8ec08c85c866ccb70c4f1c36492d890f.jpg'; }}
                            />
                            {/* <Button danger onClick={() => handleDeleteImg(curItem.id, curItem.is_setdefault, "Gallery_Set_Delete")}>  <DeleteOutlined /></Button> */}

                            <div className='img_edit'>

                                <Checkbox
                                    checked={curItem.is_setdefault}
                                    onChange={() => handleCheckboxChange(curItem.id)}

                                >
                                    Set Default
                                </Checkbox>

                                <div className='icon_bx'>
                                    <DeleteFilled onClick={() => showDeleteConfirm(curItem.id, curItem.is_setdefault, "Gallery_Set_Delete")} />

                                </div>



                            </div>

                        </div>
                    ))
                    :
                    <div>
                        <img src={NoImage} height={"300px"} />
                    </div>
                }
            </div>
        </>

    )
}

export default EventGalleryImgView;
