import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { eventsQueryKeys } from 'features/events';
import { queryClient } from 'providers';

import { paymentLinkDTO, TransectionApiServices } from '../api/transection-api.service';

const { createPaymentLink } = TransectionApiServices();

export const useCreatePaymentLink = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: paymentLinkDTO) => {
            return createPaymentLink(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: (data: any) => {
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
                if (data?.ResponseStatus === 1) {
                    message.success(data.ResponseMessage);
                } else {
                    message.error(data.ResponseMessage);
                }
            },
        },
    );
};