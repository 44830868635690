import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  UpdateUserDTO,
  UpdateUserRoleDTO,
  UserApiService
} from 'features/users/api/user-api.service';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

import { userQueryKeys } from './query-keys';

const { updateUser, updateUserRole, updateUserStatus } = UserApiService();

export const useUpdateUser = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: UpdateUserDTO) => {
      return updateUser(payload);
    },
    {
      onError: (error) => {

        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: userQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: userQueryKeys.detail(variables.id),
          }),
        ]);
        message.success(i18n.t('adminUsers.update.successfulUpdate'));
      },
    },
  );
};


export const useUpdateUserRole = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: UpdateUserRoleDTO) => {
      return updateUserRole(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: userQueryKeys.lists(),
          }),
        ]);
        message.success(i18n.t('adminUsers.update.successfulUpdate'));
      },
    },
  );
};

export const useUpdateUserStatus = () => {
  const { message } = App.useApp();
  const queryClient1 = useQueryClient();

  return useMutation(
    (payload: any) => {
      return updateUserStatus(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        queryClient1.invalidateQueries({
          queryKey: userQueryKeys.lists(),
        });

        message.success('Successfully updated status');
      },
    },
  );
};
