import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
  EventsResponse,
  // useUpdateEvent,
} from 'features/events';
import { useStatusResponse } from 'features/shop-item/use-cases/update-status-response-msg';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';
import { Event } from 'types/event';

import { CountryFilterFormPaginated } from '../manage-country-filter-form';
import { AddCountriesModal } from './add-countries-modal/add-countriesModal-modal';
;


type EventsTableProps = {
  isLoading: boolean;
  data?: EventsResponse;
  tableParams: CountryFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<CountryFilterFormPaginated>>;
  refetch: () => void;
  countriesEditData: any;
  setCountriesEditData: any;
};

export const CountriesTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
  refetch,
  countriesEditData,
  setCountriesEditData
}: EventsTableProps) => {
  const permissions = usePermissions();

  const { mutate: mutateUpdateItem, isLoading: isLoadingUpdate } =
    useStatusResponse();

  const { confirm } = Modal;

  const handleDelete = async (id: any) => {

    mutateUpdateItem({
      id: id,
      flag: "COUNTRIES_DELETE",
      status: true
    });
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: 'Are you sure you want to delete this Country?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(record.id);
      },
    });
  };
  const handleTableChange = (
    pagination: TablePaginationConfig,
    // filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Event> | SorterResult<Event>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'
          : '-name',
    });
  };


  const [showCountriesModal, setShowCountriesModal] = useState(false);

  const handleCloseModal = () => {
    setShowCountriesModal(false);
    setCountriesEditData(undefined);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = (record: any) => {
    setShowCountriesModal(true);
    setCountriesEditData(record);
    document.body.style.overflow = 'hidden';
  }


  if (!permissions) return null;

  return (
    <>
      <div className={`cstmrightmdl ${showCountriesModal && "show"}`}>

        <AddCountriesModal showCountriesModal={showCountriesModal} refetch={refetch} onClose={handleCloseModal} countriesEditData={countriesEditData} />
      </div>

      <StyledTableWrapper>
        <StyledTableHeader>
          <RecordCountBadge
            text={"Country found"}
            count={data?.total || 0}
          />
        </StyledTableHeader>
        <Table<Event>
          className="cstltblwthldr"
          locale={{
            emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
          }}
          loading={isLoading && { indicator: <div><TableLoader /></div> }}
          dataSource={isLoading ? [] : data?.items}
          rowKey='id'
          onChange={handleTableChange}
          pagination={{
            current: tableParams.page,
            defaultPageSize: tableParams.size,
            total: data?.total,
          }}
        // scroll={tableWidth}
        >

          <Column<Event>
            title={'Name'}
            dataIndex={'title'}
          />

          <Column<Event>
            title={'Name Ar'}
            dataIndex={'title_arabic'}
          // width={screens.xxl ? 'auto' : 200}
          />

          <Column<Event>
            title={"Action"}
            // width={screens.xxl ? 'auto' : 162}
            dataIndex={'is_active'}
            render={(_, record) => (
              <>
                <Space>
                  <Tooltip title="Edit">
                    <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                  </Tooltip>

                  <Tooltip title="Delete">
                    {/* <Button shape="circle" icon={<DeleteOutlined />} /> */}
                    <Button shape="circle" loading={isLoadingUpdate} icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(record)} />
                  </Tooltip>
                  <Tooltip title="Is Active">
                    <Switch
                      defaultChecked={record.is_active}
                      checked={record.is_active}
                      loading={isLoadingUpdate}
                      onChange={(isChecked) =>
                        mutateUpdateItem({
                          id: record.id,
                          flag: "COUNTRY_SET_STATUS",
                          status: isChecked,
                        })
                      }
                      onClick={(checked, event) => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    />
                  </Tooltip>
                </Space>
              </>
            )}
          />
        </Table>
      </StyledTableWrapper>
    </>

  );
};
