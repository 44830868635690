import { Button, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CouponFilterFormField, CouponFormFieldValue, ShopItemFormField, ShopItemFormFieldValue } from './type';
const { useBreakpoint } = Grid;
type UsersFilterFormProps = {
  formId: string;
  handleFilter: (values: CouponFormFieldValue) => void;
  initialValues: CouponFormFieldValue;
  closeModal?: () => void;
  refetch?: any
};

export const CouponFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: UsersFilterFormProps) => {

  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<CouponFormFieldValue>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues });
  };

  const onFinish = (formValues: CouponFormFieldValue) => {
    handleFilter(formValues as any);
    closeModal?.();
  };


  const status = [
    {
      label: true,
      name: 'Active',
    },
    {
      label: false,
      name: 'Inactive',
    },
  ];


  const status_type = status.map((res) => ({
    value: res.label,
    label: res.name,
  }));





  return (

    <Form<CouponFormFieldValue>
      id={formId}
      name={formId}
      className='filters-form'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper style={{ width: '100%' }}>
        <StandardItemsWrapper style={{ width: '100%' }}>
          {/* <Form.Item<ShopItemFormFieldValue>
            label={t('shops.formfield.itemnamecategory.label')}
            name={ShopItemFormField.CATEGORY}
          >
            <Select
              options={Category}
              placeholder={"Please Select Category"}
              dropdownMatchSelectWidth={200}
              className='filter'
              allowClear
            />
          </Form.Item> */}
          <div className='invoice_list' style={{ width: '100%' }}>
            <Form.Item<CouponFormFieldValue>
              label="Coupon Title"
              name={CouponFilterFormField.COUPONTITLE}
            >
              <Input placeholder='Enter Coupon Title' />
            </Form.Item>
            <Form.Item<CouponFormFieldValue>
              label="Coupon code"
              name={CouponFilterFormField.COUPONCODE}
            >
              <Input placeholder='Enter Coupon code' />
            </Form.Item>
            <Form.Item<ShopItemFormFieldValue>
              label={'Coupon Status'}
              name={ShopItemFormField.is_active}
            >
              <Select
                options={status_type}
                placeholder={"Select Status"}
                dropdownMatchSelectWidth={200}
                className='filter'
                allowClear
              />
            </Form.Item>
            {screens.lg && (
              <ContentFilters.ButtonsWrapper className='filter_btn'>
                <Button type='primary' htmlType='submit'>
                  {t('common.buttons.filter', { count: 1 })}
                </Button>
                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
              </ContentFilters.ButtonsWrapper>
            )}
          </div>

        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

    </Form>
  );
};
