import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { CreateEventFormDTO, EventsApiService } from 'features/events';
import { queryClient } from 'providers';

import { eventsQueryKeys } from './query-keys';

const { createRegistrationForm } = EventsApiService();

export const useCreateRegistrationForm = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: CreateEventFormDTO) => {
            return createRegistrationForm(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
                message.success("Form Created Successfully");
            },
        },
    );
};
