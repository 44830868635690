import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import { useCreateTshirtSize } from 'features/cms/use-cases/create-tshirt-size';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { shortOrderVale, TshirtFormField, TshirtFormValues } from './types';
export const AddTshirtSizeModal = ({ onClose, refetch, tshirtEditData }: { onClose: () => void; showTshirtModal: boolean, refetch: () => void, tshirtEditData: any }) => {
    const { id } = useParams();
    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<TshirtFormValues>();
    const { t } = useTranslation();
    const { isLoading, mutate } = useCreateTshirtSize();

    const getInitialValues = {
        [TshirtFormField.Id]: id,
        [TshirtFormField.Title]: undefined,
        [TshirtFormField.VendorPartNo]: undefined,
        [TshirtFormField.IsActive]: undefined,
        [TshirtFormField.ShortOrder]: undefined,

    };


    const handleUpdate = (formData: TshirtFormValues) => {
        formData[TshirtFormField.Id] = tshirtEditData?.id;
        formData[TshirtFormField.IsActive] = true;
        formData[TshirtFormField.ShortOrder] = +formData?.sort_order;
        mutate(formData, { onSuccess: hanldeSuccess });
        form.resetFields()
    };

    const hanldeSuccess = () => {
        onClose();
        refetch();
    }

    useEffect(() => {
        if (tshirtEditData) {
            form.setFieldsValue(tshirtEditData);
        } else {
            form.resetFields();
        }
    }, [tshirtEditData])

    return (

        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={onClose} className='close-btn'>
                    &times;
                </button>
                <h4>{tshirtEditData ? "Edit T-shirt Size" : "Add T-shirt Size"}</h4>
            </div>
            <div className='modal_body'>
                <Form<TshirtFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <FormSectionsSeparator title={""}>
                        <Form.Item<TshirtFormField>
                            label={"Title"}
                            name={TshirtFormField.Title}

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Title"}
                                prefix={<UserOutlined className='input-prefix-icon' />}
                            />
                        </Form.Item>

                        <Form.Item<TshirtFormField>
                            label={"Vendor Part No"}
                            name={TshirtFormField.VendorPartNo}

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Vendor Part No"}
                                prefix={<UserOutlined className='input-prefix-icon' />}
                            />
                        </Form.Item>
                        <Form.Item<TshirtFormField> label={"Sort Order"} name={TshirtFormField.ShortOrder}>
                            <Select
                                placeholder="Please select"
                                style={{ width: "100%" }}
                                options={shortOrderVale.map((res: string) => ({
                                    label: res,
                                    value: res
                                }))}
                            />
                        </Form.Item>
                    </FormSectionsSeparator>
                </Form>
                <div className='button-group'>
                    <Button htmlType='submit' onClick={form.submit} loading={isLoading} className='btn'>Save</Button>
                </div>

            </div>
        </div>

    )
};

