import { App } from 'antd';
import { UserApiService } from 'features/users';
import i18n from 'locales/i18n';
import { useState } from 'react';


const { uploadUserProfileImage } = UserApiService();




export const useUploadUserProfile = () => {

    const { message } = App.useApp();
    const [loading, setLoading] = useState<boolean>(false);

    const uploadImage = async (formData: FormData, onSuccess: (profileImage: string) => void) => {
        setLoading(true);
        try {
            const data = await uploadUserProfileImage(formData);

            onSuccess(data.profile_picture);

            message.success(data.response_message);
        } catch (error) {
            message.warning(i18n.t('fields.update.errorImageUpload'));
        } finally {
            setLoading(false);
        }
    };

    return {
        uploadImage,
        loading

    };
};
