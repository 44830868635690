import { Button, Form } from 'antd';
import { UpdateUserDTO } from 'features/users/api/user-api.service';
import { UserInfoFormItems } from 'features/users/components/modals/user-info-form-items';
import {
    UserFormField,
    UserFormValues,
} from 'features/users/components/users-filter-form';
import { useCreateUser, useUpdateUser } from 'features/users/use-cases';
import { useEffect, useState } from 'react';

import { PasswordFormItems } from './password-form-items';

export const AddAppUserModal = ({ onClose, userEditData }: { onClose: () => void, userEditData: any }) => {
    const formName = 'add-user-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<UserFormValues>();

    const { isLoading, mutate } = useCreateUser();

    const handleClose = () => {
        onClose();
        form.resetFields();
    }

    useEffect(() => {

        if (userEditData) {
            form.setFieldsValue(userEditData);
        } else {
            form.resetFields();
        }

    }, [userEditData, form]);


    const getInitialValues = {
        [UserFormField.FirstName]: undefined,
        [UserFormField.LastName]: undefined,
        [UserFormField.PhoneNumber]: undefined,
        [UserFormField.Company]: undefined,
        [UserFormField.Role]: undefined,
        [UserFormField.Email]: undefined,
        [UserFormField.Gender]: undefined,
    };

    const { mutate: mutateUpdateUser } =
        useUpdateUser();

    const handleUserUpdate = (formData: any) => {

        const payload: UpdateUserDTO = {
            id: formData.id,
            company_id: formData.company_id,
            email: formData.email.length ? formData.email : null,
            first_name: formData.first_name,
            gender: formData.gender,
            last_name: formData.last_name,
            password: formData.password,
            phone_number: formData.phone_number,
            role: formData.role,
            is_active: formData.is_active,
            address: formData.address
        };

        mutateUpdateUser(payload, { onSuccess });
    };

    const handleUpdate = (formData: UserFormValues) => {
        formData.role = 'User';
        if (userEditData) {
            formData.id = userEditData.id;
            handleUserUpdate(formData);
        } else {
            mutate(formData, { onSuccess: handleClose });
        }
    };

    const onSuccess = () => {
        handleClose();
    };

    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{userEditData ? 'Edit Customer' : 'Add Customer'}</h4>
                </div>
                <div className='modal_body'>
                    <Form<UserFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <UserInfoFormItems />
                        {!userEditData ? (<PasswordFormItems />) : ''}

                        <div className='form-button'>
                            <Button type="primary" htmlType='submit' className="btn" >Save</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

