import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Grid, Image, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
  EventsResponse,
  // useUpdateEvent,
} from 'features/events';
import { useStatusResponse } from 'features/shop-item/use-cases/update-status-response-msg';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';
import { Event } from 'types/event';

import { ManageFilterFormPaginated } from '../../manage-partners-filter-form';
import { AddPartnersModal } from '../add-partners-modal/add-partners-modal';
const { useBreakpoint } = Grid;

type EventsTableProps = {
  isLoading: boolean;
  data?: EventsResponse;
  tableParams: ManageFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<ManageFilterFormPaginated>>;
  refetch: () => void;
  partnersEditData: any;
  setPartnersEditData: any;
};

export const PartnersTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
  refetch,
  partnersEditData,
  setPartnersEditData
}: EventsTableProps) => {

  const screens = useBreakpoint();
  // const { message } = App.useApp();
  const permissions = usePermissions();
  // const [editingId, setEditingId] = useState('');
  const { confirm } = Modal;
  const { mutate: mutateUpdateItem, isLoading: isLoadingUpdate } =
    useStatusResponse();

  const handleTableChange = (
    pagination: TablePaginationConfig,
    // filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Event> | SorterResult<Event>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'
          : '-name',
    });
  };
  const handleDelete = async (id: any) => {

    mutateUpdateItem({
      id: id,
      flag: "PARTNERS_DELETE",
      status: true
    });
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: 'Are you sure you want to delete this Manage Partners?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(record.id);
      },
    });
  };

  const [showPartnersModal, setShowPartnersModal] = useState(false);
  if (!permissions) return null;
  const handleCloseModal = () => {
    setShowPartnersModal(false);
    setPartnersEditData(undefined);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = (record: any) => {
    setShowPartnersModal(true);
    setPartnersEditData(record);
    document.body.style.overflow = 'hidden';
  }


  return (
    <>
      <div className={`cstmrightmdl ${showPartnersModal && "show"}`}>

        <AddPartnersModal showPartnersModal={showPartnersModal} refetch={refetch} onClose={handleCloseModal} partnersEditData={partnersEditData} />
      </div>
      <StyledTableWrapper>
        <StyledTableHeader>
          <RecordCountBadge
            text={"Partner found"}
            count={data?.total || 0}
          />
        </StyledTableHeader>
        <Table<Event>
          className="cstltblwthldr"
          locale={{
            emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
          }}
          loading={isLoading && { indicator: <div><TableLoader /></div> }}
          dataSource={isLoading ? [] : data?.items}
          rowKey='id'
          onChange={handleTableChange}
          pagination={{
            current: tableParams.page,
            defaultPageSize: tableParams.size,
            total: data?.total,
          }}
        // scroll={tableWidth}
        >

          <Column<Event>
            title={'Link'}
            dataIndex={'link'}
          // render={(record) => (
          //   <>
          //     <RightOutlined />
          //     <span style={{ marginLeft: 8 }}>{record.link}</span>

          //   </>
          // )}

          // onCell={(record: any) => ({
          //   onClick: () => {
          //     handleOpenModal(record);
          //   },
          // })}
          />


          {/* <Column<any>
            title={'Order'}
            dataIndex={'order'}
            width={screens.xxl ? 'auto' : 120}

          /> */}

          <Column<any>
            title={'Logo'}
            dataIndex={'logo'}
            width={screens.xxl ? 'auto' : 120}
            render={(_, record) => (
              <Image
                width={80}
                src={record.image_url}
              // loading={isLoadingUpdateEvent}
              />
            )}
          />

          <Column<Event>
            title={"Action"}
            // width={screens.xxl ? 'auto' : 162}
            // dataIndex={'is_active'}
            render={(_, record) => (
              <>
                <Space>
                  <Tooltip title="Edit">
                    <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                  </Tooltip>

                  {/* <Tooltip title="copy">
                    <Button shape="circle" icon={<CopyOutlined />} />
                  </Tooltip> */}

                  <Tooltip title="Delete">
                    <Button shape="circle" loading={isLoadingUpdate} onClick={() => showDeleteConfirm(record)} icon={<DeleteOutlined />} />
                  </Tooltip>
                  <Tooltip title="Is Active">
                    <Switch
                      defaultChecked={record.is_active}
                      checked={record.is_active}
                      loading={isLoadingUpdate}
                      onChange={(isChecked) =>
                        mutateUpdateItem({
                          id: record.id,
                          flag: "PARTNERS_SET_STATUS",
                          status: isChecked,
                        })
                      }
                      onClick={(checked, event) => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    />
                  </Tooltip>
                </Space>
              </>
            )}
          />
        </Table>
      </StyledTableWrapper>
    </>

  );
};
