import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import {
    MainFilters,
    MainFiltersParams,
} from 'components/main-filters/main-filters';
import { usePermissions } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { AddDiscoverBannerModal } from '../components/discover-banner/add-discover-banner-modal/add-discover-banner-modal';
import { DiscoverBannerTable } from '../components/discover-banner/discover-banner-table/discover-banner-table';
import { DiscoverFilterForm, DiscoverFilterFormPaginated, DiscoverFilterFormValues } from '../components/discover-filter-form';
import { useGetDsicoverBanner } from '../use-cases/get-discover-banner';

const { useBreakpoint } = Grid;

const initialFilterValues: DiscoverFilterFormValues = {
    title: undefined,
    is_active: undefined
};

export const DiscoverBannerView = () => {
    const screens = useBreakpoint();
    const permissions = usePermissions();
    const { params, updateParams, resetPage } =
        usePaginationParams<DiscoverFilterFormPaginated>({
            page: 1,
            size: 10,
            order_by: '-rank',
            ...initialFilterValues,
        });

    const { data, isFetching, refetch } = useGetDsicoverBanner(params as any, { retry: false });
    const [bannerEditData, setBannerEditData] = useState<any>(undefined);
    const shouldHandleFieldCreation = permissions?.fields.add;
    // console.log(partnersEditData, "partnersEditData122");
    const handleFiltersChange = (values: DiscoverFilterFormValues) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    const handleMainFiltersChange = (mainParams: MainFiltersParams) => {
        updateParams(mainParams);
        resetPage();
    };
    const [showBannerModal, setShowBannerModal] = useState(false);
    const formId = 'events-filters-form';

    const handleCloseModal = () => {
        setShowBannerModal(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = () => {
        setShowBannerModal(true);
        document.body.style.overflow = 'hidden';
    }

    return (
        <>
            <ContentTopBar
                title={"Discover Banner"}
                renderHeadingContent={
                    <MainFilters onChange={handleMainFiltersChange} />
                }
                renderButtons={
                    <>
                        {shouldHandleFieldCreation ? (
                            <>
                                <Button
                                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                                    onClick={handleOpenModal}
                                    type='primary'
                                >
                                    {"Add Banner"}
                                </Button>
                                <div className={`cstmrightmdl ${showBannerModal && "show"}`}>
                                    <AddDiscoverBannerModal showBannerModal={showBannerModal} refetch={refetch} onClose={handleCloseModal} bannerEditData={bannerEditData} />
                                </div>

                            </>
                        ) : null}
                    </>
                }
            />

            <ContentFilters formId={formId}>
                <DiscoverFilterForm
                    formId={formId}
                    handleFilter={handleFiltersChange}
                    initialValues={initialFilterValues}
                />
            </ContentFilters>

            <DiscoverBannerTable
                data={data}
                isLoading={isFetching}
                tableParams={params as any}
                updateParams={updateParams}
                refetch={refetch}
                bannerEditData={bannerEditData}
                setBannerEditData={setBannerEditData}
            />
            <Outlet />
        </>
    );
};



