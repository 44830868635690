import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import {
    MainFilters,
    MainFiltersParams,
} from 'components/main-filters/main-filters';
import { usePermissions } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { ColorFilterForm } from '../components/color-filter-form/color-filter-form';
import { ColorTabel } from '../components/color-management';
import { AddColorModal } from '../components/color-management/Add-color-madal/add-color-modal';
import { ColorFilterFormPaginated, ColorFilterFormValues } from '../components/type';
import { useGetColor } from '../use-cases/get-color';

const { useBreakpoint } = Grid;

const initialFilterValues: ColorFilterFormValues = {
    type: undefined,
};

export const ColorManagementView = () => {
    const screens = useBreakpoint();
    const permissions = usePermissions();
    const { params, updateParams, resetPage } =
        usePaginationParams<ColorFilterFormPaginated>({
            page: 1,
            size: 10,
            order_by: '-rank',
            ...initialFilterValues,
        });

    const { data, isFetching, refetch } = useGetColor(params, { retry: false });
    const [colorEditData, setColorEditData] = useState<any>(undefined);
    const [showColorModal, setShowColorModal] = useState(false);

    const shouldHandleFieldCreation = permissions?.fields.add;

    const handleFiltersChange = (values: ColorFilterFormValues) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    const handleMainFiltersChange = (mainParams: MainFiltersParams) => {
        updateParams(mainParams);
        resetPage();
    };
    const formId = 'events-filters-form';

    const handleCloseModal = () => {
        setShowColorModal(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = () => {
        setShowColorModal(true);
        document.body.style.overflow = 'hidden';
    }

    return (
        <>
            <ContentTopBar
                title={"Color Management"}
                renderHeadingContent={
                    <MainFilters onChange={handleMainFiltersChange} />
                }
                renderButtons={
                    <>
                        {shouldHandleFieldCreation ? (
                            <>
                                <Button
                                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                                    type='primary'
                                    onClick={handleOpenModal}
                                >
                                    {"Add Color"}
                                </Button>
                                <div className={`cstmrightmdl ${showColorModal && "show"}`}>
                                    <AddColorModal showColorModal={showColorModal} refetch={refetch} onClose={handleCloseModal} colorEditData={colorEditData} />
                                </div>

                            </>
                        ) : null}
                    </>
                }
            />

            <ContentFilters formId={formId}>
                <ColorFilterForm
                    formId={formId}
                    handleFilter={handleFiltersChange}
                    initialValues={initialFilterValues}
                />
            </ContentFilters>

            <ColorTabel
                data={data}
                isLoading={isFetching}
                tableParams={params}
                updateParams={updateParams}
                refetch={refetch}
                colorEditData={colorEditData}
                setColorEditData={setColorEditData}
            />
            <Outlet />
        </>
    );
};


