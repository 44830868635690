import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { useState } from 'react';
import { CompanyType } from 'types';

import { AddWalletModal } from '../add-wallet-modal/add-wallet-modal';
import { WalletFilterFormValues } from '../wallet-filter-form/types';
import { WalletFilterForm } from '../wallet-filter-form/wallet-filter-form';

type WalletsTopBarProps = {
  getInitialFilterValues: WalletFilterFormValues;
  handleFilter: (values: WalletFilterFormValues) => void;
};

const { useBreakpoint } = Grid;

export const WalletListTopBar = ({
  getInitialFilterValues,
  handleFilter,
}: WalletsTopBarProps) => {
  useState<CompanyType>();

  const formId = 'Wallet-filters-form';
  const screens = useBreakpoint();
  const [showModal, setShowModal] = useState(false);


  const handleCloseModal = () => {
    setShowModal(false);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = () => {
    setShowModal(true);
    document.body.style.overflow = 'hidden';
  }

  return (

    <>

      <div className={`cstmrightmdl ${showModal && "show"}`}>
        <AddWalletModal onClose={handleCloseModal} isOpen={showModal} />
      </div>

      <ContentTopBar
        title={'Wallet Transactions List'}
        renderButtons={
          <Button
            icon={!screens.xs ? <PlusOutlined /> : undefined}
            onClick={handleOpenModal}
            type='primary'
          >
            Add Wallet
          </Button>
        }
      />
      <ContentFilters formId={formId}>
        <WalletFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
