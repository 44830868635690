import { Tabs } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";

import ExpoRegistrationForm from "../../event-details-form/expo-booth-registration-form/custom-for-content";
import ExpoBoothSvgLayoutTab from "./expo-booth-layout-tab/expo-booth-svg-layout/expo-booth-svg-layout-tab";



const ExpoBoothTabs = () => {

    const { id } = useParams();
    const [tabs, setTabs] = useState<any>("1")


    const handleTabChange = (activeKey: any) => {
        setTabs(activeKey)
    };

    if (!id) return null;

    const tabItems = [
        {
            key: "1",
            label: "Expo Booth Registration Form",
            children: <ExpoRegistrationForm id={id} tabs={tabs} />,
        },
        {
            key: "2",
            label: "Expo Booth Layout",
            children: <ExpoBoothSvgLayoutTab id={id} tabs={tabs} />,
        }
    ];

    return (

        <>
            <Tabs
                className="gallery_tab"
                defaultActiveKey="1"
                onChange={handleTabChange}
                items={tabItems}
            />
        </>
    )
}

export default ExpoBoothTabs;

