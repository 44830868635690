import {
    DownOutlined,
    IdcardOutlined
} from '@ant-design/icons';
import { Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Select } from 'components';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useTranslation } from 'react-i18next';

import { CampaignCodeFormField } from './types';

export const AddCampaignCodeForm = () => {
    const { t } = useTranslation();

    const provider = [
        { value: "facebook", label: 'FaceBook' },
        { value: "instagram", label: 'instagram' },
        { value: "google", label: 'Google' },
        { value: "twitter", label: 'Twitter' },
    ];




    return (
        <FormSectionsSeparator title={""}>

            <Form.Item<CampaignCodeFormField>
                label={"Select Provider"}
                name={CampaignCodeFormField.Provider}
                rules={[
                    {
                        required: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Select
                    options={provider}
                    placeholder={"Select Registration"}
                    className='has-prefix-icon'
                    suffixIcon={
                        <>
                            <IdcardOutlined className='prefix-icon' />
                            <DownOutlined className='suffix-icon' />
                        </>
                    }
                />
            </Form.Item>

            <Form.Item<CampaignCodeFormField>
                label={"Code"}
                name={CampaignCodeFormField.Code}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                    },
                ]}
            >
                <TextArea
                    placeholder={"Enter Code Code"}
                />
            </Form.Item>


        </FormSectionsSeparator>
    );
};
