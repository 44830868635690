export const formateAmount = (val: number) => {
    if (typeof val === "number") {
        return val.toFixed(3)
    }
}

export const formatDateTime = (dateTimeString: string) => {
    if (!dateTimeString) {
        return ''; // Return an empty string if no date-time string is provided
    }

    const dateTime = new Date(dateTimeString);
    if (isNaN(dateTime.getTime())) {
        return ''; // Return an empty string if the date-time is invalid
    }

    // Extract date components
    const day = dateTime.getDate().toString().padStart(2, '0');
    const month = (dateTime.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = dateTime.getFullYear().toString();

    // Extract time components
    let hours = dateTime.getHours();
    const amPM = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');

    // Construct formatted date-time string
    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${amPM}`;

    return formattedDateTime;
};


export const formatDateDisplay = (dateTimeString: string | number | Date) => {
    if (dateTimeString) {
        const date = new Date(dateTimeString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear().toString();

        return `${day}/${month}/${year}`;
    }

};