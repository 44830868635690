// import { ConfigProvider } from 'antd';
// import { usePermissions } from 'features/users';
import i18n from 'locales/i18n';
import { useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
// import { colors } from 'styles';
import { CompanyType, Option } from 'types';

// import { useMainFiltersContext } from './main-filters.context';
// import { StyledMinimalSelect } from './main-filters-select.styles';

export type MainFiltersParams = {
  company_type?: CompanyType;
  company_id?: string;
  facility_id?: string;
};

export type MainFiltersOptions = {
  company_type?: Option[];
  company_id?: Option[];
  facility_id?: Option[];
};

export type FilterTypes = 'companyType' | 'company' | 'facility';

export type OnOptionsChange = ({
  company_type,
  company_id,
  facility_id,
}: MainFiltersOptions) => void;

export type MainFiltersProps = {
  onChange: ({
    company_type,
    company_id,
    facility_id,
  }: MainFiltersParams) => void;
  onOptionsChange?: OnOptionsChange;
  filterTypes?: FilterTypes[];
};

const clientTypeOptions = [
  { value: '', label: i18n.t('bookings.allClientTypes') } as const,
  { value: CompanyType.Li3ibClient, label: 'Suffix' } as const,
  {
    value: CompanyType.SuffixManagement,
    label: 'Suffix Management',
  } as const,
];

export const MainFilters = ({
  // onChange,
  onOptionsChange,
  // filterTypes = ['companyType', 'company', 'facility'],
}: MainFiltersProps) => {
  // const permissions = usePermissions();
  // const { t } = useTranslation();

  // const {
  //   params: [filters, setFilters],
  // } = useMainFiltersContext();
  // const { user } = useUserContext();

  // const shouldHandleCompanyType =
  //   filterTypes.includes('companyType') &&
  //   Boolean(permissions?.companyType.select);
  // const shouldHandleCompanies =
  //   filterTypes.includes('company') && Boolean(permissions?.company.select);

  // const handleClientTypeChange = (value: CompanyType | '') => {
  //   setFilters(() => {
  //     const newFilters = {
  //       company_type: value || undefined,
  //       company_id: undefined,
  //       facility_id: undefined,
  //     };

  //     onChange(newFilters);

  //     return newFilters;
  //   });
  // };

  // const handleCompanyChange = (value: string | '') => {
  //   setFilters((prevFilters) => {
  //     const newFilters = {
  //       ...prevFilters,
  //       company_id: value || undefined,
  //       facility_id: undefined,
  //     };

  //     onChange(newFilters);

  //     return newFilters;
  //   });
  // };

  // const handleFacilityChange = (value: string | '') => {
  //   setFilters((prevFilters) => {
  //     const newFilters = {
  //       ...prevFilters,
  //       facility_id: value || undefined,
  //     };

  //     onChange(newFilters);

  //     return newFilters;
  //   });
  // };

  useEffect(() => {
    onOptionsChange && onOptionsChange({ company_type: clientTypeOptions });
  }, [onOptionsChange]);

  // useEffect(() => {
  //   return () => {
  //     setFilters({
  //       facility_id: undefined,
  //       company_id: user?.company_id || undefined,
  //       company_type: undefined,
  //     });
  //   };
  // }, [setFilters, user?.company_id]);

  return (
    // <ConfigProvider
    //   theme={{
    //     components: {
    //       Select: {
    //         colorTextPlaceholder: colors.greenDark,
    //         colorText: colors.greenDark,
    //       },
    //     },
    //   }}
    // >
    //   {shouldHandleCompanyType ? (
    //     <StyledMinimalSelect
    //       options={clientTypeOptions}
    //       placeholder={t('bookings.allClientTypes')}
    //       onChange={handleClientTypeChange}
    //       value={filters.company_type}
    //       bordered={false}
    //       popupMatchSelectWidth={200}
    //       className='filter_day_v'
    //     />
    //   ) : null}


    // </ConfigProvider>'
    null
  );
};
