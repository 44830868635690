import { Button, Form, Input, Select } from 'antd';
import { useCreateShopItemImage } from 'features/shop-item/use-cases/create-shop-image';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ImageFormField, ImageFormValues, SizeFormField } from './type';
export const AddItemImageModal = ({ onClose, dataSource, refetch }: { onClose: () => void; showAddItemModal: boolean, dataSource: any, refetch: any }) => {
    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [isUpdating, setIsUpdating] = useState(false);
    const [form] = Form.useForm<ImageFormValues>();
    const { id } = useParams();

    const { t } = useTranslation();
    const [uploadImages, setUploadImages] = useState<{ file: File, dataURL: string }[]>([]);

    const [error, setError] = useState<any[]>([]);

    const { isLoading, mutate } = useCreateShopItemImage();


    const handleUpdate = (formData: ImageFormValues) => {
        if (isUpdating) return; // If already updating, prevent further updates
        setIsUpdating(true); // Set updating state to true
        formData[ImageFormField.shop_item_id] = id;
        formData[ImageFormField.File] = uploadImages[0]?.file;
        formData[ImageFormField.color_id] = formData.color_id;
        formData[ImageFormField.is_featured] = true;
        mutate(formData, {
            onSuccess: () => {
                handleClose();
                setIsUpdating(false); // Reset updating state after successful update
                refetch()
            }
        });
        setIsUpdating(false); // Reset updating state if there's an error
    };

    const handleClose = () => {
        form.resetFields();
        setUploadImages([]);
        onClose();
    }


    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const selectedFiles = e.target.files;
        const defaultWidth = 600;
        const defaultHeight = 545;
        // Check if files are selected
        if (selectedFiles && selectedFiles.length > 0) {
            const newImages: { file: File, dataURL: string }[] = [];
            const errors: string[] = [];

            Array.from(selectedFiles).forEach((file) => {
                // Check if file is an image
                if (file.type.startsWith('image/')) {
                    const reader = new FileReader();

                    reader.readAsDataURL(file);

                    reader.onload = (event) => {
                        const img = new Image();
                        img.src = event?.target?.result as string;

                        img.onload = () => {
                            const { width, height } = img;
                            if (width < defaultWidth || height < defaultHeight) {
                                errors.push(`${file.name} - image is not recommended size.`);
                                form.resetFields([ImageFormField.File])
                            } else {
                                const canvas = document.createElement('canvas');
                                const ctx = canvas.getContext('2d');
                                const newHeight = (width * 90.83) / 100;
                                canvas.width = width;
                                canvas.height = newHeight;
                                ctx?.drawImage(img, 0, 0, width, newHeight);
                                const resizedImgDataUrl = canvas.toDataURL('image/jpeg');
                                const resizedFile = dataURLtoFile(resizedImgDataUrl, file.name);
                                newImages.push({ file: resizedFile, dataURL: resizedImgDataUrl });
                            }

                            if (newImages.length + errors.length === selectedFiles.length) {
                                setError(errors);
                                setUploadImages(newImages); // Add this line
                            }
                        };
                    };
                } else {
                    errors.push(`${file.name} - is not an image file.`);
                    form.resetFields([ImageFormField.File])
                }
            });
        }
    };

    const dataURLtoFile = (dataURL: string, fileName: string) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });
    };



    return (


        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={handleClose} className='close-btn'>
                    &times;
                </button>
                <h4>Add Item Images</h4>
            </div>
            <div className='modal_body'>
                <Form<ImageFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    onFinish={handleUpdate}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                // disabled={isLoading}
                >
                    <FormSectionsSeparator title={""}>
                        <Form.Item<ImageFormField>
                            label={"Color"}
                            name={SizeFormField.color_id}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Select
                                defaultValue="Please select Color"
                                options={dataSource?.map((res: any) => ({ label: res.color_title, value: res.color_id }))}
                            />
                        </Form.Item>
                        {/* <Form.Item
                            label={"Photo"}
                            name={ImageFormField.File}
                        // rules={[
                        //     {
                        //         required: true,
                        //         whitespace: true,
                        //     },
                        // ]}
                        >
                            <Upload fileList={fileList} onChange={handleFileChange} accept=".tiff,.Jfif,.gif,.jpeg,.jpg,.png" maxCount={1} beforeUpload={() => false}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>

                            </Upload>
                        </Form.Item> */}
                        <Form.Item<ImageFormField>
                            label={"Photo"}
                            name={ImageFormField.File}
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >


                            {/* <Upload fileList={fileData} onChange={handleFileChange}  accept="image/jpeg,image/png,image/gif" maxCount={1} beforeUpload={() => false}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload> */}
                            <Input type="file" max={1} onChange={handleFileChange} accept=".png,.jpg,.jpeg" />

                        </Form.Item>
                        <div style={{ marginBottom: "10px" }}>
                            <span>JPEG, PNG formats, minimum size 600x545 </span>
                            {error?.map((item, i) => (
                                <p key={i} style={{ color: "red" }}>{item}</p>
                            ))}
                        </div>
                    </FormSectionsSeparator>
                </Form>
                <div className='button-group'>
                    <Button htmlType='submit' loading={isLoading} onClick={form.submit} className='btn'>Save</Button>
                </div>

            </div>
        </div>

    )
};

