import { Button, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { EventsFilterFormValues } from 'features/events';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TshirtFilterFormField, TshirtFilterFormValues } from './types';

const { useBreakpoint } = Grid;

type EventsFilterFormProps = {
  formId: string;
  handleFilter: (values: TshirtFilterFormValues) => void;
  initialValues: TshirtFilterFormValues;
  closeModal?: () => void;
};

export const TshirtFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: EventsFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<EventsFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
    handleFilter({...initialValues})
  };

  const handleOnFinish = (values: TshirtFilterFormValues) => {
    handleFilter(values);
    closeModal?.();
  };
  const status = [
    {
      label: true,
      name: 'Active',
    },
    {
      label: false,
      name: 'InActive',
    },
  ];
  const status_value = status.map((res) => ({
    value: res.label,
    label: res.name,
  }));

  return (
    <Form<EventsFilterFormValues>
      id={formId}
      name={formId}
      form={form}
      layout='vertical'
      onFinish={handleOnFinish as any}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper style={{width:'100%'}}>
        <StandardItemsWrapper style={{width:'100%'}}>
          <div className='invoice_list' style={{width:'100%'}}>
          <Form.Item<TshirtFilterFormValues>
            label={'Title'}
            name={TshirtFilterFormField.TITLE}
          >
            <Input placeholder={'Enter title'} />
          </Form.Item>
          <Form.Item<TshirtFilterFormValues>
            label={'Status'}
            name={TshirtFilterFormField.ACTIVE}
          >
            <Select
              defaultValue="Select status"
              
              options={status_value}
            />
          </Form.Item>
          {screens.lg && (
        <ContentFilters.ButtonsWrapper className='filter_btn'>
          <Button type='primary' htmlType='submit'>
            {t('events.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{t('events.buttons.reset')}</Button>
        </ContentFilters.ButtonsWrapper>
      )}
          </div>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

      
    </Form>
  );
};
