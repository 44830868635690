import { CloudUploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useUploadUserProfile } from "features/users/use-cases/upload-user-profile";
import { useEffect, useRef, useState } from "react";




export const ProfileImageUpload = ({ id, onClose, gallaryModal, setProfileImage }: { id: string; onClose: () => void; gallaryModal: boolean; setProfileImage: React.Dispatch<React.SetStateAction<string | undefined>>; }) => {
    const { uploadImage: uploadGallaryImage, loading } = useUploadUserProfile();
    const [resizedImgUrl, setResizedImgUrl] = useState<any[]>([]);
    const [fileImages, setFileImages] = useState<{ file: File, dataURL: string }[]>([]);

    const [error, setError] = useState<any[]>([]);

    const handleClose = () => {
        onClose();
        setResizedImgUrl([]);
        setError([])
    }



    const handleSuccess = (profileImg: string | undefined): void => {
        setProfileImage(profileImg);
        handleClose();
    }


    const fileInputRef = useRef<any>();

    const handleClick = () => {
        fileInputRef.current.click();
    };


    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        const defaultWidth = 900;
        const defaultHeight = 515;
        // Check if files are selected
        if (selectedFiles && selectedFiles.length > 0) {
            const newImages: { file: File, dataURL: string }[] = [];
            const errors: string[] = [];

            Array.from(selectedFiles).forEach((file) => {
                // Check if file is an image
                if (file.type.startsWith('image/')) {
                    const reader = new FileReader();

                    reader.readAsDataURL(file);

                    reader.onload = (event) => {
                        const img = new Image();
                        img.src = event?.target?.result as string;

                        img.onload = () => {
                            const { width, height } = img;
                            if (width < defaultWidth || height < defaultHeight) {
                                errors.push(`${file.name} - image is not recommended size.`);
                            } else {
                                const canvas = document.createElement('canvas');
                                const ctx = canvas.getContext('2d');
                                const newHeight = (width * 57.2) / 100;
                                canvas.width = width;
                                canvas.height = newHeight;
                                ctx?.drawImage(img, 0, 0, width, newHeight);
                                const resizedImgDataUrl = canvas.toDataURL('image/jpeg');
                                const resizedFile = dataURLtoFile(resizedImgDataUrl, file.name);
                                newImages.push({ file: resizedFile, dataURL: resizedImgDataUrl });
                            }

                            if (newImages.length + errors.length === selectedFiles.length) {
                                setResizedImgUrl(newImages.map(image => image.dataURL));
                                setError(errors);
                                setFileImages(newImages); // Add this line
                            }
                        };
                    };
                } else {
                    errors.push(`${file.name} - is not an image file.`);
                }
            });
        }
    };

    const dataURLtoFile = (dataURL: string, fileName: string) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });
    };


    const handleUploadImg = async () => {

        const gallaryImageformData = new FormData();
        fileImages.forEach((image) => {
            const { file } = image;
            gallaryImageformData.append(`UserId`, id);
            gallaryImageformData.append(`ProfileImage`, file.name);
            gallaryImageformData.append(`file`, file, file.name);


        });

        await uploadGallaryImage(gallaryImageformData, handleSuccess);


    };


    useEffect(() => {
        setResizedImgUrl([]);
        setError([]);
    }, [gallaryModal])


    return (
        <>
            {gallaryModal ?
                <div className='popup'>
                    <div className='modal_head'>
                        <h4> Upload Profile Images</h4>
                        <button onClick={handleClose} className='close-btn'>
                            &times;
                        </button>

                    </div>
                    <div className='modal_body'>


                        {error &&
                            error.map((item, i) => (
                                <h3 key={i} style={{ color: "red" }}>{item}</h3>
                            ))
                        }

                        <div className="frmscrll">
                            <div className="imgup_wrap">
                                {resizedImgUrl?.map((item: any, i: number) => (
                                    < div className="img_bx" key={i}>
                                        <img key={item} src={item} alt="Resized Image" />

                                    </div>
                                ))}

                            </div>



                            <div className="upld_file_bx">
                                <CloudUploadOutlined />
                                <div className="upld_file">
                                    <label onClick={handleClick}>Choose Files </label>
                                    <span>  No File Chosen</span>
                                    <input
                                        id="fileInput"
                                        ref={fileInputRef}
                                        type="file"
                                        name="name"
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        multiple
                                        onChange={handleFileChange}
                                    />

                                </div>
                                <span>JPEG, PNG formats, minimum size 800x515 </span>

                            </div>

                            <div className="upld_btn">
                                <Button loading={loading} className="save_btn" disabled={resizedImgUrl.length < 1} onClick={handleUploadImg} type="primary"> Save</Button>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
};
