import { PaginatedRequestParams } from 'api';
import { MainFiltersParams } from 'components/main-filters/main-filters';

export enum discoverFilterFormField {
  TITLE = 'title',
  ACTIVE="is_active"
}

export type DiscoverFilterFormValues = {
  [discoverFilterFormField.TITLE]: string | undefined;
  [discoverFilterFormField.ACTIVE]: string | undefined;

};

export type DiscoverFilterFormPaginated = DiscoverFilterFormValues &
  MainFiltersParams &
  PaginatedRequestParams;
