import PropTypes from 'prop-types';
import { Cell, Pie, PieChart,Tooltip,TooltipProps } from 'recharts';


const Gender = ({genderGraphData}:any) => {
    // Adjusted data for male and female

    const data = genderGraphData ;  
    // [
    //     { name: 'Male', value: 400 },
    //     { name: 'Female', value: 300 },
    // ];
   
    // Two colors for male and female
    




    const CustomPieTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
        if (active && payload && payload.length) {
          const total = data.reduce((sum:any, entry:any) => sum + entry.value, 0);
          const percentage = total > 0 ?(payload[0].value!=undefined?((payload[0].value / total) * 100).toFixed(0):0):0;
      
          return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
              <p className="label">{`${payload[0].name} : ${percentage}%`}</p>
              {/* <p className="extra">{`Extra Info: ${payload[0].payload.percentage}`}</p> ${payload[0].value} */}
            </div>
          );
        }
      
        return null;
      };
 
    



    const COLORS = ['#0088FE', '#FF8042'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

        CustomPieTooltip.propTypes = {
        active: PropTypes.bool,
        payload: PropTypes.array,
        };

    return (
        <div style={{ textAlign: 'center' }}>
            <PieChart width={400} height={400}>
                <Pie
                    data={genderGraphData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {genderGraphData.map((entry:any, index:any) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
              
                <Tooltip  content={<CustomPieTooltip />} />
            </PieChart>
            <div style={{ marginTop: 20, display: 'flex' }}>
                <div style={{ display: 'inline-block', marginRight: 30 }}>
                    <div style={{ backgroundColor: COLORS[1], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span>Male</span>
                </div>
                <div style={{ display: 'inline-block' }}>
                    <div style={{ backgroundColor: COLORS[0], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span>Female</span>
                </div>
            </div>
        </div>
    );
};

export default Gender;
