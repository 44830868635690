export enum RacekitFormField {
    Id = 'id',
    Title = 'title',
    File = 'file',
    IsActive = 'is_active',
    ImageUrl = "image_url"
}

export type Racekit = {
    Id: string | undefined;
    Title: string;
    isActive: boolean;
    File: any;
    image_url: any;
};

export type RacekitFormValues = {
    [RacekitFormField.Id]: string | undefined;
    [RacekitFormField.Title]: string;
    [RacekitFormField.File]: any;
    [RacekitFormField.IsActive]: boolean;
    [RacekitFormField.ImageUrl]: string;
};