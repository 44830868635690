import { UploadOutlined } from '@ant-design/icons';
import { Card, Checkbox, Form, Grid, } from 'antd';
import { Button } from 'components';
import { ProfileImageUpload } from 'features/users/components/modals/profile_image_upload_modal';
import { UpdateAdminUserModal } from 'features/users/components/modals/update-admin-user-modal';
import { useGetUser, useUpdateUserRole } from 'features/users/use-cases';
import { useEffect, useState } from 'react';
import { CompanyType } from 'types';


// const { Title, Paragraph, Text, Link } = Typography;

const { useBreakpoint } = Grid;

type UsersTopBarProps = {
  id: string;
};

// interface Roles {
//     id:number,
//     title: string;

//   }

interface userMenu {
  id: string;
  menu: string;
  isPermission: boolean;
}



//   type DataItem = {
//     [key: string]: boolean;
// };

// type updateProfileImgResponse = {
//   profile_picture : string ;
// }

const initialValue = '/static/media/No_Image_Available.e1ce751ef1b590f719f9.jpg';

export const AdminDetailTopBar = ({
  id
}: UsersTopBarProps) => {
  useState<CompanyType>();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [profileModal, setProfileModal] = useState<boolean>(false);
  const [userMenu, setUserMenu] = useState<userMenu[] | undefined>([]);
  const [profileImage, setProfileImage] = useState<string | undefined>(undefined);

  const onCheckboxChange = (ids: string) => {

    const data = userMenu?.map((value) => {
      if (value.id === ids) {
        value.isPermission = !value.isPermission;
      }

      return value;
    })

    setUserMenu(data);
  };


  const screens = useBreakpoint();
  // const formId = 'users-filters-form';

  const handleCloseModal = () => {
    setShowAddUserModal(false);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = () => {
    setShowAddUserModal(true);
    document.body.style.overflow = 'hidden';
  }

  const handleOpenProfileModal = () => {
    setProfileModal(true);
    document.body.style.overflow = 'hidden';
  }

  const handleCloseProfileModal = () => {
    setProfileModal(false);
    document.body.style.overflow = 'visible';
  }

  const userQueryResult = useGetUser(id, {
    cacheTime: 0, //workaround to invalidate initialValues of client data form
  });

  const { mutate: mutateUpdateUser } =
    useUpdateUserRole();



  useEffect(() => {
    const menuData = userQueryResult.data?.Menus;
    setUserMenu(menuData);

  }, [userQueryResult]);

  // isLoading: isLoadingGetUser,
  // isFetching: isFetchingGetUser,
  //isError: isErrorUser,
  const { data: userData } = userQueryResult;


  const handleSubmit = () => {

    const menuData: string[] = [];
    userMenu?.map((value) => {
      if (value.isPermission) {
        menuData.push(value.id);
      }
    })

    const payload = { "user_id": userQueryResult.data?.id, "MenuIds": menuData };
    mutateUpdateUser(payload);
    //console.log('payload'+JSON.stringify(payload));
  }



  return (
    <>
      {showAddUserModal ? (
        <div className={`cstmrightmdl ${showAddUserModal && "show"}`}>
          <UpdateAdminUserModal id={id} onClose={handleCloseModal} onSuccess={handleCloseModal} />
        </div>
      ) : ''}

      <div className={`cstmrightmdl upld_modl ${profileModal && "show"}`}>
        <ProfileImageUpload id={id} onClose={handleCloseProfileModal} gallaryModal={profileModal} setProfileImage={setProfileImage} />
      </div>

      <Card className='prfle_crd' title="My Profile" extra={
        <Button
          // icon={!screens.xs ? <PlusOutlined /> : undefined}
          onClick={handleOpenModal}>
          Edit
          {/* {t('adminUsers.addNewAgent')} */}
        </Button>}  >

        <div className='profile_data'>
          <div className='prf_img'>
            <div className='prf_img_bx'>
              <img src={profileImage === undefined ? ((userData?.profile_picture === "") ? initialValue : (userData?.profile_picture)) : profileImage} alt="" />
            </div>
            <Button icon={!screens.xs ? <UploadOutlined /> : undefined} className='btn tpmrng' onClick={handleOpenProfileModal}>
              {"Upload Profile Image"}
            </Button>


            {/* <div className="upld_file">
                                    <label >Choose Files </label>
                                    <span>  No File Chosen</span>
                                    <input
                                        id="fileInput"
                                        type="file"
                                        name="name"
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        multiple
                                       
                                    />

                                </div> */}
          </div>
          <div className='user_d'>
            <p>First Name : <span>{userData?.first_name}</span></p>
            <p>Last Name : <span>{userData?.last_name}</span></p>
            <p>Mobile No : <span>{userData?.phone_number}</span></p>
            <p>Email : <span>{userData?.email}</span></p>
            <p>Address : <span>{userData?.address}</span></p>
          </div>

        </div>

      </Card>


      <Card title="Roles" className='role_card'>

        <Form
          name={'user_permission'}
          layout="horizontal"
          onFinish={handleSubmit}>
          <div className='role_list'>


            {/* <Form.Item label=""    name={'checkboxes'} valuePropName="checked" >
     <Checkbox.Group defaultValue={preSelectedMenu}> */}
            {userMenu?.map(option => (
              <Checkbox
                key={option.id}
                checked={option.isPermission}
                onChange={() => onCheckboxChange(option.id)}
              >
                {option.menu}
              </Checkbox>
            ))}
            {/* </Checkbox.Group>
     <Checkbox
                                                className="ssb_in"
                                                name={item.setting_name}
                                                checked={item.is_active}
                                                onChange={() => handleCheckboxChange(item)}
                                            >
                                                {item.setting_name}
                                            </Checkbox>
   </Form.Item> */}
          </div>



          <div className='role_btn'>
            <Button type="primary" htmlType="submit">
              Assign
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};
