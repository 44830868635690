import { Button, Form } from 'antd';
import { useCreateMarketingCode } from 'features/events/use-cases/create-marketing-code';
import { useEffect, useState } from 'react';

import { AddMarketingCodeForm } from './add-marketing-code-form';
import { MarketingCodeFormField, MarketingCodeFormValues } from './types';



export const AddMarketingCodeModal = ({ eventid, onClose, showMarketingModal, marketData }: { eventid: string; onClose: () => void; showMarketingModal: boolean; marketData: any, refetch: () => void }) => {
    const formName = 'add-Mamarketing-code-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<MarketingCodeFormValues>();
    const { isLoading, mutate } = useCreateMarketingCode(marketData?.id);

    const handleClose = () => {
        onClose();
        form.resetFields();
    }

    const getInitialValues = {
        [MarketingCodeFormField.Id]: undefined,
        [MarketingCodeFormField.EventId]: undefined,
        [MarketingCodeFormField.Provider]: undefined,
        [MarketingCodeFormField.BodyCode]: undefined,
        [MarketingCodeFormField.InvoiceCode]: undefined,
        [MarketingCodeFormField.HeaderCode]: undefined,


    };


    const handleUpdate = (formData: MarketingCodeFormValues) => {
        formData[MarketingCodeFormField.Id] = marketData?.id;
        formData[MarketingCodeFormField.EventId] = eventid;
        mutate(formData, { onSuccess: handleSuccess });
    };

    const handleSuccess = () => {
        handleClose();
        // refetch();
    }

    useEffect(() => {
        if (marketData) {
            form.setFieldsValue({
                [MarketingCodeFormField.Provider]: marketData.provider,
                [MarketingCodeFormField.BodyCode]: marketData.body_code,
                [MarketingCodeFormField.InvoiceCode]: marketData.invoice_code,
                [MarketingCodeFormField.HeaderCode]: marketData.header_code,
            });
        } else {
            form.resetFields();
        }
    }, [marketData, showMarketingModal]);

    return (

        <div className='popup'>
            <div className='modal_head'>
                <h4>Add Marketing Code</h4>
                <button onClick={handleClose} className='close-btn'>
                    &times;
                </button>

            </div>
            <div className='modal_body'>

                <Form<MarketingCodeFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    // requiredMark={'optional'}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <div className='frmscrll'>
                        <AddMarketingCodeForm />
                    </div>
                    <div className='form-button'><Button type="primary" htmlType='submit' className="btn" >Save</Button></div>

                </Form>

            </div>
        </div>

    )
};
