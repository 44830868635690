import { ContentTopBar } from 'components';
import { useGetBibAlocation } from 'features/events/use-cases/get-bib-allocation';
import { usePaginationParams } from 'hooks';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import { EventsFilterFormValues } from '../../events-filter-form';
import { BibAllocationTable } from './bib-allocation-table';


const BibAllocation = ({ tabs }: { tabs: string }) => {
    const { id } = useParams();

    const initialFilterValues: EventsFilterFormValues = {
        field_name: undefined,
    };

    const { params, updateParams } =
        usePaginationParams<any>({
            event_id: id,
            page: 1,
            size: 10,
            order_by: '',
            ...initialFilterValues,
        });

    const { data, isFetching, refetch } = useGetBibAlocation(params);

    const [organizerData, setOrganizerData] = useState<any>("");

    useEffect(() => {
        if (tabs === "4") {
            refetch();
        }
    }, [tabs])

    return (

        <>

            <ContentTopBar
                title={""}
            />

            <BibAllocationTable
                data={data}
                isLoading={isFetching}
                tableParams={params}
                updateParams={updateParams}
                organizerData={organizerData}
                setOrganizerData={setOrganizerData}
                refetch={refetch}
            />

        </>
    )
}

export default BibAllocation;