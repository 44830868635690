import { downloadVendingReport } from 'features/vending-machine';
import { VendingMachineTopBar } from 'features/vending-machine/components/top-bar/vending-machine-top-bar';
import {
  VendingFilterFormValues,
  VendingFilterFormValuesPaginated,
} from 'features/vending-machine/components/vending-machine-filter-form/types';
import { VendingMachineTable } from 'features/vending-machine/components/vending-machine-table/vending-table';
import { useGetVendingReportList } from 'features/vending-machine/use-cases/get_vending_machine_report';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

const getInitialFilterValues: VendingFilterFormValues = {
  participant_id: undefined,
  category_name: undefined,
  email_address: undefined,
  full_name: undefined,
  event_name: undefined
};

export const VendingMachineReport = () => {
  const [filterValue, setFilterValue] = useState(getInitialFilterValues);

  const { params, updateParams, resetPage } =
    usePaginationParams<VendingFilterFormValuesPaginated>({
      page: 1,
      size: 10,
      order_by: 'id',
      ...getInitialFilterValues,
    });


  const { data: getVendingReportListQueryResponse, refetch, isLoading } = useGetVendingReportList((params), { retry: false });

  const handleFiltersChange = (values: VendingFilterFormValuesPaginated) => {
    setFilterValue(values);
    updateParams(values);
    resetPage();
    refetch();
  };


  const handleDownloadReport = () => {
    downloadVendingReport(filterValue);
  }

  // useEffect(()=>{
  //  console.log('dd1'+JSON.stringify(getVendingReportListQueryResponse));
  // },[]);



  return (
    <>
      <VendingMachineTopBar
        handleFilter={handleFiltersChange}
        handleDownloadReport={handleDownloadReport}
        getInitialFilterValues={getInitialFilterValues}
      />
      <VendingMachineTable
        isUsersListLoading={isLoading}
        vendorReportList={getVendingReportListQueryResponse?.items || []}
        itemsCount={getVendingReportListQueryResponse?.total || 0}
        tableParams={params}
        updateParams={updateParams}
      />
      <Outlet />
    </>
  );
};
