import { usePaginationParams } from 'hooks';

import { UploadListTopBar } from '../component/top-bar/upload-list-bar';
import { UploadList } from '../component/transaction-table-list/upload-list';
import { useGetBulkUploadList } from '../use-cases/get-bulk-upload-list';

export const UploadListView = () => {


  const { params, updateParams } = usePaginationParams<any>({
    Flag: "admin",
    page: 1,
    size: 10,
  });

  const { data, refetch, isFetching } = useGetBulkUploadList(params, { retry: false });

  return (
    <>
      <UploadListTopBar />

      <UploadList
        isSocialListLoading={isFetching}
        DeliveryList={data}
        tableParams={params as any}
        updateParams={updateParams}
        refetch={refetch}

      />
    </>
  );
};
