import { ArrowRightOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Space, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Switch, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { getUserFullName, usePermissions } from 'features/users';
import { AddAdminUserModal } from 'features/users/components/modals/add-admin-user-modal';
import { UserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { useUpdateUserStatus } from 'features/users/use-cases/update-user';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { User } from 'types';

import { RoleCell } from './role-cell';

type UsersTableProps = {
  isUsersListLoading: boolean;
  usersList: User[];
  itemsCount: number;
  tableParams: UserFilterFormValuesPaginated;
  updateParams: Dispatch<SetStateAction<UserFilterFormValuesPaginated>>;
  adminUserEditData: any;
  setAdminUserEditData: any;
};

export const UsersTable = ({
  usersList,
  isUsersListLoading,
  itemsCount,
  tableParams,
  updateParams,
  adminUserEditData,
  setAdminUserEditData,
}: UsersTableProps) => {
  const { t } = useTranslation();
  const permissions = usePermissions();
  const navigate = useNavigate();

  const [showUpdateAdminModal, setShowUpdateAdminModal] = useState(false);
  const [loadingSwitchId, setLoadingSwitchId] = useState('');
  // const { mutate: mutateUpdateUser, isLoading: isLoadingUpdateUser } =
  //   useUpdateUser();

  const { mutate: mutateUpdateUserStatus, isLoading: isLoadingUpdateUserStatus } = useUpdateUserStatus();

  if (!permissions) return null;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'
          : '-name',
    });
  };

  const showAdminDetail = (record: any) => {
    navigate(`/users/admin/${record.id}/detail`);
  }

  const handleOpenModal = (record: any) => {
    setShowUpdateAdminModal(true);
    setAdminUserEditData(record);
  }

  const handleCloseModal = () => {
    setShowUpdateAdminModal(false);
    setAdminUserEditData(undefined);
  }

  const resetRecorId = ()=>{
    setLoadingSwitchId('');
  }


  return (
    <>

      {showUpdateAdminModal ? (<div className={`cstmrightmdl ${showUpdateAdminModal && "show"}`}>
        <AddAdminUserModal onClose={handleCloseModal} adminUserEditData={adminUserEditData} />
      </div>) : ''}


      <StyledTableWrapper>
        <StyledTableHeader>
          <RecordCountBadge
            text={t('adminUsers.foundUsers')}
            count={itemsCount}
          />
        </StyledTableHeader>
        <Table<User>
          loading={isUsersListLoading}
          dataSource={usersList}
          rowKey='id'
          onChange={handleTableChange}
          pagination={{
            current: tableParams.page,
            defaultPageSize: tableParams.size,
            total: itemsCount,
          }}
          scroll={{ x: 1440 }}
        // onRow={(record) => {
        //   return {
        //     onClick: () => {
        //       //const selection = window?.getSelection()?.toString();

        //       // if (!selection?.length && show_modal) {
        //       //   navigate(getRoute(ROUTES.USERS_ADMIN_EDIT, record.id));
        //       // }
        //     },
        //   };
        // }}
        >
          <Column
            key={'detail'}
            title={''}
            dataIndex={'detail'}
            render={() => <ArrowRightOutlined />}

            onCell={(record) => {
              return {
                onClick: () => {
                  const selection = window?.getSelection()?.toString();
                  if (!selection?.length) {
                    showAdminDetail(record)
                  }
                },
              };
            }}
          />

          <Column<User>
            title={t('adminUsers.table.customerName')}
            dataIndex={'customerName'}
            render={(_, record) => getUserFullName(record)}
            sorter={true}
            sortDirections={['ascend', 'descend', 'ascend']}
            defaultSortOrder={'ascend'}
          />
          <Column<User>
            title={t('adminUsers.table.role')}
            dataIndex={'user'}
            render={(_, record) => <RoleCell user={record} />}
          />
          {/* <Column<User>
          title={t('adminUsers.table.company')}
          dataIndex={'user'}
          render={(_, record: any) =>
            record.role === 'User'
              ? 'N/A'
              : companyTranslationsMap(record.company_name)
          }
        /> */}
          <Column<User>
            title={t('adminUsers.table.email')}
            dataIndex={'user'}
            render={(_, record) => record.email}
          />
          <Column<User>
            title={t('adminUsers.table.phoneNumber')}
            dataIndex={'user'}
            render={(_, record) => record.phone_number}
          />
          <Column<User>
            title={"Action"}
            dataIndex={'user'}
            render={(_, record) => (
              <Space>
                <Tooltip title="Edit">
                  <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                </Tooltip>
                <Tooltip title="is active">
                  <Switch
                    defaultChecked={record.is_active}
                    checked={record.is_active}
                    loading={loadingSwitchId==record.id && isLoadingUpdateUserStatus}
                    onChange={(isChecked) =>{
                      setLoadingSwitchId(record.id);
                      mutateUpdateUserStatus({
                        Id: record.id,
                        is_active: isChecked,
                        flag: "status_updated"
                      },{ onSettled: resetRecorId })
                    }}
                    
                    onClick={(checked, event) => {
                      event.stopPropagation();
                      event.preventDefault();

                    }}
                  />
                </Tooltip>
              </Space>
            )}
          />
        </Table>
      </StyledTableWrapper>
    </>
  );
};
