import type { TabsProps } from 'antd';
import { Tabs } from 'antd';
import { ContentTopBar } from 'components';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import { RouteLeavingGuard } from 'components/route-leaving-guard/route-leaving-guard';
import {
  CompanyLogo,
  PasswordForm,
  PasswordFormValues,
  ProfileForm,
  ProfileFormValues,
} from 'features/account-settings';
// import { useGetCompany } from 'features/companies';
import { useUpdateUser, useUserContext } from 'features/users';
import { omit } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AccountSettingsView = () => {
  const [activeTab, setActiveTab] = useState('1');
  const { t } = useTranslation();
  const { user } = useUserContext();
  const [isProfileFormDirty, setIsProfileFormDirty] = useState(false);
  const [isPasswordFormDirty, setIsPasswordFormDirty] = useState(false);

  const { mutate: mutateUpdateUser, isLoading: isLoadingUpdateUser } =
    useUpdateUser();

  const canFetchCompany = Boolean(user);

  // const companyQueryResult = useGetCompany(user?.company_id as string, {
  //   cacheTime: 0, //workaround to invalidate initialValues of client data form
  //   enabled: canFetchCompany,
  // });

  if (!user) return null;

  const onChange = (activeKey: string) => {
    setActiveTab(activeKey);
  };

  const handleUpdateProfile = (formData: ProfileFormValues) => {
    const payload = {
      ...omit(formData, ['phone_number', 'company']),
      id: user.id,
    };

    mutateUpdateUser(payload);
  };

  const handleUpdatePassword = (
    formData: PasswordFormValues,
    onSuccess: () => void,
  ) => {
    const payload = { ...formData, id: user.id };

    mutateUpdateUser(payload, { onSuccess });
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `${t('accountSettings.tab.profile')}`,
      children: (
        <>
          <StyledContentBackground>
            <CompanyLogo
              hasCompany={canFetchCompany}
            // companyImageUrl={companyQueryResult?.data?.image_url}
            />
            <ProfileForm
              user={user}
              // companyName={companyQueryResult?.data?.name}
              handleSubmit={handleUpdateProfile}
              isLoading={isLoadingUpdateUser}
              setIsProfileFormDirty={setIsProfileFormDirty}
            />
          </StyledContentBackground>
        </>
      ),
    },
    {
      key: '2',
      label: `${t('accountSettings.tab.password')}`,
      children: (
        <StyledContentBackground>
          <PasswordForm
            handleSubmit={handleUpdatePassword}
            isLoading={isLoadingUpdateUser}
            setIsPasswordFormDirty={setIsPasswordFormDirty}
          />
        </StyledContentBackground>
      ),
    },
  ];

  return (
    <>
      <ContentTopBar title={t('accountSettings.title')} />
      <Tabs
        defaultActiveKey='1'
        activeKey={activeTab}
        items={items}
        onChange={onChange}
      />

      <RouteLeavingGuard blocked={isProfileFormDirty || isPasswordFormDirty} />
    </>
  );
};
