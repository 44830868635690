import { Button, Form } from 'antd';
import { useCreateOutlet } from 'features/events/use-cases/create-outlet';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FaqFormField } from '../../../event-cms-tab/type';
import { OutletFormField, OutletFormValues } from '../../type';
import { AddOutletForm } from './outletformview';

export const AddEventOutletModal = ({ onClose, outletdata, refetch }: { outletdata: any, onClose: () => void; refetch: () => void }) => {
    const { id } = useParams();
    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);


    const [form] = Form.useForm<OutletFormValues>();
    const { isLoading, mutate } = useCreateOutlet(outletdata?.id);
    const getInitialValues = {
        [OutletFormField.id]: id,
        [OutletFormField.name]: undefined,
        [OutletFormField.contact_no]: undefined,
        [OutletFormField.email]: undefined,
        [OutletFormField.event_id]: undefined,
        [OutletFormField.is_active]: undefined,

    };

    const handleClose = () => {
        onClose();
        form.resetFields();
    }

    const hanldeSuccess = () => {
        handleClose();
        refetch();
    }

    const handleUpdate = (formData: OutletFormValues) => {
        formData[OutletFormField.id] = outletdata?.id
        formData[FaqFormField.event_id] = +id!;
        formData[OutletFormField.is_active] = true;
        mutate(formData, { onSuccess: hanldeSuccess });
    };

    useEffect(() => {
        if (outletdata) {
            form.setFieldsValue(outletdata);
        } else {
            form.resetFields();
        }
    }, [outletdata])

    return (

        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={onClose} className='close-btn'>
                    &times;
                </button>
                <h4>{outletdata ? 'Edit Outlet' : 'Add Outlet'}</h4>
            </div>
            <div className='modal_body'>
                <Form<OutletFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    requiredMark={'optional'}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <AddOutletForm />
                </Form>
                <div className='button-group'>
                    <Button loading={isLoading} htmlType='submit' onClick={form.submit} className='btn'>Save</Button>
                </div>

            </div>
        </div>

    )
};
