import { PageLoader } from 'components/page-loader/page-loader';
import { useGetExpoRegistraionForm } from 'features/events/use-cases/get-axpo-registraion-form';
import { useEffect, useState } from 'react';

import ExpoFormCreatedComponents from './expo-form-component/expo-form-created-components';
import ExpoFormDefaultComponents from './expo-form-component/expo-form-default-Componets';

interface props {
  id: string;
  tabs: string
}

function ExpoRegistrationForm({ id, tabs }: props) {


  const [droppedItems, setDroppedItems] = useState<any>([]);

  const userQueryResult = useGetExpoRegistraionForm(id, {
    cacheTime: 0,
  });


  const {
    isLoading,
    // isFetching: isFetchingGetUser,
    data: userData,
    // isError: isErrorUser,
    refetch,
  } = userQueryResult;

  useEffect(() => {
    if (tabs === "9") {
      refetch()
    }
  }, [tabs])

  if (isLoading) return <PageLoader />;

  return (
    <>
      {/* {isLoading ?
        <PageLoader />
        : */}
      <div className="cstmfrmscrion">

        <ExpoFormCreatedComponents userData={userData} droppedItems={droppedItems} setDroppedItems={setDroppedItems} />
        <ExpoFormDefaultComponents droppedItems={droppedItems} />
      </div>
      {/* } */}
    </>

  );
}

export default ExpoRegistrationForm;
