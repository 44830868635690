import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { App } from "antd";
import { handleError } from "api";
import { EventsParams, eventsQueryKeys, EventsResponse } from "features/events";

import { UserApiService } from "../api/vending-machine-api.service";
const { getEvents } = UserApiService();


const getEventsQuery = (
    params: EventsParams,
    options?: UseQueryOptions<EventsResponse>,
): {
    queryKey: QueryKey;
    queryFn: QueryFunction<EventsResponse>;
    options?: UseQueryOptions<EventsResponse>;
} => ({
    queryKey: eventsQueryKeys.list(params),
    queryFn: () => getEvents() as any,
    ...options,
});


export const useGetEventVending = (
    params: EventsParams,
    options?: UseQueryOptions<EventsResponse>,
) => {
    const { message } = App.useApp();

    return useQuery<EventsResponse, Error>(
        getEventsQuery(params, {
            onError: (error: unknown) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            ...options,
        }),
    );
};