import { Button, Form, Input, message, Radio, Select } from 'antd';
import { PartnersFormValues } from 'features/cms/components/manage-partners/add-partners-modal/types';
import { EventsApiService } from 'features/events';
import { useCreateSize } from 'features/shop-item/use-cases/craete-size';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ItemFormField, SizeFormField } from './type';
export const AddItemSizeModal = ({ onClose, showAddItemModal, tabItemSize }: { onClose: () => void; showAddItemModal: boolean, setSizeValue: any; tabItemSize: () => void }) => {
    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<PartnersFormValues>();
    // const [loading, setLoading] = useState(false)
    const { id } = useParams();

    const { mutate } = useCreateSize();
    const handleClose = () => {
        form.resetFields();
        onClose();
    }

    const [size, setSize] = useState({
        items: []
    } as any)
    const [Color, setColor] = useState({
        items: []
    } as any)
    // console.log(Color?.items.length, "sds");



    const initialSize = async () => {
        try {
            const apiRes = await await EventsApiService().sportCategoryId(`Flag=${'Size_Availability'}&CascadeId=${id}` as any)
            setSize(apiRes)
        } catch (error) {
            message.error('')
        }
    }


    const initialColor = async () => {
        try {
            const apiRes = await EventsApiService().sportCategoryId(`Flag=${'Colors_Availability '}&CascadeId=${id}` as any)
            setColor(apiRes)
        } catch (error) {
            message.error('')
        }
    }

    const handleUpdate = async (formData: any) => {
        const updatedFormData = {
            ...formData,
            shop_item_id: +id!,
            is_active: true
        };
        try {
            // Perform API call
            await mutate(updatedFormData, {
                onSuccess: () => {
                    handleClose();
                    // setIsUpdating(false);
                    tabItemSize();
                }
            });
        } catch (error) {
            console.error("Error updating data:", error);
        }
    };
    useEffect(() => {
        if (showAddItemModal) {
            initialSize()
            initialColor()
        }

    }, [showAddItemModal])

    return (
        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={handleClose} className='close-btn'>
                    &times;
                </button>
                <h4>Add Size</h4>
            </div>
            <div className='modal_body'>
                <Form<PartnersFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    onFinish={handleUpdate}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                >
                    <FormSectionsSeparator title={""}>
                        <Form.Item<SizeFormField>
                            label={"Size"}
                            name={SizeFormField.size_master_id}

                        >
                            <Select
                                placeholder={size?.items.length > 0 ? "Please select size" : "N/A"}
                                disabled={size?.items.length === 0}
                            >
                                {size?.items.map((res: any) => (
                                    <Select.Option key={res.Id} value={res.Id}>{res.Text}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item<ItemFormField>
                            label={"Color"}
                            name={SizeFormField.color_id}

                        >
                            <Select
                                placeholder={Color?.items.length > 0 ? "Please Select Color" : "N/A"}
                                disabled={Color?.items.length === 0}>
                                {Color?.items.map((res: any) => (
                                    <Select.Option key={res.Id} value={res.Id}>{res.Text}</Select.Option>
                                ))}
                            </Select>

                        </Form.Item>
                        <Form.Item<ItemFormField>
                            label={"Total Quantity"}
                            name={SizeFormField.total_quantity}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item<ItemFormField>
                            label={"Order"}
                            name={SizeFormField.sort_order}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>.
                        <Form.Item name={SizeFormField.is_featured} label="Featured">
                            <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </FormSectionsSeparator>

                    <Form.Item className='button-group'>
                        <Button htmlType='submit' className='btn'>Save</Button>
                    </Form.Item>
                </Form>


            </div>
        </div>

    )
};

