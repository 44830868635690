import { PaginatedRequestParams } from 'api';

export enum WalletFilterFormField {
    Name = 'name',
    MobileNo = 'mobile_no',
    Email = 'email',
    PayType = 'pay_type',
    TransactionStatus = 'transaction_status',
    Date = 'date',
    AuthId = 'auth_id',
    TransactionId = 'transaction_id',
}

export type WalletFilterFormValues = {
    [WalletFilterFormField.Name]: string | undefined;
    [WalletFilterFormField.MobileNo]: string | undefined;
    [WalletFilterFormField.Email]: string | undefined;
    [WalletFilterFormField.PayType]: string | undefined;
    [WalletFilterFormField.TransactionStatus]: string | undefined;
    [WalletFilterFormField.Date]: any;
    [WalletFilterFormField.AuthId]: string | undefined;
    [WalletFilterFormField.TransactionId]: string | undefined;
};

export type WalletFilterFormValuesPaginated = WalletFilterFormValues &
    PaginatedRequestParams;