import React from 'react';
import { TooltipProps } from 'recharts';

type CustomTooltipProps = TooltipProps<number, string>

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
        <p className="label">Year</p>
        {payload.map((data, index) => (
        <p key={index} className="label">{`${label}`} : {`${data.value}`}</p>
        
        //   <p key={index} className="intro">{`${data.name} : ${data.value}`}</p>
        ))}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
