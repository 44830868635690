export enum SponserFormField {
    Id = 'id',
    EventId = 'event_id',
    Name = 'name',
    Link = 'link',
    ContactNumber = 'contact_no',
    Email = 'email',
    Address = 'address',
    IsActive = 'is_active',
    File = 'file',
}

export type SponserFormValues = {
    [SponserFormField.Id]: string | undefined,
    [SponserFormField.EventId]: string | undefined,
    [SponserFormField.Name]: string,
    [SponserFormField.Link]: string,
    [SponserFormField.ContactNumber]: string,
    [SponserFormField.Address]: string,
    [SponserFormField.Email]: string,
    [SponserFormField.IsActive]: boolean,
    [SponserFormField.File]: any,
};



type Item = {
    id: number;
    Text: string;
    // Add more properties as needed
};

export type CurrencyCategory = {
    items: Item[];
};

