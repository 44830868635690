import { Button, Checkbox } from "antd";
import { PageLoader } from "components/page-loader/page-loader";
import { useCraeteAttributeRacekit } from "features/events/use-cases/craete-attribute-racekit";
import { useGetAttributeRacekit } from "features/events/use-cases/get-attribute-racekit";
import { usePaginationParams } from "hooks";
import { useEffect, useState } from "react";

const ShowHideRacekit = ({ id, tab }: { id: string; tab: string }) => {

    const { params } =
        usePaginationParams<any>({
            page: 1,
            size: 10,
            order_by: '-rank',
            event_id: id,
        });

    const { data, isFetching, refetch } = useGetAttributeRacekit(params, { retry: false });

    const { isLoading, mutate } = useCraeteAttributeRacekit();

    const [checkboxes, setCheckboxes] = useState<any[]>([]);

    const handleCheckboxChange = (event: any, changeId: any) => {
        const { checked } = event.target;
        setCheckboxes((prevCheckboxes) =>
            prevCheckboxes.map((checkbox) =>
                checkbox.racekit_master_id === changeId ? { ...checkbox, is_active: checked } : checkbox
            )
        );
    };


    useEffect(() => {
        if (tab === "2") {

            refetch();
        }
    }, [tab]);

    useEffect(() => {
        if (tab === "2" && data) {
            setCheckboxes(data.items);
        }
    }, [data, tab]);


    const handleSave = () => {
        const checkedItems: any[] = checkboxes
            ?.filter(item => item.is_active) // Filter items where is_active is true
            .map(item => ({
                racekit_master_id: item.racekit_master_id
            }));
        const formData = {
            event_id: id,
            racekitmasterids: checkedItems,
        }
        mutate(formData);
    }

    if (isFetching) return <PageLoader />;

    return (

        <div className="t_shirt">
            <div className="t_slist">
                {
                    checkboxes?.map((item: any, i: number) => (
                        <div className="ts_wrap" key={i}>

                            <div key={i} className="t_sbx">
                                <img src={item?.image_url} width={"100%"} height={"45rem"} />
                            </div>
                            <div className="t_lbl">
                                <Checkbox
                                    checked={item.is_active}
                                    onChange={(e) =>
                                        handleCheckboxChange(e, item.racekit_master_id)
                                    }
                                />
                                Attach with this event
                            </div>
                        </div>
                    ))}
            </div>
            <div>
                <Button loading={isLoading} onClick={handleSave} type="primary">Save</Button>
            </div>
        </div>
    )
}

export default ShowHideRacekit
