import { Button, Form } from 'antd';
import { useCreateFaq } from 'features/cms/use-cases/craete-faq';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AddFaqForm from './add-faq-form';
import { FaqFormField, FaqFormValues } from './types';

export const AddFaqModal = ({ onClose, refetch, faqEditData }: { onClose: () => void; showFaqModal: boolean, refetch: () => void, faqEditData: any }) => {
    const { id } = useParams();
    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<FaqFormValues>();
    const { isLoading, mutate } = useCreateFaq();

    const getInitialValues = {
        [FaqFormField.Id]: id,
        [FaqFormField.Question]: undefined,
        [FaqFormField.Answer]: undefined,
        [FaqFormField.IsActive]: undefined,
    };

    const handleUpdate = (formData: FaqFormValues) => {
        formData[FaqFormField.Id] = faqEditData?.id;
        mutate(formData, { onSuccess: hanldeSuccess });
    };

    const hanldeSuccess = () => {
        onClose();
        refetch();
    }

    useEffect(() => {
        if (faqEditData) {
            form.setFieldsValue(faqEditData);
        } else {
            form.resetFields();
        }
    }, [faqEditData])

    return (
        <>

            <>

                <div className={'popup'}>
                    <div className='modal_head'>
                        <button onClick={onClose} className='close-btn'>
                            &times;
                        </button>
                        <h4>{faqEditData ? "Edit Faq" : "Add Faq"}</h4>
                    </div>
                    <div className='modal_body'>
                        <Form<FaqFormValues>
                            id={formName}
                            name={formName}
                            form={form}
                            layout='vertical'
                            initialValues={getInitialValues}
                            onFinish={handleUpdate}
                            requiredMark={'optional'}
                            onFinishFailed={() => {
                                setValidateTrigger(['onChange']);
                            }}
                            validateTrigger={validateTrigger}
                            disabled={isLoading}
                        >
                            <AddFaqForm />
                        </Form>
                        <div className='button-group'>
                            <Button htmlType='submit' onClick={form.submit} className='btn'>Save</Button>
                        </div>

                    </div>
                </div>
            </>

        </>
    )
};

