import { Button, Form, Image, Input } from 'antd';
import { useCreateDiscoverBanner } from 'features/cms/use-cases/create-discover-banner';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { DiscoverBannerFormField, DiscoverBannerFormValues } from './types';


export const AddDiscoverBannerModal = ({ onClose, bannerEditData }: { onClose: () => void; showBannerModal: boolean, refetch: () => void, bannerEditData: any, }) => {
    const { id } = useParams();

    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<DiscoverBannerFormValues>();
    const { t } = useTranslation();
    const { isLoading, mutate } = useCreateDiscoverBanner();

    const getInitialValues = {
        [DiscoverBannerFormField.Id]: id,
        [DiscoverBannerFormField.Title]: undefined,
        [DiscoverBannerFormField.Url]: undefined,
        [DiscoverBannerFormField.Description]: undefined,
        [DiscoverBannerFormField.File]: undefined,
        [DiscoverBannerFormField.IsActive]: undefined,
        [DiscoverBannerFormField.ImageUrl]: undefined,
    };

    const [fileList, setFileList] = useState<File | any>(null);

    const handleFileChange = (e: any) => {
        setFileList(e.target.files[0]);
    };

    const handleUpdate = (formData: DiscoverBannerFormValues) => {
        formData[DiscoverBannerFormField.Id] = bannerEditData?.Id;
        formData[DiscoverBannerFormField.File] = fileList;
        formData[DiscoverBannerFormField.ImageUrl] = "";
        formData[DiscoverBannerFormField.IsActive] = true;
        mutate(formData, { onSuccess: hanldeSuccess, onError: hanldeError });


    };

    const handleClose = () => {
        onClose();
        setFileList(null);
        form.resetFields();
    }

    const hanldeSuccess = () => {
        // if (!bannerEditData?.Id) refetch();
        onClose();
    }

    const hanldeError = () => {
        handleClose();
        // refetch();
    }

    useEffect(() => {
        if (bannerEditData) {
            form.setFieldsValue(bannerEditData);
        } else {
            form.resetFields();
        }
    }, [bannerEditData, form])



    return (

        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={handleClose} className='close-btn'>
                    &times;
                </button>
                <h4>{bannerEditData ? "Edit Discover Banner" : "Add Discover Banner"}</h4>
            </div>
            <div className='modal_body'>
                <Form<DiscoverBannerFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <FormSectionsSeparator title={""}>
                        <Form.Item<DiscoverBannerFormField>
                            label={"Title"}
                            name={DiscoverBannerFormField.Title}

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Enter Title"}
                            // prefix={<UserOutlined className='input-prefix-icon' />}
                            />
                        </Form.Item>


                        <Form.Item<DiscoverBannerFormField>
                            label={"URL"}
                            name={DiscoverBannerFormField.Url}

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Enter URL"}
                            // prefix={<UserOutlined className='input-prefix-icon' />}
                            />
                        </Form.Item>

                        {!fileList && bannerEditData?.image_url && <Image
                            src={bannerEditData?.image_url}
                        />}

                        <Form.Item<DiscoverBannerFormField>
                            label={"Image"}
                            name={DiscoverBannerFormField.File}
                            rules={[
                                {
                                    required: !bannerEditData?.Id,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            {/* <Upload fileList={fileList} onChange={handleFileChange} accept="image/*" maxCount={1} beforeUpload={() => false}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload> */}
                            <Input type="file" onChange={handleFileChange} accept=".png,.jpg,.jpeg" />
                        </Form.Item>

                        <Form.Item<DiscoverBannerFormField>
                            label={"Description"}
                            name={DiscoverBannerFormField.Description}

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Description"}
                            // prefix={<UserOutlined className='input-prefix-icon' />}
                            />
                        </Form.Item>

                    </FormSectionsSeparator>
                    <div className='button-group'>
                        <Button htmlType='submit' className='btn' loading={isLoading}>Save</Button>
                    </div>
                </Form>


            </div>
        </div>

    )
};

