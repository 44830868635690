import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { eventsQueryKeys } from 'features/events';
import { queryClient } from 'providers';

import { TransectionApiServices } from '../api/transection-api.service';



const { uploadBulkExcel } = TransectionApiServices();

export const useUploadBulkExcel = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: any) => {
            return uploadBulkExcel(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: (data: any) => {
                const { ResponseMessage, ResponseType } = data;
                if (ResponseType === 1) {
                    Promise.all([
                        queryClient.invalidateQueries({
                            queryKey: eventsQueryKeys.all,
                        }),
                    ]);
                    message.success(ResponseMessage);
                } else if (ResponseType === 2) {
                    message.error(ResponseMessage);
                } else {
                    message.warning(ResponseMessage);
                }
            },
        },
    );
};
