import { Button, Form, Grid, Input } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CmsSportFilterFormValues, EventsFilterFormField,  } from './types';

const { useBreakpoint } = Grid;

type EventsFilterFormProps = {
  formId: string;
  handleFilter: (values: CmsSportFilterFormValues) => void;
  initialValues: CmsSportFilterFormValues;
  closeModal?: () => void;
};

export const SportsCategoryFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: EventsFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<CmsSportFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
    handleFilter({...initialValues})
  };

  const handleOnFinish = (values: CmsSportFilterFormValues) => {
    handleFilter(values);
    closeModal?.();
  };

  return (
    <Form<CmsSportFilterFormValues>
      id={formId}
      name={formId}
      form={form}
      layout='vertical'
      onFinish={handleOnFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper style={{width:'100%'}}>
        <StandardItemsWrapper style={{width:'100%'}}>
          <div className='invoice_list' style={{width:'100%'}}>

          
          <Form.Item<CmsSportFilterFormValues>
            label={'Category Name'}
            name={EventsFilterFormField.FieldName}
          >
            <Input placeholder={'Enter  category Name'} />
          </Form.Item>

          {screens.lg && (
        <ContentFilters.ButtonsWrapper className='filter_btn'>
          <Button type='primary' htmlType='submit'>
            {t('events.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{t('events.buttons.reset')}</Button>
        </ContentFilters.ButtonsWrapper>
      )}
          </div>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

    
    </Form>
  );
};
