import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, Skeleton, Space, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import { UpdateStatus } from 'features/notification/use-cases/update-status';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Notificationtype } from 'types/notification-type';

import { EditNotificationModal } from '../modal/editNotificationType';
import { NotificationFilterFormValuesPaginated } from '../type';
type NotificationTableProps = {
    isUsersListLoading: boolean;
    typeReportList: Notificationtype[];
    itemsCount: number;
    tableParams: NotificationFilterFormValuesPaginated;
    refetch: any
    updateParams: Dispatch<SetStateAction<NotificationFilterFormValuesPaginated>>;
};

export const NotificationListTypeTable = ({
    typeReportList,
    isUsersListLoading,
    itemsCount,
    tableParams,
    refetch,
    updateParams,
}: NotificationTableProps) => {
    const { t } = useTranslation();
    const [showAddItemModal, setShowAddItemModal] = useState<boolean>(false);
    const [notificationTypeEdit, setNotificationType] = useState(undefined)
    const [Data, setData] = useState<any>(/* Your initial data array */);
    const { confirm } = Modal;
    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Notificationtype> | SorterResult<Notificationtype>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: Array.isArray(sorter)
                ? undefined
                : sorter.order === 'ascend'
                    ? 'name'
                    : '-name',
        });
    };
    const handleOpenModal = (record: any) => {
        setShowAddItemModal(true)
        document.body.style.overflow = 'hidden';
        setNotificationType(record)
    }
    const { mutate: mutateUpdateItem } =
        UpdateStatus();

    const handleDelete = async (id: any) => {
        try {
            // Simulate the loading state
            mutateUpdateItem({
                id: id,
                flag: "ANNOUNCEMENT_TYPE_DELETE",
                status: true
            });

            const newData = Data.filter((item: any) => item.id !== id);
            setData(newData);
        } catch (error) {
            console.error("error")
        }
    };
    const showDeleteConfirm = (record: any) => {
        confirm({
            title: 'Are you sure you want to delete this Type?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record.id);
            },
        } as any);
    };
    const handleCloseModal = () => {
        setShowAddItemModal(false)
        document.body.style.overflow = 'visible';
    }

    return (
        <StyledTableWrapper>
            <div className={`cstmrightmdl ${showAddItemModal && "show"}`}>
                <EditNotificationModal onClose={handleCloseModal} refetch={refetch} showAddItemModal={showAddItemModal} editData={notificationTypeEdit} />
            </div>
            <StyledTableHeader>
                <RecordCountBadge
                    text={t('vendingMachine.foundRecord')}
                    count={isUsersListLoading ? 0 : itemsCount || 0}
                />
            </StyledTableHeader>
            <Table<Notificationtype>
                // loading={isUsersListLoading}
                // dataSource={typeReportList}
                className="cstltblwthldr"
                locale={{
                    emptyText: isUsersListLoading ? <Skeleton active={true} /> : <Empty />
                }}
                loading={isUsersListLoading && { indicator: <div><TableLoader /></div> }}
                dataSource={isUsersListLoading ? [] : typeReportList}
                rowKey='id'
                onChange={handleTableChange}
                pagination={{
                    current: tableParams.page,
                    defaultPageSize: tableParams.size,
                    total: itemsCount,
                }}
            >
                <Column<Notificationtype>
                    title={'Type '}
                    dataIndex={'title'}
                />
                <Column
                    title="Status"
                    dataIndex="is_active"
                    render={(text, record: any) => (record.is_active ? 'Active' : 'Inactive')}
                />
                <Column
                    title="Action"
                    dataIndex="email_address"
                    render={(_, record: any) => (
                        <Space>
                            <Tooltip title="Edit">
                                <Button
                                    shape="circle"
                                    onClick={() => handleOpenModal(record)}
                                    icon={<EditOutlined />}
                                />
                            </Tooltip>

                            <Tooltip title="Delete">
                                <Button
                                    shape="circle"
                                    onClick={() => showDeleteConfirm(record)}
                                    icon={<DeleteOutlined />}
                                />
                            </Tooltip>
                        </Space>
                    )}
                />
            </Table>
        </StyledTableWrapper>
    );
};
