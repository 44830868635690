export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  NotSpecified = 'NOT_SPECIFIED',
}

export enum Role {
  SuperAdmin = 'SuperAdmin',
  SuffixAdmin = 'SuffixAdmin',
  SuffixAgent = 'SuffixAgent'
}
type userMenu = {
  id:string ;
  menu:string ;
  isPermission:boolean ;
}

export type User = {
  created_at: string;
  email: string | null;
  first_name: string;
  gender: Gender | null;
  id: string;
  is_active: boolean;
  last_name: string;
  phone_number: string;
  role: Role;
  address?:string;
  Menus?:userMenu[] ;
  profile_picture?:string ;
  user_id?:number ;
};

export type Volunteer = {
  Id:string ;
  EventId:string ;
  EventName:string ;
  UserId:string ;
  Name:string;
  Email:string;
  PhoneNo:string;
  Status:boolean;
  IsRefundAllowed:boolean;
  CreatedDate:string;
}


      
      