import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Empty, Grid, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
    EventsFilterFormPaginated,
    EventsResponse,
} from 'features/events';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';
import { Event } from 'types/event';

import { AddFaqModal } from './Add-faq-madal/add-faq-modal';


const { useBreakpoint } = Grid;

type EventsTableProps = {
    isLoading: boolean;
    data?: EventsResponse;
    tableParams: EventsFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<EventsFilterFormPaginated>>;
    refetch: () => void;
    faqEditData: any;
    setFaqEditData: any;
};

export const FaqTabel = ({
    data,
    isLoading,
    tableParams,
    updateParams,
    refetch,
    faqEditData,
    setFaqEditData
}: EventsTableProps) => {
    const screens = useBreakpoint();
    const permissions = usePermissions();;


    const handleTableChange = (
        pagination: TablePaginationConfig,
        // filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Event> | SorterResult<Event>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: Array.isArray(sorter)
                ? undefined
                : sorter.order === 'ascend'
                    ? 'name'
                    : '-name',
        });
    };

    const tableWidth = screens.xxl ? { x: 1440 } : { x: 1200 };


    const [showFaqModal, setShowFaqModal] = useState(false);
    const handleCloseModal = () => {
        setShowFaqModal(false);
        setFaqEditData(undefined);
    }

    const handleOpenModal = (record: any) => {
        setShowFaqModal(true);
        setFaqEditData(record);
    }

    if (!permissions) return null;

    return (

        <>
            <div className={`cstmrightmdl ${showFaqModal && "show"}`}>

                <AddFaqModal showFaqModal={showFaqModal} refetch={refetch} onClose={handleCloseModal} faqEditData={faqEditData} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={"Faq found"}
                        count={data?.total || 0}
                    />
                </StyledTableHeader>
                <Table<Event>
                    className="cstltblwthldr"
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading && { indicator: <div><TableLoader /></div> }}
                    dataSource={isLoading ? [] : data?.items}
                    rowKey='id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.page,
                        defaultPageSize: tableParams.size,
                        total: data?.total,
                    }}
                    scroll={tableWidth}
                // onRow={(record) => {
                //     return {
                //         onClick: () => {
                //             handleOpenModal(record);
                //         },
                //     };
                // }}
                >
                    <Column<Event>
                        title={'Question'}
                        dataIndex={'question'}
                        // sorter={true}
                        // sortDirections={['ascend', 'descend', 'ascend']}
                        // defaultSortOrder={'descend'}
                        width={screens.xxl ? 'auto' : 200}
                    // render={(record: any) => (
                    //     <div>
                    //         <RightOutlined />
                    //         {record?.question}
                    //     </div>
                    // )}
                    // onCell={(record: any) => ({
                    //     onClick: () => {
                    //         handleOpenModal(record);
                    //     },
                    // })}
                    />
                    <Column<any>
                        title={'Answer'}
                        dataIndex={'answer'}
                        width={screens.xxl ? 'auto' : 120}

                    />

                    <Column<Event>
                        title={"Action"}
                        width={screens.xxl ? 'auto' : 162}
                        dataIndex={'is_active'}
                        render={(_, record) => (
                            <>
                                <Space>
                                    <Tooltip title="Edit">
                                        <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                                    </Tooltip>

                                    <Tooltip title="Delete">
                                        <Button shape="circle" icon={<DeleteOutlined />} />
                                    </Tooltip>
                                    <Tooltip title="Is Active">
                                        <Switch
                                            defaultChecked={record.is_active}
                                            checked={record.is_active}
                                            // loading={isLoadingUpdateEvent}
                                            // onChange={(isChecked) =>
                                            //     mutateUpdateEvent({
                                            //         id: record.id,
                                            //         is_active: isChecked,
                                            //     })
                                            // }
                                            onClick={(checked, event) => {
                                                event.stopPropagation();
                                                event.preventDefault();
                                            }}
                                        />
                                    </Tooltip>
                                </Space>
                            </>
                        )}
                    />
                </Table>
            </StyledTableWrapper>
        </>

    );
};

