import {
    UserOutlined,
} from '@ant-design/icons';
import { Form, Image, Input, UploadFile } from 'antd';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useTranslation } from 'react-i18next';

import { SponserFormField } from './types';

export const AddSponserForm = ({ fileData, setFileData, sponserData }: { fileData: UploadFile[], setFileData: any; sponserData: any }) => {
    const { t } = useTranslation();

    const handleFileChange = (e: any) => {
        setFileData(e.target.files[0]);
    };

    const validateContactNumber = (_: any, value: string) => {
        const phoneNumberPattern = /^(\+?[1-9]\d{0,2} )?\d{6,14}$/;
        if (!value || phoneNumberPattern.test(value)) {

            return Promise.resolve();
        }

        return Promise.reject(new Error('Please enter a valid contact number.'));
    };

    const validateURL = (_: any, value: string) => {
        if (!value) {

            return Promise.resolve();
        }
        try {
            new URL(value);

            return Promise.resolve();
        } catch (err) {

            return Promise.reject(new Error('Please enter a valid Link'));
        }

    };

    return (

        <FormSectionsSeparator title={""}>
            <Form.Item<SponserFormField>
                label={"Name"}
                name={SponserFormField.Name}

                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Input
                    placeholder={"Enter Name"}
                    prefix={<UserOutlined className='input-prefix-icon' />}
                />
            </Form.Item>
            <Form.Item<SponserFormField>
                label={"Link"}
                name={SponserFormField.Link}
                rules={[
                    // {
                    //     required: true,
                    //     whitespace: true,
                    //     message: t('validateMessages.custom.nomsg'),
                    // },
                    {
                        validator: validateURL
                    },
                ]}
            >
                <Input
                    placeholder={"Enter Link"}
                />
            </Form.Item>

            <Form.Item<SponserFormField>
                label={"Contact Number"}
                name={SponserFormField.ContactNumber}
                rules={[
                    // {
                    //     required: true,
                    //     whitespace: true,
                    //     message: t('validateMessages.custom.nomsg'),
                    // },
                    {
                        validator: validateContactNumber,
                    },
                ]}
            >
                <Input
                    placeholder={"Enter Contact Number"}
                    prefix={<UserOutlined className='input-prefix-icon' />}
                />
            </Form.Item>

            <Form.Item<SponserFormField>
                label={"Address"}
                name={SponserFormField.Address}
            // rules={[
            //     {
            //         required: true,
            //         whitespace: true,
            //         message: t('validateMessages.custom.nomsg'),
            //     },
            // ]}
            >
                <Input
                    placeholder={"Enter Address"}
                    prefix={<UserOutlined className='input-prefix-icon' />}
                />
            </Form.Item>

            <Form.Item<SponserFormField>
                label={"Email"}
                name={SponserFormField.Email}
            // rules={[
            //     // {
            //     //     required: true,
            //     //     message: t('validateMessages.custom.nomsg'),
            //     // },
            //     {
            //         type: "email",
            //         // message: t('validateMessages.custom.nomsg'),
            //     },
            // ]}
            >
                <Input
                    placeholder={"Enter Email"}
                    prefix={<UserOutlined className='input-prefix-icon' />}
                />
            </Form.Item>

            {!fileData && sponserData?.image_url && <Image
                src={sponserData?.image_url}
                width={"150px"}
            />}

            <Form.Item<SponserFormField>
                label={"Image"}
                name={SponserFormField.File}
                rules={[
                    {
                        required: !sponserData?.id,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                {/* <Upload fileList={fileData} onChange={handleFileChange} accept=".tiff,.Jfif,.gif,.jpeg,.jpg,.png" maxCount={1} beforeUpload={() => false}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>

                </Upload> */}
                <Input type="file" onChange={handleFileChange} accept=".png,.jpg,.jpeg" />
            </Form.Item>
        </FormSectionsSeparator>
    );
};
