/**
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const userQueryKeys = {
    all: ['user'] as const,
    lists: () => [...userQueryKeys.all, 'list'] as const,
    list: (filters: object) => [...userQueryKeys.lists(), { filters }] as const,
    details: () => [...userQueryKeys.all, 'detail'] as const,
    detail: (id: string) => [...userQueryKeys.details(), id] as const,
  };
  