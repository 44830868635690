import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { EventsApiService } from 'features/events';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

import { eventsQueryKeys } from './query-keys';

const { deleteTimeRange } = EventsApiService();

export const useDeleteTimeRange = () => {
  const { message } = App.useApp();

  return useMutation(
    (id: string) => {
      return deleteTimeRange(id);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        console.error(uiMessage);
        message.error(i18n.t('events.update.errorTimeRangeDelete'));
      },
      onSuccess: (_, id) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: eventsQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: eventsQueryKeys.detail(id),
          }),
        ]);
        message.success(i18n.t('events.update.successfulTimeRangeDelete'));
      },
    },
  );
};
