import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { App } from "antd";
import { handleError } from "api";
import { eventsQueryKeys } from "features/events";

import { InvoiceDetailsResponse, TransectionApiServices } from "../api/transection-api.service";

const { getInvoiceDetails } = TransectionApiServices();


const getEventsQuery = (
    id: string,
    // params: EventsParams,
    options?: UseQueryOptions<InvoiceDetailsResponse>,
): {
    queryKey: QueryKey;
    queryFn: QueryFunction<InvoiceDetailsResponse>;
    options?: UseQueryOptions<InvoiceDetailsResponse>;
} => ({
    queryKey: eventsQueryKeys.detail(id),
    queryFn: () => getInvoiceDetails(id),
    ...options,
});


export const useGetInvoiceDetails = (
    id: string,
    options?: UseQueryOptions<InvoiceDetailsResponse>,
) => {
    const { message } = App.useApp();

    return useQuery<InvoiceDetailsResponse, Error>(
        getEventsQuery(id, {
            onError: (error: unknown) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            ...options,
        }),
    );
};