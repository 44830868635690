export enum MarketingCodeFormField {
    Id = 'id',
    EventId = 'event_id',
    Provider = 'provider',
    BodyCode = 'body_code',
    HeaderCode = 'header_code',
    InvoiceCode = 'invoice_code',
}



export type MarketingCodeFormValues = {
    [MarketingCodeFormField.Id]: any;
    [MarketingCodeFormField.EventId]: any;
    [MarketingCodeFormField.Provider]: string;
    [MarketingCodeFormField.BodyCode]: string;
    [MarketingCodeFormField.InvoiceCode]: string;
    [MarketingCodeFormField.HeaderCode]: string;
};

export type MarketingCodeDTO = {
    id: any;
    event_id: any;
    provider: string;
    header_code: string;
    invoice_code: string;
    body_code: string;
}


