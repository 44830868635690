import { ContentFilters, ContentTopBar } from 'components';
import { useState } from 'react';
import { CompanyType } from 'types';

import { ExpoBoothFilterForm } from '../expo-booth-filter-form/expo-booth-filter-form';
import { ParticiateFilterFormValues } from '../transaction-filter-form/type';

type ParticipateTopBarProps = {
  getInitialFilterValues: ParticiateFilterFormValues;
  handleFilter: (values: ParticiateFilterFormValues) => void;
};

export const ExpoBoothTopBar = ({
  getInitialFilterValues,
  handleFilter,
}: ParticipateTopBarProps) => {
  useState<CompanyType>();
  const formId = 'expo-list-filters-form';

  return (

    <>
      <ContentTopBar
        title={'Expo Booth list'}

      />

      <ContentFilters formId={formId}>
        <ExpoBoothFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
