import { PaginatedRequestParams } from "api";
import { MainFiltersParams } from "components/main-filters/main-filters";

export enum SizeManagementFilterFormField {
    Size = 'size_name',
  }
  export enum ColorFilterFormField {
    type = 'type',
  }
  export enum RaceFilterFormField {
    Racekit = 'name',
  }
  export enum  EventMasterFormField {
    Title = 'title',
  }
  export type RaceFilterFormValues = {
    [RaceFilterFormField.Racekit]: string | undefined;
  };
  export type EventMasterFilterFormValues = {
    [EventMasterFormField.Title]: string | undefined;
  };
  export type ColorFilterFormValues = {
    [ColorFilterFormField.type]: string | undefined;
  };
  
  export type ManagementFilterFormValues = {
    [SizeManagementFilterFormField.Size]: string | undefined;
  };
  export type ColorFilterFormPaginated = ColorFilterFormValues &
  MainFiltersParams &
  PaginatedRequestParams;
  export type ManagementFilterFormPaginated = ManagementFilterFormValues &
  MainFiltersParams &
  PaginatedRequestParams;
  export type raceFilterFormPaginated = RaceFilterFormValues &
  MainFiltersParams &
  PaginatedRequestParams;
  export type EventFilterFormPaginated = EventMasterFilterFormValues &
  MainFiltersParams &
  PaginatedRequestParams;