
export enum TipsFormField {
    Id = "id",
    EventId = "event_id",
    Name = "name",
    File = "file"
};

export type TipsFormValues = {
    [TipsFormField.Id]: any;
    [TipsFormField.EventId]: string;
    [TipsFormField.Name]: string;
    [TipsFormField.File]: any;
};