export enum ParticiateFilterFormField {
    FIRSTNAME = 'first_name',
    LASTNAME = 'last_name',
    EMAIL = 'email',
    PHONE = 'phone',
    EVENTNAME = 'event_name',
    INVOICENO='invoice_id',
    PARTICIPANT='invoice_no'
  }
export type ParticiateFilterFormValues = {
    [ParticiateFilterFormField.FIRSTNAME]: string | undefined;
    [ParticiateFilterFormField.LASTNAME]: string | undefined;
    [ParticiateFilterFormField.EMAIL]: string | undefined;
    [ParticiateFilterFormField.PHONE]: string | undefined;
    [ParticiateFilterFormField.EVENTNAME]: string | undefined;
    [ParticiateFilterFormField.INVOICENO]: string | undefined;
    [ParticiateFilterFormField.PARTICIPANT]: string | undefined;
  };