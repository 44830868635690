import { Button, Card, Form, message,Select, Space } from 'antd';
import { useEffect, useState } from 'react';

import { UserApiService } from '../api/vending-machine-api.service';
import { VendingFormField, VendingFormValues } from '../type';
import { useGetEventVending } from '../use-cases/get-event';
import { useUpdateVending } from '../use-cases/update-vending';
const { Option } = Select;






export const VendingMachineEvent = () => {
  const getInitialValues = {
    [VendingFormField.EVENTID]: undefined,
    [VendingFormField.STATUS]: undefined,
  };
  const { isLoading, mutate } = useUpdateVending();
  const { data } = useGetEventVending('' as any, { retry: true });
  const [event, setEvent_name] = useState({
    items: []
  } as any)
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const initial = async () => {
    try {
      const apiRes = await UserApiService().getVendingEvents(`Flag=${'Events'}`)
      setEvent_name(apiRes)
    } catch (error) {
      message.error("error");

    }
  }
  const onClose = () => {
    // message.success("sd")
  }
  const onFinish = (values: VendingFormValues) => {
    mutate(values, { onSuccess: onClose });
  };

  useEffect(() => {   
    initial();
    if(data?.items!=null){
      
      form.setFieldValue(VendingFormField.EVENTID, data?.items[0].event_id);
       form.setFieldValue(VendingFormField.STATUS, data?.items[0].status);
    }
    
  }, [])

  return (
    <>
      <Card title="Vending Machine Event" bordered={false} style={{ width: 850 }}>
        <Form<VendingFormValues>
          {...layout}
          form={form}
          name="control-hooks"
          initialValues={getInitialValues}
          onFinish={onFinish}
          style={{ maxWidth: 600 }}
          disabled={isLoading}
        >

          <Form.Item name={VendingFormField.EVENTID} label="Event" rules={[{ required: true }]}>
            <Select
              placeholder="Select a event"
              options={event.items.map((res: any) => ({ label: res.Text, value: res.Id }))}
              allowClear
            >

            </Select>
          </Form.Item>
          <Form.Item name={VendingFormField.STATUS} label="Status" rules={[{ required: true }]}>
            <Select
              placeholder="Select a status"
              allowClear
            >
              <Option value={true} selected>Active</Option>
              <Option value={false}>Inactive</Option>

            </Select>
          </Form.Item>
          <Form.Item >
            <Space>
              <Button type="primary" loading={isLoading} htmlType="submit">
                Update
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
