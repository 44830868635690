import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Grid, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
    EventsResponse,
    // useUpdateEvent,
} from 'features/events';
import { useStatusResponse } from 'features/shop-item/use-cases/update-status-response-msg';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';
import { Event } from 'types/event';

import { TshirtFilterFormPaginated } from '../../tshirt-filter-form';
import { AddTshirtSizeModal } from '../Add-t-shirt-size-madal/add-tshirt-size-modal';
const { useBreakpoint } = Grid;

type EventsTableProps = {
    isLoading: boolean;
    data?: EventsResponse;
    tableParams: TshirtFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<TshirtFilterFormPaginated>>;
    refetch: () => void;
    tshirtEditData: any;
    setTshirtEditData: any;
};

export const ChooseTshirtSizeTabel = ({
    data,
    isLoading,
    tableParams,
    updateParams,
    refetch,
    tshirtEditData,
    setTshirtEditData
}: EventsTableProps) => {

    const screens = useBreakpoint();

    const permissions = usePermissions();

    const { mutate: mutateUpdateItem, isLoading: isLoadingUpdate } =
        useStatusResponse();


    const { confirm } = Modal;
    const handleTableChange = (
        pagination: TablePaginationConfig,
        // filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Event> | SorterResult<Event>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: Array.isArray(sorter)
                ? undefined
                : sorter.order === 'ascend'
                    ? 'name'
                    : '-name',
        });
    };

    const tableWidth = screens.xxl ? { x: 1440 } : { x: 1200 };

    // const isEditing = (id: string) => {
    //     return editingId === id;
    // };
    const handleDelete = async (id: any) => {
        mutateUpdateItem({
            id: id,
            flag: "TSHIRT_SIZEMASTER_DELETE",
            status: true
        });
    };


    const [showTshirtModal, setShowTshirtModal] = useState(false);
    if (!permissions) return null;

    const handleCloseModal = () => {
        setShowTshirtModal(false);
        setTshirtEditData(undefined);
    }
    const showDeleteConfirm = (record: any) => {
        confirm({
            title: 'Are you sure you want to delete this t-shirt?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record.id);
            },
        });
    };

    const handleOpenModal = (record: any) => {
        setShowTshirtModal(true);
        setTshirtEditData(record);
    }

    return (
        <>
            <div className={`cstmrightmdl ${showTshirtModal && "show"}`}>
                <AddTshirtSizeModal showTshirtModal={showTshirtModal} refetch={refetch} onClose={handleCloseModal} tshirtEditData={tshirtEditData} />
            </div>
            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={"T-Shirt Size found"}
                        count={data?.total || 0}
                    />
                </StyledTableHeader>
                <Table<Event>

                    className="cstltblwthldr"
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading && { indicator: <div><TableLoader /></div> }}
                    dataSource={isLoading ? [] : data?.items}
                    rowKey='id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.page,
                        defaultPageSize: tableParams.size,
                        total: data?.total,
                    }}
                    scroll={tableWidth}
                // onRow={(record) => {
                //     return {
                //         onClick: () => {
                //             handleOpenModal(record);
                //         },
                //     };
                // }}
                >
                    <Column<Event>
                        title={'Title'}
                        dataIndex={'title'}
                        // sorter={true}
                        // sortDirections={['ascend', 'descend', 'ascend']}
                        // defaultSortOrder={'descend'}
                        width={screens.xxl ? 'auto' : 200}
                    // render={(record: any) => (
                    //     <div>
                    //         <RightOutlined />
                    //         {record?.title}
                    //     </div>
                    // )}
                    // onCell={(record: any) => ({
                    //     onClick: () => {
                    //         handleOpenModal(record);
                    //     },
                    // })}
                    />
                    <Column<any>
                        title={'Vendor Machine Part No.'}
                        dataIndex={'vendor_part_no'}
                        width={screens.xxl ? 'auto' : 120}
                    />


                    <Column<Event>
                        title={"Action"}
                        width={screens.xxl ? 'auto' : 162}
                        dataIndex={'is_active'}
                        render={(_, record) => (
                            <>
                                <Space>
                                    <Tooltip title="Edit">
                                        <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                                    </Tooltip>

                                    <Tooltip title="Delete">
                                        <Button shape="circle" loading={isLoadingUpdate} onClick={() => showDeleteConfirm(record)} icon={<DeleteOutlined />} />
                                    </Tooltip>
                                    <Tooltip title="Is Active">
                                        <Switch
                                            defaultChecked={record.is_active}
                                            checked={record.is_active}
                                            loading={isLoadingUpdate}
                                            onChange={(isChecked) => {
                                                mutateUpdateItem({
                                                    id: record.id,
                                                    flag: "TSHIRT_SIZEMASTER_SET_STATUS",
                                                    status: isChecked,
                                                });
                                                // Update the is_active status locally
                                                record.is_active = isChecked;
                                            }}
                                        // onClick={(checked, event) => {
                                        //     event.stopPropagation();
                                        //     event.preventDefault();
                                        // }}
                                        />
                                    </Tooltip>
                                </Space>
                            </>
                        )}
                    />
                </Table>
            </StyledTableWrapper>
        </>

    );
};

