import { Button, Form } from 'antd';
import { useCreateColor } from 'features/cms/use-cases/create-color';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AddColorForm from './add-color-form';
import { ColorFormField, ColorFormValues } from './types';


export const AddColorModal = ({ onClose, refetch, colorEditData }: { onClose: () => void; showColorModal: boolean, refetch: () => void, colorEditData: any }) => {
    const { id } = useParams();
    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<ColorFormValues>();
    const { isLoading, mutate } = useCreateColor();

    const getInitialValues = {
        [ColorFormField.Id]: id,
        [ColorFormField.Title]: undefined,
        [ColorFormField.ColorCode]: undefined,
        [ColorFormField.IsActive]: undefined,
    };

    const handleUpdate = (formData: ColorFormValues) => {
        formData[ColorFormField.Id] = colorEditData?.id;
        formData[ColorFormField.IsActive] = true;
        mutate(formData, { onSuccess: hanldeSuccess });
    };

    const hanldeSuccess = () => {
        handleClose();
        refetch();
    }

    useEffect(() => {
        if (colorEditData) {
            form.setFieldsValue(colorEditData);
        } else {
            form.resetFields();
        }
    }, [colorEditData])

    const handleClose = () => {
        onClose();
        form.resetFields();
    }

    return (

        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={handleClose} className='close-btn'>
                    &times;
                </button>
                <h4>{colorEditData ? "Edit Color" : "Add Color"}</h4>
            </div>
            <div className='modal_body' style={{
                height: "90vh",
                overflowY: "auto"
            }}>
                <Form<ColorFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    requiredMark={'optional'}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <AddColorForm />
                </Form>
                <div className='button-group'>
                    <Button htmlType='submit' onClick={form.submit} loading={isLoading} className='btn'>Save</Button>
                </div>

            </div>
        </div>

    )
};

