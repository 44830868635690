import { Form, Spin } from 'antd';
import { Loader, Modal, Result404 } from 'components';
import { UpdateUserDTO } from 'features/users/api/user-api.service';
import { UserInfoFormItems } from 'features/users/components/modals/user-info-form-items';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import {
  UserFormField,
  UserFormValues,
} from 'features/users/components/users-filter-form';
import { useGetUser, useUpdateUser } from 'features/users/use-cases';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Gender } from 'types';


export const EditEndUserModal = ({
  onClose,
  onSuccess,
  id,
}: {
  onClose: () => void;
  onSuccess: () => void;
  id: string;
}) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const formName = 'edit-end-user-form';
  const [form] = Form.useForm<UserFormValues>();
  const { t } = useTranslation();

  const userQueryResult = useGetUser(id, {
    cacheTime: 0, //workaround to invalidate initialValues of client data form
  });

  const {
    isLoading: isLoadingGetUser,
    isFetching: isFetchingGetUser,
    data: userData,
    isError: isErrorUser,
  } = userQueryResult;


  const { mutate: mutateUpdateUser, isLoading: isLoadingUpdateUser } =
    useUpdateUser();

  const isLoading = {
    loading: isLoadingGetUser || isFetchingGetUser || isLoadingUpdateUser,
    isLoadingGetUser,
    isLoadingUpdateUser,
  };

  const handleUpdate = (formData: UserFormValues) => {
    const payload: UpdateUserDTO = {
      id: id,
      first_name: formData.first_name,
      last_name: formData.last_name,
      phone_number: formData.phone_number,
      email: formData.email.length ? formData.email : null,
      role: 'User',
      gender: formData.gender,
      is_active: formData.is_active,
      address: formData.address
    };
    mutateUpdateUser(payload, { onSuccess });
  };

  const getInitialValues = {
    [UserFormField.FirstName]: userData?.first_name || '',
    [UserFormField.LastName]: userData?.last_name || '',
    [UserFormField.PhoneNumber]: userData?.phone_number || '',
    [UserFormField.Email]: userData?.email || '',
    [UserFormField.Gender]: userData?.gender || Gender.NotSpecified,
    [UserFormField.Address]: userData?.address || '',
    [UserFormField.IsActive]: userData?.is_active || ''
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        open
        centered
        title={t('adminUsers.userInfo')}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={t('adminUsers.updateUser')}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{
          form: formName,
        }}
      >
        {isLoadingGetUser ? (
          <Loader />
        ) : isErrorUser ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoading.loading}>
            <Form<UserFormValues>
              id={formName}
              name={formName}
              form={form}
              layout='vertical'
              initialValues={getInitialValues}
              onFinish={handleUpdate}
              requiredMark={'optional'}
              onFinishFailed={() => {
                setValidateTrigger(['onChange']);
              }}
              validateTrigger={validateTrigger}
              disabled={isLoading.loading}
            >
              {/* form={form} */}
              <UserInfoFormItems  />
            </Form>
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
