
export enum OutletFormField {
    id = "id",
    event_id = "event_id",
    name = "name",
    contact_no = "contact_no",
    email = "email",
    address = "address",
    is_active = "is_active"
};

export type OutletFormValues = {
    [OutletFormField.id]: string;
    [OutletFormField.event_id]: any;
    [OutletFormField.name]: string;
    [OutletFormField.contact_no]: string;
    [OutletFormField.email]: string;
    [OutletFormField.address]: string;
    [OutletFormField.is_active]: boolean;
};