import { LeftOutlined } from '@ant-design/icons';
import { Grid, Typography } from 'antd';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  StyledBackButton,
  StyledHeadingContent,
  StyledHeadingWrapper,
  StyledTitleWrapper,
  StyledWrapper,
} from './top-content-bar.styles';

interface TopContentBarProps {
  title: string;
  renderHeadingContent?: ReactNode;
  renderButtons?: ReactNode;
  hasBackButton?: string;
  ctFilter?: ReactNode;
}

const { useBreakpoint } = Grid;

export const ContentTopBar = ({
  title,
  renderHeadingContent,
  renderButtons,
  hasBackButton = '',
  ctFilter,
}: TopContentBarProps) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  
return (
    <StyledWrapper className='cstm_head'>
      <StyledHeadingWrapper className='hed_ttl'>
        <StyledTitleWrapper className='hht_data'>

          {hasBackButton.length ? (
            <StyledBackButton type='link' onClick={() => navigate(hasBackButton)}>
              <LeftOutlined width={24} height={24} />
            </StyledBackButton>
          ) : null}
          <Typography.Title className='title'
            level={3}
            ellipsis={{ tooltip: title }}
            data-testid='page-heading'
          >
            {title} 
          </Typography.Title>          
        </StyledTitleWrapper>
        {renderButtons && !screens.lg && <div>{renderButtons}</div>}
        {renderHeadingContent && (
          <StyledHeadingContent>{renderHeadingContent}</StyledHeadingContent>
        )}

        {ctFilter && <StyledHeadingContent>{ctFilter}</StyledHeadingContent>}
      </StyledHeadingWrapper>
      {renderButtons && screens.lg && <div>{renderButtons}</div>}
    </StyledWrapper>
  );
};
