import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  EventsApiService,
  EventsParams,
  EventsResponse,
} from 'features/events/api/events-api.service';

import { eventsQueryKeys } from './query-keys';

const { getEvents } = EventsApiService();

const getEventsQuery = (
  params: EventsParams,
  options?: UseQueryOptions<EventsResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<EventsResponse>;
  options?: UseQueryOptions<EventsResponse>;
} => ({
  queryKey: eventsQueryKeys.list(params),
  queryFn: ({ signal }) => getEvents(params, { config: { signal } }),
  ...options,
});

export const useGetEvents = (
  params: EventsParams,
  options?: UseQueryOptions<EventsResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<EventsResponse, Error>(
    getEventsQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};

export const useGetEventsInfiniteQuery = (
  params: EventsParams,
  options?: UseInfiniteQueryOptions<EventsResponse>,
) => {
  const { message } = App.useApp();

  return useInfiniteQuery({
    queryKey: eventsQueryKeys.list(params),
    queryFn: ({ signal, pageParam }) =>
      getEvents(
        { ...params, page: pageParam || params.page },
        { config: { signal } },
      ),
    getNextPageParam: (lastPage) => {
      const hasMorePages = lastPage.pages > lastPage.page;

      return hasMorePages ? lastPage.page + 1 : undefined;
    },
    onError: (error: unknown) => {
      const uiMessage = handleError(error);

      message.error(uiMessage);
    },
    ...options,
  });
};
