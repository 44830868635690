import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ContentTopBar } from 'components';
import { useGetAutoDiscount } from 'features/events/use-cases/get-auto-discount';
import { usePermissions } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useEffect, useState } from 'react'

import { AddAutoDiscountModal } from './auto-discount-add-modal/add-auto-discount-modal';
import { AutoDiscountTable } from './auto-discount-list-tabel';



const AutoDiscountComponent = ({ id, tab }: { id: string; tab: string }) => {


  const permissions = usePermissions();
  const { params, updateParams } =
    usePaginationParams<any>({
      page: 1,
      size: 10,
      order_by: '-rank',
      event_id: id,
    });

  const { data, isFetching, refetch } = useGetAutoDiscount(params);
  const shouldHandleFieldCreation = permissions?.fields.add;
  const [showAddEventModal, setShowAddEventModal] = useState<boolean>(false);
  const [trackdata, setTrackdata] = useState(undefined)

  const handleCloseModal = () => {
    setShowAddEventModal(false);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = () => {
    setShowAddEventModal(true);
    setTrackdata(undefined)
    document.body.style.overflow = 'hidden';
  }

  useEffect(() => {
    if (tab === "7") {
      refetch();
    }
  }, [tab]);

  return (
    <>
      <ContentTopBar
        title={''}
        renderButtons={
          <>
            {shouldHandleFieldCreation ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <Button
                  icon={<PlusOutlined />}
                  onClick={handleOpenModal}
                >
                  Create Discount Rules
                </Button>
                <div className={`cstmrightmdl ${showAddEventModal && "show"}`}>
                  <AddAutoDiscountModal id={id} showAddEventModal={showAddEventModal} trackdata={trackdata} refetch={refetch} onClose={handleCloseModal} />
                </div>

              </div>
            ) : null}
          </>
        }
      />

      <AutoDiscountTable
        id={id}
        data={data}
        isLoading={isFetching}
        tableParams={params}
        updateParams={updateParams}
        refetch={refetch}
        setTrackdata={setTrackdata}
        trackdata={trackdata}
      />
    </>
  )
}

export default AutoDiscountComponent;
