import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, Skeleton, Space, Switch, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import { useStatusResponse } from 'features/shop-item/use-cases/update-status-response-msg';
import { usePermissions } from 'features/users';
import { EndUserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'types';
import { Category } from 'types/category';

import { EditCategoryModal } from '../modal/edit-category-modal';

type UsersTableProps = {
  isLoading: boolean;
  data: any;
  itemsCount: number;
  refetch: any
  categoryEditData: any,
  setCategoryData: any
  tableParams: EndUserFilterFormValuesPaginated;
  updateParams: Dispatch<SetStateAction<EndUserFilterFormValuesPaginated>>;
};

export const CategoryListTable = ({
  data,
  isLoading,
  itemsCount,
  categoryEditData,
  tableParams,
  updateParams,
  refetch,
  setCategoryData,
}: UsersTableProps) => {
  const { t } = useTranslation();
  const permissions = usePermissions();
  const [categoryEdit, setCategoryEdit] = useState(false)

  const handleCloseModal = () => {
    setCategoryEdit(false);
    setCategoryData(undefined);
    document.body.style.overflow = 'visible';
  }
  const { confirm } = Modal;
  const handleOpenModal = (record: any) => {
    setCategoryEdit(true);
    setCategoryData(record);
    document.body.style.overflow = 'hidden';
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<User> | SorterResult<User>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'
          : '-name',
    });
  };

  const { mutate: mutateUpdateItem, isLoading: isLoadingUpdateSocial } =
    useStatusResponse();

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: 'Are you sure you want to delete this Category?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(record.id);
      },
    });
  };


  const handleDelete = async (id: any) => {
    mutateUpdateItem({
      id: id,
      flag: "SHOP_CATEGORY_DELETE",
      status: true
    });
  };

  const handleUpdateStatus = (id: any, isChecked: any) => {
    const statusFormData = {
      id: id,
      status: isChecked,
      flag: "SHOP_CATEGORY_SET_STATUS"
    }
    mutateUpdateItem(statusFormData);
  }

  if (!permissions) return null;

  return (
    <>
      <div className={`cstmrightmdl ${categoryEdit && "show"}`}>
        <EditCategoryModal showCategorysModal={categoryEdit} refetch={refetch} onClose={handleCloseModal} CategoryEditData={categoryEditData} />
      </div>

      <StyledTableWrapper>
        <StyledTableHeader>
          {/* <RecordCountBadge text={t('social.foundSocial')} count={data.total} /> */}
        </StyledTableHeader>
        <Table<User>
          // loading={isLoading}
          className="cstltblwthldr"
          locale={{
            emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
          }}
          loading={isLoading && { indicator: <div><TableLoader /></div> }}
          dataSource={isLoading ? [] : data?.items}
          // dataSource={data?.items as any}
          rowKey='id'
          onChange={handleTableChange}
          pagination={{
            current: tableParams.page,
            defaultPageSize: tableParams.size,
            total: itemsCount,
          }}
        >
          <Column<Category>
            title={t('shops.category.table.category')}
            dataIndex={'title'}
            //   render={(_, record) => getUserFullName(record)}
            sorter={true}
            sortDirections={['ascend', 'descend', 'ascend']}
            defaultSortOrder={'ascend'}
          />
          <Column
            title={t('shops.category.table.action')}
            render={(_, record: any) => (
              <Space>
                <Tooltip title="Edit">
                  <Button shape="circle" onClick={() => handleOpenModal(record)}
                    icon={<EditOutlined />} />
                </Tooltip>
                <Tooltip title="Delete">
                  <Button shape="circle" loading={isLoadingUpdateSocial} icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(record)} />
                </Tooltip>
                <Tooltip title="Is Active">
                  <Switch
                    defaultChecked={record.is_active}
                    checked={record.Status}
                    loading={isLoadingUpdateSocial}
                    onChange={(isChecked) => handleUpdateStatus(record.id, isChecked)}
                  />
                </Tooltip>
              </Space>
            )}
          />
        </Table>
      </StyledTableWrapper>
    </>
  );
};
