import { Button, Form, Image } from 'antd';
import Input from 'antd/es/input/Input';
import { useCreateTrack } from 'features/events/use-cases/create-track';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TipsFormField, TipsFormValues } from './types';


export const AddTrackListModal = ({ id, onClose, showAddEventModal, trackdata }: { id: any, trackdata: any, onClose: () => void; showAddEventModal: boolean, refetch: () => void }) => {
    const { t } = useTranslation();
    const formName = 'add-tips-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<TipsFormValues>();

    const [imageData, setImageData] = useState<File | any>(null);

    const handleImageChange = (e: any) => {
        setImageData(e.target.files[0]);
    };

    const { isLoading, mutate } = useCreateTrack(trackdata?.id);
    const getInitialValues = {
        [TipsFormField.Id]: undefined,
        [TipsFormField.Name]: undefined,
        [TipsFormField.File]: undefined,
        [TipsFormField.EventId]: undefined,

    };


    const handleSuccess = () => {
        handleClose();
        // refetch();
    }

    const handleClose = () => {
        onClose();
        setImageData(null);
    }

    const handleUpdate = (formData: TipsFormValues) => {
        formData[TipsFormField.Id] = trackdata?.id
        formData[TipsFormField.EventId] = id;
        formData[TipsFormField.File] = imageData;
        mutate(formData, { onSuccess: handleSuccess });
    };

    useEffect(() => {
        if (trackdata) {
            form.setFieldsValue(trackdata);
        } else {
            form.resetFields();
        }
    }, [trackdata, showAddEventModal])


    return (

        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={handleClose} className='close-btn'>
                    &times;
                </button>
                <h4>{trackdata ? 'Edit Add Race Track' : "Add Race Track"}</h4>
            </div>
            <div className='modal_body'>
                <Form<TipsFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    // requiredMark={'optional'}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <FormSectionsSeparator title={""}>

                        <Form.Item<TipsFormField>
                            label={"Name"}
                            name={TipsFormField.Name}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        {imageData && trackdata?.Image && <Image
                            src={trackdata?.Image}
                        />}

                        <Form.Item<TipsFormField>
                            label={"Photo"}
                            name={TipsFormField.File}
                        >
                            {/* <Upload
                                fileList={imageData}
                                onChange={handleImageChange}
                                maxCount={1}
                                beforeUpload={() => false}
                                accept=".png,.jpg,.jpeg"
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>

                            </Upload> */}
                            <Input type="file" onChange={handleImageChange} accept=".png,.jpg,.jpeg" />
                        </Form.Item>

                    </FormSectionsSeparator>
                </Form>
                <div className='button-group'>
                    <Button loading={isLoading} htmlType='submit' onClick={form.submit} className='btn'>Save</Button>
                </div>

            </div>
        </div>
    )
};

