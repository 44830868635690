import { Button, Form } from 'antd';
import { useCreateCampignCode } from 'features/events/use-cases/create-compaing-code';
import { useEffect, useState } from 'react';

import { AddCampaignCodeForm } from './add-campaign-code-form';
import { CampaignCodeFormField, CampaignCodeFormValues } from './types';


export const AddCampaignCodeModal = ({ onClose, editId, id }: { onClose: () => void; showAddPriceModal: boolean; editId: any, refetch: () => void; id: any }) => {
    const formName = 'add-Campaign-code-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<CampaignCodeFormValues>();
    const { isLoading, mutate } = useCreateCampignCode(editId?.id);

    const handleClose = () => {
        onClose();
        form.resetFields();
    }

    const getInitialValues = {
        [CampaignCodeFormField.Id]: undefined,
        [CampaignCodeFormField.EventId]: undefined,
        [CampaignCodeFormField.Provider]: undefined,
        [CampaignCodeFormField.Code]: undefined,
    };


    const handleUpdate = (formData: CampaignCodeFormValues) => {
        formData[CampaignCodeFormField.Id] = editId?.id;
        formData[CampaignCodeFormField.EventId] = id;
        mutate(formData, { onSuccess: handleClose });
    };


    useEffect(() => {
        if (editId) {
            form.setFieldsValue(editId);
        }
    }, [editId])

    return (
        <>

            {
                <>
                    <div className='popup'>
                        <div className='modal_head'>
                            <h4>Add Campaign Code</h4>
                            <button onClick={handleClose} className='close-btn'>
                                &times;
                            </button>

                        </div>
                        <div className='modal_body'>

                            <Form<CampaignCodeFormValues>
                                id={formName}
                                name={formName}
                                form={form}
                                layout='vertical'
                                initialValues={getInitialValues}
                                onFinish={handleUpdate}
                                // requiredMark={'optional'}
                                onFinishFailed={() => {
                                    setValidateTrigger(['onChange']);
                                }}
                                validateTrigger={validateTrigger}
                                disabled={isLoading}
                            >
                                <div className='frmscrll'>
                                    <AddCampaignCodeForm />
                                </div>
                                <div className='form-button'><Button type="primary" htmlType='submit' className="btn" >Save</Button></div>

                            </Form>

                        </div>
                    </div>
                </>

            }

        </>
    )
};
