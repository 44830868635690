import { Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { EventMarketingCodeComponent } from './event marketing-code/event-marketing-code';
import { EventCampaignCodeComponent } from './EventCampaign/event-campaign-code';

const EventMarketingComponent = () => {

  const { id } = useParams();

  const [tabs, setTabs] = useState<any>("1")

  const handleTabChange = (activeKey: any) => {
    setTabs(activeKey);
  }

  if (!id) return null;

  return (
    <>
      <Tabs className='gallery_tab' defaultActiveKey='1' onChange={handleTabChange}>
        <TabPane className='cltb' tab="Campaign Code" key="1">
          <EventCampaignCodeComponent id={id} tabs={tabs} />
        </TabPane>
        <TabPane className='cltb' tab="Marketing Code" key="2">
          <EventMarketingCodeComponent id={id} tabs={tabs} />
        </TabPane>
      </Tabs>
    </>
  );
};
export default EventMarketingComponent;
