export enum LoginFormField {
  Email = 'email_id',
  //PhoneNumber = 'phone_number',
  Password = 'password',
  Remember = 'remember',
}

export type LoginFormValues = {
  [LoginFormField.Email]: string;
  [LoginFormField.Password]: string;
  [LoginFormField.Remember]: boolean;
};
