import { Button, Checkbox, DatePicker, Form } from 'antd';
import Input from 'antd/es/input/Input';
import dayjs from 'dayjs';
import { useCreateAutoDiscount } from 'features/events/use-cases/create-auto-discount';
import { handleKeyDownThree, handleKeyPercent } from 'features/events/utils';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { payFormatDate } from 'utils/payload-date-';

import { AutoDiscountField, AutoDiscountFormValues } from './types';



export const AddAutoDiscountModal = ({ id, onClose, showAddEventModal, trackdata }: { id: any, trackdata: any, onClose: () => void; showAddEventModal: boolean, refetch: () => void }) => {
    const { t } = useTranslation();
    const formName = 'add-auto-discount-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AutoDiscountFormValues>();

    const { isLoading, mutate } = useCreateAutoDiscount(trackdata?.id);

    const getInitialValues = {
        [AutoDiscountField.Id]: undefined,
        [AutoDiscountField.EventId]: undefined,
        [AutoDiscountField.Name]: undefined,
        [AutoDiscountField.Description]: undefined,
        [AutoDiscountField.Threshold]: undefined,
        [AutoDiscountField.IsActive]: true,
        [AutoDiscountField.DiscountPercentage]: undefined,
        [AutoDiscountField.StartDate]: undefined,
        [AutoDiscountField.EndDate]: undefined,

    };


    const handleSuccess = () => {
        handleClose();
        // refetch();
    }

    const handleClose = () => {
        onClose();
    }

    const handleUpdate = (formData: AutoDiscountFormValues) => {
        formData[AutoDiscountField.Id] = trackdata?.id
        formData[AutoDiscountField.EventId] = id;
        formData[AutoDiscountField.StartDate] = formData.start_date && payFormatDate(formData.start_date);
        formData[AutoDiscountField.EndDate] = formData.end_date && payFormatDate(formData.end_date)
        mutate(formData, { onSuccess: handleSuccess });
    };

    useEffect(() => {
        if (trackdata) {
            form.setFieldsValue({
                ...trackdata,
                start_date: trackdata?.start_date && dayjs(trackdata?.start_date),
                end_date: trackdata?.end_date && dayjs(trackdata?.end_date),
            });
        } else {
            form.resetFields();
        }
    }, [trackdata, showAddEventModal])


    return (

        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={handleClose} className='close-btn'>
                    &times;
                </button>
                <h4>{trackdata ? 'Edit Discount Rules' : "Add Discount Rules"}</h4>
            </div>
            <div className='modal_body'>
                <Form<AutoDiscountFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    // requiredMark={'optional'}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <FormSectionsSeparator title={""}>

                        <Form.Item<AutoDiscountField>
                            label={"Name"}
                            name={AutoDiscountField.Name}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item<AutoDiscountField>
                            label={"Description"}
                            name={AutoDiscountField.Description}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item<AutoDiscountField>
                            label={"Threshold"}
                            name={AutoDiscountField.Threshold}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                onKeyDown={handleKeyDownThree}
                            />
                        </Form.Item>
                        <Form.Item<AutoDiscountField>
                            label={"Discount Percentage"}
                            name={AutoDiscountField.DiscountPercentage}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                onKeyDown={handleKeyPercent}
                            />
                        </Form.Item>

                        <Form.Item
                            label={"Start Date"}
                            name={AutoDiscountField.StartDate}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                                // validateDates(form.getFieldValue),
                            ]}
                        >
                            <DatePicker style={{ width: '100%' }} placeholder='' />
                        </Form.Item>

                        <Form.Item
                            label={"End Date"}
                            name={AutoDiscountField.EndDate}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                                {
                                    validator(_, value) {
                                        const startDate = form.getFieldValue(AutoDiscountField.StartDate);
                                        if (!value || !startDate) {
                                            return Promise.resolve();
                                        }

                                        const startDateObj = new Date(startDate);
                                        const endDateObj = new Date(value);

                                        if (endDateObj >= startDateObj) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error('End date must be same or after start date'));
                                    },
                                },

                            ]}
                        >
                            <DatePicker style={{ width: '100%' }} placeholder='' />
                        </Form.Item>

                        <Form.Item<AutoDiscountField>
                            label={"Active Status"}
                            name={AutoDiscountField.IsActive}
                            valuePropName="checked"
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}

                        >
                            <Checkbox />
                        </Form.Item>

                    </FormSectionsSeparator>
                    <div className='button-group'>
                        <Button loading={isLoading} htmlType='submit' className='btn'>Save</Button>
                    </div>
                </Form>

            </div>
        </div>
    )
};

