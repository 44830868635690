import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, message, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import { VolunteerUserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { useUpdateStatus } from 'features/users/use-cases/create-user';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { ROUTES } from 'routes/constants';
// import { getRoute } from 'routes/utils';
import { Volunteer } from 'types';

import { AddVolunteerUserModal } from '../modals/add-volunteer-user-modal';

type UsersTableProps = {
  isUsersListLoading: boolean;
  usersList: Volunteer[];
  itemsCount: number;
  tableParams: VolunteerUserFilterFormValuesPaginated;
  updateParams: Dispatch<SetStateAction<VolunteerUserFilterFormValuesPaginated>>;
  volunteerEditData: any;
  setVolunteerEditData: any;
};

export const VolunteerUsersTable = ({
  usersList,
  isUsersListLoading,
  itemsCount,
  tableParams,
  updateParams,
  volunteerEditData,
  setVolunteerEditData,
}: UsersTableProps) => {
  const { t } = useTranslation();

  const [showVolunteerModal, setShowVolunteerModal] = useState(false);
  const [loadingSwitchId, setLoadingSwitchId] = useState('');

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Volunteer> | SorterResult<Volunteer>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'
          : '-name',
    });
  };

  const handleOpenModal = (record: any) => {
    setShowVolunteerModal(true);
    setVolunteerEditData(record);
  }

  const { confirm } = Modal;

  const showDeleteConfirm = (record: any) => {
    //console.log(record,"record");
    confirm({
      title: 'Are you sure you want to delete this Volunteer?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(record.Id);
      },
    });
  };

  const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdate } =
    useUpdateStatus();

  const handleDelete = async (id: any) => {

    try {
      // Simulate the loading state
      mutateUpdateStatus({
        Id: id,
        flag: "VOLUNTEER_DELETE",
        status: true
      });

    } catch (error) {
      // Handle any errors here
      message.error("")
    }
  };



  const handleCloseModal = () => {
    setShowVolunteerModal(false);
    setVolunteerEditData(undefined);
  }

  const resetRecorId = () => {
    setLoadingSwitchId('');
  }

  return (
    <>
      {/* refetch={refetch} */}
      {showVolunteerModal ? (<div className={`cstmrightmdl ${showVolunteerModal && "show"}`}>
        <AddVolunteerUserModal onClose={handleCloseModal} volunteerEditData={volunteerEditData} />
      </div>) : ''}



      <StyledTableWrapper>
        <StyledTableHeader>
          <RecordCountBadge
            text={'Total Volunteer '}
            count={itemsCount}
          />
        </StyledTableHeader>
        <Table<Volunteer>
          // loading={isUsersListLoading}
          // dataSource={usersList}
          rowKey='id'
          onChange={handleTableChange}
          pagination={{
            current: tableParams.page,
            defaultPageSize: tableParams.size,
            total: itemsCount,
          }}
          scroll={{ x: 1440 }}
          locale={{
            emptyText: isUsersListLoading ? <Skeleton active={true} /> : <Empty />
          }}
          loading={isUsersListLoading && { indicator: <div><TableLoader /></div> }}
          dataSource={isUsersListLoading ? [] : usersList}
          className=' cstltblwthldr'

        >
          <Column<Volunteer>
            title={'Name'}
            dataIndex={'Name'}
            sorter={true}
            sortDirections={['ascend', 'descend', 'ascend']}
            defaultSortOrder={'ascend'}
          />
          <Column<Volunteer>
            title={'Phone'}
            dataIndex={'PhoneNo'}
          />
          <Column<Volunteer>
            title={'Email'}
            dataIndex={'Email'}

          />
          <Column<Volunteer>
            title={'Refund Role'}
            dataIndex={'IsRefundAllowed'}
            render={(_, record) => record.IsRefundAllowed === true ? 'Yes' : 'No'}
          />

          <Column<Volunteer>
            title={'Event'}
            dataIndex={'EventName'}
          />

          <Column<Volunteer>
            title={t('adminUsers.table.date')}
            dataIndex={'user'}
            render={(_, record) => moment(record.CreatedDate).format("YYYY-MM-DD HH:MM:SS")}

          />

          <Column<Volunteer>
            title={"Action"}
            dataIndex={'Status'}
            render={(_, record) => (
              <Space>
                <Tooltip title="Edit">
                  <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                </Tooltip>
                <Tooltip title="delete">
                  <Button shape="circle" onClick={() => showDeleteConfirm(record)} icon={<DeleteOutlined />} />
                </Tooltip>
                <Tooltip title="is active">
                  <Switch
                    defaultChecked={record.Status}
                    checked={record.Status}
                    loading={loadingSwitchId == record.Id && isLoadingUpdate}
                    onChange={(isChecked) => {
                      setLoadingSwitchId(record.Id);
                      mutateUpdateStatus({
                        Id: record.Id,
                        flag: "VOLUNTEER_SET_STATUS",
                        status: isChecked,
                      }, { onSettled: resetRecorId });
                      record.Status = isChecked;
                    }}
                  />
                </Tooltip>
              </Space>

            )}
          />
        </Table>
      </StyledTableWrapper>
    </>
  );
};
