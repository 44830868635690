import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { UserApiService, UserListParams, UserResponse,VolunteerListParams,VolunteerResponse } from 'features/users';
import qs from 'qs';

import { userQueryKeys } from './query-keys';

const { getUsers,getVolunteerUsers } = UserApiService();

const getUsersQuery = (
  params: UserListParams,
  options?: UseQueryOptions<UserResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<UserResponse>;
  options?: UseQueryOptions<UserResponse>;
} => ({
  queryKey: userQueryKeys.list(params),
  queryFn: ({ signal }) =>
    getUsers(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

export const useGetUsersList = (
  params: UserListParams,
  options?: UseQueryOptions<UserResponse>,
) => {
  const { message } = App.useApp();
     
  return useQuery<UserResponse, Error>(
    getUsersQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};

export const useGetVolunteerUsersList = (
  params: VolunteerListParams,
  options?: UseQueryOptions<VolunteerResponse>,
) => {
  const { message } = App.useApp();
     
  return useQuery<VolunteerResponse, Error>(
    getVolunteerUsersQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};



const getVolunteerUsersQuery = (
  params: UserListParams,
  options?: UseQueryOptions<VolunteerResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<VolunteerResponse>;
  options?: UseQueryOptions<VolunteerResponse>;
} => ({
  queryKey: userQueryKeys.list(params),
  queryFn: ({ signal }) =>
    getVolunteerUsers(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

