import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message, Modal, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { Loader } from 'components';
import { useUploadBulkExcelCoupon } from 'features/shop-item/use-cases/coupon-bulk-upload';
// import { useUploadBulkExcel } from 'features/transaction_list/use-cases/upload-bulk-excel';
import { useState } from 'react';
import * as XLSX from 'xlsx';


export const EventCouponBulkUpload = ({ id }: { id: any }) => {


    const [fileList, setFileList] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const { mutate } = useUploadBulkExcelCoupon();

    const labels: string[] = ["Coupon Title", "Limit Usage", "Value Type", "Value", "Start Date", "End Date"];

    const handleUpload = async (file: RcFile) => {
        setLoading(true);
        const isExcel =
            file.type === 'application/vnd.ms-excel' ||
            file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        if (!isExcel) {
            message.error('You can only upload Excel files!');

            return false;
        }
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            const data = e.target?.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            if (jsonData.length <= 1) {
                message.error('The Excel file does not contain any data.');
            }
            const header = jsonData[0] as string[];
            const lowerCaseHeader = header.map((col) => col.toLowerCase().trim().replace(/\s+/g, ''));
            const lowerCaseRequiredColumns = labels.map((col) => col.toLowerCase().trim().replace(/\s+/g, ''));
            const missingColumns = lowerCaseRequiredColumns.filter((col) => !lowerCaseHeader.includes(col));
            const extraColumns = lowerCaseHeader.filter((col) => !lowerCaseRequiredColumns.includes(col));
            const errors: any[] = [];
            if (missingColumns.length > 0) {
                missingColumns.forEach((column) => {
                    const index = lowerCaseRequiredColumns.indexOf(column);
                    if (index !== -1) {
                        errors.push(`Missing required column: "${labels[index]}".`);
                    }
                });
            }

            if (extraColumns.length > 0) {
                errors.push(`Extra columns found "${extraColumns}". Please remove them.`);
                // return false;
            }

            const couponTitleIndex = lowerCaseHeader.indexOf('coupontitle');
            const noPeopleIndex = lowerCaseHeader.indexOf('limitusage');
            const valueTypeIndex = lowerCaseHeader.indexOf('valuetype');
            const valueIndex = lowerCaseHeader.indexOf('value');
            const startDateIndex = lowerCaseHeader.indexOf('startdate');
            const endDateIndex = lowerCaseHeader.indexOf('enddate');

            jsonData.slice(1).forEach((row: any, rowIndex) => {
                if (couponTitleIndex !== -1 && !row[couponTitleIndex]) {
                    errors.push(`Coupon Title is missing in row ${rowIndex + 2}.`);
                }

                if (noPeopleIndex !== -1 && !validateNumber(row[noPeopleIndex])) {
                    errors.push(`Limit Uses must be a number in row ${rowIndex + 2}.`);
                }

                if (valueTypeIndex !== -1 && !row[valueTypeIndex]) {
                    errors.push(`Value Type is missing in row ${rowIndex + 2}.`);
                } else if (!["Flat", "Percent"].includes(row[valueTypeIndex])) {
                    errors.push(`Value Type is not matching with options Flat or Percent in row ${rowIndex + 2}.`);
                }

                if (valueIndex !== -1 && !validateNumber(row[valueIndex])) {
                    errors.push(`Value is must be a number in row ${rowIndex + 2}.`);
                }

                const startDate = row[startDateIndex];
                const endDate = row[endDateIndex];

                if (startDateIndex !== -1) {
                    const startDateString = typeof startDate === 'number' ? convertExcelDateToJSDate(startDate) : startDate;
                    if (!validateDate(startDateString)) {
                        errors.push(`Invalid Start Date in row ${rowIndex + 2}: ${startDateString}`);
                    }
                }

                if (endDateIndex !== -1) {
                    const endDateString = typeof endDate === 'number' ? convertExcelDateToJSDate(endDate) : endDate;
                    const startDateString = typeof startDate === 'number' ? convertExcelDateToJSDate(startDate) : startDate;

                    if (!validateDate(endDateString)) {
                        errors.push(`Invalid End Date in row ${rowIndex + 2}: ${endDateString}`);
                    } else {
                        const parsedStartDate = new Date(startDateString);
                        const parsedEndDate = new Date(endDateString);

                        if (parsedStartDate >= parsedEndDate) {
                            errors.push(`End Date must be greater than Start Date in row ${rowIndex + 2}: Start Date - ${startDateString}, End Date - ${endDateString}`);
                        }
                    }
                }
            });
            setLoading(false);
            if (errors.length === 0) {
                handlePostExcel(file)
            } else {
                showValidationErrors(errors)

            }
        };
        setFileList([]);
        reader.readAsBinaryString(file);

        return false;
    };

    const showValidationErrors = (errors: string[]) => {
        Modal.error({
            title: 'Validation Errors',
            content: (
                <ul>
                    {errors.map((error, index) => (
                        <li key={index}><b>{error}</b></li>
                    ))}
                </ul>
            ),
            icon: <ExclamationCircleOutlined />,
            okText: 'OK',
        });
    };


    const validateNumber = (input: any) => {
        const re = /^[0-9]+$/;

        return re.test(input);
    };

    const convertExcelDateToJSDate = (excelDate: number) => {
        // Excel dates start from January 1, 1900
        const date = new Date(Math.round((excelDate - 25569) * 86400 * 1000));
        const dateString = date.toISOString().split('T')[0]; // Adjust format as needed

        return dateString;
    };

    const validateDate = (dateString: string | number | Date) => {
        // Function to validate date format
        const date = new Date(dateString);

        return !isNaN(date.getTime());
    }

    if (loading) return <Loader />;

    const handleChange = (info: { file: any; fileList: any; }) => {
        const newFileList = info.fileList.slice(-1); // Keep only the latest file
        setFileList(newFileList);

        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            setFileList([]); // Clear the file list
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const handlePostExcel = (file: any) => {
        const formData = new FormData();
        formData.append(`EventId`, id);
        formData.append(`File`, file);
        formData.append(`Flag`, "manual");
        mutate(formData, {
            onSuccess: () => {
                handleChange({ file, fileList: [] });
            }
        });

    }





    // if (isLoading) return <Spin />;

    return (
        <div >
            <Upload
                beforeUpload={handleUpload}
                accept=".xls, .xlsx"
                // disabled={!eventNameSelected}
                multiple={false}
                showUploadList={true}
                fileList={fileList}
                onChange={handleChange}
            >
                <Button icon={<UploadOutlined />} style={{ marginRight: '18px' }} className='btn_common'>
                    Bulk Upload
                </Button>
            </Upload>


        </div>
    );
};



