import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { DeliveryListTable } from '../components/delivery-charge/delivery-table/delivery-table';
import { SocialFilterFormValues } from '../components/shop-filter-form/type';
import { DeliveryListTopBar } from '../components/top-bar/delivery-top-bar';
import { useGetDelivery } from '../use-cases/get-delivery';

const getInitialFilterValues: any = {
  name: undefined,
};
export const DeliveryListView = () => {
  const { params, updateParams, resetPage } = usePaginationParams<any>({
    page: 1,
    size: 10,
    ...getInitialFilterValues,
  });

  const { data, refetch, isFetching } = useGetDelivery(params, {
    retry: false,
  });

  const [deliveryData, setDeliveryData] = useState<any>(undefined);
  const handleFiltersChange = (values: SocialFilterFormValues) => {
    updateParams(values);
    resetPage();
    refetch()
  };

  return (

    <>
      <DeliveryListTopBar
        handleFilter={handleFiltersChange}
        getInitialFilterValues={getInitialFilterValues}
        refetch={refetch}
      />
      <DeliveryListTable
        isDeliveryListLoading={isFetching}
        data={data}
        tableParams={params as any}
        updateParams={updateParams}
        refetch={refetch}
        deliveryEditData={deliveryData}
        setDeliveryData={setDeliveryData}
      />
      <Outlet />
    </>
  );
};
