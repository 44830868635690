import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { axiosInstance } from 'api/axios-instance';
import { CONFIG } from 'config';
// import { FieldBookingsParams, FieldBookingsResponse } from 'features/fields';
// import {
//   BookingType,
//   Company,
//   CompanyType,
//   Duration,
//   Facility,
//   Source,
//   Sport,
//   SportType,
//   Time,
//   Transaction,
//   User,
// } from 'types';
import { Event } from 'types/event';
// export type EventBooking = {
//   id: string;
//   client: Pick<User, 'first_name' | 'last_name'>;
//   start_date: string;
//   end_date: string;
//   source: Source;
//   transaction: Transaction[];
//   type: BookingType;
// };



// export type EventsBookingsParams = {
//   date: string;
//   facility_id?: string;
//   company_id?: string;
//   players?: number;
//   company_type?: CompanyType;
//   sport_id?: string;
// } & PaginatedRequestParams;

// export type EventBookingsParams = {
//   id?: string;
//   date: string;
//   field_id?: string;
//   sport_id?: string;
// };

// export type EventFormDataParams = {
//   id?: string;
// };


export type EventsResponse = PaginatedResponse<Event>;

export type EventsParams = {
  is_active?: boolean;
  field_name?: string;
  facility_id?: string;
} & PaginatedRequestParams;

export type CreateSportsCategoryDTO = {
  id?: string;
  category_name: string
  file: any
  is_active: boolean
  image_url: string
};


export type CreateEventFormDTO = {
  id?: any | undefined,
  event_id?: string | undefined,
  registration_json?: string | any[],
};

export type SettingFormDTO = {
  FeaturedEvent: boolean,
  AsATeam: boolean,
  Free: boolean,
  LockRegistration: boolean,
  PrivateLink: boolean,
  VirtualEvent: boolean,
  RegisterNow: boolean,
  ComingSoon: boolean,
  SoldOut: boolean,
  Closed: boolean,
};

export type UpdateEventDetailsDTO = {
  EventId: string,
  EventDescription: string,
  EventMasterId: string,
  EventName: string,
  EventNameAR: string,
  CategoryId: string,
  StartDate: string,
  EndDate: string,
  EventDate: string,
  CurrencyId: string,
  // Currency: string,
};

export type UpdateEventDTO = {
  is_active?: boolean;
  id: string;
  rank?: number;
} & UpdateEventDetailsDTO;

export type CraeteTshirtDTO = {
  id?: string,
  title: string,
  vendor_part_no: string,
  is_active: boolean,
}
export type CraeteDisclaimerDTO = {
  id?: string,
  text: string,
}

export type CraeteCountriesDTO = {
  id?: string,
  title: string,
  title_arabic: string,
  is_active: boolean,
}

export type CraeteEventMasterDTO = {
  id?: string,
  title: string,
  is_active: boolean,
}


export type CraeteFaqDTO = {
  id?: string,
  question: string,
  answer: string,
  is_active: boolean,
}

export type CreateGlobalDisclaimerDTO = {
  id?: string,
  data: string,
}

export type CraeteManageAreaDTO = {
  id?: string,
  cid: string,
  name: string,
  is_active: boolean,
}


export type CraeteCurrencyDTO = {
  id?: string,
  currency_name: string,
  currency_code: string,
  currency_symbol: string,
  basic_currency: string,
  friction: string,
  is_active: boolean,
}



export type CraeteConversionCurrencyDTO = {
  id?: string,
  CurrencyName: string,
  ToCurrency: string,
  ConvertValue: number,
  IsActive: boolean,
}

export type CreateColorDTO = {
  id?: string,
  title: string,
  title_arabic: string,
  color_code: string,
  is_active: boolean,
}

export type CreateSizeDTO = {
  id?: string,
  size_name: string,
  size_name_ar: string;
  is_active: boolean,
}

export const CMSApiService = () => {

  const getSportsCategory = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/sportscategory`,
      params,
    });

    return response.data;
  };

  const updateEvent = async (
    payload: UpdateEventDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<Event>({
      ...callConfig,
      endpoint: `/admin/events/${id}`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };

  const getCouponMaster = async (
    flag: any,
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/coupons?Flag=${flag}`,
      params,
    });

    return response.data;
  };


  const replaceSubstring = (
    input: string,
    target: string,
    replacement: string,
  ): string => {
    const startIndex = input.indexOf(target);
    const endIndex = startIndex + target.length;
    const replacedStr =
      input.slice(0, startIndex) + replacement + input.slice(endIndex);

    return replacedStr;
  };

  const createSportsCategory = async (formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/masters/sportscategory`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const getTshirtSize = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/tshirtsize`,
      params,
    });

    return response.data;
  };

  const getRacekit = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/racekit`,
      params,
    });

    return response.data;
  };

  const createRacekit = async (formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/masters/racekit`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const createTshirtSize = async (payload: CraeteTshirtDTO) => {
    const response = await apiClient({
      endpoint: `/admin/masters/tshirtsize`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getCurrency = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/currency`,
      params,
    });

    return response.data;
  };


  const getConversionCurrency = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/currencyconversion/getcurrencyconversionlist`,
      params,
    });

    return response.data;
  };


  const createCurrency = async (payload: CraeteCurrencyDTO) => {
    const response = await apiClient({
      endpoint: `/admin/masters/currency`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const createConversionCurrency = async (payload: CraeteConversionCurrencyDTO) => {
    const response = await apiClient({
      endpoint: `/admin/currencyconversion/addupdatecurrencyconversion`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getPartners = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/partners`,
      params,
    });

    return response.data;
  };


  const createPartners = async (formData: FormData) => {
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/masters/partners`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const createDisclaimer = async (payload: CraeteDisclaimerDTO) => {
    const response = await apiClient({
      endpoint: `/admin/masters/eventmaster`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getCountries = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/countries`,
      params,
    });

    return response.data;
  };


  const createCountries = async (payload: CraeteCountriesDTO) => {
    const response = await apiClient({
      endpoint: `/admin/masters/countries`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const getArea = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/area`,
      params,
    });

    return response.data;
  };

  const createManageArea = async (payload: CraeteManageAreaDTO) => {
    const response = await apiClient({
      endpoint: `/admin/masters/area`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getEventMaster = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/eventmaster`,
      params,
    });

    return response.data;
  };

  const createEventMaster = async (payload: CraeteEventMasterDTO) => {
    const response = await apiClient({
      endpoint: `/admin/masters/eventmaster`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getColor = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/colors`,
      params,
    });

    return response.data;
  };

  const getSize = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/size`,
      params,
    });

    return response.data;
  };

  const getFaq = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/faq`,
      params,
    });

    return response.data;
  };

  const createColor = async (payload: CreateColorDTO) => {
    const response = await apiClient({
      endpoint: `/admin/masters/colors`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const createSize = async (payload: CreateSizeDTO) => {
    const response = await apiClient({
      endpoint: `/admin/masters/size`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const createFaq = async (payload: CraeteFaqDTO) => {
    const response = await apiClient({
      endpoint: `/admin/masters/faq`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const createGlobalDisclaimer = async (payload: CreateGlobalDisclaimerDTO) => {
    const response = await apiClient({
      endpoint: `/admin/masters/globaldisclaimer`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  // const getGlobalDisclaimer = async (
  // ) => {
  //   const response = await apiClient<any>({
  //     endpoint: `/admin/globaldisclaimer`,
  //   });

  //   return response.data;
  // };

  const getGlobalDisclaimer = async () => {
    const response = await apiClient({
      endpoint: `/admin/masters/globaldisclaimer`
    })

    return response.data
  }

  // const getGlobalDisclaimer = async (
  //   params: EventsParams,
  //   callConfig?: Partial<ApiClientConfig>,
  // ) => {
  //   const response = await apiClient<EventsResponse>({
  //     ...callConfig,
  //     endpoint: `/admin/masters/globaldisclaimer`,
  //     params,
  //   });

  // }
  // const getGlobalDisclaimer = async (
  //   params: EventsParams,
  //   callConfig?: Partial<ApiClientConfig>,
  // ) => {
  //   const response = await apiClient<EventsResponse>({
  //     ...callConfig,
  //     endpoint: `/admin/masters/globaldisclaimer`,
  //     params,
  //   });

  //   return response.data;
  // };

  const getDsicoverBanner = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/discoverbanner`,
      params,
    });

    return response.data;
  };


  const createDiscoverBanner = async (formData: FormData) => {
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/masters/discoverbanner`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  return {
    createSportsCategory,
    getCouponMaster,
    updateEvent,
    getSportsCategory,
    getTshirtSize,
    getCurrency,
    createCurrency,
    getRacekit,
    createRacekit,
    createTshirtSize,
    getPartners,
    createPartners,
    createDisclaimer,
    getCountries,
    createCountries,
    getArea,
    createManageArea,
    getEventMaster,
    createEventMaster,
    getColor,
    createColor,
    getSize,
    createSize,
    getFaq,
    createFaq,
    createGlobalDisclaimer,
    getGlobalDisclaimer,
    getDsicoverBanner,
    createDiscoverBanner,
    getConversionCurrency,
    createConversionCurrency
  };
};
