import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  CreateUserDTO,
  UserApiService,
  VolunteerUserDTO
} from 'features/users/api/user-api.service';
import i18n from 'locales/i18n';

import { userQueryKeys } from './query-keys';


const { createUser, createVolunteer, updateStatus, saveEventWithAgent } = UserApiService();

export const useCreateUser = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateUserDTO) => {
      return createUser(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (user) => {
        queryClient.invalidateQueries({
          queryKey: userQueryKeys.lists(),
        });
        message.success(
          i18n.t('adminUsers.formStatus.userCreated', {
            fullName: `${user.first_name} ${user.last_name}`,
            role: user.role,
          }),
        );
      },
    },
  );
};


export const useCreateVolunteerUser = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: VolunteerUserDTO) => {
      return createVolunteer(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: userQueryKeys.lists(),
        });

        message.success(i18n.t('volunteer.add_volunteer_msg')
          // i18n.t('adminUsers.formStatus.userCreated', {
          //   fullName: `${user.first_name} ${user.last_name}`,
          //   role: user.role,
          // }),
        );
      },
    },
  );
};

export const useUpdateVolunteerUser = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: VolunteerUserDTO) => {
      return createVolunteer(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: userQueryKeys.lists(),
        });

        message.success(i18n.t('volunteer.update_volunteer_msg')
          // i18n.t('adminUsers.formStatus.userCreated', {
          //   fullName: `${user.first_name} ${user.last_name}`,
          //   role: user.role,
          // }),
        );
      },
    },
  );
};

export const useUpdateStatus = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) => {
      return updateStatus(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: userQueryKeys.lists(),
        });

        message.success(i18n.t('volunteer.update_volunteer_msg')
          // i18n.t('adminUsers.formStatus.userCreated', {
          //   fullName: `${user.first_name} ${user.last_name}`,
          //   role: user.role,
          // }),
        );
      },
    },
  );
};

// type responseMessage = {
//   ResponseStatus: number;
//   ResponseMessage: string;
// }
// type mappEventRequest = {
//   agent_id: number,
//   event_id: number,
//   event_name: string

// }

export const useEventWithAgent = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) => {
      return saveEventWithAgent(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (user) => {

        queryClient.invalidateQueries({
          queryKey: userQueryKeys.lists(),
        });
        const response: any = user;
        //'i18n.t('volunteer.update_volunteer_msg')'
        message.success(`${response.ResponseMessage} `
          // i18n.t('adminUsers.formStatus.userCreated', {
          //   fullName: `${user.first_name} ${user.last_name}`,
          //   role: user.role,
          // }),
        );
      },
    },
  );
};





