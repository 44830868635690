import { Button, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExpoBoothFilterFormField, ExpoBoothFilterFormValues } from './type';
const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
  formId: string;
  handleFilter: (values: ExpoBoothFilterFormValues) => void;
  initialValues: ExpoBoothFilterFormValues;
  closeModal?: () => void;
};

export const ExpoBoothFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: UsersFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<ExpoBoothFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues })
  };

  const onFinish = (formValues: ExpoBoothFilterFormValues) => {
    handleFilter(formValues);
    closeModal?.();
  };

  return (

    <Form<ExpoBoothFilterFormValues>
      id={formId}
      name={formId}
      className='filters-form'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper>
        <StandardItemsWrapper>
          <div className='invoice_list'>
            <Form.Item
              // label={t('shops.formfield.fieldName.label')}
              label={'First Name'}
              name={ExpoBoothFilterFormField.FIRSTNAME}
            >
              <Input placeholder={'Enter First Name'} />
            </Form.Item>
            <Form.Item
              // label={t('shops.formfield.fieldName.label')}
              label={'Last Name'}
              name={ExpoBoothFilterFormField.LASTNAME}
            >
              <Input placeholder={'Enter last name'} />
            </Form.Item>
            <Form.Item
              // label={t('shops.formfield.fieldName.label')}
              label={'EMAIL'}
              name={ExpoBoothFilterFormField.EMAIL}
            >
              <Input placeholder={'Enter Email'} />
            </Form.Item>

            <Form.Item
              // label={t('shops.formfield.fieldName.label')}
              label={'Event'}
              name={ExpoBoothFilterFormField.EVENTNAME}
            >
              <Input placeholder={'Enter Event'} />
            </Form.Item>

            <Form.Item
              label={'Status'}
              name={"transaction_status"}
            >
              <Select
                placeholder="Select Status"

                options={[
                  { value: 'is_reject', label: 'Rejected' },
                  { value: 'is_confirmed', label: 'Confirmed' },
                ]}
              />
            </Form.Item>

            {screens.lg && (
              <ContentFilters.ButtonsWrapper className='filter_btn'>
                <Button type='primary' htmlType='submit'>
                  {t('common.buttons.filter', { count: 1 })}
                </Button>
                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
              </ContentFilters.ButtonsWrapper>
            )}
          </div>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

    </Form>
  );
};
