import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { useState } from 'react';
import { CompanyType } from 'types';

import { AddDeliverymodal } from '../modal/addDelivery';
import { DeliveryFilterForm } from '../shop-filter-form/delivery-filter-form';
import { SocialFilterFormValues } from '../shop-filter-form/type';

type UsersTopBarProps = {
  getInitialFilterValues: SocialFilterFormValues;
  handleFilter: (values: SocialFilterFormValues) => void;
  refetch: any
};

const { useBreakpoint } = Grid;

export const DeliveryListTopBar = ({
  getInitialFilterValues,
  handleFilter,
  refetch,
}: UsersTopBarProps) => {
  useState<CompanyType>();

  const formId = 'users-filters-form';
  const screens = useBreakpoint();
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const handleCloseModal = () => {
    setShowAddUserModal(false);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = () => {
    setShowAddUserModal(true);
    document.body.style.overflow = 'hidden';
  }

  return (

    <>
      <div className={`cstmrightmdl ${showAddUserModal && "show"}`}>
        <AddDeliverymodal onClose={handleCloseModal} refetch={refetch} showAddUserModal={showAddUserModal} />
      </div>
      <ContentTopBar
        title={'Delivery-Charge List'}
        renderButtons={
          <>
            <Button
              icon={!screens.xs ? <PlusOutlined /> : undefined}
              type='primary'
              onClick={handleOpenModal}
            >
              Add Delivery
            </Button>
          </>
        }
      />
      <ContentFilters formId={formId}>
        <DeliveryFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
