import { PaginatedRequestParams } from 'api';

export enum MainEventsFilterFormField {
    EventName = 'event_name',
    DateFrom = 'start_date',
    DateTo = 'end_date',
    EventDate = 'event_date',
    SportsCategory = 'sports_category_id',
    Currency = 'currency_id',
}

export type MainEventsFilterFormValues = {
    [MainEventsFilterFormField.EventName]: string | undefined;
    [MainEventsFilterFormField.DateFrom]: any | undefined;
    [MainEventsFilterFormField.DateTo]: any | undefined;
    [MainEventsFilterFormField.EventDate]: any | undefined;
    [MainEventsFilterFormField.SportsCategory]: string | undefined;
    [MainEventsFilterFormField.Currency]: string | undefined;
};

export type MainEventsFilterFormPaginated = MainEventsFilterFormValues &
    PaginatedRequestParams;
