export enum DiscoverBannerFormField {
    Id = 'id',
    Title = 'title',
    Description = 'description',
    Url = 'url',
    File = 'file',
    IsActive = 'is_active',
    ImageUrl = "image_url"
}

export type DiscoverBanner = {
    Id: string | undefined;
    Title: string;
    Description: string;
    Url: string;
    isActive: boolean;
    File: any;
    image_url: any;
};

export type DiscoverBannerFormValues = {
    [DiscoverBannerFormField.Id]: string | undefined;
    [DiscoverBannerFormField.Title]: string;
    [DiscoverBannerFormField.Description]: string;
    [DiscoverBannerFormField.Url]: string;
    [DiscoverBannerFormField.File]: any;
    [DiscoverBannerFormField.IsActive]: boolean;
    [DiscoverBannerFormField.ImageUrl]: string;
};