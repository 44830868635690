import { Tabs } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";

import AutoDiscountComponent from "./auto-discount/auto-discount";
import { CouponTab } from "./coupon/coupon-tab";
import EmailContent from "./show-hide-email-content/email-content";
import ShowhideItemList from "./show-hide-item-list/show-hide-item-list";
import ShowHideRacekit from "./show-hide-racekit/show-hide-racekit";
import TrackListComponent from "./show-hide-track-list/track-list";
import ShowHideTshirtSize from "./show-hide-tshirt-size/show-hide-tshirt-size";


const ShowHideAttributes = () => {

    const { id } = useParams();
    const [tabs, setTabs] = useState<any>("1")


    const handleTabChange = (activeKey: any) => {
        setTabs(activeKey)
    };

    if (!id) return null;

    const tabItems = [
        {
            key: "1",
            label: "T-Shirt Size",
            children: <ShowHideTshirtSize id={id} tab={tabs} />,
        },
        {
            key: "2",
            label: "Race Kit",
            children: <ShowHideRacekit id={id} tab={tabs} />,
        },
        {
            key: "3",
            label: "Coupon",
            children: <CouponTab id={id} tab={tabs} />,
        },
        {
            key: "4",
            label: "Item List",
            children: <ShowhideItemList id={id} tab={tabs} />,
        },
        {
            key: "5",
            label: "Track List",
            children: <TrackListComponent id={id} tab={tabs} />,
        },
        {
            key: "6",
            label: "Email Content",
            children: <EmailContent />,
        },

        {
            key: "7",
            label: "Auto Discount",
            children: <AutoDiscountComponent id={id} tab={tabs} />,
        }
    ];

    return (

        <>
            <Tabs
                className="gallery_tab"
                defaultActiveKey="1"
                onChange={handleTabChange}
                items={tabItems}
            />
        </>
    )
}

export default ShowHideAttributes;

