import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { App } from "antd";
import { handleError } from "api";
import { EventsParams, eventsQueryKeys } from "features/events";

import { ItemSalesResponse, TransectionApiServices } from "../api/transection-api.service";

const { getItemSalesList } = TransectionApiServices();


const getEventsQuery = (
    params: EventsParams,
    options?: UseQueryOptions<ItemSalesResponse>,
): {
    queryKey: QueryKey;
    queryFn: QueryFunction<ItemSalesResponse>;
    options?: UseQueryOptions<ItemSalesResponse>;
} => ({
    queryKey: eventsQueryKeys.list(params),
    queryFn: ({ signal }) => getItemSalesList(params, { config: { signal } }) as any,
    ...options,
});


export const useGetItemSalesList = (
    params: EventsParams,
    options?: UseQueryOptions<ItemSalesResponse>,
) => {
    const { message } = App.useApp();

    return useQuery<ItemSalesResponse, Error>(
        getEventsQuery(params, {
            onError: (error: unknown) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            ...options,
        }),
    );
};