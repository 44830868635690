import PropTypes from 'prop-types';
import { Cell, Pie, PieChart, Tooltip, TooltipProps } from 'recharts';

const FeeType = ({ feeTypeGraphData }: any) => {
    // Data for captured, incomplete, and uncaptured

    const data = feeTypeGraphData;

    const CustomPieTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const total = data.reduce((sum: any, entry: any) => sum + entry.value, 0);
            const percentage = (total > 0) ? (payload[0].value != undefined ? ((payload[0].value / total) * 100).toFixed(0) : 0) : 0;

            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
                    <p className="label">{`${payload[0].name} : ${percentage}%`}</p>
                    {/* <p className="extra">{`Extra Info: ${payload[0].payload.percentage}`}</p> ${payload[0].value} */}
                </div>
            );
        }

        return null;
    };

    CustomPieTooltip.propTypes = {
        active: PropTypes.bool,
        payload: PropTypes.array,
    };

    // Three colors for the datasets
    const COLORS = ['#0088FE', '#00C49F', '#FF8042', '#df0a27', '#019922', '#9300df', '#e7b421', '#21dd41', '#0ec8d1'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <PieChart width={400} height={400}>
                <Pie
                    data={feeTypeGraphData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    innerRadius={0}
                    outerRadius={150}
                    paddingAngle={1}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {feeTypeGraphData.map((entry: any, index: any) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip content={<CustomPieTooltip />} />
            </PieChart>
            <div style={{ marginTop: 20, display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ display: 'inline-block', marginRight: 20, marginBottom: 10 }}>
                    <div style={{ backgroundColor: COLORS[5], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30, }}></div>
                    <span>K-net</span>
                </div>

                <div style={{ display: 'inline-block', marginRight: 20, marginBottom: 10 }}>
                    <div style={{ backgroundColor: COLORS[2], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span>Cash</span>
                </div>

                {/* <div style={{ display: 'inline-block', marginRight: 20, marginBottom:10 }}>
                    <div style={{ backgroundColor: COLORS[1], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span>Card</span>
                </div> */}

                <div style={{ display: 'inline-block', marginBottom: 10, marginRight: 20, }}>
                    <div style={{ backgroundColor: COLORS[3], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span>Wallet</span>
                </div>
                <div style={{ display: 'inline-block', marginBottom: 10, marginRight: 20, }}>
                    <div style={{ backgroundColor: COLORS[8], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span>APPLEPAY</span>
                </div>
                <div style={{ display: 'inline-block', marginBottom: 10, marginRight: 20, }}>
                    <div style={{ backgroundColor: COLORS[6], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span>Mada</span>
                </div>
                <div style={{ display: 'inline-block', marginRight: 20, }}>
                    <div style={{ backgroundColor: COLORS[0], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span>American Express</span>
                </div>
                <div style={{ display: 'inline-block', marginRight: 20, }}>
                    <div style={{ backgroundColor: COLORS[1], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span>CREDITCARD</span>
                </div>
                <div style={{ display: 'inline-block', marginRight: 20, }}>
                    <div style={{ backgroundColor: COLORS[7], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span> Visa/Master Card</span>
                </div>
                <div style={{ display: 'inline-block' }}>
                    <div style={{ backgroundColor: COLORS[4], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span> Free</span>
                </div>
            </div>
        </div>
    );
};

export default FeeType;