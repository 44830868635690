import { FileExcelOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { VendingFilterFormValues } from 'features/vending-machine/components/vending-machine-filter-form/types';
import { VendingFilterForm } from 'features/vending-machine/components/vending-machine-filter-form/vending-filter-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyType } from 'types';

const { useBreakpoint } = Grid;

type VendingTopBarProps = {
  getInitialFilterValues: VendingFilterFormValues;
  handleFilter: (values: VendingFilterFormValues) => void;
  handleDownloadReport: () => void;
};

export const VendingMachineTopBar = ({
  getInitialFilterValues,
  handleFilter,
  handleDownloadReport,
}: VendingTopBarProps) => {
  useState<CompanyType>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const formId = 'users-filters-form';



  return (
    <>

      <ContentTopBar
        title={t('adminUsers.heading.vending-machine')}
        renderButtons={
          <>
            <Button
              icon={!screens.xs ? <FileExcelOutlined /> : undefined}
              onClick={handleDownloadReport}
              type='primary'
            >
              Export Report
              {/* {t('adminUsers.addNewUser')} */}
            </Button>
          </>
        }
      />

      <ContentFilters formId={formId}>
        <VendingFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
