import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { WalletListTopBar } from '../component/top-bar/wallet-bar';
import { WalletFilterFormValuesPaginated } from '../component/wallet-filter-form/types';
import { WalletList } from '../component/wallet-list/wallet-list';
import { useGetWalletList } from '../use-cases/get-wallet-list';

const getInitialFilterValues: any = {
  name: undefined,
  mobile_no: undefined,
  email: undefined,
  pay_type: undefined,
  transaction_status: undefined,
  date: undefined,
  auth_id: undefined,
  transaction_id: undefined,
};

export const WalletListView = () => {
  const { params, updateParams, resetPage } = usePaginationParams<any>({
    page: 1,
    size: 10,
    ...getInitialFilterValues,
  });
  const { data, refetch, isFetching } = useGetWalletList(params, { retry: false });

  const handleFiltersChange = (values: WalletFilterFormValuesPaginated) => {
    updateParams(values);
    resetPage();
    refetch()
  };

  return (

    <>
      <WalletListTopBar
        handleFilter={handleFiltersChange}
        getInitialFilterValues={getInitialFilterValues}
      />
      <WalletList
        isSocialListLoading={isFetching}
        DeliveryList={data}
        tableParams={params as any}
        updateParams={updateParams}
        refetch={refetch}

      />
      <Outlet />
    </>
  );
};
