import { Button, Form, Input, } from 'antd';
import { useCreateEventMaster } from 'features/cms/use-cases/craete-event-master';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { EventMasterFormField, EventMasterFormValues } from './types';;


export const AddEventMasterModal = ({ onClose, refetch, eventEditData }: { onClose: () => void; refetch: () => void, eventEditData: any }) => {
    const { id } = useParams();
    const formName = 'add-event-master-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<EventMasterFormValues>();
    const { t } = useTranslation();
    const { isLoading, mutate } = useCreateEventMaster();

    const getInitialValues = {
        [EventMasterFormField.Id]: id,
        [EventMasterFormField.Title]: undefined,
        [EventMasterFormField.IsActive]: undefined,
    };


    const handleUpdate = (formData: EventMasterFormValues) => {
        formData[EventMasterFormField.Id] = eventEditData?.id;
        formData[EventMasterFormField.IsActive] = true;
        mutate(formData, { onSuccess: hanldeSuccess });
    };

    const hanldeSuccess = () => {
        onClose();
        refetch();
    }

    useEffect(() => {
        if (eventEditData) {
            form.setFieldsValue(eventEditData);
        } else {
            form.resetFields();
        }
    }, [eventEditData])


    return (

        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={onClose} className='close-btn'>
                    &times;
                </button>
                <h4>{eventEditData ? "Edit Event master" : "Add Event master"}</h4>
            </div>
            <div className='modal_body'>
                <Form<EventMasterFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    requiredMark={'optional'}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <FormSectionsSeparator title={""}>
                        <Form.Item<EventMasterFormField>
                            label={"Title"}
                            name={EventMasterFormField.Title}

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Enter title"}
                            // prefix={<UserOutlined className='input-prefix-icon' />}
                            />
                        </Form.Item>

                    </FormSectionsSeparator>
                </Form>
                <div className='button-group'>
                    <Button htmlType='submit' onClick={form.submit} loading={isLoading} className='btn'>Save</Button>
                </div>

            </div>
        </div>

    )
};

