import { ContentTopBar } from 'components'
import { usePaginationParams } from 'hooks';
import { useParams } from 'react-router-dom';

import { UploadDetailsTable } from '../component/upload-deatils-list-tabel/upload-details-list-UploadDetails-table';
import { useGetUploadDetails } from '../use-cases/get-upload-details';

const UploadListDetails = () => {
    const { id } = useParams();

    const { params, updateParams } =
        usePaginationParams<any>({
            id: id,
            page: 1,
            size: 10,
        });

    const { data, isFetching, refetch } = useGetUploadDetails(params, { retry: false });


    return (
        <>

            <ContentTopBar
                title={'Bulk Upload Participant List'}
            />


            <UploadDetailsTable
                data={data}
                isLoading={isFetching}
                tableParams={params}
                refetch={refetch}
                updateParams={updateParams}
                id={id}

            />
        </>
    )
}

export default UploadListDetails
