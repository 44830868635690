import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyType } from 'types';

import { CouponBulkUpload } from '../coupon-management/coupon-bulk-upload';
import { AddShopCouponModal } from '../modal/AddShopCouponModal';
import { CouponFilterForm } from '../shop-filter-form/coupon-filter-form';
import { CouponFormFieldValue } from '../shop-filter-form/type';


type UsersTopBarProps = {
  getInitialFilterValues: CouponFormFieldValue;
  handleFilter: (values: CouponFormFieldValue) => void;
  refetch: any
};
const { useBreakpoint } = Grid;
export const CouponListTopBar = ({
  getInitialFilterValues,
  handleFilter,
  refetch,
}: UsersTopBarProps) => {
  useState<CompanyType>();
  const { t } = useTranslation();
  const formId = 'users-filters-form';
  const screens = useBreakpoint();
  const [showAddItemModal, setShowAddItemModal] = useState<boolean>(false);

  const handleOpenModal = () => {
    setShowAddItemModal(true)
    document.body.style.overflow = 'hidden';
  }

  const handleCloseModal = () => {
    setShowAddItemModal(false)
    document.body.style.overflow = 'visible';
  }

  return (
    <>
      <div className={`cstmrightmdl ${showAddItemModal && "show"}`}>
        <AddShopCouponModal onClose={handleCloseModal} refetch={refetch} showAddItemModal={showAddItemModal} couponEditData={""} />
      </div>

      <ContentTopBar
        title={t('shops.category.coupon.heading')}
        renderButtons={
          <>
            {/* <Button
              icon={!screens.xs ? <PlusOutlined /> : undefined}
              // onClick={handleOpenModal}
              style={{ marginRight: 10 }}
              type='primary'

            >
              Bluk Upload
            </Button> */}
            <div className='two_btn'>
              <CouponBulkUpload />
              <Button

                icon={!screens.xs ? <PlusOutlined /> : undefined}
                onClick={handleOpenModal}
                type='primary'
              >
                Add Coupon
              </Button>
            </div>
          </>
        }
      />

      <ContentFilters formId={formId}>
        <CouponFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
          refetch={refetch}
        />
      </ContentFilters>
    </>
  );
};
