import { Form, Input } from 'antd';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator'
import { useTranslation } from 'react-i18next'

import { SizeFormField } from './types'

const AddSizeForm = () => {
    const { t } = useTranslation();


    return (
        <div>
            <FormSectionsSeparator title={""}>
                <Form.Item<SizeFormField>
                    label={"Size"}
                    name={SizeFormField.size_name}
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Input
                        placeholder={"Enter Size"}

                    />
                </Form.Item>
                {/* <Form.Item<SizeFormField>
                    label={"Size name in arabic"}
                    name={SizeFormField.size_name_ar}
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Input
                        placeholder={"Enter Size name in arabic"}

                    />
                </Form.Item> */}
                {/* <Form.Item<SizeFormField>
                    // label={"CategoryId"}
                    name={SizeFormField.IsActive}
                    valuePropName="checked"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Checkbox>Is Active</Checkbox>
                </Form.Item> */}
            </FormSectionsSeparator>
        </div>
    )
}

export default AddSizeForm;
