import { Button, Form } from 'antd';
import { useCreateDiscountTickets } from 'features/events/use-cases/create-discount-tickets';
import { useEffect, useState } from 'react';
import { payFormatDate } from 'utils/payload-date-';

import { AddDiscountForm } from './add-discount-form';
import { DiscountFormField, DiscountyFormValues } from './types';



export const AddDiscountModal = ({ onClose, showAddPriceModal, editId, refetch, id }: { onClose: () => void; showAddPriceModal: boolean; editId: any, refetch: () => void; id: any }) => {
    const formName = 'add-discount-category-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<DiscountyFormValues>();
    const { isLoading, mutate } = useCreateDiscountTickets();

    const handleClose = () => {
        onClose();
        form.resetFields();
    }

    const getInitialValues = {
        [DiscountFormField.EventId]: id,
        [DiscountFormField.CategoryId]: undefined,
        [DiscountFormField.PriceType]: undefined,
        [DiscountFormField.DateFrom]: undefined,
        [DiscountFormField.DateTo]: undefined,
        [DiscountFormField.IndividualPrice]: undefined,
        [DiscountFormField.TeamPrice]: undefined,
    };


    const handleUpdate = (formData: DiscountyFormValues) => {
        formData[DiscountFormField.EventId] = id;
        formData[DiscountFormField.DateFrom] = payFormatDate(formData.date_from);
        formData[DiscountFormField.DateTo] = payFormatDate(formData.date_to);
        mutate(formData, { onSuccess: handleSuccess });
    };

    const handleSuccess = () => {
        handleClose();
        refetch()
    }

    useEffect(() => {
        if (editId) {
            form.setFieldsValue(editId);
        }
    }, [editId])

    return (
        <>

            {
                <>
                    <div className='popup'>
                        <div className='modal_head'>
                            <h4>Add Discount</h4>
                            <button onClick={handleClose} className='close-btn'>
                                &times;
                            </button>

                        </div>
                        <div className='modal_body'>

                            <Form<DiscountyFormValues>
                                id={formName}
                                name={formName}
                                form={form}
                                layout='vertical'
                                initialValues={getInitialValues}
                                onFinish={handleUpdate}
                                // requiredMark={'optional'}
                                onFinishFailed={() => {
                                    setValidateTrigger(['onChange']);
                                }}
                                validateTrigger={validateTrigger}
                                disabled={isLoading}
                            >
                                <div className='frmscrll'>
                                    <AddDiscountForm editId={editId} id={id} showAddPriceModal={showAddPriceModal} />
                                </div>
                                <div className='form-button'><Button type="primary" htmlType='submit' className="btn" >Save</Button></div>

                            </Form>

                        </div>
                    </div>
                </>

            }

        </>
    )
};

