import { Button, ContentTopBar } from 'components';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { AddNotiModalList } from '../components/modal/AddModalList';
import { NotificationListTable } from '../components/notification-list-table/list-table';
import { NotificationTopBar } from '../components/top-bar/notification-list-top-bar';
import { NotificationFilterFormValues, NotificationFilterFormValuesPaginated } from '../components/type';
import { useGetList } from '../use-cases/get-list';

const getInitialFilterValues: NotificationFilterFormValues = {
  id: undefined,
  title: undefined,
  is_active: undefined
};

export const NotifictionViewList = () => {
  const [EditData, setEditData] = useState<any>(undefined);
  const [showModal, setShowModal] = useState(false);
  const { params, updateParams, resetPage } =
    usePaginationParams<NotificationFilterFormValuesPaginated>({
      page: 1,
      size: 10,
      order_by: 'id',
      ...getInitialFilterValues,
    });


  const { data, refetch, isFetching } = useGetList(params, { retry: false });

  const handleCloseModal = () => {
    setShowModal(false);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = () => {
    setShowModal(true);
    document.body.style.overflow = 'hidden';
  }
  const handleFiltersChange = (values: NotificationFilterFormValuesPaginated) => {
    updateParams(values);
    resetPage();
    refetch();
  };

  return (
    <>
      <ContentTopBar
        title={'Notification list'}
        renderButtons={
          <>
            <Button
              type='primary'
              onClick={handleOpenModal}
            >
              Add List
            </Button>
            <div className={`cstmrightmdl ${showModal && "show"}`}>
              <AddNotiModalList showModal={showModal} refetch={refetch} onClose={handleCloseModal} EditData={EditData} />
            </div>
          </>
        }
      />
      <NotificationTopBar
        handleFilter={handleFiltersChange}
        getInitialFilterValues={getInitialFilterValues}
        refetch={refetch}
      />
      <NotificationListTable
        isUsersListLoading={isFetching}
        List={data?.items as any}
        itemsCount={data?.total as number}
        tableParams={params}
        refetch={refetch}
        updateParams={updateParams}
        EditData={EditData}
        setEditData={setEditData}
      />
      <Outlet />
    </>
  );
};
