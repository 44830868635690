import { Button, Form } from 'antd';
import { UpdateUserDTO } from 'features/users/api/user-api.service';
import { UserInfoFormItems } from 'features/users/components/modals/user-info-form-items';
import {
  UserFormField,
  UserFormValues,
} from 'features/users/components/users-filter-form';
import { useGetUser, useUpdateUser } from 'features/users/use-cases';
import { useState } from 'react';
import { Gender } from 'types';

export const UpdateAdminUserModal = ({
  onClose,
  onSuccess,
  id,
}: {
  onClose: () => void;
  onSuccess: () => void;
  id: string;
}) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const formName = 'edit-user-form';
  const [form] = Form.useForm<UserFormValues>();

  const userQueryResult = useGetUser(id, {
    cacheTime: 0, //workaround to invalidate initialValues of client data form
  });

  const {
    isLoading: isLoadingGetUser,
    isFetching: isFetchingGetUser,
    data: userData,
    // isError: isErrorUser,
  } = userQueryResult;

  const { mutate: mutateUpdateUser, isLoading: isLoadingUpdateUser } =
    useUpdateUser();

  const isLoading = {
    loading: isLoadingGetUser || isFetchingGetUser || isLoadingUpdateUser,
    isLoadingGetUser,
    isLoadingUpdateUser,
  };

  const handleClose = () => {
    onClose();
    form.resetFields();
  }


  const handleUpdate = (formData: UserFormValues) => {
    const payload: UpdateUserDTO = {
      id: id,
      company_id: formData.company_id,
      email: formData.email.length ? formData.email : null,
      first_name: formData.first_name,
      gender: formData.gender,
      last_name: formData.last_name,
      password: formData.password,
      phone_number: formData.phone_number,
      role: formData.role,
      is_active: formData.is_active,
      address: formData.address
    };
    mutateUpdateUser(payload, { onSuccess });
  };

  const getInitialValues = {
    [UserFormField.FirstName]: userData?.first_name || '',
    [UserFormField.LastName]: userData?.last_name || '',
    [UserFormField.PhoneNumber]: userData?.phone_number || '',
    [UserFormField.Company]: userData?.id || undefined,
    [UserFormField.Role]: userData?.role || '',
    [UserFormField.Email]: userData?.email || '',
    [UserFormField.Gender]: userData?.gender || Gender.NotSpecified,
    [UserFormField.Address]: userData?.address || '',
    [UserFormField.IsActive]: userData?.is_active || '',

  };

  return (
    // <StyledUserModalWrapper>
    //   <Modal
    //     open

    //     title={t('adminUsers.adminInfo')}
    //     onCancel={onClose}
    //     getContainer={false}
    //     onOk={form.submit}
    //     okText={t('adminUsers.updateUser')}
    //     cancelButtonProps={{ style: { display: 'none' } }}
    //     okButtonProps={{
    //       form: formName,
    //     }}
    //   >
    //     {isLoadingGetUser ? (
    //       <Loader />
    //     ) : isErrorUser ? (
    //       <Result404 />
    //     ) : (
    //       <Spin spinning={isLoading.loading}>
    <div className={'popup'}>
      <div className='modal_head'>
        <button onClick={handleClose} className='close-btn'>
          &times;
        </button>
        <h4>Update admin user</h4>
      </div>
      <div className='modal_body'>
        <Form<UserFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          requiredMark={'optional'}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading.loading}
        >
          {/* title={t('adminUsers.accountInfo')} */}
          {/* form={form} */}
          <UserInfoFormItems />
          {/* <PasswordFormItems  /> */}
          <div className='form-button'>
            <Button type="primary" htmlType='submit' className="btn" >Update</Button>
          </div>
        </Form>
      </div>
    </div>

    //       </Spin>
    //     )}
    //   </Modal>
    // </StyledUserModalWrapper>
  );
};
