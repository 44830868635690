import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { EventsApiService, UpdateEventDTO } from 'features/events';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

import { eventsQueryKeys } from './query-keys';

const { updateEvent } = EventsApiService();

export const useUpdateEvent = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: UpdateEventDTO) => {
      return updateEvent(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: eventsQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: eventsQueryKeys.detail(variables.id),
          }),
        ]);
        message.success(i18n.t('fields.update.successfulUpdate'));
      },
    },
  );
};
