import { Empty, Skeleton, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import { VendingFilterFormValuesPaginated } from 'features/vending-machine/components/vending-machine-filter-form';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { VendingMachine } from 'types';

type VendingTableProps = {
  isUsersListLoading: boolean;
  vendorReportList: VendingMachine[];
  itemsCount: number;
  tableParams: VendingFilterFormValuesPaginated;
  updateParams: Dispatch<SetStateAction<VendingFilterFormValuesPaginated>>;
};

export const VendingMachineTable = ({
  vendorReportList,
  isUsersListLoading,
  itemsCount,
  tableParams,
  updateParams,
}: VendingTableProps) => {
  const { t } = useTranslation();
  // const permissions = usePermissions();



  // const { mutate: mutateUpdateUser, isLoading: isLoadingUpdateUser } =
  //   useUpdateUser();

  // if (!permissions) return null;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<VendingMachine> | SorterResult<VendingMachine>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'
          : '-name',
    });
  };



  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('vendingMachine.foundRecord')}
          count={itemsCount}
        />
      </StyledTableHeader>
      <Table<VendingMachine>
        // loading={isUsersListLoading}
        // dataSource={vendorReportList}
        className='cstltblwthldr'
        locale={{
          emptyText: isUsersListLoading ? <Skeleton active={true} /> : <Empty />
        }}
        loading={isUsersListLoading && { indicator: <div><TableLoader /></div> }}
        dataSource={isUsersListLoading ? [] : vendorReportList}

        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: itemsCount,
        }}
        scroll={{ x: 1440 }}

      >
        <Column<VendingMachine>
          title={'Participant ID'}
          dataIndex={'participant_id'}
          sorter={true}
          sortDirections={['ascend', 'descend', 'ascend']}
          defaultSortOrder={'ascend'}
        />

        <Column<VendingMachine>
          title={'Name'}
          dataIndex={'full_name'}

        />

        <Column<VendingMachine>
          title={'Email ID'}
          dataIndex={'email_address'}

        />
        <Column<VendingMachine>
          title={'Race category'}
          dataIndex={'category_name'}

        />

        <Column<VendingMachine>
          title={'Race Name'}
          dataIndex={'event_name'}

        />

        <Column<VendingMachine>
          title={'Time'}
          dataIndex={'created_on'}
          render={(_, record) => moment(record.created_on).format("DD-MMMM-YYYY")}

        />

      </Table>
    </StyledTableWrapper>
  );
};
