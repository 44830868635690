import { VolunteerUsersTopBar } from 'features/users/components/top-bar/volunteer-users-top-bar';
import { VolunteerUsersTable } from 'features/users/components/users/volunteer-users-table';
import {
  VolunteerUserFilterFormValues,
  VolunteerUserFilterFormValuesPaginated,
} from 'features/users/components/users-filter-form/types';
import { useGetVolunteerUsersList } from 'features/users/use-cases/get-users';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

const getInitialFilterValues: VolunteerUserFilterFormValues = {
  Name: undefined,
  PhoneNo: undefined,
  Email: undefined,
};

export const VolunteerUsersView = () => {

  const [volunteerEditData, setVolunteerEditData] = useState<any>(undefined);
  const { params, updateParams, resetPage } =
    usePaginationParams<VolunteerUserFilterFormValuesPaginated>({
      page: 1,
      size: 10,
      order_by: 'name',
      ...getInitialFilterValues,
    });

  const getUsersListQueryResponse = useGetVolunteerUsersList((params), {
    retry: false,
  });


  const handleFiltersChange = (values: VolunteerUserFilterFormValuesPaginated) => {
    updateParams(values);
    resetPage();
  };

  return (
    <>
      <VolunteerUsersTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
        volunteerEditData={volunteerEditData}
      />
      <VolunteerUsersTable
        isUsersListLoading={
          getUsersListQueryResponse.isFetching
        }
        usersList={getUsersListQueryResponse.data?.items || []}
        itemsCount={getUsersListQueryResponse.data?.total || 0}
        tableParams={params}
        updateParams={updateParams}
        volunteerEditData={volunteerEditData}
        setVolunteerEditData={setVolunteerEditData}

      />
      <Outlet />
    </>
  );
};
