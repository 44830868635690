import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { queryClient } from 'providers';

import { ShopApiService } from '../api/shop-api.service';
import { eventsQueryKeys } from './query-keys';

const { createItemImage } = ShopApiService();

export const useCreateShopItemImage = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: any) => {
      return createItemImage(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: eventsQueryKeys.all,
        });
        message.success("image uploaded successfully");
      },
    },
  );
};
