import { Cell, Pie, PieChart,Tooltip } from 'recharts';
const RegisteredUser = () => {
    // Data for captured, incomplete, and uncaptured
    const data = [
        { name: 'Website', value: 400 },
        { name: 'Ios', value: 300 },
        { name: 'Android', value: 300 },
    ];

    // Three colors for the datasets
    const COLORS = ['#0088FE', '#00C49F', '#FF8042'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <PieChart width={400} height={400}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                    
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
           
            <div style={{ marginTop: 20, display: 'flex' }}>
                <div style={{ display: 'inline-block', marginRight: 30 }}>
                    <div style={{ backgroundColor: COLORS[0], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span>Website</span>
                </div>
                <div style={{ display: 'inline-block', marginRight: 30 }}>
                    <div style={{ backgroundColor: COLORS[1], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span>Ios</span>
                </div>
                <div style={{ display: 'inline-block' }}>
                    <div style={{ backgroundColor: COLORS[2], width: 15, height: 15, display: 'inline-block', marginRight: 5, borderRadius: 30 }}></div>
                    <span>Android</span>
                </div>
            </div>
        </div>
    );
};

export default RegisteredUser;
