import { Button, Form } from 'antd';
import dayjs from 'dayjs';
import { useCreatePriceCategory } from 'features/events/use-cases/create-price-category';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { payFormatDate } from 'utils/payload-date-';

import { AddPriceCategoryForm } from './add-price-category-form';
import { PriceCategoryFormField, PriceCategoryFormValues } from './types';

export const AddPriceCategoryModal = ({ onClose, editId }: { onClose: () => void; showAddPriceModal: boolean; editId: any, refetch: () => void }) => {
    const { id } = useParams();
    const eventId = id;
    const formName = 'add-price-category-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<PriceCategoryFormValues>();
    const { isLoading, mutate } = useCreatePriceCategory(editId?.Id);

    const handleClose = () => {
        onClose();
        form.resetFields();
    }

    const getInitialValues = {
        [PriceCategoryFormField.Id]: undefined,
        [PriceCategoryFormField.EventId]: undefined,
        [PriceCategoryFormField.Category]: undefined,
        [PriceCategoryFormField.CategoryAr]: undefined,
        [PriceCategoryFormField.Description]: undefined,
        [PriceCategoryFormField.DateFrom]: undefined,
        [PriceCategoryFormField.DateTo]: undefined,
        [PriceCategoryFormField.NoOfPersons]: undefined,
        [PriceCategoryFormField.RegistrationType]: undefined,
        [PriceCategoryFormField.GenderType]: undefined,
        [PriceCategoryFormField.StartNumber]: undefined,
        [PriceCategoryFormField.EndNumber]: undefined,
        [PriceCategoryFormField.Utilization]: undefined,
        [PriceCategoryFormField.AgeFrom]: undefined,
        [PriceCategoryFormField.AgeTo]: undefined,
        [PriceCategoryFormField.CategoryId]: undefined,
        [PriceCategoryFormField.IsRefundable]: false,
        [PriceCategoryFormField.Refund_desc]: undefined,
        [PriceCategoryFormField.Refund_price]: undefined,
        [PriceCategoryFormField.IsDelivarable]: false,
        [PriceCategoryFormField.Delivery_desc]: undefined,
        [PriceCategoryFormField.Delivery_price]: undefined,
        [PriceCategoryFormField.MinPI]: 1,
        [PriceCategoryFormField.MinPT]: undefined,
        [PriceCategoryFormField.MaxPI]: undefined,
        [PriceCategoryFormField.MaxPT]: undefined,
        [PriceCategoryFormField.PriceIn]: undefined,
        [PriceCategoryFormField.PriceTe]: undefined,
        [PriceCategoryFormField.PriceType]: undefined,
        [PriceCategoryFormField.Status]: true,
        [PriceCategoryFormField.IsWaiting]: false,
        [PriceCategoryFormField.TotalWaiting]: undefined,
        [PriceCategoryFormField.RegistEndDate]: undefined,
        [PriceCategoryFormField.RegistStartDate]: undefined,
    };


    const handleUpdate = (formData: PriceCategoryFormValues) => {
        formData[PriceCategoryFormField.Id] = editId?.Id;
        formData[PriceCategoryFormField.EventId] = eventId;
        formData[PriceCategoryFormField.Refund_price] = formData.Refundable_charge ?? 0;
        formData[PriceCategoryFormField.Refund_desc] = formData.Refundable_description ?? "";
        formData[PriceCategoryFormField.Delivery_price] = formData.Delivery_charge ?? 0;
        formData[PriceCategoryFormField.Delivery_desc] = formData.Delivery_description ?? "";
        if (editId) formData[PriceCategoryFormField.PriceType] = editId.Price_type;
        formData[PriceCategoryFormField.Status] = true;
        formData[PriceCategoryFormField.RegistStartDate] = formData.WaitingStartDate && payFormatDate(formData.WaitingStartDate);
        formData[PriceCategoryFormField.RegistEndDate] = formData.WaitingEndDate && payFormatDate(formData.WaitingEndDate);

        formData[PriceCategoryFormField.DateFrom] = formData.Date_from && payFormatDate(formData.Date_from);
        formData[PriceCategoryFormField.DateTo] = formData.Date_to && payFormatDate(formData.Date_to);
        // formData[PriceCategoryFormField.TotalWaiting] = +formData.WaitingParticipant;
        formData[PriceCategoryFormField.IsWaiting] = formData.IsWaiting ? formData.IsWaiting : false;
        mutate(formData, { onSuccess: handleSuccess });
    };


    const handleSuccess = () => {
        handleClose();
        // refetch()
    }

    useEffect(() => {
        if (editId) {
            form.setFieldsValue({
                ...editId,
                WaitingStartDate: editId?.WaitingStartDate && dayjs(editId?.WaitingStartDate),
                WaitingEndDate: editId?.WaitingEndDate && dayjs(editId?.WaitingEndDate),
                Date_from: editId?.Date_from && dayjs(editId?.Date_from),
                Date_to: editId?.Date_to && dayjs(editId?.Date_to),
            });
        }
    }, [editId])

    return (
        <>

            {
                <>
                    <div className='popup'>
                        <div className='modal_head'>
                            <h4>Add Tickets</h4>
                            <button onClick={handleClose} className='close-btn'>
                                &times;
                            </button>

                        </div>
                        <div className='modal_body'>

                            <Form<PriceCategoryFormValues>
                                id={formName}
                                name={formName}
                                form={form}
                                layout='vertical'
                                initialValues={getInitialValues}
                                onFinish={handleUpdate}
                                // requiredMark={'optional'}
                                onFinishFailed={() => {
                                    setValidateTrigger(['onChange']);
                                }}
                                validateTrigger={validateTrigger}
                                disabled={isLoading}
                            >
                                <div className='frmscrll'>
                                    <AddPriceCategoryForm editId={editId} form={form} />
                                </div>
                                {/* <div className='form-button'> */}
                                <Form.Item>
                                    <Button type="primary" htmlType='submit' className="btn" >Save</Button>
                                </Form.Item>

                                {/* </div> */}

                            </Form>

                        </div>
                    </div >
                </>

            }

        </>
    )
};
