import { Grid, Table, TablePaginationConfig } from 'antd'
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components'
import { StyledTableHeader, StyledTableWrapper } from 'components/table/table.styled'
import { EventsFilterFormPaginated } from 'features/events';
import { Dispatch, SetStateAction } from 'react'

const { useBreakpoint } = Grid;

type EventsTableProps = {
    isLoading: boolean;
    data?: any;
    tableParams: EventsFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<EventsFilterFormPaginated>>
};

const UploadDetailsComponentTable = ({
    data,
    isLoading,
    tableParams,
    updateParams }: EventsTableProps) => {

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Event> | SorterResult<Event>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: Array.isArray(sorter)
                ? undefined
                : sorter.order === 'ascend'
                    ? 'name'
                    : '-name',
        });
    };

    const screens = useBreakpoint();

    const tableWidth = screens.xxl ? { x: 1440 } : { x: 1200 };

    return (
        <div>
            <StyledTableWrapper>
                <StyledTableHeader>

                    <RecordCountBadge
                        text={"Participants found"}
                        count={data?.total || 0}
                    />

                </StyledTableHeader>
                <Table<Event>
                    loading={isLoading}
                    dataSource={data?.items[0].participant_list}
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.page,
                        defaultPageSize: tableParams.size,
                        total: data?.total,
                    }}
                    className='event_table '
                    scroll={tableWidth}
                >
                    <Column<Event>
                        title={'Category'}
                        dataIndex={'category_name'}
                        sorter={true}
                        sortDirections={['ascend', 'descend', 'ascend']}
                        defaultSortOrder={'descend'}
                        width={screens.xxl ? 'auto' : 100}
                        ellipsis
                    />

                    <Column<Event>
                        title={'First Name'}
                        dataIndex={'first_name'}
                        width={screens.xxl ? 'auto' : 200}

                    />

                    <Column<Event>
                        title={'Last Name'}
                        dataIndex={'last_name'}
                        width={screens.xxl ? 'auto' : 240}
                    />
                    {/* <Column<Event>
                        title={'DOB'}
                        dataIndex={'No_of_persons'}
                        width={screens.xxl ? 'auto' : 240}
                    />

                    <Column<Event>
                        title={'Gender'}
                        // dataIndex={'Price_te'}
                        width={screens.xxl ? 'auto' : 240}

                    />
                    <Column<Event>
                        title={'Nationality'}
                        dataIndex={'No_of_persons'}
                        width={screens.xxl ? 'auto' : 240}
                    /> */}

                    <Column<Event>
                        title={'Email ID'}
                        dataIndex={'email_address'}
                        width={screens.xxl ? 'auto' : 240}
                    />
                    <Column<Event>
                        title={'Phone'}
                        dataIndex={'phone_no'}
                        width={screens.xxl ? 'auto' : 240}
                    />

                    <Column<Event>
                        title={'T-Shirt Size'}
                        dataIndex={'tshirt_size'}
                        width={screens.xxl ? 'auto' : 240}
                    />
                    <Column<any>
                        title={'Price'}
                        // dataIndex={'tshirt_price'}
                        width={screens.xxl ? 'auto' : 240}
                        render={(_, record) => (
                            <h1>{record.ticketCategoryPrice} {record.currency_name}</h1>
                        )}
                    />
                </Table>

            </StyledTableWrapper>
        </div>
    )
}

export default UploadDetailsComponentTable;
