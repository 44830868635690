import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { eventsQueryKeys } from 'features/events';
import { queryClient } from 'providers';

import { CraeteTitleCmsrDTO, ShopApiService } from '../api/shop-api.service';

const { UpdateDelivery } = ShopApiService();
export const useUpdateDelivery = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: CraeteTitleCmsrDTO) => {
      return UpdateDelivery(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: eventsQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: eventsQueryKeys.detail(variables.id as any),
          }),
        ]);
        message.success("Delivery has been updated successfully!!");

      },
    },
  );
};
