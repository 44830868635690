import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { eventsQueryKeys } from 'features/events';
import { queryClient } from 'providers';

import { CraeteTitleCmsrDTO, NotificationApiService } from '../api/notification-api.service';



const { createNotificationType } = NotificationApiService();

export const useCreateType = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: CraeteTitleCmsrDTO) => {
            return createNotificationType(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: (data:any) => {
                const { ResponseMessage } = data;
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
                if(data.ResponseStatus==0){
                    message.error(ResponseMessage)
                }else{
                    message.success(ResponseMessage);
                }
            },
        },
    );
};
