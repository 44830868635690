import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { eventsQueryKeys } from 'features/events';
import { queryClient } from 'providers';

import { TransectionApiServices } from '../api/transection-api.service';

const { applyCouponBulk } = TransectionApiServices();

export const useApplyCouponBulk = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: any) => {
            return applyCouponBulk(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);
                message.error(uiMessage);
            },
            onSuccess: (data: any, variables: any, context: any) => {
                if (context?.onSuccessCallback && data.ResponseStatus === 1) {
                    context.onSuccessCallback(data.couponAmount);
                }
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
            },
        },
    );
};
