import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
  EventsResponse,
  // useUpdateEvent,
} from 'features/events';
import { useStatusResponse } from 'features/shop-item/use-cases/update-status-response-msg';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';
import { Event } from 'types/event';

import { EventFilterFormPaginated } from '../type';
import { AddEventMasterModal } from './add-event-master-modal/add-event-master-modal';


type EventsTableProps = {
  isLoading: boolean;
  data?: EventsResponse;
  tableParams: EventFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<EventFilterFormPaginated>>;
  refetch: () => void;
  eventEditData: any;
  setEventEditData: any;
};

export const EventMasterTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
  refetch,
  eventEditData,
  setEventEditData
}: EventsTableProps) => {
  const permissions = usePermissions();
  const { confirm } = Modal;

  const { mutate: mutateUpdateItem, isLoading: isLoadingUpdate } =
    useStatusResponse();

  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<Event> | SorterResult<Event>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'
          : '-name',
    });
  };

  const [showEventMasterModal, setShowEventMasterModal] = useState(false);

  const handleCloseModal = () => {
    setShowEventMasterModal(false);
    setEventEditData(undefined);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = (record: any) => {
    setShowEventMasterModal(true);
    setEventEditData(record);
    document.body.style.overflow = 'hidden';
  }

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: 'Are you sure you want to delete this Event?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(record.id);
      },
    });
  };

  const handleDelete = async (id: any) => {
    mutateUpdateItem({
      id: id,
      flag: "EVENTS_MASTER_DELETE",
      status: true
    });
  };

  if (!permissions) return null;

  return (

    <>
      <div className={`cstmrightmdl ${showEventMasterModal && "show"}`}>
        <AddEventMasterModal refetch={refetch} onClose={handleCloseModal} eventEditData={eventEditData} />

      </div>

      <StyledTableWrapper>
        <StyledTableHeader>
          <RecordCountBadge
            text={"Event Master found"}
            count={data?.total || 0}
          />
        </StyledTableHeader>
        <Table<Event>
          className="cstltblwthldr"
          locale={{
            emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
          }}
          loading={isLoading && { indicator: <div><TableLoader /></div> }}
          dataSource={isLoading ? [] : data?.items}
          rowKey='id'
          onChange={handleTableChange}
          pagination={{
            current: tableParams.page,
            defaultPageSize: tableParams.size,
            total: data?.total,
          }}
        >

          <Column<Event>
            title={'Title'}
            dataIndex={'title'}
          />

          <Column<Event>
            title={"Is Active"}
            // width={screens.xxl ? 'auto' : 162}
            dataIndex={'is_active'}
            render={(_, record) => (
              <>
                <Space>
                  <Tooltip title="Edit">
                    <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                  </Tooltip>

                  <Tooltip title="Delete">
                    <Button shape="circle" loading={isLoadingUpdate} onClick={() => showDeleteConfirm(record)} icon={<DeleteOutlined />} />
                  </Tooltip>
                  <Tooltip title="Is Active">
                    <Switch
                      defaultChecked={record.is_active}
                      checked={record.is_active}
                      loading={isLoadingUpdate}
                      onChange={(isChecked) => {
                        mutateUpdateItem({
                          id: record.id,
                          flag: "EVENTS_MASTER_SET_STATUS",
                          status: isChecked,
                        });
                        // Update the is_active status locally
                        record.is_active = isChecked;
                      }}
                      onClick={(checked, event) => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    />
                  </Tooltip>
                </Space>
              </>
            )}
          />
        </Table>
      </StyledTableWrapper>
    </>

  );
};
