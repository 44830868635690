import { Button, Form, Grid, Input } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RaceFilterFormField, RaceFilterFormValues } from '../type';


const { useBreakpoint } = Grid;

type EventsFilterFormProps = {
  formId: string;
  handleFilter: (values: RaceFilterFormValues) => void;
  initialValues: RaceFilterFormValues;
  closeModal?: () => void;
};

export const RaceFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: EventsFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<RaceFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues })
  };

  const handleOnFinish = (values: RaceFilterFormValues) => {
    handleFilter(values as any);
    closeModal?.();
  };

  return (
    <Form<RaceFilterFormValues>
      id={formId}
      name={formId}
      form={form}
      layout='vertical'
      onFinish={handleOnFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper style={{ width: '100%' }}>
        <StandardItemsWrapper style={{ width: '100%' }}>
          <div className='invoice_list' style={{ width: '100%' }}>
            <Form.Item<RaceFilterFormValues>
              label={'Category Name'}
              name={RaceFilterFormField.Racekit}
            >
              <Input placeholder={'Enter Category Name'} />
            </Form.Item>
            {screens.lg && (
              <ContentFilters.ButtonsWrapper className='filter_btn'>
                <Button type='primary' htmlType='submit'>
                  {t('events.buttons.filter', { count: 1 })}
                </Button>
                <Button onClick={onReset}>{t('events.buttons.reset')}</Button>
              </ContentFilters.ButtonsWrapper>
            )}
          </div>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>


    </Form>
  );
};
