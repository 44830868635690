import { Button, message } from 'antd';
import {
    EventsFilterFormPaginated
} from 'features/events';
import { useApplyCouponBulk } from 'features/transaction_list/use-cases/apply-coupon';
import { useConfirmPaymentBulk } from 'features/transaction_list/use-cases/confirm-payment';
import { useCreateBooking } from 'features/transaction_list/use-cases/create-booking';
import { useRemoveCouponBulk } from 'features/transaction_list/use-cases/remove-coupon';
import { formateAmount } from 'features/transaction_list/utills/utiils';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

import UploadDetailsComponentTable from './upload-details-component--table';


type EventsTableProps = {
    isLoading: boolean;
    data?: any;
    tableParams: EventsFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<EventsFilterFormPaginated>>;
    refetch: () => void;
    id: any
};

export const UploadDetailsTable = ({
    data,
    isLoading,
    tableParams,
    updateParams,
    id,
}: EventsTableProps) => {

    const permissions = usePermissions();
    const [referenceNo, setReferenceNo] = useState("");
    const navigate = useNavigate();

    const { isLoading: bookingLoading, mutate } = useCreateBooking();

    const { isLoading: approveLoading, mutate: paymentMutate } = useConfirmPaymentBulk();

    const applyCouponBulkMutation = useApplyCouponBulk();
    const removeCouponBulkMutation = useRemoveCouponBulk();


    const [isChecked, setIsChecked] = useState(false);
    const [showError, setShowError] = useState(false);


    const [couponCode, setCouponCode] = useState<string | null>(null);
    const [couponNo, setCouponNo] = useState<string | null>(null);
    const [couponAmount, setCouponAmount] = useState<number>(0);
    const [couponId, setCouponId] = useState<number | null>(null);

    const getNestedProperty = (obj: any, path: string) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };




    useEffect(() => {
        setReferenceNo(data?.items[0]?.bank_details?.reference_no || "")
    }, [data])


    const handleBooking = () => {
        if (!isChecked) {
            setShowError(true);
            message.error('Please accept the disclaimer before proceeding.');

            return;
        }
        const contactPersonName = data?.items[0]?.bulk_upload_details?.contact_person_name || '';
        const fullName = contactPersonName.split(" ");

        const firstName = fullName[0];
        const lastName = fullName.length > 1 ? fullName.slice(1).join(" ") : "k";

        const formData = {
            event_id: data?.items[0]?.bulk_upload_details?.event_id,
            email_address: data?.items[0]?.bulk_upload_details?.contact_person_email,
            first_name: firstName,
            last_name: lastName,
            phone_number: data?.items[0]?.bulk_upload_details?.contact_person_phone,
            payment_method: "BANK_TRANSFER",
            total_cost: grandTotal,
            discount: totalDiscount,
            platform: "admin",
            source: "WebApp",
            item_coupon_code: couponCode,
            item_coupon_code_price: couponAmount,
            delivery_address_id: 0,
            delivery_standard_charges: 0,
            user_type: "",
            ty_checkout_details: "",
            Flag: "BULKUPLOAD",
            bank_ref_no: referenceNo,
            bulk_id: +id
        }

        mutate(formData, { onSuccess: handleNavigate });
    }

    const handleNavigate = () => {
        navigate(ROUTES.TRANSACTION__BULK_UPLOAD_LIST);
    }




    const handleDisclaimer = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
        setShowError(false); // Reset the error state when the checkbox is checked
    };

    const getDisabled = () => {
        if (data?.items[0]?.bank_details.reference_no) {
            return true

        } else if (!referenceNo) {
            return true

        }
        else {
            return false
        }
    }

    const handleApprovePayment = () => {
        if (!isChecked) {
            setShowError(true);
            message.error('Please accept the disclaimer before proceeding.');

            return;
        }
        const paymentData = {
            bulkid: +id
        }
        paymentMutate(paymentData);
    }

    const handleCouponApply = () => {
        if (!couponNo) {
            setCouponNo("")

            return
        }
        const applyCouponData = {
            total_item_amount: grandTotal,
            coupon_code: couponNo,
            event_id: data?.items[0]?.bulk_upload_details?.event_id
        }

        // applyCpnMutate(applyCouponData, { onSuccess: refetch });
        applyCpnMutate(applyCouponData);

    }
    const applyCpnMutate = (applyCouponData: any) => {
        applyCouponBulkMutation.mutate(applyCouponData, {
            onSuccess: (res: any) => {
                if (res.ResponseStatus === 1) {
                    setCouponCode(couponNo);
                    setCouponAmount(res.coupon_amount);
                    setCouponId(res.Id);
                    message.success(res.ResponseMessage)
                } else {
                    message.error(res.ResponseMessage);
                    setCouponCode(null);
                }
            },
        });
    };

    const handleCouponRemove = () => {
        const removeCouponData = {
            id: couponId,
            flag: "DELETE_EVENT_APPLIED_COUPON"
        }

        removeCouponBulkMutation.mutate(removeCouponData, {
            onSuccess: (removeData: any) => {
                if (removeData.ResponseStatus === 1) {
                    setCouponAmount(0);
                    setCouponId(null);
                    setCouponNo(null);
                    setCouponCode(null);
                    message.success(removeData.ResponseMessage)
                } else {
                    message.error(removeData.ResponseMessage)
                }
            },
        });
    };

    const currencyName = getNestedProperty(data, 'items.0.participant_list.0.currency_name') || 'KD';



    const eventParticipantDiscountCharge = data?.items ? data?.items[0]?.participant_list.reduce((acc: any, curr: any) => acc + curr.coupon_amount, 0) : 0;

    // console.log(eventParticipantDiscountCharge, ":eventParticipantDiscountCharge")

    const totalDiscount = (eventParticipantDiscountCharge + couponAmount + data?.items[0]?.total_amounts?.item_coupon_code_price || 0) || 0;
    // console.log(totalDiscount, "totalDiscount");


    // const currencyName = data?.items && data?.items[0]?.participant_list[0]?.currency_name;

    const grandTotal = data?.items && data?.items[0]?.total_amounts?.ticket_category_price ? (data.items[0].total_amounts.ticket_category_price + data.items[0].total_amounts.tshirt_price + data.items[0].total_amounts.medal_price + data.items[0].total_amounts.photo_price + data.items[0].total_amounts?.delivery_price + data.items[0].total_amounts?.refund_protect_price - totalDiscount)
        : 0;

    const paymentStatus = data?.items[0]?.bulk_upload_details?.payment_Status || "";


    if (!permissions) return null;

    return (

        <>

            <UploadDetailsComponentTable
                data={data}
                isLoading={isLoading}
                tableParams={tableParams}
                updateParams={updateParams}
            />


            <div className='ttl_list_wrap'>
                <div className='ttl_list_b'>
                    <div className='ttl_list'>
                        <span>Total</span>
                        <span>{formateAmount(data?.items[0].total_amounts?.ticket_category_price)} {currencyName}</span>
                    </div>
                    <div className='ttl_list'>
                        <span>T-shirt Printing</span>
                        <span>{formateAmount(data?.items[0].total_amounts?.tshirt_price)} {currencyName}</span>
                    </div>
                    <div className='ttl_list'>
                        <span>Medal Engraving</span>
                        <span>{formateAmount(data?.items[0].total_amounts?.medal_price)} {currencyName}</span>
                    </div>
                    <div className='ttl_list' style={{ border: 'none' }}>
                        <span>Post Event Photos</span>
                        <span>{formateAmount(data?.items[0].total_amounts?.photo_price)} {currencyName}</span>
                    </div>
                    <div className='ttl_list'>
                        <span>Delivery Price</span>
                        <span>{formateAmount(data?.items[0].total_amounts?.delivery_price)} {currencyName}</span>
                    </div>
                    <div className='ttl_list' style={{ border: 'none' }}>
                        <span>Refund Protect Price</span>
                        <span>{formateAmount(data?.items[0].total_amounts?.refund_protect_price)} {currencyName}</span>
                    </div>


                    <div className='ttl_list' style={{ border: 'none' }}>
                        <span>Total Discount</span>
                        <span>{formateAmount(totalDiscount)} {currencyName}</span>
                    </div>

                    {!data?.items[0].bank_details.reference_no && <div className='cupn_code'>
                        <h4 >{couponId ? "Coupon Code Applied" : "Have a Coupon Code?"}</h4>
                        <input type="text" onChange={(e) => setCouponNo(e.target.value)} className={`form-control ${couponNo === "" ? "invalid" : ""}`} />
                        {couponId ? <Button

                            onClick={handleCouponRemove}
                            className='rmve_cupn_btn'
                            loading={removeCouponBulkMutation.isLoading}
                        >Remove</Button>
                            :
                            <Button
                                className='btn'
                                onClick={handleCouponApply}
                                loading={applyCouponBulkMutation.isLoading}
                            >Apply</Button>}
                    </div>}


                    <div className='ttl_list' >
                        <span style={{ fontWeight: 500 }}>Grand Total</span>
                        <span>{formateAmount(grandTotal)} {currencyName}</span>
                    </div>
                </div>


                <div className='pymnt_dlts'>
                    <div className='row'>
                        <div className='col-8'>
                            <div className='pay_box'>
                                <p>Please Transfer <span>{formateAmount(grandTotal)} {currencyName}</span>to below account Number</p>
                                <div className='actn_wrap'>
                                    <div className='acnt_dtls'>
                                        <span>Account Name</span>
                                        <span>{data?.items[0].bank_details?.account_name}</span>
                                    </div>
                                    <div className='acnt_dtls'>
                                        <span>Account No.</span>
                                        <span>{data?.items[0].bank_details?.account_no}</span>
                                    </div>
                                    <div className='acnt_dtls'>
                                        <span>Bank Name</span>
                                        <span>{data?.items[0].bank_details?.bank_name}</span>
                                    </div>
                                    <div className='acnt_dtls'>
                                        <span>IBAN</span>
                                        <span>{data?.items[0].bank_details?.international_bank_account_no}</span>
                                    </div>
                                </div>
                                <div className='ref_no'>
                                    <span>Please enter Reference Number After Transfer Number</span>
                                    <input type="text" value={referenceNo} placeholder='Reference Number' className='form-control' disabled={data?.items[0].bank_details?.reference_no
                                    }
                                        onChange={(e) => setReferenceNo(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='pm_m'>
                                <h1>Choose Payment Method</h1>
                                <div className='pay_optin'>
                                    <label className="cstm_r">Bank Transfer
                                        <input type="radio" defaultChecked />
                                        <span className="cstm_checkmark"></span>
                                    </label>
                                </div>
                                <div className='trms_cndn'>
                                    <label className={`cstm_check_bx ${showError ? 'error' : ''}`}>I have read and accept the <span>disclaimer</span>
                                        <input type="checkbox" onChange={handleDisclaimer} />

                                        <span className="check_checkmark"></span>
                                    </label>
                                </div>

                                {data?.items[0]?.bank_details?.reference_no
                                    ?
                                    <h1 className={`${paymentStatus === "SUCCESS" ? "paygreen" : paymentStatus === "failed" ? "payred" : ""} `}>Payment Status :{data?.items[0]?.bulk_upload_details?.payment_Status}</h1>
                                    :
                                    <Button className='btn' type='primary'
                                        loading={bookingLoading}
                                        disabled={getDisabled()}
                                        onClick={handleBooking}>Proceed to Payment</Button>}

                                {data?.items[0]?.bank_details.reference_no && (paymentStatus !== "SUCCESS" && paymentStatus !== "failed") &&

                                    <Button className='btn' style={{ backgroundColor: "green", color: "white" }}
                                        loading={approveLoading}
                                        onClick={handleApprovePayment}>Confirm Payment</Button>}
                            </div>
                        </div>
                    </div>
                </div>



            </div>

        </>
    );
};


