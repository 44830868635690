import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { NotificationListTypeTable } from '../components/notification-list-table/type-table';
import { NotificationTopBarType } from '../components/top-bar/notification-type-top-bar';
import { NotificationFilterFormValues, NotificationFilterFormValuesPaginated } from '../components/type';
import { useGetType } from '../use-cases/get-type';

const getInitialFilterValues: NotificationFilterFormValues = {
    id: undefined,
    title: undefined,
    is_active: undefined
};

export const NotifictionViewTypeList = () => {
    // const [filterValue, setFilterValue] = useState(getInitialFilterValues);
    const { params, updateParams, resetPage } =
        usePaginationParams<NotificationFilterFormValuesPaginated>({
            page: 1,
            size: 10,
            order_by: 'id',
            ...getInitialFilterValues,
        });


    const { data, refetch, isFetching } = useGetType(params, { retry: false });
    const handleFiltersChange = (values: NotificationFilterFormValuesPaginated) => {
        // setFilterValue(values);
        updateParams(values);
        resetPage();
        refetch();
    };

    return (
        <>
            <NotificationTopBarType
                handleFilter={handleFiltersChange}
                getInitialFilterValues={getInitialFilterValues}
                refetch={refetch}

            />
            <NotificationListTypeTable
                isUsersListLoading={isFetching}
                typeReportList={data?.items as any}
                itemsCount={data?.total as number}
                tableParams={params}
                refetch={refetch}
                updateParams={updateParams}
            />
            <Outlet />
        </>
    );
};
