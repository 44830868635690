import { Button, Form, Grid, Input } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
// import { USER_ROLES } from 'features/users/permissions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// import { RoleTranslationsMap } from 'utils/role';
import { UserFilterFormField, UserFilterFormValues } from './types';

const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
  formId: string;
  handleFilter: (values: UserFilterFormValues) => void;
  initialValues: UserFilterFormValues;
  closeModal?: () => void;
};

export const UsersFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: UsersFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<UserFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  // const roleOptions = USER_ROLES.all.map((role) => ({
  //   value: role,
  //   label: RoleTranslationsMap[role],
  // }));

  const onReset = () => {
    form.resetFields();
    handleFilter(initialValues);
  };

  const onFinish = (formValues: UserFilterFormValues) => {
    handleFilter(formValues);
    closeModal?.();
  };

  return (

    <Form<UserFilterFormValues>
      id={formId}
      name={formId}
      className='filters-form'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper style={{ width: '100%' }}>
        <StandardItemsWrapper style={{ width: '100%' }}>
          {/* <div className='invoice_list' style={{width:'100%'}}> */}
          <Form.Item<UserFilterFormValues>
            label={t('adminUsers.formFields.userName.label')}
            name={UserFilterFormField.FullUserName}
          >
            <Input
              placeholder={t('adminUsers.formFields.userName.placeholder')}
            />
          </Form.Item>
          <Form.Item<UserFilterFormValues>
            label={t('adminUsers.formFields.phoneNumber.label')}
            name={UserFilterFormField.PhoneNumber}
          >
            <Input
              placeholder={t('adminUsers.formFields.phoneNumber.placeholder')}
            />
          </Form.Item>
          <Form.Item<UserFilterFormValues>
            label={t('adminUsers.formFields.email.label')}
            name={UserFilterFormField.Email}
          >
            <Input placeholder={t('adminUsers.formFields.email.placeholder')} />
          </Form.Item>

          {/* <Form.Item<UserFilterFormValues>
            label={t('adminUsers.formFields.role.label')}
            name={UserFilterFormField.Role}
          >
            <Select
              options={roleOptions}
              placeholder={t('adminUsers.formFields.role.placeholder')}
              popupMatchSelectWidth={200}
              className='filter'
              allowClear
            />
          </Form.Item> */}

        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

      {screens.lg && (
        <ContentFilters.ButtonsWrapper>
          <Button type='primary' htmlType='submit'>
            {t('common.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
        </ContentFilters.ButtonsWrapper>
      )}
      {/* </div>

        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper> */}


    </Form>
  );
};
