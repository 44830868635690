import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, Skeleton, Space, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import { useEventActiveStatus } from 'features/events/use-cases/event-active-status';
import { useGeneratePaymentLinkBoot } from 'features/transaction_list/use-cases/generate-payment-lint-boot';
import { EndUserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { Dispatch, SetStateAction, useState } from 'react';
import { User } from 'types';

import { EditBoothNameModal } from '../edit-booth-name-model/edit-booth-name-model';

type ParticipateTableProps = {
  isSocialListLoading: boolean;
  DeliveryList: any;
  refetch: any,
  tableParams: EndUserFilterFormValuesPaginated;
  updateParams: Dispatch<SetStateAction<EndUserFilterFormValuesPaginated>>;
};
export const ExpoBoothList = ({
  DeliveryList,
  isSocialListLoading,
  tableParams,
  updateParams,
}: ParticipateTableProps) => {

  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<User> | SorterResult<User>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'

          : '-name',
    });
  };


  const [loadingState, setLoadingState] = useState<{ id: number | null, action: string | null }>({
    id: null,
    action: null
  });

  const { confirm } = Modal;

  const showDeleteConfirm = (event: any, id: any, type: string) => {
    event.stopPropagation();
    confirm({
      title: `Are you sure you want to ${type === "approve" ? "Approve" : "Reject"} ? `,
      icon: type === "approve" ? <CheckCircleOutlined /> : <CloseCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        setLoadingState({ id, action: type });
        handleUpdateStatus(id, type);
      },
      onCancel() {
        setLoadingState({ id: null, action: null }); // Reset loading state on cancel
      }
    });
  };

  const { mutate: updateStatusMutate } = useEventActiveStatus();
  const { mutate: generatePaylinkMutate } = useGeneratePaymentLinkBoot();

  const handleUpdateStatus = (id: any, type: string) => {
    const statusFormData = {
      id: id,
      status: type === "approve" ? true : false,
      flag: type === "approve" ? "BOOTH_CONFIRM" : "BOOTH_REJECT"
    }
    updateStatusMutate(statusFormData, {
      onSettled: () => {
        setLoadingState({ id: null, action: null });
      }
    });
  }


  const [showModal, setShowModal] = useState(false);
  const [boothData, setBoothData] = useState<any>(null);


  const handleCloseModal = () => {
    setShowModal(false);
    setBoothData(null);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = (record: any) => {
    setShowModal(true);
    setBoothData(record);
    document.body.style.overflow = 'hidden';
  }


  const handleGenerateLink = (record: any) => {
    const payloadLink = {
      booth_registration_id: record?.booth_registration_id,
      total_price: record?.total_price,
    }

    generatePaylinkMutate(payloadLink)
  }



  return (
    <>
      <div className={`cstmrightmdl ${showModal && "show"}`}>
        <EditBoothNameModal onClose={handleCloseModal} isOpen={showModal} boothData={boothData} />
      </div>


      <StyledTableHeader>
        <div style={{ marginTop: '32px', color: 'black' }}>
          <RecordCountBadge
            text={"Expo Booth found"}
            count={isSocialListLoading ? 0 : DeliveryList?.total || 0}
          />
        </div>
      </StyledTableHeader>
      <Table
        dataSource={isSocialListLoading ? [] : DeliveryList?.items}
        className='cstltblwthldr'

        locale={{
          emptyText: isSocialListLoading ? <Skeleton active={true} paragraph={{
            rows: tableParams.size || 5,
          }} /> : <Empty />
        }}
        loading={isSocialListLoading && { indicator: <div><TableLoader /></div> }}
        rowKey='booth_registration_id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams?.page,
          defaultPageSize: tableParams?.size,
          total: DeliveryList?.total,
          // showSizeChanger: false
        }}
        // onRow={(record: any) => {
        //   return {
        //     onClick: () => {
        //       const selection = window?.getSelection()?.toString();
        //       if (!selection?.length) {
        //         handleNavigate(record)
        //       }
        //     },
        //   };
        // }}
        scroll={{ x: 1040 }}
      >
        <Column
          title={'Event Name'}
          dataIndex={'event_name'}
        />

        <Column
          title={'First Name'}
          dataIndex={'first_name'}
        // sorter={true}
        // sortDirections={['ascend', 'descend', 'ascend']}
        // defaultSortOrder={'ascend'}
        />
        <Column
          title={'Last Name'}
          dataIndex={'last_name'}
        // sorter={true}
        // sortDirections={['ascend', 'descend', 'ascend']}
        // defaultSortOrder={'ascend'}
        />
        <Column
          title={'Email'}
          dataIndex={'email'}

        />

        <Column
          title={'Booth No'}
          dataIndex={'booth_nos'}
        />

        <Column
          title={'Status'}
          render={(record: any) => {
            return (
              <h1>{record?.is_reject ? "Rejected" :
                record?.is_confirmed ? "Confirmed" :
                  "Pending"
              }</h1>
            )
          }}

        />

        <Column<any>
          title={"Action"}
          // width={screens.xxl ? 'auto' : 162}
          // dataIndex={'is_active'}
          render={(_, item) => (
            <>
              <Space>
                <Tooltip title="Approve">
                  <Button shape="circle" size='small' icon={<CheckOutlined />}
                    style={{ borderColor: '#28a745', color: '#28a745' }}
                    onClick={(event) => showDeleteConfirm(event, item.booth_registration_id, "approve")}
                    loading={loadingState.id === item.booth_registration_id && loadingState.action === "approve"}
                  />
                </Tooltip>

                <Tooltip title="Reject">
                  <Button shape="circle" size='small' danger icon={<CloseOutlined />} onClick={(event) => showDeleteConfirm(event, item.booth_registration_id, "reject")}
                    loading={loadingState.id === item.booth_registration_id && loadingState.action === "reject"}
                  />
                </Tooltip>

                <Tooltip title="Edit booth">
                  <Button size='small' shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(item)}
                  />
                </Tooltip>

                <Tooltip title="Generate Payment Link">
                  <Button size='small' shape="circle" icon={<LinkOutlined />} disabled={!item.is_confirmed} onClick={() => handleGenerateLink(item)}
                  />

                </Tooltip>

              </Space>
            </>
          )}
        />

      </Table>
    </>
  );
};
