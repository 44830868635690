import { Button, Form, Input } from 'antd';
import { useCreateSportsCategory } from 'features/cms/use-cases';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { SportsCatFormField, SportsCatFormValues } from './types';


export const AddSportsCategorymodal = ({ onClose, sportsEditData, showAddSportsModal }: { onClose: () => void; sportsEditData: any; showAddSportsModal: boolean }) => {
    const { id } = useParams();
    const formName = 'add-sports-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<SportsCatFormValues>();
    const { t } = useTranslation();
    const { isLoading, mutate } = useCreateSportsCategory();

    const getInitialValues = {
        [SportsCatFormField.Id]: id,
        [SportsCatFormField.CategoryName]: undefined,
        [SportsCatFormField.File]: undefined,
        [SportsCatFormField.IsActive]: undefined,
        [SportsCatFormField.ImageUrl]: undefined,
    };
    const [fileData, setFileData] = useState<File | any>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const img = new Image();
            img.onload = () => {
                if (img.width === 36 && img.height === 36) {
                    setFileData(file);
                }
            };
            img.src = URL.createObjectURL(file);
        }
    };

    const handleUpdate = (formData: SportsCatFormValues) => {
        formData[SportsCatFormField.Id] = sportsEditData?.id;
        formData[SportsCatFormField.File] = fileData;
        formData[SportsCatFormField.ImageUrl] = "";
        formData[SportsCatFormField.IsActive] = true;
        mutate(formData, { onSuccess: hanldeSuccess });
    };

    const hanldeSuccess = () => {
        onClose();
        form.resetFields()
        setFileData(null);
    }

    useEffect(() => {
        if (sportsEditData) {
            form.setFieldsValue(sportsEditData);
        } else {
            form.resetFields();
        }
    }, [showAddSportsModal, sportsEditData])

    return (

        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={onClose} className='close-btn'>
                    &times;
                </button>
                <h4>{sportsEditData ? "Edit Sports category" : "Add Sports category"}</h4>
            </div>
            <div className='modal_body'>
                <Form<SportsCatFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <FormSectionsSeparator title={""}>
                        <Form.Item<SportsCatFormField>
                            label={"Category Name"}
                            name={SportsCatFormField.CategoryName}

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Enter category Name"}

                            />
                        </Form.Item>

                        {!fileData && sportsEditData?.image_url && <img
                            src={sportsEditData?.image_url}
                            width={"250px"}
                            height={"200px"}
                        // preview={false}
                        />}

                        <Form.Item<SportsCatFormField>
                            label={"Photo"}
                            name={SportsCatFormField.File}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (value && !fileData) {

                                            return Promise.reject('Image must be 36x36 pixels.');
                                        }

                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input type="file" onChange={handleFileChange} accept=".png,.jpg,.jpeg" />
                        </Form.Item>

                        <Form.Item>
                            <div className='button-group'>
                                <Button htmlType='submit' loading={isLoading} className='btn'>Save</Button>
                            </div>
                        </Form.Item>

                    </FormSectionsSeparator>
                </Form>


            </div>
        </div>

    )
};

