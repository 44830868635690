import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Grid, Image, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
    EventsFilterFormPaginated,
} from 'features/events';
import { useEventActiveStatus } from 'features/events/use-cases/event-active-status';
import { useEventDeleteStatus } from 'features/events/use-cases/event-delete-status';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';

import { Organizer } from '../../event-organizer/types';
import AddSponserModal from './add-sponser-modal/add-sponser-modal';

const { useBreakpoint } = Grid;

type OrganizerTableProps = {
    isLoading: boolean;
    data?: any;
    tableParams: EventsFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<EventsFilterFormPaginated>>;
    refetch: () => void;
    sponserData: any;
    setSponserData: any;
};

export const SponserTable = ({
    data,
    isLoading,
    tableParams,
    updateParams,
    refetch,
    sponserData,
    setSponserData
}: OrganizerTableProps) => {

    const screens = useBreakpoint();
    const permissions = usePermissions();


    const handleTableChange = (
        pagination: TablePaginationConfig,
        // filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Organizer> | SorterResult<Organizer>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: Array.isArray(sorter)
                ? undefined
                : sorter.order === 'ascend'
                    ? 'name'
                    : '-name',
        });
    };

    const tableWidth = screens.xxl ? { x: 1440 } : { x: 1200 };

    const [showAddSponserModal, setShowAddSponserModal] = useState<boolean>(false);

    const handleCloseModal = () => {
        setShowAddSponserModal(false);
        setSponserData(undefined);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = (record: any) => {
        setShowAddSponserModal(true);
        setSponserData(record);
        document.body.style.overflow = 'hidden';
    }


    const { confirm } = Modal;

    const showDeleteConfirm = (id: any) => {
        confirm({
            title: 'Are you sure you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(id);
            },
        });
    };

    const { isLoading: deleteLoading, mutate } = useEventDeleteStatus();

    const { mutate: updateStatusMutate, isLoading: statusLoading } = useEventActiveStatus();



    const handleDelete = (id: any) => {
        const deletedFormData = {
            id: id,
            status: false,
            flag: "SPONSER_DELETE"
        }
        mutate(deletedFormData, { onSuccess: refetch });
    }

    const handleUpdateStatus = (id: any, isChecked: any) => {
        const statusFormData = {
            id: id,
            status: isChecked,
            flag: "SPONSER_SET_STATUS"
        }
        updateStatusMutate(statusFormData, { onSuccess: refetch });
    }


    if (!permissions) return null;

    return (

        <>
            <div className={`cstmrightmdl ${showAddSponserModal && "show"}`}>
                <AddSponserModal onClose={handleCloseModal} refetch={refetch} sponserData={sponserData} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={"Sponsor found"}
                        count={data?.total || 0}
                    />
                </StyledTableHeader>
                <Table<Organizer>
                    className="cstltblwthldr"
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading && { indicator: <div><TableLoader /></div> }}
                    dataSource={isLoading ? [] : data?.items}
                    rowKey='id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.page,
                        defaultPageSize: tableParams.size,
                        total: data?.total,
                    }}
                    scroll={tableWidth}
                // onRow={(record) => {
                //     return {
                //         onClick: () => {
                //             handleOpenModal(record);
                //         },
                //     };
                // }}
                >
                    <Column<Organizer>
                        title={'S.No'}
                        key="serialNumber"
                        width={100}
                        ellipsis
                        render={(text, record, index) => index + 1}
                    />
                    <Column<Organizer>
                        title={'Name'}
                        dataIndex={'name'}
                        sorter={true}
                        // sortDirections={['ascend', 'descend', 'ascend']}
                        // defaultSortOrder={'descend'}
                        width={screens.xxl ? 'auto' : 240}
                        ellipsis
                    />
                    <Column<Organizer>
                        title={'Link'}
                        // dataIndex={'link'}
                        width={screens.xxl ? 'auto' : 240}
                        render={(_, record) => (
                            <a href={record.link} target="_blank" rel="noreferrer">
                                {record.link}
                            </a>
                        )}
                    />
                    <Column<Organizer>
                        title={'Contact No'}
                        dataIndex={'contact_no'}
                        width={screens.xxl ? 'auto' : 200}
                        className='cell-direction-rtl'
                    />


                    <Column<Organizer>
                        title={'Email'}
                        dataIndex={'email'}
                        width={screens.xxl ? 'auto' : 240}
                    />
                    <Column<Organizer>
                        title={'Address'}
                        dataIndex={'address'}
                        width={screens.xxl ? 'auto' : 240}
                    />
                    <Column<Organizer>
                        title={'Image'}
                        dataIndex={'image_url'}
                        width={screens.xxl ? 'auto' : 240}
                        render={(_, record) => (
                            <Image
                                src={record?.image_url}
                                width={"100px"}
                            />
                        )}
                    />

                    <Column<Organizer>
                        title={"Action"}
                        width={screens.xxl ? 'auto' : 162}
                        dataIndex={'is_active'}
                        render={(_, record) => (
                            <Space>

                                <Tooltip title="Edit">
                                    <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                                </Tooltip>

                                <Tooltip title="Delete">
                                    <Button shape="circle" loading={deleteLoading} icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(record.id)} />
                                </Tooltip>
                                <Tooltip title="Is Active">
                                    <Switch
                                        defaultChecked={record.is_active}
                                        checked={record.is_active}
                                        loading={statusLoading}
                                        onChange={(isChecked) => {
                                            handleUpdateStatus(record.id, isChecked);
                                            // Update the is_active status locally
                                            record.is_active = isChecked;
                                        }}

                                    />
                                </Tooltip>
                            </Space>
                        )}
                    />
                </Table>
            </StyledTableWrapper>
        </>
    );
};

