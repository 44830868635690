import { ContentFilters, ContentTopBar } from 'components';

import { WaitingFilterFormValues } from '../waiting-filter-form/types';
import { WaitingFilterForm } from '../waiting-filter-form/waiting-list-filter';

type ParticipateTopBarProps = {
  getInitialFilterValues: WaitingFilterFormValues;
  handleFilter: (values: WaitingFilterFormValues) => void;
  refetch: () => void;
};


export const WaitingListTopBar = ({
  getInitialFilterValues,
  handleFilter
}: ParticipateTopBarProps) => {

  const formId = 'waiting-filters-form';



  return (

    <>
      <ContentTopBar
        title="Waiting List"
      />

      <ContentFilters formId={formId}>
        <WaitingFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};

