export enum CampaignCodeFormField {
    Id = 'id',
    EventId = 'event_id',
    Provider = 'provider',
    Code = 'campaign_code',
}

export type CampaignCodeFormValues = {
    [CampaignCodeFormField.Id]: any;
    [CampaignCodeFormField.EventId]: any;
    [CampaignCodeFormField.Provider]: string;
    [CampaignCodeFormField.Code]: string;
};

export type CampaignCodeDTO = {
    id: any;
    event_id: any;
    provider: string;
    campaign_code: string;
}


