import { checkPermission, useNewPermissions } from 'features/users';
import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

type RestrictedRouteProps = {
  children: ReactElement;
  requiredPermission: any;
  redirectPath: string;
};

export const RestrictedRoute = ({
  children
}: RestrictedRouteProps) => {

  return children;
  //const permissions = useNewPermissions();

  //if (!permissions) return null;

  //const isAllowed = checkPermission(permissions, requiredPermission);
  //const isAllowed = get(permissions, requiredPermission);
  //console.log('requiredPermission'+requiredPermission)
  // if (isAllowed === true) {
  //   return children;
  // }

  // return <Navigate to={redirectPath} replace />;
};


export const RestrictedSidebarRoute = ({
  children,
  redirectPath,
  requiredPermission,
}: RestrictedRouteProps) => {


  const permissions = useNewPermissions();

  if (!permissions) return null;

  const isAllowed = checkPermission(permissions, requiredPermission);
  //const isAllowed = get(permissions, requiredPermission);
  //console.log('requiredPermission'+requiredPermission)
  if (isAllowed === true) {
    return children;
  }

  return <Navigate to={redirectPath} replace />;
};

