import { UserOutlined } from '@ant-design/icons'
import { Checkbox, Form, Input } from 'antd'
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator'
import { useTranslation } from 'react-i18next'

import { FaqFormField } from './types'

const AddFaqForm = () => {
    const { t } = useTranslation();

    return (

        <div>
            <FormSectionsSeparator title={""}>



                <Form.Item<FaqFormField>
                    label={"Question"}
                    name={FaqFormField.Question}

                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Input
                        placeholder={"Question"}
                        prefix={<UserOutlined className='input-prefix-icon' />}
                    />
                </Form.Item>



                <Form.Item<FaqFormField>
                    label={"Answer"}
                    name={FaqFormField.Answer}

                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Input
                        placeholder={"Answer"}
                    />
                </Form.Item>

                <Form.Item<FaqFormField>
                    label={"Is Active"}
                    name={FaqFormField.IsActive}
                    valuePropName="checked"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Checkbox />
                </Form.Item>



            </FormSectionsSeparator>
        </div>
    )
}

export default AddFaqForm;
