export enum BibAllowFormField {
    EventId = 'event_id',
    TicketCategoryId = 'ticket_category_id',
    StartNo = 'start_no',
    EndNo = 'end_no',
}


export type BibAllowFormValues = {
    [BibAllowFormField.EventId]: string;
    [BibAllowFormField.TicketCategoryId]: string;
    [BibAllowFormField.StartNo]: number;
    [BibAllowFormField.EndNo]: number;
};