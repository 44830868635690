import "./module.css";

import ReactQuill from "react-quill";


const TermsDescriptionTextEditor = ({ handleProcedureContentChange, description }: { handleProcedureContentChange: any; description: string }) => {

    return (
        <>
            <div className="frmscrll">
                <div className="des_editr">
                    {/* <h2>English Description</h2> */}
                    <ReactQuill
                        className="editor-large"
                        theme="snow"
                        value={description || ''}
                        onChange={handleProcedureContentChange}
                        modules={TermsDescriptionTextEditor.modules}
                        formats={TermsDescriptionTextEditor.formats}
                    // dangerouslySetInnerHTML={{ __html: code }}
                    // readOnly={true}
                    />
                </div>

            </div>
        </>
    );
};

TermsDescriptionTextEditor.modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, false] }], // Select dropdown for heading
        [{ 'font': [] }], // Select dropdown for font size
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'align': [] }], // Select dropdown for text alignment
        [{ 'color': [] }, { 'background': [] }], // Select dropdowns for text and background color
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link'],
        ['clean']
    ],
    clipboard: {
        matchVisual: true,
    }
};

TermsDescriptionTextEditor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'align', 'color', 'background'
];

export default TermsDescriptionTextEditor;