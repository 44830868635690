import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { AddAgentUserModal } from 'features/users/components/modals/add-agent-user-modal';
import { UserFilterFormValues } from 'features/users/components/users-filter-form/types';
import { UsersFilterForm } from 'features/users/components/users-filter-form/users-filter-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyType } from 'types';

const { useBreakpoint } = Grid;

type UsersTopBarProps = {
  getInitialFilterValues: UserFilterFormValues;
  handleFilter: (values: UserFilterFormValues) => void;
  agentUserEditData:any;
};

export const AgentUsersTopBar = ({
  getInitialFilterValues,
  handleFilter,
  agentUserEditData,
}: UsersTopBarProps) => {
  useState<CompanyType>();
  const { t } = useTranslation();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const screens = useBreakpoint();
  const formId = 'users-filters-form';

  const handleCloseModal = () => {
    setShowAddUserModal(false);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = () => {
    setShowAddUserModal(true);
    document.body.style.overflow = 'hidden';
  }

  return (
    <>
      <div className={`cstmrightmdl ${showAddUserModal && "show"}`}>
        <AddAgentUserModal onClose={handleCloseModal} agentUserEditData={agentUserEditData} />
      </div>

      <ContentTopBar
        title={t('adminUsers.heading.suffix-agent')}
        renderButtons={
          <>
            <Button
              icon={!screens.xs ? <PlusOutlined /> : undefined}
              onClick={handleOpenModal}
            >
              {t('adminUsers.addNewAgent')}
            </Button>
          </>
        }
      />
      <ContentFilters formId={formId}>
        <UsersFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
