import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { queryClient } from 'providers';

import { CMSApiService, CreateGlobalDisclaimerDTO } from '../api/cms-api.service';
import { eventsQueryKeys } from './query-keys';

const { createGlobalDisclaimer } = CMSApiService();

export const useCreateGlobalDisclaimer = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: CreateGlobalDisclaimerDTO) => {
            return createGlobalDisclaimer(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
                message.success("Updated Succesfully");
            },
        },
    );
};
