export enum PartnersFormField {
    Id = 'id',
    WebsiteLink = 'link',
    File = 'file',
    IsActive = 'is_active',
    ImageUrl = "image_url"
}

export type partners = {
    Id: string | undefined;
    WebsiteName: string;
    isActive: boolean;
    File: any;
    image_url: any;
};

export type PartnersFormValues = {
    [PartnersFormField.Id]: string | undefined;
    [PartnersFormField.WebsiteLink]: string;
    [PartnersFormField.File]: any;
    [PartnersFormField.IsActive]: boolean;
    [PartnersFormField.ImageUrl]: string;
};