import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Grid, Image, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
  EventsResponse,
  // useUpdateEvent,
} from 'features/events';
import { useStatusResponse } from 'features/shop-item/use-cases/update-status-response-msg';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';
import { Event } from 'types/event';

import { raceFilterFormPaginated } from '../type';
import { AddRacekitmodal } from './add-racekit-modal/add-racekit-modal';

const { useBreakpoint } = Grid;

type EventsTableProps = {
  isLoading: boolean;
  data?: EventsResponse;
  tableParams: raceFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<raceFilterFormPaginated>>;
  refetch: () => void;
  racekitEditData: any;
  setRacekitEditData: any;
};

export const RacekitTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
  refetch,
  racekitEditData,
  setRacekitEditData
}: EventsTableProps) => {

  const screens = useBreakpoint();

  const permissions = usePermissions();

  const { mutate: mutateUpdateItem, isLoading: isLoadingUpdate } =
    useStatusResponse();



  const handleTableChange = (
    pagination: TablePaginationConfig,
    // filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Event> | SorterResult<Event>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'
          : '-name',
    });
  };
  const { confirm } = Modal;

  const tableWidth = screens.xxl ? { x: 1440 } : { x: 1200 };


  const [showAddRacekitModal, setShowAddRacekitModal] = useState(false);


  const handleCloseModal = () => {
    setShowAddRacekitModal(false);
    setRacekitEditData(undefined);
    document.body.style.overflow = 'visible';
  }

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: 'Are you sure you want to delete this Race?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(record.id);
      },
    });
  };

  const handleDelete = async (id: any) => {
    mutateUpdateItem({
      id: id,
      flag: "RACEKIT_MASTER_DELETE",
      status: true
    });

  };

  const handleOpenModal = (record: any) => {
    setShowAddRacekitModal(true);
    setRacekitEditData(record);
    document.body.style.overflow = 'hidden';
  }

  if (!permissions) return null;

  return (
    <>
      <div className={`cstmrightmdl ${showAddRacekitModal && "show"}`}>

        <AddRacekitmodal showAddRacekitModal={showAddRacekitModal} refetch={refetch} onClose={handleCloseModal} racekitEditData={racekitEditData} />
      </div>

      <StyledTableWrapper>
        <StyledTableHeader>
          <RecordCountBadge
            text={"Racekit found"}
            count={data?.total || 0}
          />
        </StyledTableHeader>
        <Table<Event>
          className="cstltblwthldr"
          locale={{
            emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
          }}
          loading={isLoading && { indicator: <div><TableLoader /></div> }}
          dataSource={isLoading ? [] : data?.items}
          rowKey='id'
          onChange={handleTableChange}
          pagination={{
            current: tableParams.page,
            defaultPageSize: tableParams.size,
            total: data?.total,
          }}
          scroll={tableWidth}
        // onRow={(record) => {
        //   return {
        //     onClick: () => {
        //       handleOpenModal(record);
        //     },
        //   };
        // }}
        >
          <Column<Event>
            title={'RaceKit Name'}
            dataIndex={'title'}
            width={screens.xxl ? 'auto' : 200}
          />
          <Column<any>
            title={'Image'}
            dataIndex={'image_url'}
            width={screens.xxl ? 'auto' : 120}
            render={(_, record) => (
              <Image
                width={80}
                src={record.image_url}
              // loading={isLoadingUpdateEvent}
              />
            )}
          />


          <Column<Event>
            title={"Action"}
            width={screens.xxl ? 'auto' : 162}
            dataIndex={'is_active'}
            render={(_, record) => (
              <>
                <Space>
                  <Tooltip title="Edit">
                    <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                  </Tooltip>

                  <Tooltip title="Delete">
                    <Button
                      shape="circle"
                      loading={isLoadingUpdate}
                      onClick={() => showDeleteConfirm(record)}
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                  <Tooltip title="Is Active">
                    <Switch
                      defaultChecked={record.is_active}
                      checked={record.is_active}
                      loading={isLoadingUpdate}
                      onChange={(isChecked) => {
                        mutateUpdateItem({
                          id: record.id,
                          flag: "RACEKIT_MASTER_SET_STATUS",
                          status: isChecked,
                        });
                        // Update the is_active status locally
                        record.is_active = isChecked;
                      }}
                      onClick={(checked, event) => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    />
                  </Tooltip>
                </Space>
              </>
            )}
          />
        </Table>
      </StyledTableWrapper>
    </>

  );
};
