import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { queryClient } from 'providers';

import { CMSApiService } from '../api/cms-api.service';
import { eventsQueryKeys } from './query-keys';

const { createSportsCategory } = CMSApiService();

export const useCreateSportsCategory = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: any) => {
      return createSportsCategory(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      // onSuccess: () => {
      //   queryClient.invalidateQueries({
      //     queryKey: eventsQueryKeys.all,
      //   });
      //   message.success(i18n.t('fields.create.successfulCreate'));

      // },
      onSuccess: (data) => {
        const { ResponseMessage } = data;
        queryClient.invalidateQueries({
          queryKey: eventsQueryKeys.all,
        });
        if (data.ResponseStatus == 0) {
          message.error(ResponseMessage)
        } else {
          message.success("Sport  added successfully");
        }
      },
    },
  );
};
