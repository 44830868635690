
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ContentTopBar } from 'components';
import { useGetOutlet } from 'features/events/use-cases/get-outlet';
import { usePermissions } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import { EventsFilterFormValues } from '../../events-filter-form';
import { AddEventOutletModal } from './components/modal/addEventOutletModal';
import { OutletViewTable } from './components/modal/outlettable';

const OutletComponent = ({ tabs }: { tabs: string }) => {
  const { id } = useParams();
  const initialFilterValues: EventsFilterFormValues = {
    field_name: undefined,
  };

  const permissions = usePermissions();
  const { params, updateParams } =
    usePaginationParams<any>({
      page: 1,
      size: 10,
      order_by: '-rank',
      event_id: id,
      ...initialFilterValues,
    });
  const { data, isFetching, refetch } = useGetOutlet(params);
  const shouldHandleFieldCreation = permissions?.fields.add;
  const [showAddEventModal, setShowAddEventModal] = useState<boolean>(false);
  const [outletdata, setOutletdata] = useState(undefined)

  const handleCloseModal = () => {
    setShowAddEventModal(false);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = () => {
    setShowAddEventModal(true);
    setOutletdata(undefined);
    document.body.style.overflow = 'hidden';
  }

  useEffect(() => {
    if (tabs === "3") {
      refetch();
    }
  }, [tabs]);

  return (

    <>
      <ContentTopBar
        title={''}
        renderButtons={
          <>
            {shouldHandleFieldCreation ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <Button
                  icon={<PlusOutlined />}
                  onClick={handleOpenModal}
                >
                  Add Outlet
                </Button>
                <div className={`cstmrightmdl ${showAddEventModal && "show"}`}>
                  <AddEventOutletModal outletdata={outletdata} refetch={refetch} onClose={handleCloseModal} />

                </div>

              </div>
            ) : null}
          </>
        }
      />
      <OutletViewTable
        data={data}
        isLoading={isFetching}
        tableParams={params}
        updateParams={updateParams}
        refetch={refetch}
        setOutletdata={setOutletdata}
        outletdata={outletdata}
      />
    </>
  )
}

export default OutletComponent;
