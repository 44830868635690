import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Empty, Grid, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
    EventsFilterFormPaginated
} from 'features/events';
import { useEventActiveStatus } from 'features/events/use-cases/event-active-status';
import { useEventDeleteStatus } from 'features/events/use-cases/event-delete-status';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Event } from 'types/event';

import { AddPriceCategoryModal } from '../add-price-category-modal/add-price-category-modal';
import { AddDiscountModal } from './add-discount-modal/add-discount-modal';
const { useBreakpoint } = Grid;

type EventsTableProps = {
    isLoading: boolean;
    data?: any;
    tableParams: EventsFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<EventsFilterFormPaginated>>;
    refetch: () => void;
};

export const PriceTable = ({
    data,
    isLoading,
    tableParams,
    updateParams,
    refetch
}: EventsTableProps) => {
    const screens = useBreakpoint();
    const permissions = usePermissions();
    const { id } = useParams();

    const [showAddPriceModal, setShowAddPriceModal] = useState<boolean>(false);
    const [editId, setEditId] = useState<string | null>(null);
    const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);

    const tableWidth = screens.xxl ? { x: 1440 } : { x: 1200 };
    const handleCloseModal = () => {
        document.body.style.overflow = 'visible';
        setEditId(null);
        setShowAddPriceModal(false);
    }

    const handleCloseModalDiscount = () => {
        document.body.style.overflow = 'visible';
        setEditId(null);
        setShowDiscountModal(false);
    }

    const handleOpenModal = (record: any) => {
        document.body.style.overflow = 'hidden';
        setEditId(record);
        setShowAddPriceModal(true);
    }


    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Event> | SorterResult<Event>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: Array.isArray(sorter)
                ? undefined
                : sorter.order === 'ascend'
                    ? 'name'
                    : '-name',
        });
    };
    ;



    const { mutate } = useEventDeleteStatus();

    const handleDelete = (record: any) => {
        const deletedFormData = {
            id: record.Id,
            status: false,
            flag: "TICKET_DELETE"
        }
        mutate(deletedFormData);
    }

    const { mutate: updateStatusMutate, isLoading: statusLoading } = useEventActiveStatus();

    const handleUpdateStatus = (updateId: any, isChecked: any) => {
        const statusFormData = {
            id: updateId,
            status: isChecked,
            flag: "TICKET_SET_STATUS"
        }
        updateStatusMutate(statusFormData);
    }

    if (!permissions) return null;

    const handleOpenDiscount = () => {
        setShowDiscountModal(true)
        document.body.style.overflow = 'hidden';
    }

    const { confirm } = Modal;

    const showDeleteConfirm = (event: any, record: any) => {
        event.stopPropagation();
        confirm({
            title: 'Are you sure you want to delete this Ticket?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record);
            },
        });
    };



    return (

        <>
            <div className={`cstmrightmdl ${showDiscountModal ? "show" : ''}`}>
                <AddDiscountModal onClose={handleCloseModalDiscount} showAddPriceModal={showDiscountModal} editId={editId} refetch={refetch} id={id} />
            </div>
            <div className={`cstmrightmdl ${showAddPriceModal ? "show" : ''}`}>
                <AddPriceCategoryModal onClose={handleCloseModal} showAddPriceModal={showAddPriceModal} editId={editId} refetch={refetch} />
            </div>


            <StyledTableWrapper>
                <StyledTableHeader>
                    <div className='tket_head'>
                        <RecordCountBadge
                            text={"Tickets found"}
                            count={data?.total || 0}
                        />
                        <div>
                            {data?.total > 0 &&
                                <Button
                                    className='btn_common'
                                    icon={<PlusOutlined />}
                                    onClick={handleOpenDiscount}
                                //  className='adtct'
                                >
                                    Add Discount

                                </Button>}

                            <Button
                                className='btn_common'
                                icon={<PlusOutlined />}
                                onClick={() => handleOpenModal(null)}
                            >
                                Add Tickets

                            </Button>

                        </div>
                    </div>

                </StyledTableHeader>
                <Table<Event>
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading && { indicator: <div><TableLoader /></div> }}

                    dataSource={isLoading ? [] : data?.items}
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.page,
                        defaultPageSize: tableParams.size,
                        total: data?.total,
                    }}
                    className='event_table cstltblwthldr'
                    scroll={tableWidth}
                    rowClassName={(record) => (record.price_type_name === 'Early Bird' ? 'early-bird-row' : '')}
                >
                    <Column<Event>
                        title={'Category Name'}
                        dataIndex={'Category'}
                        // sorter={true}
                        // sortDirections={['ascend', 'descend', 'ascend']}
                        // defaultSortOrder={'descend'}
                        width={screens.xxl ? 200 : 100}
                    // width={100}

                    />
                    <Column<Event>
                        title={'Price Type'}
                        // dataIndex={'price_type_name'}
                        width={screens.xxl ? 'auto' : 100}
                        render={(record) => (
                            record.price_type_name ? record.price_type_name : "Normal"
                        )}
                    />

                    <Column<Event>
                        title={'Individual Price'}
                        // dataIndex={'Price_in'}
                        width={screens.xxl ? 'auto' : 100}
                        className='cell-direction-rtl'

                        render={(record) => (
                            record.Price_in
                        )}
                    />

                    <Column<Event>
                        title={'Team Price'}
                        // dataIndex={'Price_te'}
                        width={screens.xxl ? 'auto' : 100}

                        render={(record) => (
                            record.Price_te
                        )}
                    />
                    <Column<Event>
                        title={'No of Person'}
                        dataIndex={'No_of_persons'}
                        width={screens.xxl ? 'auto' : 100}
                    />
                    <Column<Event>
                        title={'Waiting Participant'}
                        dataIndex={'WaitingParticipant'}
                        width={screens.xxl ? 'auto' : 100}
                    />

                    {/* <Column<Event>
                        title={'Waiting Start Date'}
                        // dataIndex={'WaitingStartDate'}
                        width={screens.xxl ? 'auto' : 100}
                        render={(record) => (
                            formatDateDisplay(record.WaitingStartDate)
                        )}
                    />

                    <Column<Event>
                        title={'Waiting End Date'}
                        // dataIndex={'WaitingEndDate'}
                        width={screens.xxl ? 'auto' : 100}
                        render={(record) => (
                            formatDateDisplay(record.WaitingEndDate)
                        )}
                    /> */}
                    <Column<Event>
                        title={"Action"}
                        width={screens.xxl ? 'auto' : 120}
                        dataIndex={'is_active'}
                        render={(_, record) => (
                            <>
                                <Space>
                                    <Tooltip title="Edit">
                                        <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                                    </Tooltip>

                                    <Tooltip title="Delete">
                                        <Button shape="circle" icon={<DeleteOutlined />} onClick={(event) => showDeleteConfirm(event, record)} />
                                    </Tooltip>
                                    <Tooltip title="Is Active">
                                        <Switch
                                            defaultChecked={record.Status}
                                            checked={record.Status}
                                            loading={statusLoading}
                                            onChange={(isChecked) => handleUpdateStatus(record.Id, isChecked)}
                                        />
                                    </Tooltip>
                                </Space>
                            </>
                        )}
                    />
                </Table>

            </StyledTableWrapper>
        </>
    );
};


