
export enum AutoDiscountField {
    Id = "id",
    EventId = "event_id",
    Name = "title",
    Description = "description",
    Threshold = "threshold",
    DiscountPercentage = "discount_percentage",
    IsActive = "is_active",
    StartDate = "start_date",
    EndDate = "end_date",

};

export type AutoDiscountFormValues = {
    [AutoDiscountField.Id]: any;
    [AutoDiscountField.EventId]: string;
    [AutoDiscountField.Name]: string;
    [AutoDiscountField.Description]: string;
    [AutoDiscountField.Threshold]: number;
    [AutoDiscountField.DiscountPercentage]: number;
    [AutoDiscountField.IsActive]: boolean;
    [AutoDiscountField.StartDate]: any;
    [AutoDiscountField.EndDate]: any;

};

export type AutoDiscountDTO = {
    id: any;
    event_id: string;
    title: string;
    description: string;
    threshold: number;
    discount_percentage: number;
    is_active: boolean;
    start_date: any;
    end_date: any

}
