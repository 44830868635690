import 'react-quill/dist/quill.snow.css';

import { Button, Col, Form, Row, Skeleton } from 'antd';
import dayjs from 'dayjs';
import {
  UpdateEventDetailsDTO,
} from 'features/events/api/events-api.service';
import { useUpdateEventDetails } from 'features/events/use-cases/event-discription-update';
import { useEffect, useState } from 'react';
import { payFormatDate, payFormatDateTime } from 'utils/payload-date-';

import NoImage from '../../../../assets/No_Image_Available.jpg';
import BasicInfoTextEditor from './basic-info-text-editor/basic-info-text-editor';
import DetailsInformationForm from './details-information-form/details-information-form';

// interface EventDescriptionData {
//   EventDescription: string;
//   EventId: number;
//   EventMasterId: string;
//   EventName: string;
//   EventNameAR: string;
//   CategoryId: number;
//   StartDate: string;
//   EndDate: string;
//   EventDate: string;
//   CurrencyId: number;
//   image_url: string;
//   Address: string,
//   AddressLat: string,
//   AddressLong: string,
//   LocationMapURL: string,
//   Venue: string,
//   MedalPrice: number,
//   TShirtPrice: number,
//   DeliveryPrice: number,
//   PhotoPrice: number,
//   RefundInsurance: boolean,
//   RefundProtectValue: number,
//   EventDescriptionAR: string;
// }

interface props {
  id: string;
  data: any;
  refetch: () => void;
}
const BasicContent = ({ id, data, refetch }: props) => {
  const { isLoading, mutate } = useUpdateEventDetails();
  const [code, setCode] = useState<string>('');
  const [arebicDescr, setArebicDescr] = useState<string>('');
  const [disData, setDisData] = useState<any>('');
  const [discriptionEdit, setDiscriptionEdit] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [mainData, setMainData] = useState<any>();

  const formName = "event-all-details"

  const [form] = Form.useForm();
  const onFinish = (values: any) => {

    const payload = getPayload(values);
    mutate(payload, {
      onSuccess: () => {
        setMainData("")
        refetch()
      }
    });
    setEdit(false);
  };

  const getPayload = (values: any) => {
    const payload: UpdateEventDetailsDTO = {
      ...values,
      EventId: id,
      EventMasterId: id,
      StartDate: payFormatDate(values?.StartDate),
      EndDate: payFormatDate(values?.EndDate),
      EventDate: payFormatDateTime(values?.EventDate),
    };

    return payload;
  };


  function decodeHTMLEntities(text: string) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;

    return textArea.value;
  }


  useEffect(() => {
    setMainData(data)
    form.resetFields();
    setCode(decodeHTMLEntities(data?.EventDescription));
    setDisData(decodeHTMLEntities(data?.EventDescription as any));
    setArebicDescr(data?.EventDescriptionAR as any);
    form.setFieldsValue({
      ...mainData,
      StartDate: dayjs(mainData?.StartDate),
      EndDate: dayjs(mainData?.EndDate),
      EventDate: dayjs(mainData?.EventDate),
    });
    const resjson = JSON.stringify(data)
    localStorage.setItem("event_details", resjson)
  }, [data, mainData]);


  const handleOpenModal = () => {
    setDiscriptionEdit(true);
    document.body.style.overflow = 'hidden';
  }

  const handleCloseModal = () => {
    setDiscriptionEdit(false);
    document.body.style.overflow = 'visible';
  }
  // const rows = 5;
  // const cols = 3;

  // // Create an array to hold the form items
  // const formItems = Array.from({ length: rows * cols }, (_, index) => (
  //   <Col span={8} key={index} style={{ marginBottom: '20px' }}>
  //     <Form.Item name={`field${index + 1}`}>
  //       <Skeleton.Input active style={{ width: "240px" }} />
  //     </Form.Item>
  //   </Col>
  // ));

  const handleEditCancel = () => {
    setMainData("");
    setEdit(false);
    refetch();
  }
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    setTimeout(() => setLoading(false), 1000);
    //I mock loading with setTimeout,the skeleton disappears in 5 seconds

  }, [])

  return (

    <>
      {/* {isLoadingGet ? (
        <>

          <Row gutter={16} >
            <Col span={10}>
              <Skeleton.Image style={{ width: 200, height: 200 }} />
            </Col>
            <Col span={12}>
              <Skeleton active paragraph={{ rows: 5 }} />
            </Col>
            <Col span={2}>
              <Skeleton.Button active />
            </Col>
            <Form layout="vertical" style={{ marginTop: "40px" }}>
              {Array.from({ length: rows }, (_, rowIndex) => (
                <Row gutter={30} key={rowIndex}>
                  {formItems.slice(rowIndex * cols, (rowIndex + 1) * cols)}
                </Row>
              ))}
              <Form.Item>
                <Skeleton.Button active />
              </Form.Item>
            </Form>
          </Row>

        </>
      ) : ( */}

      <div className='tabcstmbx'>
        <Form
          // form={form}
          // onFinish={onFinish}
          // layout='vertical'
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          // initialValues={getInitialValues}
          onFinish={onFinish}
          // requiredMark={'optional'}
          // onFinishFailed={() => {
          //   setValidateTrigger(['onChange']);
          // }}
          // validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <Row className='edbx'>
            <Col className='cellbx edbx_clm'>
              <div className='edbxcld'>
                <Row>
                  <Col className='edfromedtr' span={24}>
                    <h3>Event Information</h3>

                    <div className='descrip'>
                      {loading ? <Skeleton.Image active={true} style={{ height: "250px", width: "400px" }} />
                        :
                        <div className='des_img' style={{ backgroundImage: `url(${data?.image_url})`, }}>

                          <img src={data?.image_url}
                            alt=''
                            onError={(e: any) => { e.target.src = NoImage }}
                          />
                        </div>
                      }

                      <div className='des_dtls'>
                        <div className='des_d_head'>
                          <h5>Description</h5>
                          <Button onClick={handleOpenModal}>Edit</Button>
                        </div>
                        <div className='des_b' title={disData && new DOMParser().parseFromString(disData, 'text/html').documentElement.textContent}>
                          <p dangerouslySetInnerHTML={{ __html: disData }} />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>

                    <div className='edit_info_btns'>
                      <Form.Item className='button-group'>
                        {!edit ?
                          <Button
                            type='primary'
                            className='btn'
                            onClick={() => setEdit(true)}
                          >
                            Edit
                          </Button>
                          :
                          <>
                            <Button
                              type='primary'
                              className='btn cancel_btn'
                              onClick={handleEditCancel}
                            >
                              Cancel
                            </Button>

                            <Button
                              type='primary'
                              className='btn'
                              htmlType='submit'
                              loading={isLoading}
                            >
                              Update
                            </Button>
                          </>

                        }
                      </Form.Item>
                    </div>
                    {/* </Form.Item> */}
                    <div className='edfrom'>
                      <DetailsInformationForm edit={edit} form={form} mainData={mainData} />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
      </div >


      <div className={`cstmrightmdl ${discriptionEdit && "show"}`}>
        <BasicInfoTextEditor
          setCode={setCode}
          code={code}
          showModal={discriptionEdit}
          onClose={handleCloseModal}
          id={id}
          fetchEventDiscriptionData={refetch}
          arebicDescr={arebicDescr}
          setArebicDescr={setArebicDescr}
        />
      </div>
    </>
  );
};

export default BasicContent;
