import { PaginatedRequestParams } from 'api';
import { Dayjs } from 'dayjs';
import { Gender as GenderType } from 'types';

export enum UserFilterFormField {
  FullUserName = 'full_user_name',
  PhoneNumber = 'phone_number',
  Role = 'role',
  Company = 'company_id',
  Email = 'email',
}

export type UserFilterFormValues = {
  [UserFilterFormField.FullUserName]: string | undefined;
  [UserFilterFormField.PhoneNumber]: string | undefined;
  [UserFilterFormField.Email]: string | undefined;
  [UserFilterFormField.Role]: string | undefined;
  [UserFilterFormField.Company]: string | undefined;
};

export type UserFilterFormValuesPaginated = UserFilterFormValues &
  PaginatedRequestParams;

export enum EndUserFilterFormField {
  Date = 'date',
  FullUserName = 'full_user_name',
  PhoneNumber = 'phone_number',
  Email = 'email',
}

export type EndUserFilterFormValues = {
  [EndUserFilterFormField.Date]: [Dayjs | undefined, Dayjs | undefined] | null;
  [EndUserFilterFormField.FullUserName]: string | undefined;
  [EndUserFilterFormField.PhoneNumber]: string | undefined;
  [EndUserFilterFormField.Email]: string | undefined;
};

export type EndUserFilterFormValuesPaginated = EndUserFilterFormValues &
  PaginatedRequestParams;

export enum UserFormField {
  Id = 'id',
  Date = 'date',
  Company = 'company_id',
  Role = 'role',
  FirstName = 'first_name',
  LastName = 'last_name',
  Gender = 'gender',
  Email = 'email',
  PhoneNumber = 'phone_number',
  Password = 'password',
  ConfirmPassword = 'confirm_password',
  Address = 'address',
  IsActive = 'is_active',
}

export type UserFormValues = {
  [UserFormField.Id]?: string | undefined;
  [UserFormField.Date]: string;
  [UserFormField.Company]: string;
  [UserFormField.Role]: string;
  [UserFormField.FirstName]: string;
  [UserFormField.LastName]: string;
  [UserFormField.Gender]: GenderType | null;
  [UserFormField.Email]: string;
  [UserFormField.PhoneNumber]: string;
  [UserFormField.Password]: string;
  [UserFormField.ConfirmPassword]: string;
  [UserFormField.Address]: string;
  [UserFormField.IsActive]: boolean;
};

export enum EndUserFormField {
  FirstName = 'first_name',
  LastName = 'last_name',
  Gender = 'gender',
  Email = 'email',
  PhoneNumber = 'phone_number',
}

export type EndUserFormValues = {
  [UserFormField.FirstName]: string;
  [UserFormField.LastName]: string;
  [UserFormField.Gender]: GenderType | null;
  [UserFormField.Email]: string;
  [UserFormField.PhoneNumber]: string;
};

export enum AddEventFormField {
  EventName = 'event_id',
}

export type AddEventFormValues = {
  [AddEventFormField.EventName]: {
    label: string,
    value: number
  };
}

export enum VolunteerUserFilterFormField {
  Name = 'Name',
  Email = 'Email',
  PhoneNumber = 'PhoneNo',
}

export type VolunteerUserFilterFormValues = {
  [VolunteerUserFilterFormField.Name]: string | undefined;
  [VolunteerUserFilterFormField.Email]: string | undefined;
  [VolunteerUserFilterFormField.PhoneNumber]: string | undefined;

};

export type VolunteerUserFilterFormValuesPaginated = VolunteerUserFilterFormValues &
  PaginatedRequestParams;

export enum VolunteerUserFormField {
  Id = 'Id',
  Name = 'Name',
  EventId = 'EventId',
  Email = 'Email',
  PhoneNumber = 'PhoneNo',
  Password = 'Password',
  ConfirmPassword = 'confirm_password',
  IsRefundAllowed = 'IsRefundAllowed',
  Status = 'Status',
}

export type VolunteerUserFormValues = {
  [VolunteerUserFormField.Id]: number;
  [VolunteerUserFormField.Name]: string;
  [VolunteerUserFormField.EventId]: string;
  [VolunteerUserFormField.Email]: string;
  [VolunteerUserFormField.PhoneNumber]: string;
  [VolunteerUserFormField.Password]: string;
  [VolunteerUserFormField.IsRefundAllowed]: boolean;
  [VolunteerUserFormField.Status]: boolean;
};

export enum AgentBankFormField {
  BankName = 'bank_name',
  AccountNo = 'account_no',
  AccountName = 'account_name',
  IbanCode = 'iban_code',
  SwiftCode = 'swift_code',
}

export type AgentBankFormValues = {
  [AgentBankFormField.BankName]: string;
  [AgentBankFormField.AccountNo]: string;
  [AgentBankFormField.AccountName]: string;
  [AgentBankFormField.IbanCode]: string;
  [AgentBankFormField.SwiftCode]: string;
};



export enum ItemSalesFilterFormField {
  InvoiceNo = 'invoice_no',
  Name = 'name',
  PhoneNumber = 'phone_number',
  Email = 'email_address',
  PaymentMethod = 'payment_method',
  TransactionStatus = "payment_status",
  CouponApplied = 'coupon_applied',
  ItemsDelivery = "item_delivery",
  OrderFrom = 'order_from',
  OrderTo = "order_to",
}

export type ItemSalesFilterFormValues = {
  [ItemSalesFilterFormField.InvoiceNo]: string | undefined;
  [ItemSalesFilterFormField.Name]: string | undefined;
  [ItemSalesFilterFormField.PhoneNumber]: string | undefined;
  [ItemSalesFilterFormField.Email]: string | undefined;
  [ItemSalesFilterFormField.PaymentMethod]: string | undefined;
  [ItemSalesFilterFormField.TransactionStatus]: string | undefined;
  [ItemSalesFilterFormField.CouponApplied]: string | undefined;
  [ItemSalesFilterFormField.ItemsDelivery]: string | undefined;
  [ItemSalesFilterFormField.OrderFrom]: any;
  [ItemSalesFilterFormField.OrderTo]: any;
};

export type ItemSalesFilterFormValuesPaginated = ItemSalesFilterFormValues &
  PaginatedRequestParams;