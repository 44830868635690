import { PaginatedRequestParams } from 'api';

export enum WaitingFilterFormField {
    InvoiceId = "invoice_number",
    Name = 'name',
    MobileNo = 'MobileNumber',
    Email = 'email',
    paymentMethod = "paymentMethod",
    TransactionStatus = 'tansactionStatus',
    BookingDate = 'bookingDate',
    TransactionId = 'tranactionId',
    RefrenceNumber = 'RefrenceNumber',
    AuthId = 'AuthId',
    Currency = "currency",
    UserType = 'userType',
    Year = "year"
    // PayType = 'pay_type',
    // Date = 'date',
}

export type WaitingFilterFormValues = {
    [WaitingFilterFormField.InvoiceId]: string;
    [WaitingFilterFormField.Name]: string;
    [WaitingFilterFormField.MobileNo]: string;
    [WaitingFilterFormField.Email]: string;
    [WaitingFilterFormField.paymentMethod]: string;
    [WaitingFilterFormField.TransactionStatus]: string;
    [WaitingFilterFormField.BookingDate]: any;
    [WaitingFilterFormField.TransactionId]: string;
    [WaitingFilterFormField.AuthId]: string;
    [WaitingFilterFormField.RefrenceNumber]: string;
    [WaitingFilterFormField.UserType]: string;
    [WaitingFilterFormField.Year]: string;
};

export type WaitingFilterFormValuesPaginated = WaitingFilterFormValues &
    PaginatedRequestParams;