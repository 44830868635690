export enum ItemStatusFormField {
    Id = 'booking_id',
    Status = 'order_status',
    Name = 'shelf_name',
    Comment = 'comment',
}



export type ItemStatusFormValues = {
    [ItemStatusFormField.Id]: string;
    [ItemStatusFormField.Status]: string;
    [ItemStatusFormField.Name]: string;
    [ItemStatusFormField.Comment]: string;
};
