import { useParams } from 'react-router-dom';

import EventDetailsTabs from '../components/event-details-form/event-details-tabs';

export const EventDetailsView = () => {
  const { id } = useParams();

  if (!id) return null;

  return <EventDetailsTabs />;
};
