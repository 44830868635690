import { PlusOutlined } from '@ant-design/icons';
import { Button, Grid } from 'antd';
import { ContentTopBar } from 'components';
import { getSuffixUserId } from 'features/auth';
import { useGetSponser } from 'features/events/use-cases/get-sponser';
import { usePermissions } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import { EventsFilterFormValues } from '../../events-filter-form';
import AddSponserModal from './add-sponser-modal/add-sponser-modal';
import { SponserTable } from './sponser-table';


const EventSponser = ({ tabs }: { tabs: string }) => {

    const { useBreakpoint } = Grid;
    const { id } = useParams();
    const initialFilterValues: EventsFilterFormValues = {
        field_name: undefined,
    };

    const screens = useBreakpoint();
    const permissions = usePermissions();
    const suffixUserId = getSuffixUserId();
    const { params, updateParams } =
        usePaginationParams<any>({
            user_id: suffixUserId,
            event_id: id,
            order_by: '-rank',
            size: 10,
            page: 1,
            ...initialFilterValues,
        });

    const { data, isFetching, refetch } = useGetSponser(params);

    const shouldHandleFieldCreation = permissions?.fields.add;

    const [showAddSponserModal, setShowAddSponserModal] = useState<boolean>(false);
    const [sponserData, setSponserData] = useState<any>(undefined);

    const handleCloseModal = () => {
        setShowAddSponserModal(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = () => {
        setShowAddSponserModal(true);
        document.body.style.overflow = 'hidden';
    }


    useEffect(() => {
        if (tabs === "2") {
            refetch();
        }
    }, [tabs]);

    return (

        <>
            <ContentTopBar
                title={""}
                renderButtons={
                    <>
                        {shouldHandleFieldCreation ? (
                            <>
                                <Button
                                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                                    onClick={handleOpenModal}
                                >
                                    Add Events Sponsor
                                </Button>
                                <div className={`cstmrightmdl ${showAddSponserModal && "show"}`}>

                                    <AddSponserModal onClose={handleCloseModal} refetch={refetch} sponserData={sponserData} />
                                </div>

                            </>
                        ) : null}
                    </>
                }
            />
            <SponserTable
                data={data}
                isLoading={isFetching}
                tableParams={params}
                updateParams={updateParams}
                refetch={refetch}
                sponserData={sponserData}
                setSponserData={setSponserData}
            />
        </>
    )
}

export default EventSponser;
