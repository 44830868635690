import { PaginatedRequestParams } from "api";

export enum NotificationFilterFormField {
  ID = 'id',
  TITLE = 'title',
  is_active = 'is_active'
}
export enum NotificationFilterFormFieldList {
  ID = 'id',
  TITLE = 'title',
  CONTENT='content',
  ANNOUNCEMENTTYPEID='announcement_type_id',
  ACTIVE="is_active"
}

export type NotificationFilterFormValues = {
  [NotificationFilterFormField.ID]: string | undefined;
  [NotificationFilterFormField.TITLE]: string | undefined;
  [NotificationFilterFormField.is_active]: any;
};
export type NotificationFilterFormValueslist = {
  [NotificationFilterFormFieldList.ID]: string | undefined;
  [NotificationFilterFormFieldList.TITLE]: string | undefined;
  [NotificationFilterFormFieldList.ANNOUNCEMENTTYPEID]: string | undefined;
  [NotificationFilterFormFieldList.ACTIVE]: any;
};
export type NotificationFilterFormValuesPaginated = NotificationFilterFormValues &
  PaginatedRequestParams;