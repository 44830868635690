export enum OrganizerFormField {
    id = 'id',
    event_id = 'event_id',
    name = 'name',
    link = 'link',
    contact_no = 'contact_no',
    email = 'email',
    address = 'address',
    is_active = 'is_active',
    image_url = 'image_url',
    user_id = 'user_id',
}

export type Organizer = {
    [x: string]: any;
    id: number;
    event_id: string | undefined;
    name: string;
    link: string;
    contact_no: string;
    email: string;
    address: string;
    is_active: boolean;
    image_url: any;
};

export type OrganizerFormValues = {
    [OrganizerFormField.id]: string | undefined;
    [OrganizerFormField.event_id]: string | undefined;
    [OrganizerFormField.name]: string;
    [OrganizerFormField.link]: string;
    [OrganizerFormField.contact_no]: string;
    [OrganizerFormField.email]: string;
    [OrganizerFormField.address]: string;
    [OrganizerFormField.is_active]: boolean;
    [OrganizerFormField.image_url]: any;
};