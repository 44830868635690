import { Tabs } from 'antd';
import { useState } from 'react';

import EventGallary from './event-gallary';
import EventReceiptImage from './event-receipt-image/event-receipt-image';

const GalleryComponent = () => {

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (key: string) => {
    setActiveTab(key)
  }

  const tabItems = [
    {
      key: '1',
      label: 'Event List Images',
      children: (
        <div className='mglrybx'>
          <EventGallary activeTab={activeTab} />
        </div>
      ),
      className: 'cltb'
    },
    {
      key: '2',
      label: 'Event Details Images',
      children: (
        <div className='mglrybx'>
          <EventGallary activeTab={activeTab} />
        </div>
      ),
      className: 'cltb'
    },
    {
      key: '3',
      label: 'Receipt Image',
      children: <EventReceiptImage activeTab={activeTab} />,
      className: 'cltb'
    },
    // Uncomment and update this part if needed
    // {
    //   key: '4',
    //   label: 'Website',
    //   children: <EventWebsiteComponent />,
    // },
  ];

  return (

    <>
      {/* <Tabs className='gallery_tab' defaultActiveKey='1' onChange={handleTabChange}>

        <TabPane className='cltb' tab='Event List Images' key='1' >
          <div className='mglrybx'><EventGallary activeTab={activeTab} /></div>
        </TabPane>
        <TabPane className='cltb' tab='Event Details Images' key='2' >
          <div className='mglrybx'><EventGallary activeTab={activeTab} /></div>
        </TabPane>
        <TabPane className='cltb' tab='Receipt Image' key='3'>
          <EventReceiptImage activeTab={activeTab} />
        </TabPane>
       
      </Tabs> */}

      <Tabs className='gallery_tab' defaultActiveKey='1' onChange={handleTabChange} items={tabItems} />
    </>
  );
};
export default GalleryComponent;
