import { DownOutlined, IdcardOutlined, UserOutlined } from '@ant-design/icons'
import { DatePicker, Form, Input, Select } from 'antd'
import { handleKeyAmount, handleKeyDownThree } from 'features/events/utils'
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator'
import { SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CouponFormField } from './types'

const AddCouponForm = ({ couponEditData, form }: { couponEditData: any; form: any }) => {
    const { t } = useTranslation();

    const valueType = [
        { value: 1, label: 'Flat' },
        { value: 2, label: 'Percent' },
    ];

    const couponType = [
        { value: "1", label: 'Automatic' },
        { value: "2", label: 'Manual' },
    ];

    const validateValue = (_: any, value: number) => {
        const selectedValueType = form.getFieldValue(CouponFormField.ValueType);
        if (selectedValueType === 2 && value > 100) {

            return Promise.reject(new Error('Value cannot be more than 100 when ValueType is Percent'));
        }

        return Promise.resolve();
    };

    const [couponTypeVal, setCouponTypeVal] = useState(null);

    const handleCouponTypeChange = (value: SetStateAction<null>) => {
        setCouponTypeVal(value);
    };

    useEffect(() => {
        if (couponEditData) {
            setCouponTypeVal(couponEditData.coupon_type);
        }
    }, [couponEditData])

    return (
        <div className='frmscrll'>
            <FormSectionsSeparator title={""}>
                {!couponEditData?.id && <Form.Item<CouponFormField>
                    label={"Coupon Type"}
                    name={CouponFormField.CouponType}
                    rules={[
                        {
                            required: true,
                            // whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Select
                        options={couponType}
                        placeholder={"select coupon type"}
                        className='has-prefix-icon'
                        onChange={handleCouponTypeChange}
                        suffixIcon={
                            <>
                                <IdcardOutlined className='prefix-icon' />
                                <DownOutlined className='suffix-icon' />
                            </>
                        }
                    />
                </Form.Item>}

                <Form.Item<CouponFormField>
                    label={"Coupon Title"}
                    name={CouponFormField.Title}

                    rules={[
                        {
                            required: true,
                            // whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Input
                        placeholder={"Title"}
                        prefix={<UserOutlined className='input-prefix-icon' />}
                    />
                </Form.Item>



                {(!couponTypeVal || couponTypeVal != "2") &&
                    <Form.Item<CouponFormField>
                        label={"Prefix"}
                        name={CouponFormField.Prefix}

                        rules={[
                            {
                                required: true,
                                // whitespace: true,
                                message: t('validateMessages.custom.nomsg'),
                            },
                            {
                                min: 2,
                                max: 2,
                                message: 'Prefix must be exactly 2 characters long',
                            },
                        ]}
                    >
                        <Input
                            placeholder={"Prefix"}
                            maxLength={2}
                            disabled={couponEditData?.id}
                        />
                    </Form.Item>}

                {couponEditData?.id &&
                    <Form.Item<CouponFormField>
                        label={"Coupon Code"}
                        name={CouponFormField.couponCode}

                    // rules={[
                    //     {
                    //         required: true,
                    //         // whitespace: true,
                    //         message: t('validateMessages.custom.nomsg'),
                    //     },
                    // ]}
                    >
                        <Input
                            placeholder={""}
                            disabled={couponEditData?.id}
                        />
                    </Form.Item>
                }


                {(!couponEditData?.id && couponTypeVal != "2") && <Form.Item<CouponFormField>
                    label={"No of Coupons"}
                    name={CouponFormField.NoOfCoupons}

                    rules={[
                        {
                            required: true,
                            // whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Input
                        placeholder={"No of coupons"}
                        onKeyDown={handleKeyDownThree}
                    />
                </Form.Item>}


                {!couponEditData?.id && <Form.Item<CouponFormField>
                    label={"Limit Usage"}
                    name={CouponFormField.Limit}

                    rules={[
                        {
                            required: true,
                            // whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Input
                        placeholder={"Limit Usage"}
                        onKeyDown={handleKeyDownThree}
                    />
                </Form.Item>}


                <Form.Item<CouponFormField>
                    label={"Value Type"}
                    name={CouponFormField.ValueType}
                    rules={[
                        {
                            required: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <Select
                        options={valueType}
                        placeholder={"select Value type"}
                        className='has-prefix-icon'
                        suffixIcon={
                            <>
                                <IdcardOutlined className='prefix-icon' />
                                <DownOutlined className='suffix-icon' />
                            </>
                        }
                    />
                </Form.Item>

                <Form.Item<CouponFormField>
                    label={"Value"}
                    name={CouponFormField.Value}

                    rules={[
                        {
                            required: true,
                            message: t('validateMessages.custom.nomsg'),
                        },

                        {
                            validator(_, value) {
                                if (!value) {
                                    return Promise.resolve();
                                }
                                const stringValue = String(value);
                                const parsedValue = stringValue.match(/^\d*\.?\d{0,3}$/);
                                if (parsedValue) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('Please enter a valid numeric value with at most three decimal places'));
                            },
                        },
                        {
                            validator: validateValue,
                        },
                    ]}
                >
                    <Input
                        placeholder={"Value"}
                        onKeyDown={handleKeyAmount}
                    />
                </Form.Item>

                <Form.Item<CouponFormField>
                    label={"Start Date"}
                    name={CouponFormField.StartDate}
                    rules={[
                        {
                            required: true,
                            // whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                // rules={[
                //     {
                //         required: true,
                //         // message: "Please select StartDate"

                //     },
                // ]}
                >
                    <DatePicker style={{ width: '100%' }} format={"DD-MM-YYYY"} />
                </Form.Item>

                <Form.Item<CouponFormField>
                    label={"End Date"}
                    name={CouponFormField.EndDate}
                    rules={[
                        {
                            required: true,
                            // whitespace: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <DatePicker style={{ width: '100%' }} format={"DD-MM-YYYY"} />
                </Form.Item>



            </FormSectionsSeparator>
        </div>
    )
}

export default AddCouponForm;
