import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyType } from 'types';

import { AddItemModal } from '../modal/additem';
import { ItemFilterForm } from '../shop-filter-form/item-filter-form';
import { ShopItemFormFieldValue } from '../shop-filter-form/type';
type UsersTopBarProps = {
  getInitialFilterValues: ShopItemFormFieldValue;
  handleFilter: (values: ShopItemFormFieldValue) => void;
  refetch: any
};
const { useBreakpoint } = Grid;
export const ItemListTopBar = ({
  getInitialFilterValues,
  handleFilter,
  refetch,
}: UsersTopBarProps) => {
  useState<CompanyType>();
  const { t } = useTranslation();
  const formId = 'users-filters-form';
  const screens = useBreakpoint();
  const [showAddItemModal, setShowAddItemModal] = useState<boolean>(false);

  const handleOpenModal = () => {
    setShowAddItemModal(true)
    document.body.style.overflow = 'hidden';
  }

  const handleCloseModal = () => {
    setShowAddItemModal(false)
    document.body.style.overflow = 'visible';
  }

  return (
    <>
      <div className={`cstmrightmdl ${showAddItemModal && "show"}`}>
        <AddItemModal onClose={handleCloseModal} refetch={refetch} showAddItemModal={showAddItemModal} />
      </div>

      <ContentTopBar
        title={t('shops.itemlist.heading')}
        renderButtons={
          <>
            <Button
               icon={!screens.xs ? <PlusOutlined /> : undefined}
              onClick={handleOpenModal}
              type='primary'
            >
              Add Item
            </Button>
          </>
        }
      />
      <ContentFilters formId={formId}>
        <ItemFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
          refetch={refetch}
        />
      </ContentFilters>
    </>
  );
};
