import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import {
    MainFilters,
    MainFiltersParams,
} from 'components/main-filters/main-filters';
import {
    EventsFilterFormValues,
} from 'features/events';
import { usePermissions } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { ChooseTshirtSizeTabel } from '../components/choose-tshirt-size';
import { AddTshirtSizeModal } from '../components/choose-tshirt-size/Add-t-shirt-size-madal/add-tshirt-size-modal';
import { TshirtFilterForm, TshirtFilterFormPaginated, TshirtFilterFormValues } from '../components/tshirt-filter-form';
import { useGetTshirtSize } from '../use-cases/get-tshirt-size';
// import { AddEventModal } from '../components/modals/add-event-modal';
const { useBreakpoint } = Grid;
const initialFilterValues: TshirtFilterFormValues = {
    title: undefined,
    is_active: undefined
};

export const ChooseTshirtSizeView = () => {
    const screens = useBreakpoint();
    const permissions = usePermissions();
    const { params, updateParams, resetPage } =
        usePaginationParams<TshirtFilterFormPaginated>({
            page: 1,
            size: 10,
            order_by: '-rank',
            ...initialFilterValues,
        });
    const { data, isFetching, refetch } = useGetTshirtSize(params as any, { retry: false });
    const [tshirtEditData, setTshirtEditData] = useState<any>(undefined);
    const [showTshirtModal, setShowTshirtModal] = useState(false);

    const shouldHandleFieldCreation = permissions?.fields.add;

    const handleFiltersChange = (values: EventsFilterFormValues) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    const handleMainFiltersChange = (mainParams: MainFiltersParams) => {
        updateParams(mainParams);
        resetPage();
    };
    const formId = 'tshirt-filters-form';

    const handleCloseModal = () => {
        setShowTshirtModal(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = () => {
        setShowTshirtModal(true);
        document.body.style.overflow = 'hidden';
    }

    return (
        <>
            <ContentTopBar
                title={"TShirt Size List"}
                renderHeadingContent={
                    <MainFilters onChange={handleMainFiltersChange} />
                }
                renderButtons={
                    <>
                        {shouldHandleFieldCreation ? (
                            <>
                                <Button
                                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                                    type='primary'
                                    onClick={handleOpenModal}
                                >
                                    {"Add Size"}
                                </Button>
                                <div className={`cstmrightmdl ${showTshirtModal && "show"}`}>
                                    <AddTshirtSizeModal showTshirtModal={showTshirtModal} refetch={refetch} onClose={handleCloseModal} tshirtEditData={tshirtEditData} />
                                </div>

                            </>
                        ) : null}
                    </>
                }
            />

            <ContentFilters formId={formId}>
                <TshirtFilterForm
                    formId={formId}
                    handleFilter={handleFiltersChange as any}
                    initialValues={initialFilterValues}
                />
            </ContentFilters>

            <ChooseTshirtSizeTabel
                data={data}
                isLoading={isFetching}
                tableParams={params}
                updateParams={updateParams}
                refetch={refetch}
                tshirtEditData={tshirtEditData}
                setTshirtEditData={setTshirtEditData}
            />
            <Outlet />
        </>
    );
};

