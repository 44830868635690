import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, Skeleton, Space, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, Table } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import { useStatusResponse } from 'features/shop-item/use-cases/update-status-response-msg';
import { usePermissions } from 'features/users';
import { EndUserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'types';
import { Item } from 'types/item';

import { EditDeliveryModal } from '../../modal/edit-delivery-modal';
type UsersTableProps = {
    isDeliveryListLoading: boolean;
    data: any;
    refetch: any,
    deliveryEditData: any,
    setDeliveryData: any
    tableParams: EndUserFilterFormValuesPaginated;
    updateParams: Dispatch<SetStateAction<EndUserFilterFormValuesPaginated>>;
};

export const DeliveryListTable = ({
    data,
    isDeliveryListLoading,
    tableParams,
    updateParams,
    refetch,
    deliveryEditData,
    setDeliveryData
}: UsersTableProps) => {
    const { t } = useTranslation();
    const { confirm } = Modal;
    const permissions = usePermissions();
    const { mutate: mutateUpdateItem, isLoading } =
        useStatusResponse();

    const [deliveryEdit, setDeliveryEdit] = useState(false)

    const handleCloseModal = () => {
        setDeliveryEdit(false);
        setDeliveryData(undefined);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = (record: any) => {
        setDeliveryEdit(true);
        setDeliveryData(record);
        document.body.style.overflow = 'hidden';
    }


    if (!permissions) return null;


    const handleDelete = async (id: any) => {
        mutateUpdateItem({
            id: id,
            flag: "DELIVERY_CHARGE_DELETE",
            status: true
        });
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        sorter: SorterResult<User> | SorterResult<User>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: Array.isArray(sorter)
                ? undefined
                : sorter.order === 'ascend'
                    ? 'name'
                    : '-name',
        });
    };
    const showDeleteConfirm = (record: any) => {
        confirm({
            title: 'Are you sure you want to delete this Delivery?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record.id);
            },
        });
    };

    return (
        <>
            <div className={`cstmrightmdl ${deliveryEdit && "show"}`}>
                <EditDeliveryModal showCategorysModal={deliveryEdit} refetch={refetch} onClose={handleCloseModal} deliveryEditData={deliveryEditData} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    {/* <RecordCountBadge text={t('social.foundSocial')} count={itemsCount} /> */}
                </StyledTableHeader>
                <Table<User>
                    className="cstltblwthldr"
                    locale={{
                        emptyText: isDeliveryListLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isDeliveryListLoading && { indicator: <div><TableLoader /></div> }}
                    dataSource={isDeliveryListLoading ? [] : data?.items}
                    rowKey='id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.page,
                        defaultPageSize: tableParams.size,
                        total: data?.total,
                    }}
                // scroll={{ x: 1440 }}
                >
                    <Column<Item>
                        title={'Country'}
                        dataIndex={'country_name'}

                    />
                    <Column<Item>
                        title={'Area'}
                        dataIndex={'area_name'}
                    />
                    <Column<Item>
                        title={'Standard Delivery'}
                        dataIndex={'delivery_charges'}
                    />
                    <Column<Item>
                        title={'Express Delivery'}
                        dataIndex={'expr_delivery_charges'}
                    />
                    <Column
                        title={t('shops.category.table.action')}
                        render={(_, record: any) => (
                            <Space>
                                <Tooltip title="Edit">
                                    <Button shape="circle" onClick={() => handleOpenModal(record)}
                                        icon={<EditOutlined />} />
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <Button shape="circle" loading={isLoading} icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(record)} />
                                </Tooltip>
                            </Space>
                        )}
                    />
                </Table>
            </StyledTableWrapper>
        </>
    );
};
