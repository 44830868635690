import { Button, Form, Input, message, Radio, Select } from 'antd';
import { PartnersFormField, PartnersFormValues } from 'features/cms/components/manage-partners/add-partners-modal/types';
import { ShopApiService } from 'features/shop-item/api/shop-api.service';
import { useCreateItem } from 'features/shop-item/use-cases/create-item';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ItemFormField, ItemFormValues } from './type';
export const AddItemModal = ({ onClose, refetch, showAddItemModal }: { onClose: () => void; showAddItemModal: boolean, refetch: () => void }) => {
    const { id } = useParams();
    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<PartnersFormValues>();
    const [sizeAvailability, setSizeAvailability] = useState('');
    const [colorAvailability, setColorAvailability] = useState('');
    const { t } = useTranslation();


    const handleSizeAvailabilityChange = (e: any) => {
        setSizeAvailability(e.target.value);
    };
    const handleColorAvailabilityChange = (e: any) => {
        setColorAvailability(e.target.value);
    };
    const [category, setCategory] = useState({
        items: []
    } as any)
    const { isLoading, mutate } = useCreateItem();
    const getInitialValues = {
        [PartnersFormField.Id]: id,
        [PartnersFormField.WebsiteLink]: undefined,
        [PartnersFormField.File]: undefined,
        [PartnersFormField.IsActive]: undefined,
        [PartnersFormField.ImageUrl]: undefined,
    };
    const handleUpdate = (formData: ItemFormValues) => {
        const updatedFormData = {
            ...formData, // Spread existing formData
            size_availability: sizeAvailability,
            color_availability: colorAvailability,
            is_active: true
        };
        mutate(updatedFormData as any, { onSuccess: handleSuccess });
        form.resetFields()
    };

    const initialCategory = async () => {
        try {
            const apiRes = await ShopApiService().getCategory('' as any)
            setCategory(apiRes)
        } catch (error) {
            message.error('')
        }
    }

    const handleSuccess = () => {
        handleClose();
        refetch();
    }

    const handleClose = () => {
        onClose();
        form.resetFields();
    }

    useEffect(() => {
        if (showAddItemModal) {
            initialCategory()
        }

    }, [showAddItemModal])

    return (
        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={handleClose} className='close-btn'>
                    &times;
                </button>
                <h4>Add Item</h4>
            </div>
            <div className='modal_body'>
                <Form<PartnersFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate as any}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <FormSectionsSeparator title={""}>
                        <Form.Item<ItemFormField>
                            label={"Item Name"}
                            name={ItemFormField.title}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Enter item name"}

                            />
                        </Form.Item>
                        <Form.Item<ItemFormField>
                            label={"Item Name Arabic"}
                            name={ItemFormField.title_arabic}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Enter Item Name Arabic"}
                            />
                        </Form.Item>
                        <Form.Item<ItemFormField>
                            label={"Category"}
                            name={ItemFormField.shop_category_id}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Select
                                defaultValue="Select Category"
                                style={{ width: "100%" }}
                                options={category?.items.map((res: any) => ({ label: res.title, value: res.id }))}
                            />
                        </Form.Item>
                        <Form.Item<ItemFormField>
                            label={"Base Price"}
                            name={ItemFormField.base_price}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                                {
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.resolve();
                                        }
                                        const stringValue = String(value);
                                        const parsedValue = stringValue.match(/^\d*\.?\d{0,3}$/);
                                        if (parsedValue) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error('Please enter a valid numeric value with at most three decimal places'));
                                    }
                                }
                            ]}
                        >
                            <Input
                                placeholder={"Enter Base Price"}
                                onKeyPress={(e) => {
                                    const inputValue = (e.target as HTMLInputElement).value || '';
                                    const key = e.key;

                                    // Allow only numbers, single decimal point, and Backspace
                                    const isValidKey = /^[\d.]$/.test(key) || key === 'Backspace';

                                    // If the entered key is not valid or there are already two decimal places
                                    if (!isValidKey || (inputValue.includes('.') && key === '.')) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item<ItemFormField>
                            label={"Sales Price"}
                            name={ItemFormField.sales_price}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                                {
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.resolve();
                                        }
                                        const stringValue = String(value);
                                        const parsedValue = stringValue.match(/^\d*\.?\d{0,3}$/);
                                        if (parsedValue) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error('Please enter a valid numeric value with at most three decimal places'));
                                    }
                                }
                            ]}
                        >
                            <Input
                                placeholder={"Enter Sales Price"}
                                onKeyPress={(e) => {
                                    // Get the current input value
                                    const inputValue = (e.target as HTMLInputElement).value || '';

                                    // Get the key that was pressed
                                    const key = e.key;

                                    // Allow only numbers, single decimal point, and Backspace
                                    const isValidKey = /^[\d.]$/.test(key) || key === 'Backspace';

                                    // If the entered key is not valid or there are already two decimal places
                                    if (!isValidKey || (inputValue.includes('.') && key === '.')) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item<ItemFormField>
                            label={"Discount type"}
                            name={ItemFormField.discount_type_id}
                        >
                            <Select
                                defaultValue="Select Discount Type"
                                style={{ width: "100%" }}
                                options={[
                                    { value: '1', label: 'Flat' },
                                    { value: '2', label: 'Percent' },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item name={ItemFormField.size_availability} label="Size Availability"
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Radio.Group onChange={handleSizeAvailabilityChange} value={sizeAvailability}>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name={ItemFormField.color_availability} label="Color Availability"
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Radio.Group onChange={handleColorAvailabilityChange} value={colorAvailability}>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>

                    </FormSectionsSeparator>
                </Form>
                <div className='button-group'>
                    <Button htmlType='submit' onClick={form.submit} loading={isLoading} className='btn'>Save</Button>
                </div>
            </div>
        </div>

    )
};

