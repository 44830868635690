import { Button, Form, Input, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useUpdateItemsStatus } from 'features/transaction_list/use-cases/update-items-status';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemStatusFormField, ItemStatusFormValues } from './types';

interface props {
    onClose: () => void,
    isOpen: boolean;
    id: any;
    refetch: any
}

export const UpdateItemStatusModal = ({ onClose, isOpen, id }: props) => {
    const formName = 'update-status-item-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<ItemStatusFormValues>();
    const { t } = useTranslation();

    const { isLoading, mutate } = useUpdateItemsStatus();

    const getInitialValues = {
        [ItemStatusFormField.Id]: undefined,
        [ItemStatusFormField.Status]: undefined,
        [ItemStatusFormField.Comment]: undefined,
        [ItemStatusFormField.Name]: undefined,

    };

    const handleUpdate = (formData: ItemStatusFormValues) => {
        formData[ItemStatusFormField.Id] = id;

        mutate(formData, {
            onSuccess: () => {
                onClose();
                // refetch();
            },
        });
    };

    useEffect(() => {
        form.resetFields();
    }, [isOpen])


    return (

        <>
            <div className='popup'>
                <div className='modal_head'>
                    <button onClick={onClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>Update order</h4>
                </div>
                <div className='modal_body'>
                    <Form<ItemStatusFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <FormSectionsSeparator title={""}>
                            <Form.Item<ItemStatusFormField>
                                label={'Operation'}
                                name={ItemStatusFormField.Status}
                                rules={[
                                    {
                                        required: true,
                                        // whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },

                                ]}
                            >
                                <Select
                                    defaultValue="Select Pay Type"

                                    options={[
                                        { value: 'Pending', label: 'Pending' },
                                        { value: 'Order Confirmed', label: 'Order Confirmed' },
                                        { value: 'Order In Process', label: 'Order In Process' },
                                        { value: 'Out for Delivery', label: 'Out for Delivery' },
                                        { value: 'Order Delivered', label: 'Order Delivered' },

                                    ]}
                                />
                            </Form.Item>

                            <Form.Item<ItemStatusFormField>
                                label={"Self Name"}
                                name={ItemStatusFormField.Name}
                            // rules={[
                            //     {
                            //         required: true,
                            //         // whitespace: true,
                            //         message: t('validateMessages.custom.nomsg'),
                            //     },

                            // ]}
                            >
                                <Input
                                    placeholder={"Enter Self Name"}

                                />
                            </Form.Item>

                            <Form.Item<ItemStatusFormField>
                                label={"Comment"}
                                name={ItemStatusFormField.Comment}
                            // rules={[
                            //     {
                            //         required: true,
                            //         whitespace: true,
                            //         message: t('validateMessages.custom.nomsg'),
                            //     },
                            // ]}
                            >
                                <TextArea
                                    placeholder={"Enter Comment"}
                                />
                            </Form.Item>
                        </FormSectionsSeparator>

                        <div className='button-group'>
                            <Button loading={isLoading} className='btn' htmlType='submit'>Save</Button>
                        </div>
                    </Form>

                </div>
            </div>
        </>
    );
};
