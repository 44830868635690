import 'react-quill/dist/quill.snow.css';

import { Form, FormInstance, Input } from 'antd';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';


export const CategoryInfoFormItems = ({
  form,
}: {
  form: FormInstance;
}) => {

  const { t } = useTranslation();
  useEffect(() => {
    form.getFieldValue('question');
  }, []);

  return (
    <>
      <FormSectionsSeparator title={""}>
        <Form.Item
          //   name={BadgeFormFields.title}
          name="title"
          label='Title'
          rules={[{
            required: true,
            message: t('validateMessages.custom.nomsg'),
          }]}
        >
          <Input placeholder='Enter your Title' />
        </Form.Item>
      </FormSectionsSeparator>
    </>
  );
};
