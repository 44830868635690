import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { CreateEventDTO, EventsApiService } from 'features/events';
import { queryClient } from 'providers';

import { eventsQueryKeys } from './query-keys';

const { createDisclaimer } = EventsApiService();

export const useCreateDisclaimer = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: CreateEventDTO) => {
      return createDisclaimer(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: eventsQueryKeys.all,
        });
        message.success('Disclaimer has been created successfully!!');
      },
    },
  );
};
