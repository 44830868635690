import { Button, DatePicker, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { ItemSalesFilterFormField, ItemSalesFilterFormValues } from 'features/users/components/users-filter-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { payFormatDate } from 'utils/payload-date-';

const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
    formId: string;
    handleFilter: (values: ItemSalesFilterFormValues) => void;
    initialValues: ItemSalesFilterFormValues;
    closeModal?: () => void;
};

export const ItemSalesFilterForm = ({
    formId,
    handleFilter,
    initialValues,
    closeModal,
}: UsersFilterFormProps) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<ItemSalesFilterFormValues>();
    const { t } = useTranslation();
    const screens = useBreakpoint();
    const onReset = () => {
        form.resetFields();
        handleFilter({ ...initialValues });
    };

    const onFinish = (formValues: ItemSalesFilterFormValues) => {
        const formattedValues = {
            ...formValues,
            [ItemSalesFilterFormField.OrderFrom]: formValues?.order_from && payFormatDate(formValues?.order_from),
            [ItemSalesFilterFormField.OrderTo]: formValues?.order_to && payFormatDate(formValues?.order_to),
        };
        handleFilter(formattedValues);
        closeModal?.();
    };


    return (

        <Form<ItemSalesFilterFormValues>
            id={formId}
            name={formId}
            className='filters-form'
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            onFinishFailed={() => {
                setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
        >
            <ContentFilters.FormItemsWrapper>
                <StandardItemsWrapper>

                    <div className='invoice_list'>
                        <Form.Item
                            label={'Invoice Id'}
                            name={ItemSalesFilterFormField.InvoiceNo}
                        >
                            <Input placeholder={'Enter Invoice Id'} />
                        </Form.Item>
                        <Form.Item
                            label={'Name'}
                            name={ItemSalesFilterFormField.Name}
                        >
                            <Input placeholder={'Enter Name'} />
                        </Form.Item>

                        <Form.Item
                            label={'Phone'}
                            name={ItemSalesFilterFormField.PhoneNumber}
                        >
                            <Input placeholder={'Enter phone'} />
                        </Form.Item>

                        <Form.Item
                            label={'Email'}
                            name={ItemSalesFilterFormField.Email}
                        >
                            <Input placeholder={'Enter Email'} />
                        </Form.Item>

                        <Form.Item
                            label={'Pay Type'}
                            name={ItemSalesFilterFormField.PaymentMethod}
                        >
                            <Select
                                defaultValue="Select Pay Type"
                                style={{ width: 180 }}
                                options={[
                                    { value: 'Cash', label: 'Cash' },
                                    { value: 'Card', label: 'Card' },
                                    { value: 'Wallet', label: 'Wallet' },
                                    { value: 'Bank Transfer', label: 'Bank Transfer' },
                                    { value: 'Knet', label: 'Knet' },
                                    { value: 'AMEX', label: 'AMEX' },
                                    { value: 'Mada', label: 'Mada' },
                                    { value: 'ApplePay', label: 'Apple Pay' },
                                    { value: 'Omani', label: 'Omani' },
                                    { value: 'Waiting', label: 'Waiting' },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label={'Transaction Status'}
                            name={ItemSalesFilterFormField.TransactionStatus}
                        >
                            <Select
                                defaultValue="Select Transaction Status"
                                style={{ width: 180 }}
                                options={[
                                    { value: 'Captured', label: 'Captured' },
                                    { value: 'Success', label: 'Success' },
                                    { value: 'Error', label: 'Error' },
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            label={'Coupon Applied'}
                            name={ItemSalesFilterFormField.CouponApplied}
                        >
                            <Select
                                defaultValue="Select Coupon Applied"
                                style={{ width: 180 }}
                                options={[
                                    { value: 'yes', label: 'Yes' },
                                    { value: 'no', label: 'No' },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label={'Delivery Status'}
                            name={ItemSalesFilterFormField.ItemsDelivery}
                        >
                            <Select
                                defaultValue="Select Delivery Status"
                                style={{ width: 180 }}
                                options={[
                                    { value: 'Pending', label: 'Pending' },
                                    { value: 'order_confirm', label: 'Order Confirm' },
                                    { value: 'order_process', label: 'Order Process' },
                                    { value: 'out_of_delivery', label: 'Out of Delivery' },
                                    { value: 'order_delivered', label: 'Order Delivered' },
                                ]}
                            />
                        </Form.Item>


                        <Form.Item
                            label={'Order From'}
                            name={ItemSalesFilterFormField.OrderFrom}
                        >
                            <DatePicker placeholder={"Order From"} format="DD-MM-YYYY" />
                        </Form.Item>
                        <Form.Item
                            label={'Order To'}
                            name={ItemSalesFilterFormField.OrderTo}
                        >
                            <DatePicker placeholder={"Order To"} format="DD-MM-YYYY" />
                        </Form.Item>
                        {screens.lg && (
                            <ContentFilters.ButtonsWrapper className='filter_btn'>
                                <Button type='primary' htmlType='submit'>
                                    {t('common.buttons.filter', { count: 1 })}
                                </Button>
                                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
                            </ContentFilters.ButtonsWrapper>
                        )}

                    </div>


                </StandardItemsWrapper>
            </ContentFilters.FormItemsWrapper>

        </Form>
    );
};
