export enum EventMasterFormField {
    Id = 'id',
    Title = 'title',
    IsActive = 'is_active',
}

export type SportsCat = {
    Id: string | undefined;
    Title: string;
    isActive: boolean;
};

export type EventMasterFormValues = {
    [EventMasterFormField.Id]: string | undefined;
    [EventMasterFormField.Title]: string;
    [EventMasterFormField.IsActive]: boolean;
};