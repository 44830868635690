import { Button, Form, Input, message } from "antd";
import Checkbox from "antd/es/checkbox/Checkbox";
import { useForm } from "antd/es/form/Form";
import { useCreateExpoBooth } from "features/events/use-cases/create-expo-booth";
import { useGetBoothAllZoneData } from "features/events/use-cases/get-booth-all-zone-data";
import { handleKeyAmount } from "features/events/utils";
import { useEffect, useState } from "react";


interface props {
    onClose: () => void,
    isOpen: boolean;
    setCreatedArrBooth: any;
    editData: any,
    id: any
}

export const LayoutAllBoothModal = ({ onClose, isOpen, editData, id }: props) => {

    const [form] = useForm<any>();

    const [boothPrice, setBoothPrice] = useState<string>("");
    // const [available, setAvailable] = useState<boolean>(true);
    const [selectedBooths, setSelectedBooths] = useState<string[]>([]);
    // const [selectedZone, setSelectedZone] = useState<string[]>([]);
    // const [isHidden, setIsHidden] = useState<boolean>(false);

    const [zoneName, setZoneName] = useState<string>("");

    const { mutate, isLoading } = useCreateExpoBooth();


    const { data: apiZoneData, refetch } = useGetBoothAllZoneData(id);
    useEffect(() => {
        refetch();
    }, [isOpen])

    const handleClose = () => {
        onClose();
        setZoneName("");
        setBoothPrice("");
        setSelectedBooths([]);
        setFilteredZone(null);
        form.resetFields();
    };

    const handleSubmit = () => {

        if (!selectedBooths.length) return message.error("please select booth");

        const boothPayload = selectedBooths.map((item: any) => ({ BoothId: item }));  // Map to BoothId format

        const payload = {
            event_id: id,
            zone: zoneName,
            price: boothPrice,
            tySetBoothZone: boothPayload
        };

        mutate(payload, { onSuccess: handleClose });
    };


    const handleCheckboxChange = (checkId: string) => {
        setSelectedBooths((prevSelected) =>
            prevSelected.includes(checkId)
                ? prevSelected.filter((boothId) => boothId !== checkId) // Unselect if already selected
                : [...prevSelected, checkId] // Select if not selected
        );
    };


    const [filteredZone, setFilteredZone] = useState<string | null>(null);

    // Grouping the data by zone
    const groupedData = apiZoneData?.zone_wise_booth_list?.reduce((acc: any, item: { zone: string; }) => {
        if (item.zone) {
            acc[item.zone] = acc[item.zone] || [];
            acc[item.zone].push(item);
        }

        // else {
        //     acc["no"] = acc["no"] || [];
        //     acc["no"].push(item);
        // }

        return acc;

    }, {});


    // Get unique zones for button creation
    const uniqueZones = groupedData && Object.keys(groupedData);

    const handleFilterdZone = (zone: string) => {
        setFilteredZone(zone);
        // const zonedata = apiZoneData?.zone_wise_booth_list?.filter((item: any) => item?.zone === zone)?.map((item: { booth_id: any; }) => item.booth_id);

        // setSelectedZone(zonedata);

    }

    // const handleEditCheckboxChange = (editId: string) => {
    //     setSelectedZone((prevSelected) =>
    //         prevSelected.includes(editId)
    //             ? prevSelected.filter((boothId) => boothId !== editId) // Unselect if already selected
    //             : [...prevSelected, editId] // Select if not selected
    //     );
    // };


    // const handleUpdate = () => {
    //     if (!selectedZone.length) return alert("please select booth")
    //     const updatedBooths = editData.map((booth: any) =>
    //         filteredZone &&
    //             selectedBooths.includes(booth.id)
    //             ? { ...booth, zone: zoneName }
    //             : booth)
    //     setCreatedArrBooth(updatedBooths); // Pass updated booths to the parent
    //     setZoneName("");
    //     setBoothPrice(0);
    //     setSelectedZone([]);
    // }




    if (!editData) return null;

    return (

        <>
            <div className='popup zone_pop'>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>Booth Zone Details</h4>
                </div>
                <div className='modal_body'>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={handleSubmit}>
                        <div className="mdl_input">

                            <Form.Item
                                label="Create Zone"
                                name="zoneName"
                            >
                                <Input
                                    onChange={(e) => setZoneName(e.target.value)}
                                    value={zoneName}
                                    placeholder="Please Enter Zone Name"
                                />
                            </Form.Item>

                            <Form.Item
                                label="Price"
                                name="Price"
                                rules={[
                                    {
                                        required: true,
                                        message: ""
                                    }
                                ]}
                            >
                                <Input
                                    onChange={(e) => setBoothPrice(e.target.value)}
                                    value={boothPrice}
                                    onKeyDown={handleKeyAmount}
                                />

                            </Form.Item>
                        </div>

                        {/* <div className="form-group" style={{ marginTop: "20px" }}>
                        <Checkbox
                            id="hidden"
                            name="hidden"
                            checked={available}
                            onChange={(e) => setAvailable(e.target.checked)}
                        >
                            isAvailable
                        </Checkbox>
                    </div>

                    <div className="form-group" style={{ marginTop: "20px", marginBottom: "20px" }}>

                        <Checkbox
                            id="hidden"
                            name="hidden"
                            checked={isHidden}
                            onChange={(e) => setIsHidden(e.target.checked)}
                        >
                            Is Hidden
                        </Checkbox>

                    </div> */}

                        <div className="zone_num">



                            {editData.filter((cur: any) =>
                                !apiZoneData?.zone_wise_booth_list?.some((booth: any) => booth.booth_id === cur.id)
                            ).map((item: any) => (
                                <Checkbox
                                    key={item.id}
                                    checked={selectedBooths.includes(item.id)}
                                    onChange={() => handleCheckboxChange(item.id)}
                                >
                                    {item.id}
                                </Checkbox>
                            ))}
                        </div>


                        <div className='button-group' style={{ marginTop: "20px" }}>
                            <Button type="primary" htmlType="submit" loading={isLoading} >Submit</Button>
                        </div>

                    </Form>

                    <hr style={{ marginTop: "20px" }} />




                    <>
                        <div className="zone_nme_btn">


                            {uniqueZones?.length > 0 && <h1>Zone Details</h1>}
                            {uniqueZones?.map((zone: any) => (
                                <Button
                                    key={zone}
                                    onClick={() => handleFilterdZone(zone)}

                                    className={`${filteredZone === zone ? "active" : "inactive"}`}
                                >
                                    {zone.toUpperCase()} Zone
                                </Button>
                            ))}
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            {/* Display the items for the selected zone */}
                            {filteredZone && (
                                <div className="zone_name">
                                    <h3>Items in {filteredZone.toUpperCase()} Zone:</h3>
                                    <ul>
                                        {groupedData[filteredZone].map((item: any) => (
                                            <li key={item.id}>
                                                <span> Booth No: {item.booth_id}</span>
                                                <p>Price: {item.price}</p></li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>

                        {/* <>
               
                        {
                            filteredZone &&
                            <div>
                                <>
                                    {editData.filter((cur: any) => filteredZone || !cur.zone).map((item: any) => (
                                        <Checkbox
                                            key={item.id}
                                            checked={selectedZone.includes(item.id)}
                                            onChange={() => handleEditCheckboxChange(item.id)}
                                        >
                                            {item.id}
                                        </Checkbox>
                                    ))}
                                </>

                                <div className='button-group' style={{ marginTop: "20px" }}>
                                    <Button type="primary" onClick={handleUpdate}>Update</Button>
                                </div>
                            </div>}
                            </> */}
                    </>

                </div>
            </div >
        </>
    );
};

