import { Button, Form } from 'antd';
import { useCreateOrganizer } from 'features/events/use-cases/create-organizer';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AddOrganizerForm } from './add-organizer-form';
import { OrganizerFormField, OrganizerFormValues } from './types';


export const AddOrganizerModal = ({ onClose, showAddEventModal, organizerData, refetch }: { onClose: () => void; showAddEventModal: boolean, organizerData: any, refetch: () => void }) => {
    const { id } = useParams();
    const eventId = id;
    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<OrganizerFormValues>();

    const { isLoading, mutate } = useCreateOrganizer(organizerData?.id);

    const getInitialValues = {
        [OrganizerFormField.id]: id,
        [OrganizerFormField.event_id]: eventId,
        [OrganizerFormField.name]: undefined,
        [OrganizerFormField.link]: undefined,
        [OrganizerFormField.contact_no]: undefined,
        [OrganizerFormField.email]: undefined,
        [OrganizerFormField.address]: undefined,
        [OrganizerFormField.is_active]: undefined,
        [OrganizerFormField.image_url]: undefined,
    };

    const handleUpdate = (formData: OrganizerFormValues) => {
        formData[OrganizerFormField.id] = organizerData?.id;
        formData[OrganizerFormField.event_id] = id;
        formData[OrganizerFormField.is_active] = true;
        mutate(formData, { onSuccess: handleSuccess });

    };

    const handleSuccess = () => {
        onClose();
        refetch()
    }

    useEffect(() => {
        if (organizerData) {
            form.setFieldsValue(organizerData);
        } else {
            form.resetFields();
        }
    }, [organizerData, showAddEventModal])

    return (
        <>
            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={onClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{organizerData ? "Add Organizer" : "Add Organizer"}</h4>
                </div>
                <div className='modal_body'>
                    <Form<OrganizerFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <AddOrganizerForm />
                    </Form>
                    <div className='button-group'>
                        <Button loading={isLoading} htmlType='submit' onClick={form.submit} className='btn'>Save</Button>
                    </div>

                </div>
            </div>
        </>
    )
};
