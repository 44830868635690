import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Image, Input } from 'antd';
import { useCreatePartners } from 'features/cms/use-cases/create-partners';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PartnersFormField, PartnersFormValues } from './types';


export const AddPartnersModal = ({ onClose, refetch, partnersEditData }: { onClose: () => void; showPartnersModal: boolean, refetch: () => void, partnersEditData: any }) => {
    const { id } = useParams();
    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<PartnersFormValues>();
    const { t } = useTranslation();
    const { isLoading, mutate } = useCreatePartners();

    const getInitialValues = {
        [PartnersFormField.Id]: id,
        [PartnersFormField.WebsiteLink]: undefined,
        [PartnersFormField.File]: undefined,
        [PartnersFormField.IsActive]: undefined,
        [PartnersFormField.ImageUrl]: undefined,
    };
    const [fileData, setFileData] = useState<File | any>(null);

    const handleFileChange = (e: any) => {
        setFileData(e.target.files[0]);
    };

    const handleUpdate = (formData: PartnersFormValues) => {
        formData[PartnersFormField.Id] = partnersEditData?.id;
        formData[PartnersFormField.File] = fileData;
        formData[PartnersFormField.ImageUrl] = "";
        formData[PartnersFormField.IsActive] = true;
        mutate(formData, { onSuccess: hanldeSuccess });
        form.resetFields();
    };
    const handleClose = () => {
        onClose();
        refetch();
        setFileData(null);
    }

    const hanldeSuccess = () => {
        handleClose();
        refetch();
    }

    useEffect(() => {
        if (partnersEditData) {
            form.setFieldsValue(partnersEditData);
        } else {
            form.resetFields();
        }
    }, [partnersEditData])

    return (

        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={handleClose} className='close-btn'>
                    &times;
                </button>
                <h4>{partnersEditData ? "Edit Partners" : "Add Partners"}</h4>
            </div>
            <div className='modal_body'>
                <Form<PartnersFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <FormSectionsSeparator title={""}>
                        <Form.Item<PartnersFormField>
                            label={"Website Link"}
                            name={PartnersFormField.WebsiteLink}

                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Website Link"}
                                prefix={<UserOutlined className='input-prefix-icon' />}
                            />
                        </Form.Item>

                        {!fileData && partnersEditData?.image_url && <Image
                            src={partnersEditData?.image_url}
                        />}

                        <Form.Item<PartnersFormField>
                            label={"Image"}
                            name={PartnersFormField.File}
                            rules={[
                                {
                                    required: !partnersEditData?.id,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            {/* <Upload fileList={fileData} onChange={handleFileChange} accept="image/*" maxCount={1} beforeUpload={() => false}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>

                            </Upload> */}
                            <Input type="file" onChange={handleFileChange} accept=".png,.jpg,.jpeg" />
                        </Form.Item>

                    </FormSectionsSeparator>
                </Form>
                <div className='button-group'>
                    <Button htmlType='submit' onClick={form.submit} loading={isLoading} className='btn'>Save</Button>
                </div>

            </div>
        </div>

    )
};

