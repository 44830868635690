import { DownOutlined, IdcardOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, Select } from 'antd';
import { EventsApiService } from 'features/events/api/events-api.service';
import { validateAmount } from 'features/events/utils';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import { CurrencyCategory } from '../../modals/types';

const DetailsInformationForm = ({ edit, form, mainData }: { edit: boolean, form: any, mainData: any }) => {

    const { Option } = Select;
    const [category, setCategory] = useState<CurrencyCategory>({
        items: []
    })

    const getCategory = async () => {
        const apires = await EventsApiService().sportCategoryId(`Flag=${'category'}` as any)
        setCategory(apires as CurrencyCategory)
    }

    useEffect(() => {
        getCategory()
    }, [])

    const { t } = useTranslation();

    const validateLat = (_: any, value: string) => {
        const latPattern = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
        if (!value || latPattern.test(value)) {

            return Promise.resolve();
        }

        return Promise.reject();
    };

    const validateLng = (_: any, value: string) => {
        const lngPattern = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;
        if (!value || lngPattern.test(value)) {
            return Promise.resolve();
        }

        return Promise.reject(new Error('Please enter a valid longitude'));
    };


    const validateDates = (getFieldValue: (arg0: any) => any) => ({
        validator() {
            const startDate = getFieldValue("StartDate");
            const endDate = getFieldValue("EndDate");

            if (startDate && endDate && startDate.isAfter(endDate)) {
                return Promise.reject(new Error('Registration End Date must be after or equal to Registration Start Date'));
            }

            return Promise.resolve();
        },
    });


    const validateEventDates = (getFieldValue: (arg0: any) => any) => ({
        validator() {
            const startDate = getFieldValue("StartDate");
            const endDate = getFieldValue("EndDate");
            const eventDate = getFieldValue("EventDate");

            if (startDate && eventDate && eventDate.isBefore(endDate)) {
                return Promise.reject(new Error('Event Date must be after or equal to Registration End Date'));
            }

            return Promise.resolve();
        },
    });



    return (
        <FormSectionsSeparator title={''}>

            <div className='event_dtls'>
                <Form.Item className='cell_box' name="EventName" label="Event Name (EN)"
                    rules={[{
                        required: true,
                        message: t('validateMessages.custom.nomsg')
                    }]}>
                    <Input disabled={!edit} />
                </Form.Item>

                <Form.Item className='cell_box' name="EventNameAR" label="Event Name (AR)" >
                    <Input disabled={!edit} />
                </Form.Item>

                <Form.Item className='cell_box' name="CategoryId" label="Sports Category"
                    rules={[{
                        required: true,
                        message: t('validateMessages.custom.nomsg')
                    }]}
                >
                    <Select
                        disabled={!edit}
                        options={category?.items.map((res: any) => ({ label: res.Text, value: res.Id }))}
                        suffixIcon={
                            <>
                                <IdcardOutlined className='prefix-icon' />
                                <DownOutlined className='suffix-icon' />
                            </>
                        }
                    >

                    </Select>
                </Form.Item>

                <Form.Item className='cell_box' name="StartDate" label="Registration Start Date"
                    rules={[{
                        required: true,
                        message: t('validateMessages.custom.nomsg')
                    },
                        // validateDates(form.getFieldValue),
                    ]}
                >
                    <DatePicker style={{ width: "100%" }} disabled={!edit} />
                </Form.Item>

                <Form.Item className='cell_box' name="EndDate" label="Registration End Date"
                    rules={[{
                        required: true,
                        message: t('validateMessages.custom.nomsg')
                    },
                    validateDates(form.getFieldValue),
                    ]}>
                    <DatePicker style={{ width: "100%" }} disabled={!edit} />
                </Form.Item>

                <Form.Item className='cell_box' name="EventDate" label="Event Date"
                    rules={[{
                        required: true,
                        message: t('validateMessages.custom.nomsg')
                    },
                    validateEventDates(form.getFieldValue),
                    ]}
                >
                    <DatePicker showTime style={{ width: "100%" }} disabled={!edit} />
                </Form.Item>
            </div>


            <div className='lctn_dtls'>
                <h4>Location Details</h4>
                <div className='lctn_frm'>
                    <Form.Item className='cell_box' name="Address" label="Address" >
                        <Input disabled={!edit} />
                    </Form.Item>

                    <Form.Item className='cell_box' name="AddressLat" label="Latitude"
                        rules={[
                            {
                                validator: validateLat,

                            }
                        ]}
                    >
                        <Input disabled={!edit} />
                    </Form.Item>
                    <Form.Item className='cell_box' name="AddressLong" label="Longitude"
                        rules={[
                            {
                                validator: validateLng,

                            }
                        ]}
                    >
                        <Input disabled={!edit} />
                    </Form.Item>
                    <Form.Item className='cell_box' name="LocationMapURL" label="Location map URL" >
                        <Input disabled={!edit} />
                    </Form.Item>
                    <Form.Item className='cell_box' name="Venue" label="Venue" >
                        <Input disabled={!edit} />
                    </Form.Item>

                </div>
            </div>

            <div className='lctn_dtls'>
                <h4>Add ons</h4>
                <div className='lctn_frm'>
                    <Form.Item className='cell_box' name="MedalPrice" label="Medal Price"
                        rules={[
                            {
                                validator: validateAmount
                            }
                        ]}>
                        <Input placeholder='0.000' disabled={!edit} />
                    </Form.Item>
                    <Form.Item className='cell_box' name="TShirtPrice" label="T-Shirt Price"
                        rules={[
                            {
                                validator: validateAmount
                            }
                        ]} >
                        <Input placeholder='0.000' disabled={!edit} />
                    </Form.Item>
                    {/* <Form.Item className='cell_box' name="DeliveryPrice" label="Delivery Price"
                        rules={[
                            {
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.resolve();
                                    }
                                    const stringValue = String(value);
                                    const parsedValue = stringValue.match(/^\d*\.?\d{0,3}$/);
                                    if (parsedValue) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('Please enter a valid numeric value with at most three decimal places'));
                                },
                            },
                        ]}
                    >
                        <Input placeholder='0.000' />
                    </Form.Item> */}
                    <Form.Item className='cell_box' name="PhotoPrice" label="Photos Price"
                        rules={[
                            {
                                validator: validateAmount
                            }
                        ]}
                    >
                        <Input placeholder='0.000' disabled={!edit} />
                    </Form.Item>
                    <Form.Item className='cell_box' name="RefundInsurance" label="Refund Protect
" >
                        <Select disabled={!edit} >
                            <Option value={true}>Yes</Option>
                            <Option value={false}>No</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item className='cell_box' name="RefundProtectValue" label="Refund Protect Value(%)"
                        rules={[
                            {
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.resolve();
                                    }
                                    const numberValue = parseFloat(value);
                                    const pattern = /^\d{1,3}(\.\d{0,3})?$/;
                                    if (!value || (pattern.test(value) && numberValue >= 1 && numberValue <= 100)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('Please enter a valid numeric value between 1 and 100 with at most three decimal places'));
                                },
                            },
                        ]}
                    >
                        <Input placeholder='0.000' disabled={!edit} />
                    </Form.Item>

                    <Form.Item
                        className='cell_box'
                        label={"Bib Allocation"}
                        name={"BibAllocation"}
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: t('validateMessages.custom.nomsg'),
                    //     },
                    // ]}
                    >
                        <Select
                            disabled={!edit || mainData?.BibAllocation === 1 || mainData?.BibAllocation === 2}

                            // style={{ width: 180 }}
                            options={[
                                { value: 0, label: 'Select Mode' },
                                { value: 2, label: 'Automatic' },
                                { value: 1, label: 'Manual' },
                            ]}
                        />
                    </Form.Item>
                </div>
            </div>
        </FormSectionsSeparator>
    )
}
export default DetailsInformationForm;