import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Form, Input, message, Modal, Pagination, Radio, Select, Table, Tabs, Tag, Tooltip } from "antd"
import { Loader, StyledPaginationWrapper } from 'components';
import { ShopApiService } from "features/shop-item/api/shop-api.service";
import { useGetEventImage } from 'features/shop-item/use-cases/get-item-image';
import { Fragment, useEffect, useState } from "react";
import ReactQuill from "react-quill"
import { useParams } from "react-router-dom";

import { AddItemImageModal } from "./add-image";
import { AddItemSizeModal } from "./add-size-quantity";
import { EditItemSizeModal } from "./edit-size-modal";

interface TabWithButton {
    key: string;
    label: string;
    children: JSX.Element | string;
}

const columns = [
    {
        title: 'Size',
        dataIndex: 'size_value',
        key: 'name',
    },
    {
        title: 'Color',
        dataIndex: 'color_title',
        key: 'age',
    },
    {
        title: 'Code Title',
        dataIndex: 'color_title',
        key: 'address',
    },
    {
        title: 'Color Code',
        dataIndex: 'color_code',
        key: 'address',
    },
    {
        title: 'In Stock Quantity',
        dataIndex: 'total_quantity',
        key: 'address',
    },
    {
        title: 'Sortorder',
        dataIndex: 'sort_order',
        key: 'sort_order',
    },
    {
        title: 'Featured',
        dataIndex: 'is_featured',
        key: 'Featured',
    },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'address',
    },
];

const EditItem = () => {
    const [form] = Form.useForm();
    const { id } = useParams();
    const [showAddItemModal, setShowAddItemModal] = useState(false);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showAddImageModal, setShowAddImageModal] = useState(false);
    const [itemdataModal, setShowItemdataModal] = useState('');
    const [loading, setLoading] = useState(false);

    const [imgPage, setImgPage] = useState<number>(1);
    const [imgTotalPage, setImgTotalPage] = useState<any>(0);
    const [imgCurrentPage, setImgCurrentPage] = useState<any>(1);
    const params = {
        shop_item_id: id,
        page: imgPage,
        size: 12
    }

    const { data, refetch } = useGetEventImage(params);

    useEffect(() => {
        setImgTotalPage(data?.total);
        setImgCurrentPage(data?.page)
    }, [data])



    const [defaultValue, setDefaultValue] = useState<any>('')

    const { confirm } = Modal;
    const [sizeValue, setSizeValue] = useState({ items: [] });
    const [category, setCategory] = useState({
        items: []
    } as any)

    const [page, setPage] = useState<number>(1);
    const [totalPage, setTotalPage] = useState<any>(0);
    const [currentPage, setCurrentPage] = useState<any>(1);

    const initialCategory = async () => {
        try {
            const apiRes = await ShopApiService().getCategory('' as any)
            setCategory(apiRes)
        } catch (error) {
            message.error('')
        }
    }

    const initialSize = async () => {
        try {
            const apiRes = await ShopApiService().getItemShopSize(`shop_item_id=${id}&page=${page}&size=10` as any)
            setSizeValue(apiRes);

            setTotalPage(apiRes?.total);
            setCurrentPage(apiRes?.page);
            if (apiRes?.items?.length === 0 && currentPage > 1) {
                setPage(pre => pre - 1)
            } else {
                setPage(apiRes?.page);
            }
        } catch (error) {
            message.error('')
        }
    }

    useEffect(() => {
        initialSize()
    }, [page])

    const handleImageDelete = async (deleteId: string, name: string) => {
        try {
            const deleteData = {
                id: deleteId,
                flag: name === "image" ? "SHOP_ITEMS_IMAGE_DELETE" : "SHOP_ITEMS_SIZE_DELETE",
                status: true
            }
            await ShopApiService().updateStatus(deleteData as any)
            initaial()
            // initialImage()
            initialSize()
            refetch()
        } catch (error) {
            message.error('')
        }
    }

    const updateStatus = async (res: any) => {
        setLoading(true)
        try {
            const updateData = {
                id: res.id,
                flag: "Item_Image_Set_Default",
                status: true
            }
            await ShopApiService().updateStatus(updateData as any)
            await initaial();
            // await initialImage();
            refetch()
        } catch (error) {
            message.error('')
        }
        setLoading(false)
    }

    const handlesubmit = async (fromData: any) => {
        setLoading(true)
        try {
            const dataSubmit = {
                id: id,
                description: fromData.description,
                shiping_returns: fromData.shiping_returns,
                title: fromData.title,
                title_arabic: fromData.title_arabic,
                shop_category_id: fromData.shop_category_id,
                sales_price: fromData.sales_price,
                base_price: fromData.base_price,
                discount_type_id: fromData.discount_type_id,
                size_availability: fromData.size_availability,
                color_availability: fromData.color_availability,
                is_active: fromData.is_active
            }
            await ShopApiService().UpdateItem(dataSubmit as any)
            message.success("Item edit successfully")
            initaial()
        } catch (error: any) {
            message.error(error)
        }
        setLoading(false)
    }

    const handleOpenModal = (record: any) => {
        setShowItemdataModal(record)
        setShowEditItemModal(true)
        document.body.style.overflow = 'hidden';
    }

    const closeEditSizeModal = () => {
        setShowItemdataModal("");
        setShowEditItemModal(false);
        document.body.style.overflow = 'visible';
    }

    const addItemOpenModal = () => {
        setShowAddItemModal(true)
        document.body.style.overflow = 'hidden';
    }

    const addItemCloseModal = () => {
        setShowAddItemModal(false);
        document.body.style.overflow = 'visible';
    }

    const imageOpenModal = () => {
        setShowAddImageModal(true)
        document.body.style.overflow = 'hidden';
    }

    const imageCloseModal = () => {
        setShowAddImageModal(false);
        document.body.style.overflow = 'visible';
    }


    const showDeleteConfirm = (record: any, name: string) => {
        confirm({
            title: `Are you sure you want to delete this ${name == "Size" ? "Size" : 'Image'}?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleImageDelete(record, name == "Size" ? 'Size' : "image");
            },

        }
        );
    };

    const dataSource = sizeValue?.items?.map((res: any, index: number) => {
        return {
            key: index.toString(),
            size_value: res.size_value,
            color_id: res.color_id || 0,
            color_title: res.color_title || 'N/A',
            color_code: <Tag color={res.color_code} style={{ width: 25, height: 15 }}></Tag>,
            total_quantity: res.total_quantity,
            sort_order: res.sort_order,
            is_featured: res.is_featured == true ? "Yes" : "No",
            action: <div>
                <Tooltip>
                    <Button shape="circle" onClick={() => handleOpenModal(res)} icon={<EditOutlined />} />
                </Tooltip>
                <Tooltip title="delete">
                    <Button shape="circle" onClick={() => showDeleteConfirm(res.id, "Size")} icon={<DeleteOutlined />} />
                </Tooltip>
            </div>
        };

    });

    const handleChangePagination = (pageVal: number) => {

        setPage(pageVal);
    };

    const handleImgChangePagination = (pageVal: number) => {
        setImgPage(pageVal);
    };


    const items: TabWithButton[] = [
        {
            key: '1',
            label: 'Add Size',
            children: <div>
                <div className="size_wrap add_img_wrap">
                    <div className="sze_head add_img_head">
                        <h4>Add size with quantity</h4>
                        <Button onClick={addItemOpenModal}>Add</Button>
                    </div>
                    <Table dataSource={dataSource} columns={columns}
                        pagination={{
                            total: totalPage, // Total number of records
                            pageSize: 10,     // Number of records per page
                            current: currentPage, // Current active page
                            onChange: handleChangePagination, // Handle page change
                        }} />

                </div>
            </div>
        },
        {
            key: '2',
            label: 'Add Image',
            children: <div>
                <div className="add_img_wrap">
                    <div className="add_img_head">
                        <h4>Item Image List</h4>
                        <Button onClick={imageOpenModal}>Upload Image</Button>
                    </div>
                    {loading ? <Loader /> :
                        <div className="ad_im_wrap">

                            {data?.items.map((item: any, index: number) => {

                                return (

                                    <div className="ad_img" key={index}>
                                        <div className="ime_bx">
                                            <img src={item.img_url} />
                                        </div>
                                        <div className="img_e">
                                            <Radio.Group
                                                onChange={() => updateStatus(item)}
                                                value={item.set_default == true ? true : false}
                                            >
                                                <Radio value={true} >Set as default</Radio>
                                            </Radio.Group>
                                            <Button shape="circle" icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(item.id, 'image')} />
                                        </div>
                                    </div>

                                )
                            })}


                        </div>}
                    <>
                        <StyledPaginationWrapper>
                            < Pagination
                                total={imgTotalPage} // Total number of items
                                pageSize={12} // Number of items per page
                                current={imgCurrentPage} // Current page number
                                onChange={handleImgChangePagination} // Function to handle page change
                            />
                        </StyledPaginationWrapper>
                    </>
                </div>
            </div >

        },
    ];
    const initaial = async () => {
        try {
            const apiRes = await ShopApiService().getitemdetails(id as any)
            form.setFieldsValue(apiRes);
            setDefaultValue(apiRes)
        } catch (error) {
            message.error('')
        }
    }

    useEffect(() => {
        initaial()

        // initialImage()
    }, [])

    useEffect(() => {
        initialCategory()
    }, [])




    useEffect(() => {
        form.setFieldValue("base_price", defaultValue?.base_price?.toFixed(3))
        form.setFieldValue("sales_price", defaultValue?.sales_price?.toFixed(3))
    }, [defaultValue])

    return (

        <>

            <Fragment>
                <div className={`cstmrightmdl ${itemdataModal && "show"}`}>
                    <EditItemSizeModal itemdataModal={itemdataModal} defaultValue={defaultValue} setSizeValue={setSizeValue} onClose={closeEditSizeModal} showEditItemModal={showEditItemModal} tabItemSize={initialSize} />

                </div>

                <div className={`cstmrightmdl ${showAddItemModal && "show"}`}>
                    <AddItemSizeModal setSizeValue={setSizeValue} onClose={addItemCloseModal} showAddItemModal={showAddItemModal} tabItemSize={initialSize} />

                </div>

                <div className={`cstmrightmdl ${showAddImageModal && "show"}`}>
                    <AddItemImageModal onClose={imageCloseModal} refetch={refetch} showAddItemModal={showAddImageModal} dataSource={dataSource} />

                </div>

                <Form
                    className='item_wrap'
                    layout="vertical"
                    onFinish={handlesubmit}
                    form={form}
                >
                    <div className='cstm_head'>
                        <div className='hed_ttl'>
                            <Breadcrumb separator="/" className='page_brdcrumb'>
                                <Breadcrumb.Item className='text-decoration-none'><a href='/shop-item/item-list'>Item List</a></Breadcrumb.Item>
                                <Breadcrumb.Item className='text-decoration-none'>Details</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                    <div className='bg_white'>
                        <div className='item_d'>
                            <div className='item_bx img_b'>
                                <div className='item_img'>
                                    {defaultValue.image_url ? <img src={defaultValue.image_url} alt="" /> :
                                        // <img src={defaultValue.image_url} alt="" />
                                        <div className='no_img'>
                                            <span>No Image Available</span>
                                        </div>}
                                </div>
                            </div>
                            <div className='item_bx'>
                                <Form.Item label="Description" name="description">
                                    <ReactQuill theme="snow" />
                                </Form.Item>
                            </div>
                            <div className='item_bx'>
                                <Form.Item label="Shipping & return" name="shiping_returns">
                                    <ReactQuill theme="snow" />
                                </Form.Item>
                            </div>
                        </div>
                        {/* <div className='item_dtls'>
                         <div className='item_head'>
                            <h2>Details</h2>
                        </div> 
                    </div> */}
                        <div className='dtls_form_list'>
                            <div className='dtls_f'>
                                <Form.Item label="Item Name" name="title">
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className='dtls_f'>
                                <Form.Item label="Item Name Arabic" name="title_arabic">
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className='dtls_f'>
                                <Form.Item label="category" name="shop_category_id">
                                    <Select
                                        defaultValue="Select Category"

                                        options={category?.items.map((res: any) => ({ label: res.title, value: res.id }))}
                                    />
                                </Form.Item>
                            </div>
                            <div className='dtls_f'>
                                <Form.Item label="Base Price" name="base_price">
                                    <Input
                                    />
                                </Form.Item>

                            </div>
                            <div className='dtls_f'>
                                <Form.Item label="Sales Price" name="sales_price">
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className='dtls_f'>
                                <Form.Item
                                    label={"Discunt type"}
                                    name={"discount_type_id"}
                                >
                                    <Select
                                        defaultValue="Select Discount Type"
                                        style={{ width: "100%" }}
                                        options={[
                                            { value: 1, label: 'Flat' },
                                            { value: 2, label: 'Percent' },
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                            <div className='dtls_f'>
                                <Form.Item label="Is Active" name="is_active">
                                    <Radio.Group >
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <div className='dtls_f'>
                                <Form.Item label="Size Availability" name="size_availability">
                                    <Radio.Group >
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <div className='dtls_f'>
                                <Form.Item label="Color Availability" name="color_availability">
                                    <Radio.Group>
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>


                        </div>
                        <div className='dtls_btn'>
                            <Form.Item>
                                <Button htmlType="submit" type='primary' loading={loading}>Save</Button>
                            </Form.Item>
                        </div>
                    </div>

                    <div className='bg_white'>
                        <Tabs defaultActiveKey="1" items={items} />
                    </div>
                </Form>
            </Fragment>
        </>
    )
}
export default EditItem
