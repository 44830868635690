import { Button, Form, Input, message, Select } from 'antd';
import { useCreateManageArea } from 'features/cms/use-cases/create-manage-area';
import { EventsApiService } from 'features/events';
import { CurrencyCategory } from 'features/events/components/modals/types';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ManageAreaFormField, ManageAreaFormValues } from './types';

export const AddAreaModal = ({ onClose, refetch, areaEditData }: { onClose: () => void; showAreaModal: boolean, refetch: () => void, areaEditData: any }) => {
    const { id } = useParams();
    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<ManageAreaFormValues>();
    const { t } = useTranslation();
    const { isLoading, mutate } = useCreateManageArea();

    const getInitialValues = {
        [ManageAreaFormField.Id]: id,
        [ManageAreaFormField.Cid]: undefined,
        [ManageAreaFormField.Name]: undefined,
        [ManageAreaFormField.IsActive]: undefined,
    };

    const handleUpdate = (formData: ManageAreaFormValues) => {
        formData[ManageAreaFormField.Id] = areaEditData?.id;
        formData[ManageAreaFormField.IsActive] = true;
        mutate(formData, { onSuccess: hanldeSuccess });
        form.resetFields()
    };

    const hanldeSuccess = () => {
        onClose();
        refetch();
    }

    useEffect(() => {
        if (areaEditData) {
            form.setFieldsValue(areaEditData);
        }
    }, [areaEditData])

    const [country, setCountry] = useState<CurrencyCategory>({
        items: []
    })

    const sportCategoryId = async () => {
        try {
            const apires = await EventsApiService().sportCategoryId(`Flag=${'country'}` as any)
            setCountry(apires as any)
        } catch (error) {
            message.error("")
        }
    }

    useEffect(() => {
        sportCategoryId()
    }, [])

    return (

        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={onClose} className='close-btn'>
                    &times;
                </button>
                <h4>{areaEditData ? "Edit Area" : "Add Area"}</h4>
            </div>
            <div className='modal_body'>
                <Form<ManageAreaFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    requiredMark={'optional'}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <FormSectionsSeparator title={""}>

                        <Form.Item<ManageAreaFormField>
                            label={"Country"}
                            name={ManageAreaFormField.Cid}
                            rules={[
                                {
                                    required: true,
                                    // whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Select
                                options={country?.items.map((res: any) => ({ label: res.Text, value: res.Id }))}
                                placeholder={"Select Country"}
                                className='has-prefix-icon'
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                showSearch
                            />
                        </Form.Item>

                        <Form.Item<ManageAreaFormField>
                            label={"Area Name"}
                            name={ManageAreaFormField.Name}

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },

                            ]}
                        >
                            <Input
                                placeholder={"Area Name"}
                            />
                        </Form.Item>

                    </FormSectionsSeparator>
                </Form>
                <div className='button-group'>
                    <Button htmlType='submit' onClick={form.submit} className='btn'>Save</Button>
                </div>

            </div>
        </div>

    )
};

