import {
    QueryFunction,
    QueryKey,
    useQuery,
    UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';

import { EventsApiService } from '../api/events-api.service';

const { getParticipantsRequest } = EventsApiService();

const getParticipantsRequestQuery = (
    id: any,
    options?: UseQueryOptions<any>,
): {
    queryKey: QueryKey;
    queryFn: QueryFunction<any>;
    options?: UseQueryOptions<any>;
} => ({
    queryKey: ['getParticipantsRequest', id],
    queryFn: ({ signal }) => getParticipantsRequest(id, { config: { signal } }),
    ...options,
});

export const useGetParticipantsRequest = (
    id: any,
    options?: UseQueryOptions<any>,
) => {
    const { message } = App.useApp();

    return useQuery<any, Error>(
        getParticipantsRequestQuery(id, {
            onError: (error: unknown) => {
                const uiMessage = handleError(error);
                message.error(uiMessage);
            },
            onSuccess: (data) => {
                const res = data.ResponseStatus
                if (res === 1) {
                    message.success(data.ResponseMessage || "success");
                } else {
                    message.error(data.ResponseMessage || "failed");
                }
            },

            ...options,
        }),
    );
};
