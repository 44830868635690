import { Button, Form } from 'antd';
import { useCreateEvent } from 'features/events/use-cases';
import { useEffect, useState } from 'react';
import { payFormatDate, payFormatDateTime } from 'utils/payload-date-';

import { EventFormField, EventFormValues } from '../modals/types';
import { EventInfoFormItems } from './event-info-form-items';

interface props {
  onClose: () => void,
  isOpen: boolean;
  sportsData: any;
  currencyData: any
}

export const AddEventModal = ({ onClose, isOpen, sportsData, currencyData }: props) => {
  const formName = 'add-event-form';
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<EventFormValues>();
  // const { t } = useTranslation();

  const { isLoading, mutate } = useCreateEvent();



  const getInitialValues = {
    [EventFormField.EventMaster]: undefined,
    [EventFormField.EventName]: undefined,
    [EventFormField.EventNameArabic]: undefined,
    [EventFormField.SportsCategory]: undefined,
    [EventFormField.StartDate]: undefined,
    [EventFormField.EndDate]: undefined,
    [EventFormField.EventDate]: undefined,
    [EventFormField.Currency]: undefined,
  };



  const handleUpdate = (formData: EventFormValues) => {

    formData.start_date = payFormatDate(formData.start_date);
    formData.end_date = payFormatDate(formData.end_date);

    formData.event_date = payFormatDateTime(formData.event_date);

    // formData.start_date = formatDate(formData.start_date);
    // formData.end_date = formatDate(formData.end_date);

    // formData.event_date = formatDate(formData.event_date);

    formData.is_active = true;
    mutate(formData, { onSuccess: onClose });
  };

  useEffect(() => {
    form.resetFields();
  }, [isOpen])

  return (

    <>
      <div className='popup'>
        <div className='modal_head'>
          <button onClick={onClose} className='close-btn'>
            &times;
          </button>
          <h4>Add Event</h4>
        </div>
        <div className='modal_body'>
          <Form<EventFormValues>
            id={formName}
            name={formName}
            form={form}
            layout='vertical'
            initialValues={getInitialValues}
            onFinish={handleUpdate}
            // requiredMark={'optional'}
            onFinishFailed={() => {
              setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
            disabled={isLoading}
          >
            <EventInfoFormItems
              isOpen={isOpen}
              sportsData={sportsData}
              currencyData={currencyData}
              form={form}
            />

            <div className='button-group'>
              <Button loading={isLoading} className='btn' htmlType='submit'>Save</Button>
            </div>
          </Form>

        </div>
      </div>
    </>
  );
};
