export enum FaqFormField {
    Id = 'id',
    Question = 'question',
    Answer = 'answer',
    IsActive = 'is_active',
}



export type Faq = {
    Id?: string;
    question: string;
    answer: string;
    IsActive: boolean;
};

export type FaqFormValues = {
    [FaqFormField.Id]: string | undefined;
    [FaqFormField.Question]: string;
    [FaqFormField.Answer]: string;
    [FaqFormField.IsActive]: boolean;

};