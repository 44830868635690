import { Button } from 'antd';
import { ContentFilters } from 'components';
import { ContentTopBar } from 'components/content-top-bar/top-content-bar';
import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ReportsTableList } from '../component/report-list/reports-table';
import { ReportFilterForm } from '../component/top-bar/report-filter-form/report-list-filter';
import { ReportFilterFormValues, ReportFilterFormValuesPaginated } from '../component/top-bar/report-filter-form/types';
import { useGetReportsList } from '../use-cases/get-reports-exports';



export const ReportsListView = () => {

    const formId = "report-filter"

    const getInitialFilterValues: ReportFilterFormValues = {
        ReportType: undefined,

    };

    const { params, updateParams, resetPage } = usePaginationParams<any>({
        page: 1,
        size: 10,
        ...getInitialFilterValues
    });



    const { data, refetch, isFetching } = useGetReportsList(params, { retry: false });

    const handleRefetch = () => {
        refetch();
    }

    const handleFiltersChange = (values: ReportFilterFormValuesPaginated) => {
        updateParams(values);
        resetPage();
        refetch()
    };

    return (

        <>
            <ContentTopBar
                title='Reports List'
                renderButtons={

                    <Button
                        onClick={handleRefetch}
                        type='primary'
                        loading={isFetching}
                    >
                        Refresh
                    </Button>

                }
            />

            <ContentFilters formId={formId}>
                <ReportFilterForm
                    formId={formId}
                    handleFilter={handleFiltersChange}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>

            <ReportsTableList
                isSocialListLoading={isFetching}
                DeliveryList={data}
                tableParams={params as any}
                updateParams={updateParams}
                refetch={refetch}
                isFetching={isFetching}

            />
            <Outlet />
        </>
    );
};
