import { PaginatedRequestParams } from 'api';
import { MainFiltersParams } from 'components/main-filters/main-filters';

export enum EventsFilterFormField {
  FieldName = 'field_name',
  // DateFrom = 'date_from',
  // DateTo = 'date_to',
  // EventDate = 'event_date',
}

export type EventsFilterFormValues = {
  [EventsFilterFormField.FieldName]: string | undefined;
  // [EventsFilterFormField.DateFrom]: string | undefined;
  // [EventsFilterFormField.DateTo]: string | undefined;
  // [EventsFilterFormField.EventDate]: string | undefined;
};

export type EventsFilterFormPaginated = EventsFilterFormValues &
  MainFiltersParams &
  PaginatedRequestParams;
