import { Empty, Skeleton, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
    StyledTableHeader,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import { formatDateTime } from 'features/transaction_list/utills/utiils';
import { usePermissions } from 'features/users';
import { EndUserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { Dispatch, SetStateAction } from 'react';
import { User } from 'types';

type ParticipateTableProps = {
    isSocialListLoading: boolean;
    DeliveryList: any;
    refetch: any,
    tableParams: EndUserFilterFormValuesPaginated;
    updateParams: Dispatch<SetStateAction<EndUserFilterFormValuesPaginated>>;

};

export const WalletList = ({
    DeliveryList,
    isSocialListLoading,
    tableParams,
    updateParams,

}: ParticipateTableProps) => {
    const permissions = usePermissions();
    // const [deliveryEdit, setDeliveryEdit] = useState(false)

    // const navigate = useNavigate()
    // const handleOpenModal = (record: any) => {
    //     console.log(record, "record");
    //     setDeliveryEdit(true);
    // }

    if (!permissions) return null;
    const handleTableChange = (
        pagination: TablePaginationConfig,
        sorter: SorterResult<User> | SorterResult<User>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: Array.isArray(sorter)
                ? undefined
                : sorter.order === 'ascend'
                    ? 'name'
                    : '-name',
        });
    };

    return (
        <>
            {/* Your modal component */}
            <StyledTableHeader>
                <div style={{ marginTop: '32px', color: 'black' }}>
                    <RecordCountBadge
                        text={"Wallet found"}
                        count={isSocialListLoading ? 0 : DeliveryList?.total || 0}
                    />
                </div>
            </StyledTableHeader>
            <Table className='cstltblwthldr'
                dataSource={isSocialListLoading ? [] : DeliveryList?.items}
                // loading={isSocialListLoading}
                rowKey='id'
                onChange={handleTableChange}
                pagination={{
                    current: tableParams?.page,
                    defaultPageSize: tableParams?.size,
                    total: DeliveryList?.total,
                }}
                scroll={{ x: 1440 }}
                locale={{
                    emptyText: isSocialListLoading ? <Skeleton active={true} /> : <Empty />
                }}
                loading={isSocialListLoading && { indicator: <div><TableLoader /></div> }}

            >
                <Column
                    title={'Name'}
                    dataIndex={'name'}
                    className='no_wrap'
                />

                <Column
                    title={'Email'}
                    dataIndex={'email'}
                    className='no_wrap'
                />
                <Column
                    title={'Amount'}
                    dataIndex={'amount'}
                    className='no_wrap'
                />
                <Column
                    title={'Payments mode'}
                    dataIndex={'payment_method'}
                    className='no_wrap'
                />
                <Column
                    title={'Result Code'}
                    dataIndex={'result_code'}
                    className='no_wrap'
                />
                <Column
                    title={'Date'}
                    className='no_wrap'
                    render={(_, record: any) => (
                        <h1>{formatDateTime(record.date)}</h1>
                    )}

                />
                <Column
                    title={'Auth Id'}
                    dataIndex={'auth_id'}
                    className='no_wrap'
                />

                <Column
                    title={'Transaction Id'}
                    dataIndex={'transaction_id'}
                    className='no_wrap'
                />
                <Column
                    title={'Reference No'}
                    dataIndex={'reference_id'}
                    className='no_wrap'
                />

            </Table>
        </>
    );
};
