import { ContentFilters, ContentTopBar } from 'components';
import { useState } from 'react';
import { CompanyType } from 'types';

import { LogsUsersFilterForm } from '../users-filter-form/logs-filter-form';
import { LogsUserFilterFormValues } from '../users-filter-form/logs-types';


type UsersTopBarProps = {
  getInitialFilterValues: LogsUserFilterFormValues;
  handleFilter: (values: LogsUserFilterFormValues) => void;
};

export const LogsUsersTopBar = ({
  getInitialFilterValues,
  handleFilter,
}: UsersTopBarProps) => {
  useState<CompanyType>();
  const formId = 'logs-users-filters-form';
  // const [showAppUserModal, setShowAppUserModal] = useState(false);


  // const screens = useBreakpoint();





  return (
    <>
      <ContentTopBar title={'User Logs List'}
      // renderButtons={
      //   <>
      //     <Button
      //       icon={!screens.xs ? <PlusOutlined /> : undefined}
      //       type='primary'
      //       onClick={handleOpenModal}
      //     >
      //       Add Volunteer
      //       {/* {t('adminUsers.addNewUser')} */}
      //     </Button>
      //   </>
      // }
      />
      <ContentFilters formId={formId}>
        <LogsUsersFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
