import { Button, Form, Input } from 'antd';
import { useCreateCountries } from 'features/cms/use-cases/create-countries';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CountriesFormField, CountriesFormValues } from './types';

export const AddCountriesModal = ({ onClose, refetch, countriesEditData }: { onClose: () => void; showCountriesModal: boolean, refetch: () => void, countriesEditData: any }) => {
    const { id } = useParams();
    const formName = 'add-countries-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<CountriesFormValues>();
    const { t } = useTranslation();
    const { isLoading, mutate } = useCreateCountries();
    const getInitialValues = {
        [CountriesFormField.Id]: id,
        [CountriesFormField.Name]: undefined,
        [CountriesFormField.NameAr]: undefined,
        [CountriesFormField.IsActive]: undefined,
    };

    const handleUpdate = (formData: CountriesFormValues) => {
        formData[CountriesFormField.Id] = countriesEditData?.id;
        formData[CountriesFormField.IsActive] = true;
        mutate(formData, { onSuccess: hanldeSuccess });
        form.resetFields()
    };

    const hanldeSuccess = () => {
        onClose();
        refetch();
    }

    useEffect(() => {
        if (countriesEditData) {
            form.setFieldsValue(countriesEditData);
        } else {
            form.resetFields();
        }
    }, [countriesEditData])

    return (

        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={onClose} className='close-btn'>
                    &times;
                </button>
                <h4>{countriesEditData ? "Edit Country" : "Add Country"}</h4>
            </div>
            <div className='modal_body'>
                <Form<CountriesFormValues>
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    requiredMark={'optional'}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >
                    <FormSectionsSeparator title={""}>
                        <Form.Item<CountriesFormField>
                            label={"Name"}
                            name={CountriesFormField.Name}

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Name"}
                            // prefix={<UserOutlined className='input-prefix-icon' />}
                            />
                        </Form.Item>


                        <Form.Item<CountriesFormField>
                            label={"Name Ar"}
                            name={CountriesFormField.NameAr}

                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Name Ar"}
                            // prefix={<UserOutlined className='input-prefix-icon' />}
                            />
                        </Form.Item>

                        {/* <Form.Item<ManageAreaFormField>
                                            // label={"CategoryId"}
                                            name={ManageAreaFormField.IsActive}
                                            valuePropName="checked"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Checkbox>Is Active</Checkbox>
                                        </Form.Item> */}
                    </FormSectionsSeparator>
                </Form>
                <div className='button-group'>
                    <Button htmlType='submit' onClick={form.submit} loading={isLoading} className='btn'>Save</Button>
                </div>

            </div>
        </div>

    )
};

