import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';
import { useUpdateExpoBoothNo } from 'features/transaction_list/use-cases/update-expo-booth-number';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


type booth_no_list = {
    booth_no: number;
    booth_price: number;
    booth_registration_id: number;
    event_id: number;
    id: number;
}
type boothData = {
    booth_nos: string;
    booth_registration_id: number;
    email: string;
    event_id: number;
    booth_no_list: booth_no_list[]
}

interface props {
    onClose: () => void,
    isOpen: boolean,
    boothData: boothData
}

export const EditBoothNameModal = ({ onClose, isOpen, boothData }: props) => {

    const formName = 'edit-booth-name-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<any>();
    const { t } = useTranslation();

    const { isLoading, mutate } = useUpdateExpoBoothNo();
    const [editBoothId, setEditBoothId] = useState<number | null>(null)


    useEffect(() => {
        form.resetFields();
    }, [isOpen])

    const handleClose = () => {
        setEditBoothId(null);
        form.resetFields();
        onClose();
    };

    const handleUpdate = (formData: any) => {
        const payload = {
            Flag: "CHANGE",
            booth_no_old: editBoothId,      // Old booth id
            booth_no_new: +formData.booth_name,  // New booth name from form input
            booth_registration_id: boothData.booth_registration_id,
        };
        mutate(payload, { onSuccess: handleClose });
    };

    const handleDeleteClick = (item: any) => {
        const payload = {
            Flag: "DELETE",
            booth_no_old: item.booth_no,  // Booth id to be deleted
            booth_registration_id: boothData.booth_registration_id,
        };
        mutate(payload, { onSuccess: onClose });
    };

    const handleEditClick = (booth: { booth_no: number; booth_price?: number; booth_registration_id?: number; event_id?: number; id: any; }) => {
        setEditBoothId(booth.booth_no);  // Set the clicked booth for editing
        form.setFieldsValue({ booth_name: booth.booth_no });  // Pre-fill the input with the selected booth's value
    };

    const handleCancelClick = () => {
        setEditBoothId(null);
        form.resetFields();
    };

    const handleSaveClick = () => {
        form.submit();  // Save the form data
    };


    return (

        <>
            <div className='popup'>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>Edit Booth Number</h4>
                </div>
                <div className='modal_body'>
                    <Form
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        // initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <FormSectionsSeparator title={""}>
                            <div>
                                {boothData?.booth_no_list?.map((item) => (
                                    <div key={item.id} className='bt_wrap'>
                                        <Space className='booth_num'>
                                            {editBoothId === item.booth_no ? (
                                                <Form.Item
                                                    label={"Edit Booth Number"}
                                                    name={"booth_name"}
                                                    rules={[{
                                                        required: true,
                                                        message: t('validateMessages.custom.nomsg'),
                                                    }]}
                                                >
                                                    <Input placeholder="Enter Booth Name" />
                                                </Form.Item>
                                            ) : (
                                                <span className='both_no'>{item.booth_no}</span>
                                            )}

                                            {editBoothId === item.booth_no ? (
                                                <div className='booth_btn'>
                                                    <Button onClick={handleSaveClick} loading={isLoading} type='primary'>Save</Button>
                                                    <Button onClick={handleCancelClick} className='brder_btn'>Cancel</Button>
                                                </div>
                                            ) : (
                                                <div className='booth_btn'>
                                                    <Button onClick={() => handleEditClick(item)}><EditOutlined /></Button>
                                                    <Button onClick={() => handleDeleteClick(item)} danger><DeleteOutlined /></Button>
                                                </div>
                                            )}
                                        </Space>
                                    </div>
                                ))}
                            </div>
                        </FormSectionsSeparator>

                    </Form>

                </div>
            </div>
        </>
    );
};
