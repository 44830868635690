import { RightOutlined } from '@ant-design/icons';
import { Empty, Skeleton, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import { usePermissions } from 'features/users';
import { EndUserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { User } from 'types';

type ParticipateTableProps = {
  isSocialListLoading: boolean;
  DeliveryList: any;
  refetch: any,
  tableParams: EndUserFilterFormValuesPaginated;
  updateParams: Dispatch<SetStateAction<EndUserFilterFormValuesPaginated>>;
};
export const UploadList = ({
  DeliveryList,
  isSocialListLoading,
  tableParams,
  updateParams,
}: ParticipateTableProps) => {
  const permissions = usePermissions();
  const navigate = useNavigate();
  if (!permissions) return null;
  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<User> | SorterResult<User>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'

          : '-name',
    });
  };


  function formatString(str: string) {
    if (str) {
      return str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
  }



  return (
    <>
      <StyledTableHeader>
        <div style={{ marginTop: '32px', color: 'black' }}>
          <RecordCountBadge
            text={"Bulk Upload found"}
            count={DeliveryList?.total || 0}
          />
        </div>
      </StyledTableHeader>
      <Table className='cstltblwthldr cstm_table'
        dataSource={isSocialListLoading ? [] : DeliveryList?.items}
        // loading={isSocialListLoading}
        locale={{
          emptyText: isSocialListLoading ? <Skeleton active={true} /> : <Empty />
        }}
        loading={isSocialListLoading && { indicator: <div><TableLoader /></div> }}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams?.page,
          defaultPageSize: tableParams?.size,
          total: DeliveryList?.total,
        }}
        onRow={(record: any) => {
          return {
            onClick: () => {

              navigate(getRoute(ROUTES.TRANSACTION__BULK_UPLOAD_LIST_DETAILS, record.id));

            },
          };
        }}
      // scroll={{ x: 1440 }}
      >
        <Column
          title={'Event'}
          // dataIndex={'event_name'}
          render={(value: any, record: any) => {
            return (
              <span className='arow_tab'>
                <RightOutlined />
                <h1>{record.event_name}</h1>
              </span>

            )
          }}

        />
        <Column
          title={'Group'}
          dataIndex={'group_name'}
        // sorter={true}
        // sortDirections={['ascend', 'descend', 'ascend']}
        // defaultSortOrder={'ascend'}
        />
        <Column
          title={'Contact Person'}
          dataIndex={'contact_person_name'}
        // sorter={true}
        // sortDirections={['ascend', 'descend', 'ascend']}
        // defaultSortOrder={'ascend'}
        />
        <Column
          title={'Mobile'}
          dataIndex={'contact_person_phone'}

        />

        <Column
          title={'Email'}
          dataIndex={'contact_person_email'}

        />

        <Column
          title={'No. of Participants'}
          dataIndex={'number_of_participant'}

        />
        <Column
          title={'Payment Method'}
          // dataIndex={'payment_method'}
          render={(value: any, record: any) => {
            return (
              <h1>{formatString(record?.payment_method)}</h1>
            )
          }}

        />

        <Column
          title={'Status'}
          dataIndex={'payment_Status'}

        />

      </Table>
    </>
  );
};
