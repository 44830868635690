import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { queryClient } from 'providers';

import { ShopApiService } from '../api/shop-api.service';
import { eventsQueryKeys } from './query-keys';

const { updateStatus } = ShopApiService();

export const useStatusResponse = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: any) => {
            return updateStatus(payload as any);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: (data: any) => {
                const { ResponseMessage, ResponseStatus } = data;

                if (ResponseStatus === 1) {
                    Promise.all([
                        queryClient.invalidateQueries({
                            queryKey: eventsQueryKeys.lists(),
                        }),
                    ]);
                    message.success(ResponseMessage);
                } else if (ResponseStatus === 2) {
                    message.error(ResponseMessage);
                } else {
                    message.warning(ResponseMessage);
                }
            },
        },
    );
};
