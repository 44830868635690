import { PaginatedRequestParams } from 'api';

export enum ReportFilterFormField {
    ReportType = "ReportType",
}

export type ReportFilterFormValues = {
    [ReportFilterFormField.ReportType]: any;
};

export type ReportFilterFormValuesPaginated = ReportFilterFormValues &
    PaginatedRequestParams;