
export enum TipsFormField {
    Id = "Id",
    EventId = "EventId",
    Url = "Url",
    Image = "Imagefile",
    Description = "Description",
    File = "Fullfile"
};

export type TipsFormValues = {
    [TipsFormField.Id]: any;
    [TipsFormField.EventId]: string;
    [TipsFormField.Url]: string;
    [TipsFormField.Image]: any;
    [TipsFormField.Description]: string;
    [TipsFormField.File]: any;
};