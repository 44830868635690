import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useCreateResultPDF } from "features/events/use-cases/create-result-pdf";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";


export const AddResultPdfModal = ({ onClose, id, refetch }: { onClose: () => void; showModal: boolean, id: string; refetch: () => void }) => {

    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const { isLoading, mutate } = useCreateResultPDF();

    const fileInputRef = useRef<any>();
    const [selectedFile, setSelectedFile] = useState<any>(null);


    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);

        // Filter only the first PDF file
        const pdfFile = files.find(file => file.type === 'application/pdf');

        setSelectedFile(pdfFile);
    };


    const handleUpdate = (values: any) => {
        const formData = new FormData();
        formData.append(`event_id`, id);
        formData.append(`pdf_title`, values.Title);
        formData.append(`file`, selectedFile);
        mutate({ id, formData }, {
            onSuccess() {
                onClose();
                refetch();
                setSelectedFile(null);
                form.resetFields();
            },
        });
    };

    return (
        <>
            <div className='popup'>
                <div className='modal_head'>
                    <h4>Add Result PDF</h4>
                    <button onClick={onClose} className='close-btn'>
                        &times;
                    </button>

                </div>
                <div className='modal_body'

                >
                    <div className="frmscrll">

                        <Form
                            // id={formName}
                            // name={formName}
                            form={form}
                            layout='vertical'
                            // initialValues={getInitialValues}
                            onFinish={handleUpdate}
                            // requiredMark={'optional'}
                            onFinishFailed={() => {
                                setValidateTrigger(['onChange']);
                            }}
                            validateTrigger={validateTrigger}

                        >
                            <Form.Item
                                label={"PDF Title"}
                                name="Title"

                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Enter PDF Title"}
                                />
                            </Form.Item>
                            <div className="upld_file_bx " onClick={handleClick}>
                                <CloudUploadOutlined />
                                <div className="upld_file">
                                    <label >Choose File </label>
                                    <span>{selectedFile ? selectedFile.name : 'No File Chosen'}</span>
                                    <input
                                        id="fileInput"
                                        ref={fileInputRef}
                                        type="file"
                                        name="name"
                                        style={{ display: 'none' }}
                                        accept="application/pdf"
                                        onChange={handleFileChange}
                                    />
                                </div>
                            </div>
                            <div className="upld_btn">
                                <Button htmlType="submit" loading={isLoading} className="btn" type="primary"> Save</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
};
