import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { axiosInstance } from 'api/axios-instance';
import { CONFIG } from 'config';
import { VendingMachine } from 'types';


export interface VendorListParams extends PaginatedRequestParams {
  qr_code_number?: string;
  participant_id?: string;
  created_on?: string;
  category_name?: string;
  email_address?: string;
  full_name?: string;
  event_name?: string;
}
export type VendingDTO = {
  event_id?: string;
  status: string;
}


export type VendingReportDTO = {
  participant_id: string | undefined;
  full_name: string | undefined;
  email_address: string | undefined;
  category_name: string | undefined;
  event_name: string | undefined;
}


export type VendingReportResponse = PaginatedResponse<VendingMachine>;

const replaceSubstring = (
  input: string,
  target: string,
  replacement: string,
): string => {
  const startIndex = input.indexOf(target);
  const endIndex = startIndex + target.length;
  const replacedStr =
    input.slice(0, startIndex) + replacement + input.slice(endIndex);

  return replacedStr;
};

export const downloadVendingReport = async (params: VendingReportDTO) => {
  //console.log('params'+JSON.stringify(params));    
  const participant_id = params.participant_id ? params.participant_id : "";
  const name = params.full_name ? params.full_name : "";
  const email = params.email_address ? params.email_address : "";
  const categoryName = params.category_name ? params.category_name : "";
  const eventName = params.event_name ? params.event_name : "";


  const sportsService = replaceSubstring(
    CONFIG.SUFFIX_API_BASE_URL,
    '/identity/',
    '/events/',
  );

  const response = await axiosInstance.get(
    `${sportsService}/v1/admin/vendingmachine/downloadvendingmachinereport?participant_id=${participant_id}&name=${name}&email_address=${email}&ticket_category=${categoryName}&event_name=${eventName}`,
    {
      responseType: 'blob',
    },
  );

  // return response;

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `vendorReport.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

}

export const UserApiService = () => {

  const getVendingReports = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<VendingReportResponse>({
      ...callConfig,
      endpoint: `admin/vendingmachine/vendingmachinereport`,
      params,
    });

    return response.data;
  };

  const getVendingEvents = async (Flag: string) => {
    const response = await apiClient({
      endpoint: `admin/dropdownlist?${Flag}`
    })

    return response.data
  }
  const createVending = async (payload: VendingDTO) => {
    const response = await apiClient({
      endpoint: `admin/vendingmachine/updatevendingmachine`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const getEvents = async () => {
    const response = await apiClient({
      endpoint: `admin/vendingmachine/vendingmachineevents`
    })

    return response.data
  }

  return {
    getVendingReports,
    getVendingEvents,
    createVending,
    getEvents,
  };
};
