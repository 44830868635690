export enum SizeFormField {
    Id = 'id',
    size_name = 'size_name',
    size_name_ar = 'size_name_ar',
    IsActive = 'is_active',
}



export type size = {
    Id?: string;
    Title: string;
    ArTitle: string;
    IsActive: boolean;
};

export type SizeFormValues = {
    [SizeFormField.Id]: string | undefined;
    [SizeFormField.size_name]: string;
    [SizeFormField.size_name_ar]: string;
    [SizeFormField.IsActive]: boolean;

};