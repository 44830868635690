import {
  DownOutlined,
  IdcardOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Form, Input, Radio, Select } from 'antd';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import {
  VolunteerUserFormField,
} from 'features/users/components/users-filter-form';
import { useTranslation } from 'react-i18next';
import { phoneNumberPattern } from 'utils/validations';

type eventListType = {
  Id: string;
  Text: string;
  Value: string;
}

export const VolunteerInfoFormItems = ({
  eventList,
  handleChange,
}: {
  eventList: eventListType[],
  handleChange: any

}) => {


  const { t } = useTranslation();



  return (
    <FormSectionsSeparator title={""} >
      <Form.Item<VolunteerUserFormField>
        label={t('volunteer.field.name.label')}
        name={VolunteerUserFormField.Name}
        rules={[
          {
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Input
          placeholder={t('volunteer.field.name.placeholder')}
          prefix={<UserOutlined className='input-prefix-icon' />}
        />
      </Form.Item>

      <Form.Item<VolunteerUserFormField>
        label={t('volunteer.field.assign_event.label')}
        name={VolunteerUserFormField.EventId}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          showSearch
          options={eventList.map((res: any) => ({ label: res.Text, value: res.Id }))}
          placeholder={t('volunteer.field.assign_event.placeholder')}
          className='has-prefix-icon'
          onChange={handleChange}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          suffixIcon={
            <>
              <IdcardOutlined className='prefix-icon' />
              <DownOutlined className='suffix-icon' />
            </>
          }
        />

      </Form.Item>


      <Form.Item<VolunteerUserFormField>
        label={t('adminUsers.formFields.phoneNumber.label')}
        name={VolunteerUserFormField.PhoneNumber}
        rules={[
          {
            required: true,
          },
          {
            pattern: phoneNumberPattern,
            message: t('validateMessages.custom.phoneNumber'),
          },
        ]}
      >
        <Input
          type='tel'
          placeholder={t('adminUsers.formFields.phoneNumber.placeholder')}
          prefix={<PhoneOutlined className='input-prefix-icon' />}
          maxLength={17}
          onKeyPress={(e) => {
            // Check if the entered key is a number, "+", or Backspace (to allow deletion)
            const isNumberOrPlus = /^[0-9+]*$/.test(e.key);
            const isBackspace = e.key === 'Backspace';

            // Check if the total length after the new character insertion will be <= 15
            const inputValue = (e.target as HTMLInputElement).value || '';
            const totalLength = inputValue.length + (isBackspace ? -1 : 1);

            if (!isNumberOrPlus || totalLength > 17) {
              e.preventDefault();
            }
          }}
        />
      </Form.Item>

      <Form.Item<VolunteerUserFormField >
        label={t('adminUsers.formFields.email.label')}
        name={VolunteerUserFormField.Email}
        rules={[
          {
            required: true,
          },
          {
            type: 'email',
            message: t('validateMessages.custom.email'),
          },
        ]}
      >
        <Input
          placeholder={t('adminUsers.formFields.email.placeholder')}
          prefix={<MailOutlined className='input-prefix-icon' />}
          autoComplete="off"
        // name="unique-email"
        />
      </Form.Item>



      <Form.Item<VolunteerUserFormField>
        label={t('volunteer.field.able_to_refund')}
        name={VolunteerUserFormField.IsRefundAllowed}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group >
          <Radio value={true} > Yes</Radio>
          <Radio value={false}> No </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item<VolunteerUserFormField>
        label={t('volunteer.field.status')}
        name={VolunteerUserFormField.Status}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group >
          <Radio value={true} > Yes</Radio>
          <Radio value={false}> No </Radio>
        </Radio.Group>
      </Form.Item>

    </FormSectionsSeparator>
  );
};
