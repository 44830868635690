import 'react-quill/dist/quill.snow.css';

import { Button, message } from "antd";
import { CMSApiService, CreateGlobalDisclaimerDTO } from "features/cms/api/cms-api.service";
import { useCreateGlobalDisclaimer } from "features/cms/use-cases/craete-global-disclaimer";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";


const DisclaimerEditor = () => {
    const [code, setCode] = useState<string>("");
    const [id, setId] = useState<string>("");
    const handleProcedureContentChange = (
        content: React.SetStateAction<string>,
        // delta: any,
        // source: any,
        // editor: any
    ) => {
        setCode(content);
    };

    const { mutate, isLoading } = useCreateGlobalDisclaimer();
    const formData: CreateGlobalDisclaimerDTO = {
        id: id,
        data: code
    }
    const handleUpdate = () => {
        mutate(formData);
    };

    // const hanldeSuccess = (): void => {
    //     setEdit(false);
    // }

    const getDisclaimer = async () => {
        try {
            const apires: any = await CMSApiService().getGlobalDisclaimer();
            setCode(apires.data);
            setId(apires?.id)
        } catch (error) {
            message.error("")
        }
    };


    useEffect(() => {
        getDisclaimer();
    }, [])


    return (

        <>

            <ReactQuill
                className="editor-large"
                theme="snow"
                value={code || ''}
                onChange={handleProcedureContentChange}
                modules={DisclaimerEditor.modules}
                formats={DisclaimerEditor.formats}
            // dangerouslySetInnerHTML={{ __html: code }}
            // readOnly={!edit}
            />
            <Button
                loading={isLoading}
                onClick={handleUpdate} type='primary' style={{ marginTop: 10 }}
            >Save</Button>
        </>


    );
};

DisclaimerEditor.modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, false] }], // Select dropdown for heading
        [{ 'font': [] }], // Select dropdown for font size
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'align': [] }], // Select dropdown for text alignment
        [{ 'color': [] }, { 'background': [] }], // Select dropdowns for text and background color
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link'],
        ['clean']
    ],
    clipboard: {
        matchVisual: true,
    }
};

DisclaimerEditor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'align', 'color', 'background'
];



export default DisclaimerEditor;


