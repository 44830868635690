import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CONFIG } from 'config';
import { ReactNode } from 'react';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: CONFIG.ENVIRONMENT !== 'development',
    },
  },
});

export const ReactQueryProvider = ({ children }: { children: ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {/* -<ReactQueryDevtools position='bottom-right' /> */}
      {children}
    </QueryClientProvider>
  );
};
