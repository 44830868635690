export enum ManageAreaFormField {
    Id = 'id',
    Cid = "cid",
    Name = 'name',
    IsActive = 'is_active',
}

export type ManageArea = {
    Id: string | undefined;
    Cid: string;
    AreaName: string;
    isActive: boolean;
};

export type ManageAreaFormValues = {
    [ManageAreaFormField.Id]: string | undefined;
    [ManageAreaFormField.Cid]: string;
    [ManageAreaFormField.Name]: string;
    [ManageAreaFormField.IsActive]: boolean;
};