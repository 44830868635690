import { PaginatedRequestParams } from 'api';
import { MainFiltersParams } from 'components/main-filters/main-filters';

export enum AreaFilterFormField {
  Name = 'name',
  ACTIVE="is_active"
}

export type AreaFilterFormValues = {
  [AreaFilterFormField.Name]: string | undefined;
  [AreaFilterFormField.ACTIVE]: string | undefined;

};

export type AreaFilterFormPaginated = AreaFilterFormValues &
  MainFiltersParams &
  PaginatedRequestParams;
