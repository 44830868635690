export enum CurrencyFormField {
    Id = 'id',
    CurrencyName = 'currency_name',
    CurrencyCode = 'currency_code',
    CurrencySymbol = 'currency_symbol',
    BasicCurrency = 'basic_currency',
    Friction = 'friction',
    IsActive = 'is_active',
}

export type Currency = {
    Id: string | undefined;
    CurrencyName: string;
    CurrencyCode: string;
    CurrencySymbol: string;
    BasicCurrency: string;
    Friction: string;
    isActive: boolean;
};

export type CurrencyFormValues = {
    [CurrencyFormField.Id]: string | undefined;
    [CurrencyFormField.CurrencyName]: string;
    [CurrencyFormField.CurrencyCode]: string;
    [CurrencyFormField.CurrencySymbol]: string;
    [CurrencyFormField.BasicCurrency]: string;
    [CurrencyFormField.Friction]: string;
    [CurrencyFormField.IsActive]: boolean;
};