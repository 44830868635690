import {
    QueryFunction,
    QueryKey,
    useQuery,
    UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';

import { EventsApiService } from '../api/events-api.service';
import { fieldsBookingsQueryKeys } from './price-query-keys';



const { getBoothAllZoneData } = EventsApiService();

const getBoothAllZoneDataQuery = (
    id: string,
    options?: UseQueryOptions<any>,
): {
    queryKey: QueryKey;
    queryFn: QueryFunction<any>;
    options?: UseQueryOptions<any>;
} => ({
    queryKey: fieldsBookingsQueryKeys.list(id),
    queryFn: ({ signal }) => getBoothAllZoneData(id, { config: { signal } }),
    ...options,
});

export const useGetBoothAllZoneData = (
    id: string,
    options?: UseQueryOptions<any>,
) => {
    const { message } = App.useApp();

    return useQuery<any, Error>(
        getBoothAllZoneDataQuery(id, {
            onError: (error: unknown) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            ...options,
        }),
    );
};



