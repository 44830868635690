import { AgentUsersTopBar } from 'features/users/components/top-bar/agent-users-top-bar';
import { AgentUsersTable } from 'features/users/components/users/agent-users-table';
import {
  UserFilterFormValues,
  UserFilterFormValuesPaginated,
} from 'features/users/components/users-filter-form/types';
// import {
//   // allAvailableRolesExceptUser,
//   allAvailableRolesForLoggedInUser,
//   USER_ROLES,
// } from 'features/users/permissions';
import { useGetUsersList } from 'features/users/use-cases/get-users';
import { useUserContext } from 'features/users/user-context';
import { usePaginationParams } from 'hooks';
import { omit } from 'lodash';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { User } from 'types';


const getInitialFilterValues: UserFilterFormValues = {
  full_user_name: undefined,
  phone_number: undefined,
  email: undefined,
  role: undefined,
  company_id: undefined,
};

const preparePayload = (params: UserFilterFormValuesPaginated, user?: User) => {
  if (!user) return params;

  let roles = new Array("SuffixAgent");//allAvailableRolesForLoggedInUser(user); // allAvailableRolesExceptUser(user);


  if (params.role) {
    roles = roles.filter((role) => role === params.role);
  }

  // if (params.company_id === 'suffix-admin') {
  //   roles = roles.filter((role) => USER_ROLES.li3ibRoles.includes(role));

  //   return omit({ ...params, roles }, ['role', 'company_id']);
  // }

  return omit({ ...params, roles }, ['role']);
};

export const AgentUsersView = () => {

  const [agentUserEditData, setAgentUserEditData] = useState<any>(undefined);

  const { params, updateParams, resetPage } =
    usePaginationParams<UserFilterFormValuesPaginated>({
      page: 1,
      size: 10,
      order_by: 'name',
      ...getInitialFilterValues,
    });

  const { user } = useUserContext();
  const getUsersListQueryResponse = useGetUsersList(
    preparePayload(params, user),
    {
      retry: false,
      enabled: !!user,
    },
  );

  const handleFiltersChange = (values: UserFilterFormValuesPaginated) => {
    updateParams(values);
    resetPage();
  };

  // useCallback(
  //   (values: WeekViewFiltersFormValues) => {
  //     setParams((prevParams) => ({
  //       ...prevParams,
  //       ...values,
  //       date: formatDate({
  //         date: values.date || prevParams.date,
  //         format: 'isoDate',
  //       }),
  //     }));
  //   },
  //   [],
  // );

  return (
    <>
      <div>
        <AgentUsersTopBar
          handleFilter={handleFiltersChange}
          getInitialFilterValues={getInitialFilterValues}
          agentUserEditData={agentUserEditData}
        />
        <AgentUsersTable
          isUsersListLoading={
            getUsersListQueryResponse.isLoading &&
            getUsersListQueryResponse.isFetching
          }
          usersList={getUsersListQueryResponse.data?.items || []}
          itemsCount={getUsersListQueryResponse.data?.total || 0}
          tableParams={params}
          updateParams={updateParams}
          agentUserEditData={agentUserEditData}
          setAgentUserEditData={setAgentUserEditData}

        />
        <Outlet /></div>

    </>
  );
};
