import { Tabs } from 'antd';
import leftline from 'assets/icons/arrow-left-line.png';
import { PageLoader } from 'components/page-loader/page-loader';
import { useGetEventDetailsData } from 'features/events/use-cases/get-event-details';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import EventCMSTab from '../event-details/event-cms-tab/event-cms-tab';
import EventDetailsPriceCategory from '../event-details/event-details-price-category/event-details-price-category';
import EventDetailsTopbar from '../event-details/event-details-topbar';
import GalleryComponent from '../event-details/event-gallary/gallery';
import EventMarketingComponent from '../event-details/event-marketing/event-marketing';
import ExpoBoothTabs from '../event-details/expo-booth-tabs/expo-booth-tabs';
import ShowHideAttributes from '../event-details/show-hide-attributes/show-hide-attributes';
import EventResultComponent from '../event-result/event-result';
import BasicContent from './basic-info';
import CustomFormsContent from './custom-for-content';
import { EventDetailsSetting } from './event-details-setting/event-details-setting';
// import ExpoRegistrationForm from './expo-booth-registration-form/custom-for-content';

const EventDetailsTabs = () => {
  const [tabs, setTabs] = useState<any>("1")
  const [show, setShow] = useState<boolean>(false)
  const { id } = useParams();
  const { data, refetch } = useGetEventDetailsData(id);

  const handleTabChange = (activeKey: any) => {
    setTabs(activeKey);
  };


  useEffect(() => {
    if (tabs === "1") {
      refetch();
    }
  }, [tabs]);

  if (!id) return null;

  if (!data) return <PageLoader />;

  const items = [
    {
      key: "1",
      label: "Event Details",
      children: <BasicContent id={id} data={data} refetch={refetch} />,
      className: 'cltb'

    },
    {
      key: "2",
      label: "Gallery",
      children: <GalleryComponent />,
      className: 'cltb'

    },
    {
      key: "3",
      label: "Tickets",
      children: <EventDetailsPriceCategory id={id} />,
      className: 'cltb'

    },
    {
      key: "4",
      label: "Results Management",
      children: <EventResultComponent />,
      className: 'cltb'

    },
    {
      key: "5",
      label: "Attributes",
      children: <ShowHideAttributes />,
      className: 'cltb'

    },
    {
      key: "6",
      label: "Marketing",
      children: <EventMarketingComponent />,
      className: 'cltb'

    },
    {
      key: "7",
      label: "CMS",
      children: <EventCMSTab />,
      className: 'cltb'
    },
    {
      key: "8",
      label: "Registration Form",
      children: <CustomFormsContent id={id} tabs={tabs} />,
      className: 'cltb'
    },

    {
      key: "9",
      label: "Expo Booth Tab",
      children: <ExpoBoothTabs />,
      className: 'cltb'
    },

    // {
    //   key: "9",
    //   label: "Expo Booth Registration Form",
    //   children: <ExpoRegistrationForm id={id} tabs={tabs} />,
    //   className: 'cltb'
    // },

  ];

  return (
    <>
      <EventDetailsTopbar name={data.EventName} />

      <div className='sctnbx'>
        <div className={`row_wrap ${show ? "visible" : "hide"}`}>
          <div className='col'>

            <Tabs className="sctabbx" activeKey={tabs} onChange={handleTabChange} items={items} />

            <div className='ar_icon'>
              {show && <button type='button' className='btn' onClick={() => setShow(!show)}>
                <img className='' src={leftline} />
              </button>}
            </div>
          </div>

          <div className='edbx_clm col'>
            <div className='bgcard evt_set' >
              <EventDetailsSetting id={id} setShow={setShow} show={show} />
            </div>
          </div>
        </div>


      </div>
    </>
  );
};

export default EventDetailsTabs;
