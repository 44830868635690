import { useEffect, useState } from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const NationalityView = ({ data }: any) => {

  const [nationalityTitle, setNationalityTitle] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setNationalityTitle(1);
    }, 5000);
  }, [])
  // const data = [
  //   {
  //     name: 'Page A',
  //     value: 590,       
  //   },
  //   {
  //     name: 'Page B',
  //     value: 868,
  //   }

  // ];

  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart className='rcnmbx'
          layout="horizontal"
          width={200}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          {/* {setTimeout(()=>{})} */}


          {nationalityTitle == 1 ? (<XAxis dataKey="name" type="category" />) : ''}




          <YAxis type="number" />
          <Tooltip />
          <Area dataKey="value" fill="#8884d8" stroke="#8884d8" />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export default NationalityView;
