import {
  Bar,
  BarChart,
  // CartesianGrid,
  // Legend,
  // Rectangle,
  Tooltip,
  XAxis
} from 'recharts';

import CustomTooltip from '../custom-tooltip';

const SalesView = ({ data }: any) => {

  // var  data = [
  // {"name":"Achieve","value":2000},
  // {"name":"Target","value":9000}

  // ];

  return (
    <>
      <BarChart
        width={980}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >

        <XAxis dataKey="name" />
        {/* <YAxis /> */}
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend />         */}
        <Bar dataKey="value" fill="#82ca9d" />
      </BarChart>
    </>
  )
}
export default SalesView;