import { RedoOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Empty, Skeleton, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
    StyledTableHeader,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import { useInvoiceListRefresh } from 'features/transaction_list/use-cases/refresh-invoicelist';
import { formateAmount } from 'features/transaction_list/utills/utiils';
import { usePermissions } from 'features/users';
import { EndUserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { User } from 'types';


type ParticipateTableProps = {
    isSocialListLoading: boolean;
    DeliveryList: any;
    refetch: any,
    tableParams: EndUserFilterFormValuesPaginated;
    updateParams: Dispatch<SetStateAction<EndUserFilterFormValuesPaginated>>;
    isFetching: boolean;
};
export const InvoicetList = ({
    DeliveryList,
    isSocialListLoading,
    tableParams,
    updateParams,
    isFetching,
}: ParticipateTableProps) => {

    const permissions = usePermissions();
    const navigate = useNavigate()

    const { mutate: refreshMutate, isLoading: refreshLoading } = useInvoiceListRefresh();


    const handleTableChange = (
        pagination: TablePaginationConfig,
        sorter: SorterResult<User> | SorterResult<User>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: Array.isArray(sorter)
                ? undefined
                : sorter.order === 'ascend'
                    ? 'name'
                    : '-name',
        });
    };

    const formatDate = (dateTimeString: string | number | Date) => {
        const date = new Date(dateTimeString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear().toString();

        return `${day}/${month}/${year}`;
    };


    const handleRefresh = (record: any) => {
        const formData = {
            booking_id: record.booking_id,
            payment_method: record.payment_method
        }

        refreshMutate(formData);
    }

    if (!permissions) return null;

    return (
        <>
            {/* Your modal component */}
            <StyledTableHeader>
                <div style={{ marginTop: '32px', color: 'black' }}>
                    <RecordCountBadge
                        text={"Invoice found"}
                        count={isSocialListLoading ? 0 : DeliveryList?.total || 0}
                    />
                </div>
            </StyledTableHeader>



            <Table className='cstltblwthldr scrl_tble'
                locale={{
                    emptyText: isFetching ? <Skeleton active={true} /> : <Empty />
                }}
                loading={isSocialListLoading && { indicator: <div><TableLoader /></div> }}
                dataSource={isSocialListLoading ? [] : DeliveryList?.items}

                rowKey='transaction_id'
                onChange={handleTableChange}
                pagination={{
                    current: tableParams?.page,
                    defaultPageSize: tableParams?.size,
                    total: DeliveryList?.total,
                }}
                scroll={{ x: 1440 }}
            // onRow={(record: any) => {
            //     return {
            //         onClick: () => {
            //             const selection = window?.getSelection()?.toString();

            //             if (!selection?.length) {
            //                 navigate(getRoute(ROUTES.TRANSACTION_INVOICE_LIST_EDIT, record.booking_id));
            //             }
            //         },
            //     };
            // }}
            >
                <Column
                    title={'Invoice'}
                    // dataIndex={'invoice_no'}
                    className='pointer invoice_bld'
                    // sorter={true}
                    // sortDirections={['ascend', 'descend', 'ascend']}
                    // defaultSortOrder={'ascend'}
                    onCell={(record: any) => {
                        return {
                            onClick: () => {
                                const selection = window?.getSelection()?.toString();

                                if (!selection?.length) {
                                    navigate(getRoute(ROUTES.TRANSACTION_INVOICE_LIST_EDIT, record.booking_id));
                                }
                            },
                        };
                    }}
                    render={(record: any) => {
                        return (
                            <>
                                <span className='arow_tab'>
                                    <RightOutlined />
                                    <div className='invce_bx'>
                                        <h2>{record.invoice_no}</h2>
                                        <h3 className='no_wrap'>{record.booking_id}</h3>
                                    </div>


                                </span>

                            </>

                        )
                    }}
                />



                <Column
                    title={'Name'}
                    dataIndex={'first_name'}
                    // sorter={true}
                    // sortDirections={['ascend', 'descend', 'ascend']}
                    // width={240}
                    // defaultSortOrder={'ascend'}
                    className='no_wrap'
                    render={(_, record: any) => (
                        <h2 >{record.first_name} {record.last_name}</h2>
                    )}
                />
                <Column
                    title={'Mobile No'}
                    dataIndex={'phone_number'}
                    className='no_wrap'
                // sorter={true}
                // sortDirections={['ascend', 'descend', 'ascend']}
                // defaultSortOrder={'ascend'}

                />event_name
                <Column
                    title={'Email'}
                    dataIndex={'email_address'}
                    className='no_wrap'

                />

                <Column
                    title={'Total Amount'}
                    // dataIndex={'total_cost_with_discount'}
                    // width={screens.xxl ? 'auto' : 200}
                    className='no_wrap'
                    render={(_, record: any) => (
                        <h2 > {record.total_cost ? formateAmount(record.total_cost) : '0.000'}</h2>
                    )}

                />

                {/* <Column
                    title={'Event Name'}
                    dataIndex={'event_name'}
                    className='no_wrap'

                /> */}
                <Column
                    title={'Pay Type'}
                    dataIndex={'type'}
                    className='no_wrap'

                />

                <Column
                    title={'Payments Currency'}
                    dataIndex={'currency'}
                    className='no_wrap'

                />
                <Column
                    title={'Transaction Status'}
                    dataIndex={'booking_status'}
                    className='no_wrap'

                />
                <Column
                    title={'Booking Date'}
                    // dataIndex={'paid_at'}
                    className='no_wrap'
                    render={(record: any) => {
                        return <h2 >{formatDate(record?.paid_at)} </h2>
                    }}
                />
                <Column
                    title={'Tranascation Id'}
                    dataIndex={'TransactionId'}
                    className='no_wrap'
                />

                <Column
                    title={'Auth Id'}
                    dataIndex={'AuthId'}
                    className='no_wrap'

                />

                <Column
                    title={'Reference No'}
                    dataIndex={'ReferenceNumber'}
                    className='no_wrap'

                />

                <Column
                    title={'Action'}
                    className='no_wrap'
                    render={(record: any) => {
                        return <Button
                            icon={<RedoOutlined />}
                            type='primary'
                            loading={refreshLoading}
                            onClick={() => handleRefresh(record)}
                        >
                            Refresh
                        </Button>
                    }}
                />


            </Table >
        </>
    );
};
