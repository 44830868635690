import { Tabs } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import BibAllocation from './bib-allocation-table/bib-allocation';
import ParticipantDataComponent from './participant-data';
import ResultsComponent from './result';
import ResultsPDFComponent from './result-pdf';


const EventResultComponent = () => {
  const { id } = useParams();

  const [tabs, setTabs] = useState<string>("");

  if (!id) return null;

  const tabItems = [
    {
      key: '1',
      label: 'Results',
      children: <ResultsComponent id={id} />,
      className: 'cltb'
    },
    {
      key: '3',
      label: 'Result PDF',
      children: <ResultsPDFComponent id={id} />,
      className: 'cltb'
    },
    {
      key: '2',
      label: 'Participant Data',
      children: <ParticipantDataComponent id={id} />,
      className: 'cltb'
    },
    {
      key: '4',
      label: 'Bib Allocation',
      children: <BibAllocation tabs={tabs} />,
      className: 'cltb'
    },
  ];

  const handleTabChange = (activeKey: any) => {
    setTabs(activeKey)
  };


  return (

    <>
      <Tabs className='gallery_tab' defaultActiveKey='1' items={tabItems} onChange={handleTabChange} />
    </>
  );
};
export default EventResultComponent;
