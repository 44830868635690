import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import {
  MainFilters,
  MainFiltersParams,
} from 'components/main-filters/main-filters';
import { usePermissions } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { SportsCategoryTable } from '../components';
import { cmsFilterFormPaginated, CmsSportFilterFormValues, SportsCategoryFilterForm } from '../components/sports-category-filter-form';
import { AddSportsCategorymodal } from '../components/sports-category-table/add-sports-category-modal/add-sports-category-modal';
import { useGetSportsCategory } from '../use-cases/get-sports-category';

const { useBreakpoint } = Grid;

const initialFilterValues: CmsSportFilterFormValues = {
  name: undefined,
};

export const SportsCategoryView = () => {
  const screens = useBreakpoint();
  const permissions = usePermissions();
  const { params, updateParams, resetPage } =
    usePaginationParams<cmsFilterFormPaginated>({
      page: 1,
      size: 10,
      order_by: '-rank',
      ...initialFilterValues,
    });

  const { data, isFetching, refetch } = useGetSportsCategory(params, { retry: false });
  const [sportsEditData, setSportsEditData] = useState<any>(undefined);

  const shouldHandleFieldCreation = permissions?.fields.add;

  const handleFiltersChange = (values: CmsSportFilterFormValues) => {
    updateParams(values);
    resetPage();
    refetch();
  };

  const handleMainFiltersChange = (mainParams: MainFiltersParams) => {
    updateParams(mainParams);
    resetPage();
  };


  const [showAddSportsModal, setShowAddSportsModal] = useState(false);
  const formId = 'events-filters-form';

  const handleCloseModal = () => {
    setShowAddSportsModal(false);
    document.body.style.overflow = 'visible';
  }


  const handleOpenModal = () => {
    setShowAddSportsModal(true);
    document.body.style.overflow = 'hidden';
  }

  return (
    <>
      <ContentTopBar
        title={"Sports  Category"}
        renderHeadingContent={
          <MainFilters onChange={handleMainFiltersChange} />
        }
        renderButtons={
          <>
            {shouldHandleFieldCreation ? (
              <>
                <Button
                  icon={!screens.xs ? <PlusOutlined /> : undefined}
                  onClick={handleOpenModal}
                  type='primary'
                >
                  {"Add Sport Category"}
                </Button>
                <div className={`cstmrightmdl ${showAddSportsModal && "show"}`}>

                  <AddSportsCategorymodal onClose={handleCloseModal} sportsEditData={sportsEditData} showAddSportsModal={showAddSportsModal} />
                </div>

              </>
            ) : null}
          </>
        }
      />

      <ContentFilters formId={formId}>
        <SportsCategoryFilterForm
          formId={formId}
          handleFilter={handleFiltersChange}
          initialValues={initialFilterValues}
        />
      </ContentFilters>

      <SportsCategoryTable
        data={data}
        isLoading={isFetching}
        tableParams={params}
        updateParams={updateParams}
        sportsEditData={sportsEditData}
        setSportsEditData={setSportsEditData}
      />
      <Outlet />
    </>
  );
};
