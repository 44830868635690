import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { queryClient } from 'providers';

import { NotificationApiService } from '../api/notification-api.service';
import { NotificationQueryKeys } from './query-keys';


const { updateStatus } = NotificationApiService();

export const UpdateStatus = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: any) => {
      return updateStatus(payload as any);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data:any, variables) => {
      
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: NotificationQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: NotificationQueryKeys.detail(variables.id as any),
          }),
        ]);
        message.success(data.ResponseMessage);
      },
    },
  );
};
