import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Grid, Image, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
  EventsFilterFormPaginated,
  EventsResponse,
  // useUpdateEvent,
} from 'features/events';
import { useStatusResponse } from 'features/shop-item/use-cases/update-status-response-msg';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';
import { Event } from 'types/event';

import { AddDiscoverBannerModal } from '../add-discover-banner-modal/add-discover-banner-modal';

const { useBreakpoint } = Grid;

type EventsTableProps = {
  isLoading: boolean;
  data?: EventsResponse;
  tableParams: EventsFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<EventsFilterFormPaginated>>;
  refetch: () => void;
  bannerEditData: any;
  setBannerEditData: any;
};

export const DiscoverBannerTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
  refetch,
  bannerEditData,
  setBannerEditData
}: EventsTableProps) => {

  const screens = useBreakpoint();
  const permissions = usePermissions();

  const { mutate: mutateUpdateItem, isLoading: isLoadingUpdate } =
    useStatusResponse();


  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<Event> | SorterResult<Event>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'
          : '-name',
    });
  };
  const { confirm } = Modal;
  const showDeleteConfirm = (record: any) => {
    confirm({
      title: 'Are you sure you want to delete this Banner?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(record.Id);
      },
    });
  };
  const handleDelete = async (id: any) => {
    mutateUpdateItem({
      id: id,
      flag: "DISCOVER_BANNER_DELETE",
      status: true
    });
  };

  const [showBannerModal, setShowBannerModal] = useState(false);

  const handleCloseModal = () => {
    setShowBannerModal(false);
    setBannerEditData(undefined);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = (record: any) => {
    setShowBannerModal(true);
    setBannerEditData(record);
    document.body.style.overflow = 'hidden';
  }

  if (!permissions) return null;


  return (
    <>
      <div className={`cstmrightmdl ${showBannerModal && "show"}`}>
        <AddDiscoverBannerModal showBannerModal={showBannerModal} refetch={refetch} onClose={handleCloseModal} bannerEditData={bannerEditData} />

      </div>
      <StyledTableWrapper>
        <StyledTableHeader>
          <RecordCountBadge
            text={"Discover Banner found"}
            count={data?.total || 0}
          />
        </StyledTableHeader>
        <Table<Event>
          className="cstltblwthldr"
          locale={{
            emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
          }}
          loading={isLoading && { indicator: <div><TableLoader /></div> }}
          dataSource={isLoading ? [] : data?.items}
          rowKey='id'
          onChange={handleTableChange}
          pagination={{
            current: tableParams.page,
            defaultPageSize: tableParams.size,
            total: data?.total,
          }}
        >

          <Column<Event>
            title={'Title'}
            dataIndex={'title'}



          />


          <Column<any>
            title={'Description'}
            dataIndex={'description'}


          />

          <Column<any>
            title={'Image'}
            dataIndex={'logo'}
            width={screens.xxl ? 'auto' : 120}
            render={(_, record) => (
              <Image
                width={80}
                src={record.image_url}
              />
            )}
          />
          <Column<any>
            title={'URL'}
            dataIndex={'url'}
            width={screens.xxl ? 'auto' : 120}

          />
          <Column<Event>
            width={screens.xxl ? 'auto' : 120}
            title={"Action"}
            render={(_, record) => (
              <>
                <Space>
                  <Tooltip title="Edit">
                    <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <Button loading={isLoadingUpdate} shape="circle" onClick={() => showDeleteConfirm(record)} icon={<DeleteOutlined />} />
                  </Tooltip>
                  <Tooltip title="Is Active">
                    <Switch
                      defaultChecked={record.is_active}
                      checked={record.is_active}
                      loading={isLoadingUpdate}
                      onChange={(isChecked) => {
                        mutateUpdateItem({
                          Id: record.Id,
                          flag: "DISCOVER_BANNER_SET_STATUS",
                          status: isChecked,
                        });
                        record.is_active = isChecked;
                      }}
                    />
                  </Tooltip>
                </Space>
              </>
            )}
          />
        </Table>
      </StyledTableWrapper>
    </>

  );
};
