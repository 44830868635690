import { PaginatedRequestParams } from 'api';
import { MainFiltersParams } from 'components/main-filters/main-filters';

export enum CountryFilterFormField {
  Name = 'name',
  ACTIVE="is_active"
}

export type CountryFilterFormValues = {
  [CountryFilterFormField.Name]: string | undefined;
  [CountryFilterFormField.ACTIVE]: string | undefined;

};

export type CountryFilterFormPaginated = CountryFilterFormValues &
  MainFiltersParams &
  PaginatedRequestParams;
