export enum BulkUploadFormField {
    EventId = 'EventId ',
    Email = 'contact_person_email ',
    GroupName = 'group_name',
    PersonName = 'contact_person_name ',
    PersonPhone = 'contact_person_phone',
    File = "File ",
}

export type BulkUploadFormValues = {
    [BulkUploadFormField.EventId]: string;
    [BulkUploadFormField.Email]: string;
    [BulkUploadFormField.GroupName]: string;
    [BulkUploadFormField.PersonName]: string;
    [BulkUploadFormField.PersonPhone]: string;
    [BulkUploadFormField.File]: any;

};
