import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { EndUsersFilterForm } from 'features/users/components/users-filter-form/end-users-filter-form';
import { EndUserFilterFormValues } from 'features/users/components/users-filter-form/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyType } from 'types';

import { AddAppUserModal } from '../modals/add-app-user-modal';

const { useBreakpoint } = Grid;

type UsersTopBarProps = {
  getInitialFilterValues: EndUserFilterFormValues;
  handleFilter: (values: EndUserFilterFormValues) => void;
  userEditData:any ;
};

export const EndUsersTopBar = ({
  getInitialFilterValues,
  handleFilter,
  userEditData,
}: UsersTopBarProps) => {
  useState<CompanyType>();
  const { t } = useTranslation();
  const formId = 'end-users-filters-form';
  const [showAppUserModal, setShowAppUserModal] = useState(false);
  const screens = useBreakpoint();


  const handleCloseModal = () => {
    setShowAppUserModal(false);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = () => {
    setShowAppUserModal(true);
    document.body.style.overflow = 'hidden';
  }

  return (
    <>
      <div className={`cstmrightmdl ${showAppUserModal && "show"}`}>
        <AddAppUserModal onClose={handleCloseModal} userEditData={userEditData} />
      </div>

      <ContentTopBar title={t('adminUsers.heading.suffix-admin')}
        renderButtons={
          <>
            <Button
               icon={!screens.xs ? <PlusOutlined /> : undefined}
              type='primary'
              onClick={handleOpenModal}
            >
              {t('adminUsers.addNewUser')}
            </Button>
          </>
        }
      />
      <ContentFilters formId={formId}>
        <EndUsersFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
