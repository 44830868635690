import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Image, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
  EventsResponse,
  // useUpdateEvent,
} from 'features/events';
import { useStatusResponse } from 'features/shop-item/use-cases/update-status-response-msg';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';
import { Event } from 'types/event';

import { cmsFilterFormPaginated } from '../sports-category-filter-form';
import { AddSportsCategorymodal } from './add-sports-category-modal/add-sports-category-modal';
type EventsTableProps = {
  isLoading: boolean;
  data?: EventsResponse;
  tableParams: cmsFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<cmsFilterFormPaginated>>;
  sportsEditData: any;
  setSportsEditData: any;
};
export const SportsCategoryTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
  sportsEditData,
  setSportsEditData,
}: EventsTableProps) => {

  const permissions = usePermissions();
  const { confirm } = Modal;

  const { mutate: mutateUpdateItem, isLoading: isLoadingUpdate } =
    useStatusResponse();

  const handleTableChange = (
    pagination: TablePaginationConfig,
    // filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Event> | SorterResult<Event>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'
          : '-name',
    });
  };
  const [showAddSportsModal, setShowAddSportsModal] = useState(false);
  if (!permissions) return null;

  const handleCloseModal = () => {
    setShowAddSportsModal(false);
    setSportsEditData(undefined);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = (record: any) => {
    setShowAddSportsModal(true);
    setSportsEditData(record);
    document.body.style.overflow = 'hidden';
  }

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: 'Are you sure you want to delete this Sport?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(record.id);
      },
    });
  };

  const handleDelete = async (id: any) => {
    mutateUpdateItem({
      id: id,
      flag: "SPORTS_CATEGORY_DELETE",
      status: true
    });
  };

  return (
    <>
      <div className={`cstmrightmdl ${showAddSportsModal && "show"}`}>
        <AddSportsCategorymodal onClose={handleCloseModal} sportsEditData={sportsEditData} showAddSportsModal={showAddSportsModal} />
      </div>

      <StyledTableWrapper>
        <StyledTableHeader>
          <RecordCountBadge
            text={"Sport Category found"}
            count={data?.total || 0}
          />
        </StyledTableHeader>
        <Table<Event>
          className="cstltblwthldr"
          locale={{
            emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
          }}
          loading={isLoading && { indicator: <div><TableLoader /></div> }}
          dataSource={isLoading ? [] : data?.items}
          rowKey='id'
          onChange={handleTableChange}
          pagination={{
            current: tableParams.page,
            defaultPageSize: tableParams.size,
            total: data?.total,
          }}
        >
          <Column<Event>
            title={'Category Name'}
            dataIndex={'category_name'}
            width={600}
          />

          <Column<Event>
            title={'Image'}
            dataIndex={'image_url'}
            render={(_, record) => (
              <Image
                width={80}
                src={record.image_url}
              // loading={isLoadingUpdateEvent}
              />
            )}
          />

          <Column<Event>
            title={"Action"}
            dataIndex={'is_active'}
            align='right'
            render={(_, record) => (
              <>
                <Space>
                  <Tooltip title="Edit">
                    <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                  </Tooltip>

                  <Tooltip title="Delete">
                    <Button shape="circle" loading={isLoadingUpdate} onClick={() => showDeleteConfirm(record)} icon={<DeleteOutlined />} />
                  </Tooltip>
                  <Tooltip title="Is Active">
                    <Switch
                      defaultChecked={record.is_active}
                      checked={record.is_active}
                      loading={isLoadingUpdate}
                      onChange={(isChecked) => {
                        mutateUpdateItem({
                          id: record.id,
                          flag: "SPORTS_CATEGORY_SET_STATUS",
                          status: isChecked,
                        });
                        record.is_active = isChecked;
                      }}

                    />
                  </Tooltip>
                </Space>
              </>
            )}
          />
        </Table>
      </StyledTableWrapper>
    </>

  );
};
