import {
  useQuery,
} from '@tanstack/react-query';
import { DashboardApiService } from 'features/dashboard/api/dashboard-api.services';

import { listDataType } from '../components/type';


const { getEvents, getDashboardGraphData } = DashboardApiService();


export const useGetEvents = (flag: string) => {

  return useQuery(['dashboardEventList', flag], () => getEvents(flag));

};

export const useGetParticipantData = (eventId: number, categoryId: number, flag: string) => {

  return useQuery<listDataType>(['useGetParticipantGraphData', eventId, categoryId, flag], () => getDashboardGraphData(eventId, categoryId, flag));

};


export const useGetGenderData = (eventId: number, categoryId: number, flag: string) => {

  return useQuery<listDataType>(['useGetParticipantGraphData', eventId, categoryId, flag], () => getDashboardGraphData(eventId, categoryId, flag));

};



