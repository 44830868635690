import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { VolunteerUserFilterFormValues } from 'features/users/components/users-filter-form/types';
import { VolunteerUsersFilterForm } from 'features/users/components/users-filter-form/volunteer-filter-form';
import { useState } from 'react';
import { CompanyType } from 'types';

import { AddVolunteerUserModal } from '../modals/add-volunteer-user-modal';

const { useBreakpoint } = Grid;

type UsersTopBarProps = {
  getInitialFilterValues: VolunteerUserFilterFormValues;
  handleFilter: (values: VolunteerUserFilterFormValues) => void;
  volunteerEditData: any;
};

export const VolunteerUsersTopBar = ({
  getInitialFilterValues,
  handleFilter,
  volunteerEditData,
}: UsersTopBarProps) => {
  useState<CompanyType>();
  const formId = 'end-users-filters-form';
  const [showAppUserModal, setShowAppUserModal] = useState(false);


  const screens = useBreakpoint();



  const handleCloseModal = () => {
    setShowAppUserModal(false);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = () => {
    setShowAppUserModal(true);
    document.body.style.overflow = 'hidden';
  }



  return (
    <>
      <div className={`cstmrightmdl ${showAppUserModal && "show"}`}>
        <AddVolunteerUserModal onClose={handleCloseModal} volunteerEditData={volunteerEditData} />
      </div>

      <ContentTopBar title={'Volunteer List'}
        renderButtons={
          <>
            <Button
              icon={!screens.xs ? <PlusOutlined /> : undefined}
              type='primary'
              onClick={handleOpenModal}
            >
              Add Volunteer
              {/* {t('adminUsers.addNewUser')} */}
            </Button>
          </>
        }
      />
      <ContentFilters formId={formId}>
        <VolunteerUsersFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
