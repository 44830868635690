import { AgentDetail } from 'features/users/components/top-bar/agent-detail-top-bar';
import { useParams } from 'react-router-dom';

export const AgentDetailView = () => {
    
  const { id } = useParams();
  if (!id) return null;

  return (
    <>
      <AgentDetail  id={id} />   
    </>
  );
};
