import EventDisclaimerEditor from 'features/events/components/event-details/event-disclaimer-editor/event-disclaimer-editor';
import { useState } from 'react';

export const DisclaimerComponent = () => {

  const [edit, setEdit] = useState<boolean>(false);



  return (
    <>

      <EventDisclaimerEditor edit={edit} setEdit={setEdit} />
    </>
  );
};



