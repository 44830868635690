import 'react-quill/dist/quill.snow.css';

import { Button } from "antd";
import { EventsApiService } from 'features/events/api/events-api.service';
import { useCreateEmailContent } from 'features/events/use-cases/create-email-content';
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useParams } from 'react-router-dom';


const EmailContent = () => {
    const [code, setCode] = useState<string>("");
    const params = useParams();
    const handleProcedureContentChange = (
        content: React.SetStateAction<string>,
        // delta: any,
        // source: any,
        // editor: any
    ) => {
        setCode(content);
    };

    const { mutate, isLoading } = useCreateEmailContent();

    const formData: any = {
        event_id: params.id ? +params.id : 0,
        description: code
    }

    const handleUpdate = () => {
        mutate(formData);
    };

    const getDisclaimer = async () => {
        const apires = await EventsApiService().getEventEmailContent({ 'event_id': params.id } as any);
        setCode(apires?.description);
    };


    useEffect(() => {
        getDisclaimer();
    }, [])


    return (

        <>

            <ReactQuill
                className="editor-large"
                theme="snow"
                value={code || ''}
                onChange={handleProcedureContentChange}
                modules={EmailContent.modules}
                formats={EmailContent.formats}
                // dangerouslySetInnerHTML={{ __html: code }}
                // readOnly={!edit} 
                style={{ marginBottom: 10 }}
            />
            <Button loading={isLoading}
                onClick={handleUpdate} type='primary'
            >Save</Button>
        </>


    );
};

EmailContent.modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, false] }], // Select dropdown for heading
        [{ 'font': [] }], // Select dropdown for font size
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'align': [] }], // Select dropdown for text alignment
        [{ 'color': [] }, { 'background': [] }], // Select dropdowns for text and background color
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link'],
        ['clean']
    ],
    clipboard: {
        matchVisual: true,
    }
};

EmailContent.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'align', 'color', 'background'
];



export default EmailContent;




