import { PaginatedRequestParams } from 'api';
import { MainFiltersParams } from 'components/main-filters/main-filters';

export enum ManageFilterFormField {
  FieldName = 'link',
}

export type ManageFilterFormValues = {
  [ManageFilterFormField.FieldName]: string | undefined;
};

export type ManageFilterFormPaginated = ManageFilterFormValues &
  MainFiltersParams &
  PaginatedRequestParams;
