import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { queryClient } from 'providers';

import { CMSApiService } from '../api/cms-api.service';
import { eventsQueryKeys } from './query-keys';

const { createDiscoverBanner } = CMSApiService();

export const useCreateDiscoverBanner = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: any) => {
            return createDiscoverBanner(payload);
        },
        {
            onError: (error: any) => {
                let uiMessage = handleError(error);
                if (error.response && error.response.data && error.response.data.ResponseMessage) {
                    uiMessage = error.response.data.ResponseMessage;
                }

                message.error(uiMessage);
            },
            onSuccess: (data) => {
                const { ResponseMessage } = data;
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
                if (data.ResponseStatus == 0) {
                    message.error(ResponseMessage)
                } else {
                    message.success("Discover Banner added successfully");
                }
            },
        },
    );
};
