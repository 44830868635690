import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { EventsApiService } from 'features/events';
import { queryClient } from 'providers';

import { eventsQueryKeys } from './query-keys';

const { updateBoothDetails } = EventsApiService();

export const useUpdateBoothDetails = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: any) => {
            return updateBoothDetails(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: (res: any) => {
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
                if (res?.ResponseStatus === 1) {
                    message.success(res?.ResponseMessage);
                } else {
                    message.error(res?.ResponseMessage);
                }

            },
        },
    );
};
