import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Grid, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tag, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import {
    EventsResponse,
} from 'features/events';
import { useStatusResponse } from 'features/shop-item/use-cases/update-status-response-msg';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';
import { Event } from 'types/event';

import { ColorFilterFormPaginated } from '../type';
import { AddColorModal } from './Add-color-madal/add-color-modal';
const { useBreakpoint } = Grid;
type EventsTableProps = {
    isLoading: boolean;
    data?: EventsResponse;
    tableParams: ColorFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<ColorFilterFormPaginated>>;
    refetch: () => void;
    colorEditData: any;
    setColorEditData: any;
};
export const ColorTabel = ({
    data,
    isLoading,
    tableParams,
    updateParams,
    refetch,
    colorEditData,
    setColorEditData
}: EventsTableProps) => {
    const screens = useBreakpoint();
    const permissions = usePermissions();
    const { mutate: mutateUpdateItem, isLoading: isLoadingUpdateSocial } =
        useStatusResponse();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        sorter: SorterResult<Event> | SorterResult<Event>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: Array.isArray(sorter)
                ? undefined
                : sorter.order === 'ascend'
                    ? 'name'
                    : '-name',
        });
    };
    const [showColorModal, setShowColorModal] = useState(false);

    const { confirm } = Modal;
    const handleCloseModal = () => {
        setShowColorModal(false);
        setColorEditData(undefined);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = (record: any) => {
        setShowColorModal(true);
        setColorEditData(record);
        document.body.style.overflow = 'hidden';
    }

    if (!permissions) return null;

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: 'Are you sure you want to delete this Color?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record.id);
            },
        });
    };
    const handleDelete = async (id: any) => {
        mutateUpdateItem({
            id: id,
            flag: "COLORS_DELETE",
            status: true
        });
    };

    return (
        <>
            <div className={`cstmrightmdl ${showColorModal && "show"}`}>
                <AddColorModal showColorModal={showColorModal} refetch={refetch} onClose={handleCloseModal} colorEditData={colorEditData} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={"Color found"}
                        count={data?.total || 0}
                    />
                </StyledTableHeader>
                <Table<Event>

                    className="cstltblwthldr"
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading && { indicator: <div><TableLoader /></div> }}
                    dataSource={isLoading ? [] : data?.items}

                    rowKey='id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.page,
                        defaultPageSize: tableParams.size,
                        total: data?.total,
                    }}
                >
                    <Column<Event>
                        title={'Title'}
                        dataIndex={'title'}
                        width={screens.xxl ? 'auto' : 200}
                    />
                    <Column<any>
                        title={'Ar Title'}
                        dataIndex={'title_arabic'}
                        width={screens.xxl ? 'auto' : 120}

                    />

                    <Column<any>
                        title={'Color code'}
                        dataIndex={'color_code'}
                        width={screens.xxl ? 'auto' : 120}
                        render={(_, record: any) => (
                            <Tag color={record.color_code} style={{ width: 25, height: 15 }}></Tag>
                        )}

                    />

                    <Column<Event>
                        title={"Action"}
                        width={screens.xxl ? 'auto' : 162}
                        dataIndex={'is_active'}
                        render={(_, record) => (
                            <>
                                <Space>
                                    <Tooltip title="Edit">
                                        <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenModal(record)} />
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <Button shape="circle" loading={isLoadingUpdateSocial} onClick={() => showDeleteConfirm(record)} icon={<DeleteOutlined />} />
                                    </Tooltip>
                                    <Tooltip title="Is Active">
                                        <Switch
                                            defaultChecked={record.is_active}
                                            checked={record.is_active}
                                            loading={isLoadingUpdateSocial}
                                            onChange={(isChecked) =>
                                                mutateUpdateItem({
                                                    id: record.id,
                                                    flag: "COLORS_SET_STATUS",
                                                    status: isChecked,
                                                })
                                            }
                                        />
                                    </Tooltip>
                                </Space>
                            </>
                        )}
                    />
                </Table>
            </StyledTableWrapper>
        </>

    );
};

