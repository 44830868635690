import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { ContentFilters, ContentTopBar } from 'components';
import { ItemSalesFilterFormValues } from 'features/users/components/users-filter-form/types';
import { useState } from 'react';
import { CompanyType } from 'types';

import { ItemSalesFilterForm } from '../item-sales-filter-form/item-sales-filter-form';
import { ParticipantReportModal } from './participant-report-filter/participant-report-modal';

type ParticipateTopBarProps = {
    getInitialFilterValues: ItemSalesFilterFormValues;
    handleFilter: (values: ItemSalesFilterFormValues) => void;
};



export const ItemSalesTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: ParticipateTopBarProps) => {
    useState<CompanyType>();

    const formId = 'item-sales-filters-form';
    const [exportModal, setExportModal] = useState(false);
    const [modalType] = useState("ItemSalesReport");


    const openExportModal = () => {
        setExportModal(true);
        document.body.style.overflow = 'hidden';
    }

    const closeExportModal = () => {
        setExportModal(false);
        document.body.style.overflow = 'visible';
    }

    return (

        <>
            <ContentTopBar
                title={'Item sales List'}
                renderButtons={
                    <>
                        <Tooltip title={"Export"}>
                            <Button
                                icon={<FileExcelOutlined />}
                                type='primary'
                                onClick={openExportModal}
                            />
                        </Tooltip>
                    </>
                }

            />
            <ContentFilters formId={formId}>
                <ItemSalesFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>

            <div className={`cstmrightmdl ${exportModal && "show"}`}>
                <ParticipantReportModal
                    closeModal={closeExportModal}
                    isOpen={exportModal}
                    modalType={modalType}
                />
            </div>
        </>
    );
};
