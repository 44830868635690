import { Card, Row } from 'antd';
import Background from 'assets/main_login.png';
import styled from 'styled-components';



export const StyledBackground = styled(Row)`  
  background-image: url(${Background});  
`;

export const StyledCard = styled(Card)``;
export const StyledRow = styled(Row)``;
