import { Empty, Skeleton, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
    StyledTableHeader,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import { ItemSalesResponse } from 'features/transaction_list/api/transection-api.service';
import { formateAmount } from 'features/transaction_list/utills/utiils';
import { usePermissions } from 'features/users';
import { EndUserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { User } from 'types';

type ParticipateTableProps = {
    isSocialListLoading: boolean;
    DeliveryList: any;
    refetch: any,
    tableParams: EndUserFilterFormValuesPaginated;
    updateParams: Dispatch<SetStateAction<EndUserFilterFormValuesPaginated>>;
};

export const ItemSalesTable = ({
    DeliveryList,
    isSocialListLoading,
    tableParams,
    updateParams,
}: ParticipateTableProps) => {

    const permissions = usePermissions();
    const navigate = useNavigate()

    // const handleOpenModal = (record: any) => {
    //     console.log(record, "record");
    //     setDeliveryEdit(true);
    // }

    if (!permissions) return null;

    const handleTableChange = (
        pagination: TablePaginationConfig,
        sorter: SorterResult<User> | SorterResult<User>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: Array.isArray(sorter)
                ? undefined
                : sorter.order === 'ascend'
                    ? 'name'
                    : '-name',
        });
    };




    return (
        <>
            {/* Your modal component */}
            <StyledTableHeader>
                <div style={{ marginTop: '32px', color: 'black' }}>
                    <RecordCountBadge
                        text={"Item sales found"}
                        count={DeliveryList?.items?.length || 0}
                    />
                </div>
            </StyledTableHeader>
            <Table<ItemSalesResponse>
                className='cstltblwthldr scrl_tble pointer'
                locale={{
                    emptyText: isSocialListLoading ? <Skeleton active={true} /> : <Empty />
                }}
                loading={isSocialListLoading && { indicator: <div><TableLoader /></div> }}
                dataSource={isSocialListLoading ? [] : DeliveryList?.items}
                rowKey='booking_id'
                onChange={handleTableChange}
                pagination={{
                    current: tableParams?.page,
                    defaultPageSize: tableParams?.size,
                    total: DeliveryList?.total,
                }}
                scroll={{ x: 1440 }}
                onRow={(record: any) => {
                    return {
                        onClick: () => {
                            const selection = window?.getSelection()?.toString();

                            if (!selection?.length) {
                                navigate(getRoute(ROUTES.TRANSACTION__ITEM_SALES_DETAILS, record?.booking_id));
                            }
                        },
                    };
                }}
            >
                <Column<ItemSalesResponse>
                    title={'Invoice'}
                    dataIndex={'invoice_no'}
                    sorter={true}
                    sortDirections={['ascend', 'descend', 'ascend']}
                    defaultSortOrder={'ascend'}
                />
                <Column<ItemSalesResponse>
                    title={'Customer Details'}
                    className='no_wrap'
                    render={(_, record: any) => (
                        <>
                            <h1>{record.first_name} ,  {record.last_name} ,  {record.phone_number}
                                ,  {record.email_address}
                            </h1>
                        </>
                    )}
                />


                <Column<ItemSalesResponse>
                    title={'Total Amount'}
                    className='no_wrap'
                    render={(_, record: any) => (
                        <>
                            <h1>
                                {record?.total_cost && formateAmount(record.total_cost)}
                            </h1>
                        </>
                    )}
                />
                <Column<ItemSalesResponse>
                    title={'Order Date'}
                    className='no_wrap'
                    dataIndex={'order_date'}
                />
                <Column<ItemSalesResponse>
                    title={'Payment Type'}
                    className='no_wrap'
                    dataIndex={'payment_method'}
                />
                <Column<ItemSalesResponse>
                    title={'Payment Status'}
                    className='no_wrap'
                    dataIndex={'payment_status'}
                />
                <Column<ItemSalesResponse>
                    title={'Shop Coupon Applied'}
                    className='no_wrap'
                    render={(_, record: any) => (
                        <>
                            <h1>{record.item_coupon_code ? "Yes" : "No"}
                            </h1>
                        </>
                    )}
                />
                <Column<ItemSalesResponse>
                    title={'Shop Coupon value'}
                    className='no_wrap'
                    render={(_, record: any) => (
                        <>
                            <h1>
                                {record?.item_coupon_code_price && formateAmount(record.item_coupon_code_price)}
                            </h1>
                        </>
                    )}
                />
                <Column<ItemSalesResponse>
                    title={'Shop Coupon Code'}
                    className='no_wrap'
                    dataIndex={'item_coupon_code'}
                />
                <Column<ItemSalesResponse>
                    title={'Delivery Status'}
                    className='no_wrap'
                    dataIndex={'item_delivery'}
                />

                {/* <Column
                    title={t('shops.category.table.action')}
                    render={(_, record: any) => (
                        <Space>
                            <Tooltip title="is active">
                                <Switch
                                    defaultChecked={record.is_active}
                                    checked={record.is_active}
                                    onClick={(checked, event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                    }}
                                />
                            </Tooltip>
                        </Space>
                    )}
                /> */}
            </Table>
        </>
    );
};
