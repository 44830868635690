import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { queryClient } from 'providers';

import { EventsApiService } from '../api/events-api.service';
import { eventsQueryKeys } from './query-keys';

const { AddSponser } = EventsApiService();

export const useAddSponser = (id: any) => {
    const { message } = App.useApp();

    return useMutation(
        (payload: any) => {
            return AddSponser(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
                if (id) {
                    message.success("Sponsor has been updated successfully!!");
                } else {
                    message.success("Sponsor has been created successfully!!");
                }
            },
        },
    );
};
