
import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Pagination } from "antd";
import { StyledPaginationWrapper } from "components";
import { PageLoader } from "components/page-loader/page-loader";
import { useEventDeleteStatus } from "features/events/use-cases/event-delete-status";
import { useGetEventResultPdf } from "features/events/use-cases/get-event-resul-pdf";
import { usePaginationParams } from "hooks";
import { useEffect, useState } from "react";

import logo from "../../../../assets/icons/pdf_icon.png"
import { EventsFilterFormValues } from "../events-filter-form";
import { AddResultPdfModal } from "./add-result-pdf-modal";

const ResultsPDFComponent = ({ id }: { id: string }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShowModal = () => {
    setShowModal(true);
    document.body.style.overflow = 'hidden';
  }

  const handleCloseModal = () => {
    setShowModal(false);
    document.body.style.overflow = 'visible';
  }

  const initialFilterValues: EventsFilterFormValues = {
    field_name: undefined,
  };
  const [page, setPage] = useState<any>(1);
  const [totalPage, setTotalPage] = useState<any>(0);
  const [currentPage, setCurrentPage] = useState<any>(1);

  // Get initial params
  const initialParams = {
    event_id: id,
    order_by: '-rank',
    size: 12,
    ...initialFilterValues,
  };

  const { params, setParams } = usePaginationParams<any>({
    ...initialParams,
    page: page,
  });


  useEffect(() => {
    setParams({ ...params, page: page });
  }, [page]);


  const { data, isLoading, refetch } = useGetEventResultPdf(params);

  useEffect(() => {
    setTotalPage(data?.total);
    setCurrentPage(data?.page)
  }, [data])

  useEffect(() => {
    refetch();
  }, [params]);


  const handleChangePagination = (val: any) => {
    setPage(val);
  }


  const handleDownload = (imageUrl: string) => {
    window.open(imageUrl, '_blank');
  };


  const { mutate } = useEventDeleteStatus();

  const { confirm } = Modal;

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: 'Are you sure you want to delete this Ticket?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(record);
      },
    });
  };


  const handleDelete = (record: any) => {
    const deletedFormData = {
      id: record?.id,
      status: false,
      flag: "EVENT_RESULTPDF_DELETE  "
    }
    mutate(deletedFormData, {
      onSuccess: () => refetch(),
    });
  }

  if (isLoading) return <PageLoader />;


  return (

    <>
      <div className={`cstmrightmdl upld_modl ${showModal && "show"}`}>
        <AddResultPdfModal onClose={handleCloseModal} showModal={showModal} id={id} refetch={refetch} />
      </div>

      <div className="rslt_m">
        <div className="rslt_mdl_head">
          <h5>Result PDF List</h5>
          <Button type="primary" onClick={handleShowModal} icon={<PlusOutlined />}>
            Add Result PDF</Button>
        </div>
        <div className="rslt_mdl_bdy">
          <div className="pdf_list">
            {data?.items?.map((item: any, i: number) => (
              <div key={i} className="pdf_bx" style={{ cursor: "pointer" }}>
                <img src={logo} alt="" onClick={() => handleDownload(item.image_url)} />
                <p>{item?.image_url?.slice(item?.image_url?.length - 20)}</p>
                <Button type="primary" onClick={() => showDeleteConfirm(item)}>Remove <DeleteOutlined /> </Button>
              </div>
            ))}
          </div>
        </div>

        <StyledPaginationWrapper>
          < Pagination
            total={totalPage} // Total number of items
            pageSize={12} // Number of items per page
            current={currentPage} // Current page number
            onChange={handleChangePagination} // Function to handle page change
          />
        </StyledPaginationWrapper>

      </div>

    </>
  )
};

export default ResultsPDFComponent;
