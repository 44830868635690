import {
    DownOutlined,
    IdcardOutlined,
} from '@ant-design/icons';
import { DatePicker, Form, Input, message } from 'antd';
import { Select } from 'components';
import dayjs, { Dayjs } from 'dayjs';
import { EventsApiService } from 'features/events/api/events-api.service';
import { validateAmount } from 'features/events/utils';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DiscountFormField } from './types';



export const AddDiscountForm = ({ id, showAddPriceModal }: { id: any; editId: any; showAddPriceModal: boolean }) => {
    const { t } = useTranslation();

    const [priseType, setPriseType] = useState({
        items: []
    })


    const getPriseTypeType = async () => {
        try {
            const apires = await EventsApiService().sportCategoryId(`Flag=${'PriceType'}` as any)
            setPriseType(apires as any)
        } catch (error) {
            message.error('')
        }
    }

    useEffect(() => {
        getPriseTypeType();
    }, [])


    const [category, setCategory] = useState({
        items: []
    })


    const getCategory = async () => {
        try {
            const apires = await EventsApiService().sportCategoryId(`Flag=${'EventCategory'}&&CascadeId=${id}` as any)
            setCategory(apires as any)
        } catch (error) {
            message.error('')
        }
    }

    useEffect(() => {
        if (showAddPriceModal) {
            getCategory();
        }
    }, [showAddPriceModal])

    const eventDetailsString = localStorage.getItem("event_details")
    const eventDetails = eventDetailsString ? JSON.parse(eventDetailsString) : [];

    const disabledDate = (current: Dayjs) => {
        const minDate = dayjs(eventDetails.StartDate);
        const maxDate = dayjs(eventDetails.EventDate);

        return current && (current.isBefore(minDate) || current.isAfter(maxDate));
    };

    const handleKeyAmount = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const input = event.currentTarget as HTMLInputElement;
        const currentValue = input.value;
        const selectionStart = input.selectionStart ?? 0;
        const selectionEnd = input.selectionEnd ?? 0;

        if (event.key === 'Backspace') {

            return;
        }

        if (!/[0-9.]/.test(event.key)) {
            event.preventDefault();

            return;
        }

        const dotIndex = currentValue.indexOf('.');
        const hasDecimal = dotIndex !== -1;

        const [integerPart, decimalPart] = currentValue.split('.');

        if (!hasDecimal && integerPart.length >= 4 && selectionStart <= dotIndex) {
            event.preventDefault();

            return;
        }

        if (hasDecimal && selectionStart > dotIndex && decimalPart?.length >= 3) {
            event.preventDefault();

            return;
        }

        if (event.key === '.' && hasDecimal) {
            event.preventDefault();

            return;

        }

        if (integerPart.length >= 4 && !hasDecimal && event.key !== '.' && selectionStart === selectionEnd) {
            event.preventDefault();

            return;
        }
    };

    return (
        <FormSectionsSeparator title={""}>
            <Form.Item<DiscountFormField>
                label={"Category"}
                name={DiscountFormField.CategoryId}
                rules={[
                    {
                        required: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Select
                    options={category?.items?.map((res: any) => ({ label: res.Text, value: res.Id }))}
                    placeholder={"Select Category"}
                    className='has-prefix-icon'
                    suffixIcon={
                        <>
                            <IdcardOutlined className='prefix-icon' />
                            <DownOutlined className='suffix-icon' />
                        </>
                    }
                />
            </Form.Item>

            <Form.Item<DiscountFormField>
                label={"Price Type"}
                name={DiscountFormField.PriceType}
                rules={[
                    {
                        required: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Select
                    options={priseType?.items?.map((res: any) => ({ label: res.Text, value: res.Id }))}
                    placeholder={"Select Price Type"}
                    className='has-prefix-icon'
                    suffixIcon={
                        <>
                            <IdcardOutlined className='prefix-icon' />
                            <DownOutlined className='suffix-icon' />
                        </>
                    }
                />
            </Form.Item>

            <Form.Item<DiscountFormField>
                label={"Date From"}
                name={DiscountFormField.DateFrom}
                rules={[
                    {
                        required: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <DatePicker style={{ width: '100%' }}
                    disabledDate={disabledDate}
                />
            </Form.Item>


            <Form.Item<DiscountFormField>
                label={"Date To"}
                name={DiscountFormField.DateTo}
                rules={[
                    {
                        required: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <DatePicker style={{ width: '100%' }}
                    disabledDate={disabledDate}
                />
            </Form.Item>

            <Form.Item
                label="Individual Price"
                name={DiscountFormField.IndividualPrice}
                rules={[
                    {
                        required: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                    {
                        validator: validateAmount
                    }
                ]}
            >
                <Input
                    placeholder={"0000.000"}
                    onKeyDown={handleKeyAmount}
                />
            </Form.Item>

            <Form.Item
                label="Team Price"
                name={DiscountFormField.TeamPrice}
                rules={[
                    {
                        required: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                    {
                        validator: validateAmount
                    }
                ]}
            >
                <Input
                    placeholder={"0000.000"}
                    onKeyDown={handleKeyAmount}
                />
            </Form.Item>

        </FormSectionsSeparator>
    );
};

