export enum CouponFormField {
    Id = 'id',
    Title = 'title',
    CouponType = 'coupon_type',
    couponCode = 'coupon_code',
    ValueType = 'value_type',
    Value = 'value',

    StartDate = 'start_date',
    EndDate = 'end_date',
    EventId = 'event_id',

    NoOfCoupons = 'no_of_coupons',
    Limit = 'limit',
    Prefix = 'prefix',
    IsActive = 'is_active',
    Flag = 'flag',
}


export type Coupon = {
    Id?: string;
    Title: string;
    CouponType: string;
    couponCode: string;
    ValueType: string;
    Value: string;

    StartDate: string;
    EndDate: string;
    EventId: string;
    ParticipantId: string;

    NoOfCoupons: number;
    Limit: number;
    Prefix: string;

    IsActive: boolean;
    Flag: string;
};

export type CouponFormValues = {
    [CouponFormField.Id]: string | undefined;
    [CouponFormField.Title]: string;
    [CouponFormField.CouponType]: string;
    [CouponFormField.couponCode]: string;

    [CouponFormField.ValueType]: string;
    [CouponFormField.Value]: string;
    [CouponFormField.StartDate]: string;
    [CouponFormField.EndDate]: string;
    [CouponFormField.EventId]: string;

    [CouponFormField.Limit]: number;
    [CouponFormField.NoOfCoupons]: number;
    [CouponFormField.Prefix]: string;
    [CouponFormField.IsActive]: boolean;
    [CouponFormField.Flag]: string;
};