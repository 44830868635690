import { Form, Select } from 'antd';

import { ParticipantFilterTopBarProps } from '../type';


export const CategoryEventTopBar = (
  {
    handleFilter,
    eventList,
    sportCategory,
    formId,
    graphType
  }: ParticipantFilterTopBarProps
) => {
  const [form] = Form.useForm(); //<EndUserFilterFormValues>

  const onFinish = (value: any) => {
    handleFilter(value, graphType);
  }

  // const getInitialValue = (params: Params): InitialValue => {
  //   switch (params.type) {
  //     case 'string':
  //       return params.defaultValue || '';
  //     case 'number':
  //       return params.defaultValue || 0;
  //     case 'boolean':
  //       return params.defaultValue || false;
  //     case 'array':
  //       return params.defaultValue || [];
  //     case 'object':
  //       return params.defaultValue || {};
  //     default:
  //       return params.defaultValue || null;
  //   }
  // };

  // useEffect(()=>{
  //   console.log('getInitialFilterValues'+JSON.stringify(getInitialFilterValues));
  // },[]);

  const handleSelectChange = () => {
    // Manually trigger the form submission
    form.submit();
  };

  if (!sportCategory) return null;
  if (!eventList) return null;

  return (<>
    <div className='dash_gph_head'>
      <h4></h4>
      <div className='filter_dash'>
        <Form
          id={formId}
          name={formId}
          className='filters-form'
          form={form}
          layout='horizontal'
          onFinish={onFinish}
        //initialValues={getInitialFilterValues}
        // onFinishFailed={() => {
        // // setValidateTrigger(['onChange']);
        // }}
        //validateTrigger={validateTrigger}
        >


          <Form.Item name={'participant_category'}>
            <Select
              //defaultValue="Crossfit"
              placeholder="Category"
              onChange={handleSelectChange}
              // style={{ width: 120 }}
              className='form_item'
              options={[
                { label: "All", value: 0 },
                ...sportCategory.items.map((res: any) => ({
                  label: res.Text,
                  value: res.Id,
                })),
              ]}
            />
          </Form.Item>

          <Form.Item name={'participant_event'}>
            <Select
              // defaultValue="Events"
              placeholder="Events"
              onChange={handleSelectChange}
              // style={{ width: 120 }}
              className='form_item'
              options={[
                { label: "All", value: 0 },
                ...eventList.items.map((res: any) => ({ label: res.Text, value: res.Id }))
              ]}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  </>);
}