import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { EventsApiService } from 'features/events';
import { queryClient } from 'providers';

import { CampaignCodeDTO } from '../components/event-details/event-marketing/EventCampaign/add-campaign-code-modal/types';
import { eventsQueryKeys } from './query-keys';

const { createCampignCode } = EventsApiService();

export const useCreateCampignCode = (id: any) => {
    const { message } = App.useApp();

    return useMutation(
        (payload: CampaignCodeDTO) => {
            return createCampignCode(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: eventsQueryKeys.all,
                });
                if (id) {
                    message.success("Campaign Code has been updated successfully!!");
                } else {
                    message.success("Campaign Code has been created successfully!!");
                }
            },
        },
    );
};
