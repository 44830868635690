
import smalllogo from 'assets/logo2.png';
import { StyledWrapper } from 'components/full-screen-loader/full-screen-loader.styles';


export const TableLoader = () => {

    return (
        <StyledWrapper align='center'>

            <div className='cstmtblldr'>
                <div className='nlbx'>
                    <span className='call'><img src={smalllogo} alt="" /></span>
                    <span className='call'>
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'>
                            <circle fill='#ef4023' stroke='#ef4023' strokeWidth='15' r='15' cx='40' cy='100'><animate attributeName='opacity' calcMode='spline' dur='2' values='1;0;1;' keySplines='.5 0 .5 1;.5 0 .5 1' repeatCount='indefinite' begin='-.4'></animate></circle><circle fill='#ef4023' stroke='#ef4023' strokeWidth='15' r='15' cx='100' cy='100'><animate attributeName='opacity' calcMode='spline' dur='2' values='1;0;1;' keySplines='.5 0 .5 1;.5 0 .5 1' repeatCount='indefinite' begin='-.2'></animate></circle><circle fill='#ef4023' stroke='#ef4023' strokeWidth='15' r='15' cx='160' cy='100'><animate attributeName='opacity' calcMode='spline' dur='2' values='1;0;1;' keySplines='.5 0 .5 1;.5 0 .5 1' repeatCount='indefinite' begin='0'></animate></circle></svg>
                    </span>
                </div>
            </div>

        </StyledWrapper>


    );
};
