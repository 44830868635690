import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ContentTopBar } from 'components';
import { useGetOrganizer } from 'features/events/use-cases/get-organizer';
import { usePermissions } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import { EventsFilterFormValues } from '../events-filter-form';
import { AddOrganizerModal } from './add-organizer-modal';
import { OrganizerTable } from './organizer-table';


const Organizer = ({ tabs }: { tabs: string }) => {
    const { id } = useParams();
    const initialFilterValues: EventsFilterFormValues = {
        field_name: undefined,
    };

    const permissions = usePermissions();
    const { params, updateParams } =
        usePaginationParams<any>({
            page: 1,
            size: 10,
            order_by: '',
            event_id: id,
            ...initialFilterValues,
        });

    const { data, isFetching, refetch } = useGetOrganizer(params);


    const shouldHandleFieldCreation = permissions?.fields.add;

    const [showAddEventModal, setShowAddEventModal] = useState<boolean>(false);
    const [organizerData, setOrganizerData] = useState<any>("");

    const handleCloseModal = () => {
        setShowAddEventModal(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpen = () => {
        setShowAddEventModal(true);
        document.body.style.overflow = 'hidden';
    }

    useEffect(() => {
        if (tabs === "1") {
            refetch();
        }
    }, [tabs]);


    return (

        <>

            <ContentTopBar
                title={""}
                renderButtons={
                    <>
                        {shouldHandleFieldCreation ? (
                            <>
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={handleOpen}
                                >
                                    Add Organizer
                                </Button>

                            </>
                        ) : null}
                    </>
                }
            />
            <OrganizerTable
                data={data}
                isLoading={isFetching}
                tableParams={params}
                updateParams={updateParams}
                organizerData={organizerData}
                setOrganizerData={setOrganizerData}
                refetch={refetch}
            />

            <div className={`cstmrightmdl ${showAddEventModal && "show"}`}>
                {<AddOrganizerModal showAddEventModal={showAddEventModal} refetch={refetch} onClose={handleCloseModal} organizerData={organizerData} />}
            </div>
        </>
    )
}

export default Organizer;