import { FileExcelOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { Button, ContentFilters } from 'components';
import { StyledHeadingWrapper, StyledTitleWrapper, StyledWrapper } from 'components/content-top-bar/top-content-bar.styles';
import { useState } from 'react';

import { InvoiceFilterForm } from '../invoice-filter-form/invoice-list-filter';
import { InvoiceFilterFormValues } from '../invoice-filter-form/types';
import { ParticipantReportModal } from './participant-report-filter/participant-report-modal';

type ParticipateTopBarProps = {
  getInitialFilterValues: InvoiceFilterFormValues;
  handleFilter: (values: InvoiceFilterFormValues) => void;
  history: string;
  setHistory: any;
  refetch: () => void;
};


export const InvoiceListTopBar = ({
  getInitialFilterValues,
  handleFilter,
  history,
  setHistory,
}: ParticipateTopBarProps) => {

  const formId = 'invoice-filters-form';
  const [exportModal, setExportModal] = useState(false);
  const [modalType] = useState("InvoiceReport");



  const handleNormal = () => {
    setHistory("Normal");
    // refetch()
  }

  const closeExportModal = () => {
    setExportModal(false);
  }


  return (

    <>


      <StyledWrapper className='cstm_head'>
        <StyledHeadingWrapper className='hed_ttl'>
          <StyledTitleWrapper className='hht_data'>
            <Typography.Title className='title'>
              <span className="btn" onClick={handleNormal}>Invoice List</span>
              {history === "History" &&
                <>
                  <span>/</span>
                  <span className='sub_m'>{history}</span>
                </>}
            </Typography.Title>
          </StyledTitleWrapper>
        </StyledHeadingWrapper>

        <Button
          icon={<FileExcelOutlined />}
          type='primary'
          onClick={() => setExportModal(true)}
        >
          Invoice Report
        </Button>

      </StyledWrapper>


      <div className={`cstmrightmdl ${exportModal && "show"}`}>
        <ParticipantReportModal
          closeModal={closeExportModal}
          isOpen={exportModal}
          modalType={modalType}
        />
      </div>



      <ContentFilters formId={formId}>
        <InvoiceFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
