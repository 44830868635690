import { Button, Form, Grid, Input, message, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { ShopApiService } from 'features/shop-item/api/shop-api.service';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ShopItemFormField, ShopItemFormFieldValue } from './type';
const { useBreakpoint } = Grid;
type UsersFilterFormProps = {
  formId: string;
  handleFilter: (values: ShopItemFormFieldValue) => void;
  initialValues: ShopItemFormFieldValue;
  closeModal?: () => void;
  refetch?: any
};

export const ItemFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: UsersFilterFormProps) => {

  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<ShopItemFormFieldValue>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [category, setCategory] = useState({
    items: []
  } as any)

  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues });
  };

  const onFinish = (formValues: ShopItemFormFieldValue) => {
    handleFilter(formValues as any);
    closeModal?.();
  };

  const initaialCategory = async () => {
    try {
      const apiRes = await ShopApiService().getCategory('' as any)
      setCategory(apiRes)
    } catch (error) {
      message.error("")
    }
  }
  const status = [
    {
      label: true,
      name: 'Active',
    },
    {
      label: false,
      name: 'Inactive',
    },
  ];


  const status_type = status.map((res) => ({
    value: res.label,
    label: res.name,
  }));

  const Category = category.items.map((res: any) => ({
    value: res.id,
    label: res.title,
  }));

  useEffect(() => {
    initaialCategory()
  }, [])

  return (

    <Form<ShopItemFormFieldValue>
      id={formId}
      name={formId}
      className='filters-form'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper style={{ width: '100%' }}>
        <StandardItemsWrapper style={{ width: '100%' }}>
          <div className='invoice_list' style={{ width: '100%' }}>
            <Form.Item<ShopItemFormFieldValue>
              label={t('shops.formfield.itemnamecategory.label')}
              name={ShopItemFormField.CATEGORY}
            >
              <Select
                options={Category}
                placeholder={"Please Select Category"}
                dropdownMatchSelectWidth={200}
                className='filter'
                allowClear
              />
            </Form.Item>
            <Form.Item<ShopItemFormFieldValue>
              // label={t('shops.formfield.fieldName.label')}
              label="Item Name"
              name={ShopItemFormField.item_name}
            >
              <Input placeholder='Enter item name' />
            </Form.Item>
            <Form.Item<ShopItemFormFieldValue>
              label={t('shops.formfield.itemnamestatus.label')}
              name={ShopItemFormField.is_active}
            >
              <Select
                options={status_type}
                placeholder={"Select Status"}
                dropdownMatchSelectWidth={200}
                className='filter'
                allowClear
              />
            </Form.Item>
            {screens.lg && (
              <ContentFilters.ButtonsWrapper className='filter_btn'>
                <Button type='primary' htmlType='submit'>
                  {t('common.buttons.filter', { count: 1 })}
                </Button>
                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
              </ContentFilters.ButtonsWrapper>
            )}
          </div>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

    </Form>
  );
};
