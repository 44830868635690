import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { EventsApiService } from 'features/events';
import { Event } from 'types/event';

import { eventsQueryKeys } from './query-keys';

const { getEvent } = EventsApiService();

const getEventQuery = (
  id: string,
  options?: UseQueryOptions<Event>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<Event>;
  options?: UseQueryOptions<Event>;
} => ({
  queryKey: eventsQueryKeys.detail(id),
  queryFn: ({ signal }) => getEvent(id, { config: { signal } }),
  ...options,
});

export const useGetEvent = (id: string, options?: UseQueryOptions<Event>) => {
  const { message } = App.useApp();

  return useQuery<Event, Error>(
    getEventQuery(id, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
