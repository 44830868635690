export enum ExpoBoothFilterFormField {
  FIRSTNAME = 'first_name',
  LASTNAME = 'last_name',
  EMAIL = 'email',
  PHONE = 'phone',
  EVENTNAME = 'event_name',
  INVOICENO = 'invoice_id',
  PARTICIPANT = 'invoice_no'
}
export type ExpoBoothFilterFormValues = {
  [ExpoBoothFilterFormField.FIRSTNAME]: string | undefined;
  [ExpoBoothFilterFormField.LASTNAME]: string | undefined;
  [ExpoBoothFilterFormField.EMAIL]: string | undefined;
  [ExpoBoothFilterFormField.PHONE]: string | undefined;
  [ExpoBoothFilterFormField.EVENTNAME]: string | undefined;
  [ExpoBoothFilterFormField.INVOICENO]: string | undefined;
  [ExpoBoothFilterFormField.PARTICIPANT]: string | undefined;
};