import { Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';

import { FaqFormField } from './type';
export const AddFaqForm = () => {
    return (

        <FormSectionsSeparator title={""}>
            <Form.Item<FaqFormField>
                label={"Question"}
                name={FaqFormField.question}

                rules={[
                    {
                        required: true,
                        whitespace: true,
                    },
                ]}
            >
                <Input
                    placeholder={"Question Name"}
                />
            </Form.Item>
            <Form.Item<FaqFormField>
                label={"Answer"}
                name={FaqFormField.answer}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                    },
                ]}
            >
                <TextArea rows={4} />
            </Form.Item>

        </FormSectionsSeparator>
    );
};
