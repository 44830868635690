import {
  DownOutlined,
  IdcardOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Col, DatePicker, Form, Input, Row } from 'antd';
import { Select } from 'components';
import { useGetNewDropdown } from 'features/events/use-cases/get-new-drodown';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useTranslation } from 'react-i18next';

import { EventFormField } from '../modals/types';



export const EventInfoFormItems = ({ sportsData, currencyData, form }: { isOpen: boolean, sportsData: any, currencyData: any; form: any }) => {
  const { t } = useTranslation();

  const masterParams: any = { Flag: "EventMaster" };

  const { data: evtMasterData } = useGetNewDropdown(masterParams, { retry: false });

  const dateFormat = 'DD-MM-YYYY';
  const timeFormat = 'HH:mm:ss';

  const validateDates = (getFieldValue: (arg0: EventFormField) => any) => ({
    validator() {
      const startDate = getFieldValue(EventFormField.StartDate);
      const endDate = getFieldValue(EventFormField.EndDate);

      if (startDate && endDate && startDate.isAfter(endDate)) {

        return Promise.reject(new Error('Registration End Date must be after or equal to Registration Start Date'));
      }

      return Promise.resolve();
    },
  });


  const validateEventDates = (getFieldValue: (arg0: EventFormField) => any) => ({
    validator() {
      const startDate = getFieldValue(EventFormField.StartDate);
      const endDate = getFieldValue(EventFormField.EndDate);
      const eventDate = getFieldValue(EventFormField.EventDate);

      if (startDate && eventDate && eventDate.isBefore(endDate)) {

        return Promise.reject(new Error('Event Date must be after or equal to Registration End Date'));
      }

      return Promise.resolve();
    },
  });



  return (
    <FormSectionsSeparator title={""}>
      <Form.Item<EventFormField>
        label={t('events.formEvents.eventMaster.label')}
        name={EventFormField.EventMaster}

        rules={[
          {
            required: true,
            // whitespace: true,
            message: t('validateMessages.custom.nomsg'),
          },
        ]}
      >
        <Select
          options={evtMasterData?.items.map((res: any) => ({ label: res.Text, value: res.Id }))}
          placeholder={t('events.formEvents.eventMaster.placeholder')}
          className='has-prefix-icon'
          allowClear
          suffixIcon={
            <>
              <IdcardOutlined className='prefix-icon' />
              <DownOutlined className='suffix-icon' />
            </>
          }
        />
      </Form.Item>
      <Form.Item<EventFormField>
        label={t('events.formEvents.name.label')}
        name={EventFormField.EventName}
        rules={[
          {
            required: true,
            whitespace: true,
            message: t('validateMessages.custom.nomsg'),
          },
        ]}
      >
        <Input
          placeholder={t('events.formEvents.name.placeholder')}
          prefix={<UserOutlined className='input-prefix-icon' />}
        />
      </Form.Item>

      <Form.Item<EventFormField>
        label={t('events.formEvents.nameArabic.label')}
        name={EventFormField.EventNameArabic}
        rules={[
          {
            required: true,
            whitespace: true,
            message: t('validateMessages.custom.nomsg'),
          },
        ]}
      >
        <Input
          placeholder={t('events.formEvents.nameArabic.placeholder')}
          prefix={<UserOutlined className='input-prefix-icon' />}
          // onKeyPress={handleKeyPress}
          // onPaste={handlePaste}
          autoComplete="off"
        />
      </Form.Item>

      <Row gutter={8}>
        <Col span={12}>

          <Form.Item<EventFormField>
            label={t('events.formEvents.sportsCategory.label')}
            name={EventFormField.SportsCategory}
            rules={[
              {
                required: true,
                message: t('validateMessages.custom.nomsg'),
              },
            ]}
          >
            <Select
              options={sportsData?.items.map((res: any) => ({ label: res.Text, value: res.Id }))}
              placeholder={t('events.formEvents.sportsCategory.placeholder')}
              className='has-prefix-icon'
              suffixIcon={
                <>
                  <IdcardOutlined className='prefix-icon' />
                  <DownOutlined className='suffix-icon' />
                </>
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item<EventFormField>
            label={t('events.formEvents.currency.label')}
            name={EventFormField.Currency}
            rules={[
              {
                required: true,
                message: t('validateMessages.custom.nomsg'),
              },
            ]}
          >
            <Select
              options={currencyData?.items.map((res: any) => ({ label: res.Text, value: res.Id }))}
              placeholder={t('events.formEvents.currency.placeholder')}
              className='has-prefix-icon'
              suffixIcon={
                <>
                  <IdcardOutlined className='prefix-icon' />
                  <DownOutlined className='suffix-icon' />
                </>
              }
            />
          </Form.Item>
        </Col>
      </Row>





      <Row gutter={8}>
        <Col span={12}>
          <Form.Item<EventFormField>
            label={"Registration Start Date"}
            name={EventFormField.StartDate}
            rules={[
              {
                required: true,
                message: t('validateMessages.custom.nomsg'),
              },
              // validateDates(form.getFieldValue),
            ]}
          >
            <DatePicker style={{ width: '100%' }} format={dateFormat} />
            {/* <Input type='date'
              onFocus={(e) => e.target.showPicker()}
            /> */}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item<EventFormField>
            label={"Registration End Date"}
            name={EventFormField.EndDate}
            rules={[
              {
                required: true,
                message: t('validateMessages.custom.nomsg'),
              },
              validateDates(form.getFieldValue),
            ]}
          >
            <DatePicker style={{ width: '100%' }} format={dateFormat}
            />
            {/* <Input type='date'
              onFocus={(e) => e.target.showPicker()}
            /> */}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item<EventFormField>
        label={"Event Date"}
        name={EventFormField.EventDate}
        rules={[
          {
            required: true,
            message: t('validateMessages.custom.nomsg'),
          },
          validateEventDates(form.getFieldValue),
        ]}
      >
        <DatePicker showTime style={{ width: '100%' }}
          format={`${dateFormat} ${timeFormat}`}
        />
        {/* <Input type="datetime-local"
          onFocus={(e) => e.target.showPicker()}
        /> */}

      </Form.Item>
    </FormSectionsSeparator>
  );
};
